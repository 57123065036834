import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HttpClient from "@httpClient";

import {
  Alert,
  Button,
  Container,
  Grid,
  Checkbox,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import Article from "@components/products/articles/Article";
import Filters from "@components/products/articles/Filters";
import ProductModal from "@components/products/articles/ProductModal";
import Loader from "@common/Loaders/LoaderList";
import SharedModal from "@shared/Modal";
import CustomModal from "@shared/CustomModal";
import useAlert from "@customHooks/useAlert";
import { hasValues } from "@utils";
import { ArticleApi } from "@api/ArticleApi";
import { SupplierProductApi } from "@src/apis";
import PopupAlert from "@contexts/PopupAlert";
import { useTranslation } from "react-i18next";
import { singularize } from "@src/utils/sharedHelper";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 20,
  width: "70%",
  background: "#FFFFFF",
  boxShadow: 24,
  p: 4,
};

const List = (props) => {
  const supplierProductSuggestionObject = JSON.parse(
    localStorage.getItem("supplierProductSuggestion"),
  );
  const addPopupAlert = useContext(PopupAlert);
  const { t } = useTranslation();

  const [originalArticles, setOriginalArticles] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [selectAllArticles, setSelectAllArticles] = useState(false);
  const [massDropDown, setMassDropDown] = useState(null);
  const [actionDropDown, setActionDropDown] = useState(null);
  const [massActionModalOpen, setMassActionModalOpen] = useState(false);
  const [massActionForm, setMassActionForm] = useState({});
  const [disabledMenu, setDisabledMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useAlert(null);
  const [selectedArticle, setSelectedArticle] = useAlert(null);
  const [modalContent, setModalContent] = useState("");
  const [suggestionProducts, setSuggestionProducts] = useState([]);
  const [suggestedProduct, setSuggestedProduct] = useState(
    supplierProductSuggestionObject?.suggestedProduct,
  );

  const apiType = props.match.path.includes("supplier-products")
    ? "supplier_products"
    : "products";
  const supplierProductsView = props.match.path.includes("supplier-products");

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      Promise.all([
        ArticleApi.index(props.match.params.id, apiType),
        supplierProductsView
          ? SupplierProductApi.getSuggestedProducts(props.match.params.id)
          : new Promise((resolve) => {
              resolve([]);
            }),
      ])
        .then(([articlesData, suggestedProductsData]) => {
          // For Articles
          setArticles(articlesData);
          setOriginalArticles(articlesData);
          if (
            supplierProductsView &&
            supplierProductSuggestionObject &&
            supplierProductSuggestionObject.supplierProductId ===
              props.match.params.id
          ) {
            const _unmergedArticles = articlesData.filter(
              (art) => art.state !== "merged",
            );
            setSelectedArticles(_unmergedArticles);
            setSelectAllArticles(true);
            setShowModal(true);
          } else {
            setSelectedArticles(Array(articlesData.length).fill(false));
          }
          // For Suggested Products
          if (suggestedProductsData.length > 0) {
            setSuggestionProducts(suggestedProductsData);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          addPopupAlert(
            t("articles.actions.load"),
            "error",
            parseErrorMessage(e.message),
          );
          console.log(e);
        });
    };

    fetchData();
  }, [props.match.params.id]);

  useEffect(() => {
    let _selectedArticle = selectedArticles.filter(
      (article) => article === true,
    );
    if (_selectedArticle.length === 0) {
      setSelectAllArticles(false);
    }
  }, [selectedArticles]);

  const saveArticle = (article) => {
    let _articles = [...articles];
    const index = _articles.findIndex((a) => a.id === article.id);
    if (index !== -1) {
      HttpClient.put(
        `/${apiType}/${props.match.params.id}/articles/${
          article.id
        }?product_type=${singularize(apiType)}`,
        { article },
      )
        .then(() => {
          _articles[index] = article;
          setArticles(_articles);
        })
        .catch((error) => console.error(error));
    }
  };

  const updateSelectedArticlesAndOriginalArticles = (massForm) => {
    const _articles = [...articles];
    const updatedOriginalArticles = [...originalArticles];

    _articles.map((article, index) => {
      if (selectedArticles[index]) {
        for (const key in massForm) {
          // To handle false case
          if (
            (massForm[key] !== undefined || massForm[key] !== null) &&
            massForm[key] !== ""
          )
            article[key] = massForm[key];
        }
        const originalIndex = originalArticles.findIndex(
          (o) => o.id === article.id,
        );

        if (originalIndex > -1) {
          updatedOriginalArticles[originalIndex] = article;
        } else {
          updatedOriginalArticles.push(article);
        }
      }
    });
    setArticles(_articles);
    setOriginalArticles(updatedOriginalArticles);
  };

  const fetchSelectedArticleIds = () => {
    let articleIds = [];
    articles.map((article, index) => {
      if (selectedArticles[index]) articleIds.push(article.id);
    });

    return articleIds;
  };

  const getSelectedArticles = () => {
    let selectedArticlesForModal = [];
    articles.map((article, index) => {
      if (selectedArticles[index]) selectedArticlesForModal.push(article);
    });

    return selectedArticlesForModal;
  };

  const selectedArticlesForModal = getSelectedArticles();

  const handleMassActiveArticles = async (status) => {
    if (disabledMenu) return;

    setDisabledMenu(true);

    let articleIds = fetchSelectedArticleIds();

    if (articleIds.length <= 0) {
      setAlert({ severity: "error", text: "Please select article" });
      setDisabledMenu(false);
      return;
    }
    let response = null;
    if (articleIds.length !== 0) {
      response = await HttpClient.post(
        `/${apiType}/${props.match.params.id}/articles/bulk_update`,
        {
          ids: articleIds,
          article: { active: status },
        },
      );
    }
    setActionDropDown(null);
    if (!response || !response["success"]) {
      setDisabledMenu(false);
      return;
    }
    updateSelectedArticlesAndOriginalArticles({ active: status });
    setMassActionForm({});
    setDisabledMenu(false);
  };

  const handleArticleUpdate = async () => {
    if (disabledMenu || (!hasValues(massActionForm) && !selectedArticle)) {
      setActionDropDown(null);
      setDisabledMenu(false);
      return;
    }

    setDisabledMenu(true);

    let response = null;
    if (selectedArticle) {
      response = await HttpClient.put(
        `/${apiType}/${props.match.params.id}/articles/${
          selectedArticle.id
        }?product_type=${singularize(apiType)}`,
        { article: selectedArticle },
      );

      if (response) {
        const _articles = [...articles];
        let index = -1;
        _articles.forEach((a, i) => {
          if (a.id === selectedArticle.id) index = i;
        });
        _articles[index] = { ...selectedArticle };
        setArticles(_articles);
      }
    } else {
      let articleIds = fetchSelectedArticleIds();
      if (articleIds.length <= 0) {
        setAlert({ severity: "error", text: "Please select article" });
        setActionDropDown(null);
        setDisabledMenu(false);
        return;
      }
      if (articleIds.length !== 0) {
        response = await HttpClient.post(
          `/${apiType}/${props.match.params.id}/articles/bulk_update`,
          {
            ids: articleIds,
            article: massActionForm,
          },
        );
      }
      if (response && response["success"]) {
        updateSelectedArticlesAndOriginalArticles(massActionForm);
      }
    }
    setMassActionForm({});
    setMassActionModalOpen(false);
    setActionDropDown(null);
    setDisabledMenu(false);
    setSelectedArticle(null);
  };

  const handleFilter = (filters) => {
    let filtered = [...originalArticles];
    if (filters.supplierId.length) {
      filtered = filtered.filter(
        (a) => a.supplier && a.supplier.id === filters.supplierId,
      );
    }
    if (filters.colorGroupId.length) {
      filtered = filtered.filter(
        (a) => a.color_group && a.color_group.id === filters.colorGroupId,
      );
    }
    setArticles(filtered);
  };

  const replaceArticlesWithOriginalArticles = () => {
    setArticles([...originalArticles]);
  };

  const handleSelectAllArticles = (e) => {
    setSelectAllArticles(e.target.checked);
    let _selectedArticles = selectedArticles.map((article, index) => {
      if (supplierProductsView) {
        if (articles[index].state === "merged") {
          return false;
        } else {
          return e.target.checked;
        }
      } else {
        return e.target.checked;
      }
    });
    setSelectedArticles(_selectedArticles);
  };

  const handleMassModalOpen = (e) => {
    useAlert;
    setModalContent(e.currentTarget.getAttribute("data-mass-action-value"));
    setMassActionModalOpen(true);
    setActionDropDown(null);
  };

  const handleMenuSelect = (e) => {
    let allSelected;
    switch (e.currentTarget.dataset.menuValue) {
      case "all":
        allSelected = true;
        break;
      case "none":
        allSelected = false;
        break;
    }
    setSelectAllArticles(allSelected);
    setSelectedArticles(
      selectedArticles.map((article, index) => {
        if (
          allSelected &&
          supplierProductsView &&
          articles[index].state === "merged"
        ) {
          return false;
        } else {
          return allSelected;
        }
      }),
    );
    setMassDropDown(null);
  };

  const handleModalClose = () => {
    setMassActionModalOpen(false);
    setSelectedArticle(null);
  };

  const handleAvailableStock = (e) => {
    if (selectedArticle) {
      let _selectedArticle = { ...selectedArticle };
      _selectedArticle.stock_direct = e.target.value;
      setSelectedArticle(_selectedArticle);
    } else {
      setMassActionForm({ ...massActionForm, stock_direct: e.target.value });
    }
  };

  const handleNextStock = (e) => {
    if (selectedArticle) {
      let _selectedArticle = { ...selectedArticle };
      _selectedArticle.stock_next_po = e.target.value;
      setSelectedArticle(_selectedArticle);
    } else {
      setMassActionForm({ ...massActionForm, stock_next_po: e.target.value });
    }
  };

  const handleNextStockDate = (e) => {
    if (selectedArticle) {
      let _selectedArticle = { ...selectedArticle };
      _selectedArticle.stock_date_next_po = e.target.value;
      setSelectedArticle(_selectedArticle);
    } else {
      setMassActionForm({
        ...massActionForm,
        stock_date_next_po: e.target.value,
      });
    }
  };

  const handleFutureDate = (e) => {
    if (selectedArticle) {
      let _selectedArticle = { ...selectedArticle };
      _selectedArticle.stock_future = e.target.value;
      setSelectedArticle(_selectedArticle);
    } else {
      setMassActionForm({ ...massActionForm, stock_future: e.target.value });
    }
  };

  const handleAlwaysAvailable = (e) => {
    if (selectedArticle) {
      let _selectedArticle = { ...selectedArticle };
      _selectedArticle.stock_always_available = e.target.checked;
      setSelectedArticle(_selectedArticle);
    } else {
      setMassActionForm({
        ...massActionForm,
        stock_always_available: e.target.checked,
      });
    }
  };

  const renderMassAddSupplierBody = () => {
    return (
      <Box style={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Lägg till leverantörsnummer
        </Typography>
        <FormControl fullWidth margin="dense">
          <TextField
            label="Leverantörsnummer"
            value={massActionForm["supplier_article_number"] || ""}
            onChange={(e) =>
              setMassActionForm({
                ...massActionForm,
                supplier_article_number: e.target.value,
              })
            }
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (
              window.confirm(
                "Är du säker? \n This effects all the articles you have filtered.",
              )
            )
              handleArticleUpdate();
          }}
        >
          Spara
        </Button>
      </Box>
    );
  };

  const updateArticlesAfterMerging = (articlesIds) => {
    let _articles = articles.map((article) => {
      if (articlesIds.includes(article.id)) {
        return { ...article, state: "merged" };
      } else {
        return { ...article };
      }
    });
    setArticles(_articles);
    setSelectedArticles(Array(articles.length).fill(false));
  };

  const renderMassEdit = (article = null) => {
    return (
      <Box style={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Redigera
        </Typography>
        <FormControl fullWidth margin="dense">
          <TextField
            name="stock_direct"
            label="Available Stock"
            type="number"
            value={
              article?.stock_direct || massActionForm["stock_direct"] || ""
            }
            onChange={handleAvailableStock}
            variant="outlined"
            disabled={
              article?.stock_always_available ||
              massActionForm["stock_always_available"]
            }
            style={{
              marginTop: 10,
              marginBottom: 10,
              border: 
              article?.stock_direct < 0 || massActionForm["stock_direct"] < 0
                ? "1px solid red" : "",
            }}
            inputProps={{ min: 0 }}
            helperText={t("validations.greater_than_zero_helper_text")}
          />
          <TextField
            label="Next Stock in PO"
            type="number"
            name="stock_next_po"
            value={
              article?.stock_next_po || massActionForm["stock_next_po"] || ""
            }
            onChange={handleNextStock}
            variant="outlined"
            disabled={
              article?.stock_always_available ||
              massActionForm["stock_always_available"]
            }
            style={{
              marginTop: 10,
              marginBottom: 10,
              border: 
              article?.stock_next_po < 0 || massActionForm["stock_next_po"] < 0
                ? "1px solid red" : "",
            }}
            inputProps={{ min: 0 }}
            helperText={t("validations.greater_than_zero_helper_text")}
          />
          <TextField
            label="Next Stock PO date"
            type="date"
            value={
              article?.stock_date_next_po ||
              massActionForm["stock_date_next_po"]
            }
            onChange={handleNextStockDate}
            variant="outlined"
            disabled={
              article?.stock_always_available ||
              massActionForm["stock_always_available"]
            }
            style={{ marginTop: 10, marginBottom: 10 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Future Stock"
            type="number"
            name="stock_future"
            value={
              article?.stock_future || massActionForm["stock_future"] || ""
            }
            onChange={handleFutureDate}
            variant="outlined"
            disabled={
              article?.stock_always_available ||
              massActionForm["stock_always_available"]
            }
            style={{
              marginTop: 10,
              marginBottom: 10,
              border: 
              article?.stock_future < 0 || massActionForm["stock_future"] < 0
                ? "1px solid red" : "",
            }}
            inputProps={{ min: 0 }}
            helperText={t("validations.greater_than_zero_helper_text")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  article?.stock_always_available ||
                  massActionForm["stock_always_available"]
                }
                onChange={handleAlwaysAvailable}
                name="stock_always_available"
              />
            }
            label="Stock always available"
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (
              window.confirm(
                selectedArticle
                  ? "Är du säker?"
                  : "Är du säker? \n This effects all the articles you have filtered.",
              )
            )
            if (validate()){
              handleArticleUpdate();
            }
          }}
        >
          Spara
        </Button>
      </Box>
    );
  };

  const mergeArticlesClickHandle = () => {
    if (selectedArticles.includes(true)) {
      setShowModal(true);
      setActionDropDown(null);
    }
  };

  const closeProductModal = () => {
    setShowModal(false);
    localStorage.removeItem("supplierProductSuggestion");
  };

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          {alert && (
            <Grid item xs={12}>
              <Alert severity={alert.severity} onClose={() => setAlert(null)}>
                {alert.text}
              </Alert>
            </Grid>
          )}
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={1}>
              <Checkbox
                color="primary"
                checked={selectAllArticles}
                onChange={handleSelectAllArticles}
              />
              <IconButton
                aria-controls="menu-dropdown"
                size="small"
                aria-haspopup="true"
                onClick={(e) => setMassDropDown(e.currentTarget)}
              >
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id="menu-dropdown"
                anchorEl={massDropDown}
                keepMounted
                open={Boolean(massDropDown)}
                onClose={() => setMassDropDown(null)}
              >
                <MenuItem onClick={handleMenuSelect} data-menu-value={"all"}>
                  Alla
                </MenuItem>
                <MenuItem onClick={handleMenuSelect} data-menu-value={"none"}>
                  Ingen
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={9}>
              <Filters
                onFilter={handleFilter}
                productId={props.match.params.id}
                productType={apiType}
                replaceArticlesWithOriginalArticles={
                  replaceArticlesWithOriginalArticles
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                aria-controls="action-dropdown"
                variant="outlined"
                color="primary"
                onClick={(e) => setActionDropDown(e.currentTarget)}
              >
                Åtgärder{" "}
                {actionDropDown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
              <Menu
                id="action-dropdown"
                anchorEl={actionDropDown}
                keepMounted
                open={Boolean(actionDropDown)}
                onClose={() => setActionDropDown(null)}
              >
                {apiType === "supplier_products" ? (
                  <MenuItem onClick={mergeArticlesClickHandle}>
                    Slå samman artiklar
                  </MenuItem>
                ) : (
                  <>
                    <MenuItem
                      onClick={(e) => handleMassModalOpen(e)}
                      data-mass-action-value={"mass_edit"}
                      disabled={disabledMenu}
                    >
                      Redigera
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleMassModalOpen(e)}
                      data-mass-action-value={"mass_supplier_number"}
                      disabled={disabledMenu}
                    >
                      Lägg till leverantörsnummer
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (
                          window.confirm(
                            "Är du säker? \n This effects all the articles you have filtered.",
                          )
                        )
                          handleMassActiveArticles(true);
                      }}
                      disabled={disabledMenu}
                      data-mass-action-value={"mass_active"}
                    >{`Aktivera`}</MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (
                          window.confirm(
                            "Är du säker? \n This effects all the articles you have filtered.",
                          )
                        )
                          handleMassActiveArticles(false);
                      }}
                      disabled={disabledMenu}
                      data-mass-action-value={"mass_inctive"}
                    >{`Inaktivera`}</MenuItem>
                  </>
                )}
              </Menu>
            </Grid>
          </Grid>
          {showModal && (
            <SharedModal onCloseModal={() => setShowModal(false)}>
              <Box sx={modalStyle}>
                <ProductModal
                  selectedArticles={selectedArticlesForModal}
                  closeModal={closeProductModal}
                  updateArticles={updateArticlesAfterMerging}
                  setAlert={setAlert}
                  suggestedProduct={suggestedProduct}
                  suggestionProducts={suggestionProducts}
                  supplierProductId={props.match.params.id}
                />
              </Box>
            </SharedModal>
          )}
          <Grid container spacing={2}>
            <CustomModal
              open={massActionModalOpen}
              body={
                modalContent === "mass_edit"
                  ? () => renderMassEdit(selectedArticle)
                  : renderMassAddSupplierBody
              }
              handleClose={handleModalClose}
            />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            {articles.map((a, i) => (
              <Article
                key={a.id}
                article={a}
                saveArticle={saveArticle}
                selectedArticle={selectedArticles[i]}
                selectAllArticles={selectAllArticles}
                setSelectedArticles={setSelectedArticles}
                selectedArticles={selectedArticles}
                articleIndex={i}
                setMassActionModalOpen={setMassActionModalOpen}
                setModalContent={setModalContent}
                setSelectedArticle={setSelectedArticle}
                supplierProductsView={supplierProductsView}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

List.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default List;
