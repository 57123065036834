import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Container, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import HttpClient from "@httpClient";
import TitleBar from "@components/topbar/TitleBar";
import ListTable from "@common/ListingTable/ListTable";
import { SupplierIntegrationJobStatsApi } from "@src/apis/SupplierIntegrationJobStatsApi";
import JobStatsRow from "@components/supplier_integration_job_stats/JobStatsRow";
import { makeStyles } from "@mui/styles";

const List = ({ location, history }) => {
  const [jobsStats, setJobsStats] = useState([]);
  const [page, setPage] = useState(1);
  const [jobsStatsCount, setJobsStatsCount] = useState(0);
  const { t } = useTranslation();

  const classes = makeStyles(() => ({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: { width: "100%" },
  }))();

  const jobsTableContent = () => {
    const jobsStatsTableBody = jobsStats?.map((statsJob) => (
      <JobStatsRow key={statsJob.id} {...statsJob} />
    ));
    return {
      body: jobsStatsTableBody,
      heading: [
        t("supplierIntegrationJobStats.attributes.created_at"),
        t("supplierIntegrationJobStats.attributes.type"),
        t("supplierIntegrationJobStats.attributes.restarted_at"),
        t("supplierIntegrationJobStats.attributes.updated_at"),
        t("supplierIntegrationJobStats.attributes.status"),
        t("supplierIntegrationJobStats.attributes.no_of_tries"),
        t("supplierIntegrationJobStats.attributes.total"),
        t("supplierIntegrationJobStats.attributes.success"),
        t("supplierIntegrationJobStats.attributes.failed"),
        t("supplierIntegrationJobStats.attributes.unchanged"),
      ],
    };
  };

  const tableContent = jobsTableContent();

  useEffect(() => {
    let didCancel = false;
    getJobsStats(1);
    return () => {
      didCancel = true;
      HttpClient.abort();
    };
  }, []);

  const getJobsStats = (pageNo, perPageCount = 20) => {
    SupplierIntegrationJobStatsApi.index(
      {
        page: pageNo,
        per_page: perPageCount,
      },
      true,
    )
      .then((data) => {
        setJobsStats(data.jobs_stats);
        setJobsStatsCount(data.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <TitleBar title={t("supplierIntegrationJobStats.plural")}></TitleBar>
      <Container>
        <Paper square className={classes.root} style={{ marginTop: "15px" }}>
          <ListTable
            tableHeadings={tableContent.heading}
            tableContent={tableContent.body}
            count={jobsStatsCount}
            pageNo={page}
            rowsPerPage={20}
            onPageChange={(event, pageNo) => {
              setPage(pageNo + 1);
              getJobsStats(pageNo + 1);
            }}
            rowsControl={false}
          />
        </Paper>
      </Container>
    </React.Fragment>
  );
};

List.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default List;
