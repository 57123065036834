import HttpClient from "@components/HttpClient";
import { setUrlForGetRequest } from "@api/SharedFunctions";

const index = (params = {}, showLoader = false) => {
  let url = setUrlForGetRequest("/supplier_integration_job_stats", params);
  return HttpClient.get(url, {}, showLoader);
};

const show = (id, params = {}, showLoader = false) => {
  let url = setUrlForGetRequest(
    `/supplier_integration_job_stats/${id}`,
    params,
  );
  return HttpClient.get(url, {}, showLoader);
};

const SupplierIntegrationJobStatsApi = {
  show,
  index,
};

export { SupplierIntegrationJobStatsApi };
