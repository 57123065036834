import React from "react";
import PropTypes from "prop-types";

import Form from "@components/attributes/Form";

const AttributeFrom = (props) => {
  const initialState = {
    name: "",
    type: "",
    unit: "",
    parent: { id: "" },
    p_attribute_values: [],
    child_attributes: [],
  };

  return (
    <Form
      attribute={initialState}
      productId={props.productId}
      onSave={(response) => props.onCreate({ ...initialState, ...response })}
    />
  )
};

AttributeFrom.propTypes = {
  productId: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
};

export default AttributeFrom;
