import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import InlineLoader from "@components/common/Loaders/InlineLoader";

const BackdropLoader = ({ InlineLoaderProps = {}, ...rest }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 999999 }} open={open} {...rest}>
        <InlineLoader color="inherit" {...InlineLoaderProps} />
      </Backdrop>
    </div>
  );
};

BackdropLoader.propTypes = {
  InlineLoaderProps: PropTypes.object,
};

export default BackdropLoader;
