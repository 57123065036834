import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionActions from '@mui/material/AccordionActions'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Chip from "@mui/material/Chip";
import PrimarySupplierButton from "./PrimarySupplierButton";
import Alert from "@mui/material/Alert";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Collapse from '@material-ui/core/Collapse';
import { Box } from '@mui/material'
import Button from "@mui/material/Button";
import HttpClient from "@httpClient";
import { ProductApi } from '@src/apis'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  // accordion: {
  //   border: '1px solid rgba(0, 0, 0, .125)',
  //   boxShadow: 'none',
  //   '&:not(:last-child)': {
  //     borderBottom: 0,
  //   },
  //   '&:before': {
  //     display: 'none',
  //   },
  //   '&$expanded': {
  //     margin: 'auto',
  //   },
  // },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
  },
  attributeContainer: {
    marginBottom: theme.spacing(2),
  },
  accordionDetailsRoot: {
    padding: theme.spacing(2),
    display: 'block'
  },
}))

export default function SupplierList(props) {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const [suppliers, setSuppliers] = useState([]);
  const [markPrimaryErrorMessage, setmarkPrimaryErrorMessage] = useState('');
  const [markPrimaryAlert, setmarkPrimaryAlert] = useState({ message: '', severity: '' });
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setSuppliers(props.suppliers)
  }, [props.suppliers])

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleMarkAsPrimary = (priceListID, priceListRowID) => {
    ProductApi.markSupplierAsPrimary(props.productId, priceListID, priceListRowID)
      .then((response) => {
        if (response.success) {
          const updatedSuppliers = suppliers.map((supplier) => {
            if (supplier.price_list_row_id === priceListRowID) {
              return { ...supplier, primary_price_list_row: true };
            } else {
              return supplier;
            }
          });
          setmarkPrimaryAlert({ message: t('suppliers.markPrimarySuccessful'), severity: 'success' });
          setSuppliers(updatedSuppliers);
        } else {
          setmarkPrimaryAlert({ message: t('suppliers.markPrimaryUnSuccessful'), severity: 'error' });
        }
      })
      .catch((error) => {
        console.error('Error marking supplier as primary:', error);
        setmarkPrimaryAlert({ message: t('suppliers.markPrimaryUnSuccessful'), severity: 'error' });
      });
  };

  const markAsPrimaryPrintingSupplier = (productID, primaryPrintingSupplierId, priceListRowID) => {
    ProductApi.updateProductAttributes(productID, { product: { primary_printing_supplier: { id: primaryPrintingSupplierId } } })
      .then((res) => {
        const updatedPrintingSuppliers = suppliers.map((supplier) => {
          if (supplier.supplier.id === primaryPrintingSupplierId) {
            return { ...supplier, primary_printing_supplier_id: primaryPrintingSupplierId };
          } else {
            return supplier;
          }
        });
        setmarkPrimaryAlert({ message: t('suppliers.markPrimarySuccessful'), severity: 'success' });
        setSuppliers(updatedPrintingSuppliers);
      })
      .catch((error) => {
        setUpdating(false);
        setmarkPrimaryAlert({ message: t('suppliers.markPrimaryUnSuccessful'), severity: 'error' });
        console.error("Error marking printing supplier as primary: ", error);
      });
  };



  return (
    <div className={classes.root}>
      {suppliers.map(supplier => (
        <Accordion key={supplier.supplier.id} expanded={expanded === `panel${supplier.supplier.id}`} onChange={handleChange(`panel${supplier.supplier.id}`)} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ position: "relative" }}
          >
            <Typography className={classes.heading}>{supplier.supplier.name}
            </Typography>
            {((props.listType == "suppliers" && supplier.primary_price_list_row) || (props.listType == "printingSuppliers" && supplier.primary_printing_supplier_id))  && (
              <Chip label={t('suppliers.primary')}  size="small" style={{ marginLeft: '5px' }} />
            )}
            {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetailsRoot}>
            <Box mb={2}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.name')}</InputLabel>
                    <Typography component="p"><Link target="_blank" to={`/suppliers/${supplier.supplier.id}`}>{supplier.supplier.name}</Link></Typography>
                  </div>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.url')}</InputLabel>
                    <Typography component="p"><Link to={supplier.supplier.url}>{supplier.supplier.url}</Link></Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.phone')}</InputLabel>
                    <Typography component="p">{supplier.supplier.phone}</Typography>
                  </div>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.email')}</InputLabel>
                    <Typography component="p">{supplier.supplier.emails}</Typography>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2}>
              <Typography component="h4">{t('suppliers.login')}</Typography>
            </Box>
            <Box mb={2}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.loginUrl')}</InputLabel>
                    <Typography component="p"><Link to={supplier.supplier.login_url}>{supplier.supplier.login_url}</Link></Typography>
                  </div>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.customerNumber')}</InputLabel>
                    <Typography component="p">{supplier.supplier.customer_number}</Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.username')}</InputLabel>
                    <Typography component="p">{supplier.supplier.username}</Typography>
                  </div>
                  <div className={classes.attributeContainer}>
                    <InputLabel className={classes.label}>{t('suppliers.password')}</InputLabel>
                    <Typography component="p">{supplier.supplier.password}</Typography>
                  </div>
                </Grid>
              </Grid>
            </Box>
            {markPrimaryAlert.message && (
              <Collapse in={open}>
                <Alert severity={markPrimaryAlert.severity}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {markPrimaryAlert.message}
                </Alert>
              </Collapse>
            )}
          </AccordionDetails>
          <AccordionActions>
            <PrimarySupplierButton
              listType={props.listType}
              supplier={supplier}
              productId={props.productId}
              handleMarkAsPrimary={handleMarkAsPrimary}
              markAsPrimaryPrintingSupplier={markAsPrimaryPrintingSupplier}
            />
          </AccordionActions>
        </Accordion>
      ))}
    </div>
  )
}

SupplierList.propTypes = {
  suppliers: PropTypes.array.isRequired,
  productId: PropTypes.number.isRequired,
}
