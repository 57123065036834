import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const Price = (props) => {
  const [price, setPrice] = useState(props.price);

  useEffect(() => {
    setPrice(props.price);
  }, [props.price]);

  return (
    <Grid item xs={1}>
      <FormControl margin="normal" fullWidth variant="outlined">
        <TextField
          fullWidth
          disabled={props.supplierProductsView || true}
          label="Qty"
          name="quantity"
          value={price.quantity || ""}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="normal" fullWidth variant="outlined">
        <TextField
          fullWidth
          disabled={props.supplierProductsView || true}
          label="In"
          name="our_price"
          value={price.our_price || ""}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="normal" fullWidth variant="outlined">
        <TextField
          fullWidth
          disabled={props.supplierProductsView || true}
          label="Out"
          name="customer_price"
          value={price.customer_price || ""}
          variant="outlined"
        />
      </FormControl>
    </Grid>
  );
};

Price.propTypes = {
  price: PropTypes.object.isRequired,
};
export default Price;
