import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@components/supplier_integration_job_stats/List";
import Show from "@components/supplier_integration_job_stats/Show";

const SupplierIntegrationJobStats = ({ match }) => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${match.path}/`} component={List}></Route>
        <Route exact path={`${match.path}/:id`} component={Show}></Route>
      </Switch>
    </React.Fragment>
  );
};

SupplierIntegrationJobStats.propTypes = {
  match: PropTypes.object,
};

export default SupplierIntegrationJobStats;
