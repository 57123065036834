import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { Grid, TableCell, TableRow, Chip, Button, Paper } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'

import { SupplierProductApi } from '@api'

import DropdownWithButton from '@shared/DropdownWithButton'
import SharedModal from '@shared/Modal'
import CategoryModal from '@components/supplier_products/CategoryModal'
import SuggestedProductsModal from '@components/supplier_products/SuggestedProductsModal'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  indexColumn: {
    width: '50px'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '45%',
    '@media (max-width:600px)': {
      width: '95%', // sm
    },
    '@media (max-width:960px)': {
      width: '70%', // md
    },
    '@media (max-width:1200px)': {
      width: '70%', // md
    },
    boxShadow: 24
  },
  table: { width: '100%' },
}))

const SupplierProduct = ({ supplierProduct, index, updateProductSupplierState, tabValue }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalStage, setModalStage] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)

  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  const updateProductStatus = async (id, value) => {
    setActionLoading(true)
    await SupplierProductApi.updateStatus(id, { state: value })
    updateProductSupplierState(id, value)
    setActionLoading(false)
  }

  const handleModalStage = () => {
    if (supplierProduct.suggested_products.length > 0) {
      setModalStage('suggested_products_selection')
    } else {
      setModalStage('category_selection')
    }
    setShowModal(!showModal)
  }

  const rowActions = (id, state) => {
    return [
      {
        disabled: false, name: t('supplierProducts.actions.edit'), clickEvent: () => history.push(`/supplier-products/${id}/edit`)
      },
      {
        disabled: state === 'merged', name: t('supplierProducts.actions.merge'), clickEvent: () => updateProductStatus(id, 'merged')
      },
      {
        disabled: false, name: t('supplierProducts.actions.mute'), clickEvent: () => updateProductStatus(id, 'muted')
      },
      {
        disabled: state === 'merged', name: t('supplierProducts.actions.create_new_product'), clickEvent: handleModalStage
      },
      {
        disabled: state === 'merged', name: t('supplierProducts.actions.merge_into_existing'), clickEvent: () => history.push(`/supplier-products/${id}/articles`)
      }
    ]
  }

  const selectProductButtonAction = (product) => {
    localStorage.setItem('supplierProductSuggestion', JSON.stringify({
      supplierProductId: supplierProduct.id,
      suggestedProduct: {
        id: product.id,
        name: product.name,
        image_url: product.image_url
      }
    }))
    history.push(`/supplier-products/${supplierProduct.id}/articles`)
  }

  return (
    <TableRow key={supplierProduct.id} style={{ position: 'relative' }}>
      <TableCell component="th" scope="row" className={classes.indexColumn}><Link
        to={`supplier-products/${supplierProduct.id}/edit`}>{index}</Link></TableCell>
      <TableCell><Link to={`supplier-products/${supplierProduct.id}`} relative="path">{supplierProduct.name}</Link></TableCell>
      <TableCell><Link to={`suppliers/${supplierProduct.supplier_id}`} relative="path">{supplierProduct.supplier_name}</Link></TableCell>
      <TableCell>
        { supplierProduct.category_id && supplierProduct.category_name }
      </TableCell>
      <TableCell>
        <Chip label={t(`supplierProducts.attributes.status.${supplierProduct.state || 'unmerged'}`)} />
      </TableCell>
      {
        tabValue === 'unmerged' &&
        <TableCell>
          { supplierProduct.suggested_products.length !== 0 ?
            <Button variant={"secondary"} size={"small"} onClick={handleModalStage}>
              {supplierProduct.suggested_products.length}
            </Button>
            :
            0
          }
        </TableCell>
      }
      <TableCell align="right">
        <Grid container spacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <DropdownWithButton
              clickableComponent={
                <LoadingButton loading={actionLoading} variant="outlined">
                  <MoreHorizIcon/>
                </LoadingButton>
              }
              rowId={supplierProduct.id}
              listElements={()=>rowActions(supplierProduct.id, supplierProduct.state)}
              aligned={'right'}
            />
          </Grid>
        </Grid>
      </TableCell>

      { showModal &&
        <SharedModal onCloseModal={() => setShowModal(false)}>
          <div className={classes.modal}>
            {
              modalStage === 'category_selection' ?
                <CategoryModal
                  setProductSupplierState={updateProductSupplierState}
                  closeModal={() => setShowModal(false)}
                  supplierProduct={supplierProduct}
                />
                :
                <Paper sx={{ padding: "10px" }}>
                  <SuggestedProductsModal
                    closeModal={() => setShowModal(false)}
                    page={'listing'}
                    supplierProductId={supplierProduct.id}
                    selectAction={selectProductButtonAction}
                    setModalStage={() => setModalStage('category_selection')}
                  />
                </Paper>
            }
          </div>
        </SharedModal>
      }
    </TableRow>
  )
}

SupplierProduct.propTypes = {
  supplierProduct: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateProductSupplierState: PropTypes.func.isRequired
}

export default SupplierProduct;
