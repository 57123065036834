import React from 'react'
import PropTypes from 'prop-types'
import { Container, Typography } from '@mui/material'



const Dashboard = () => {
  return (
    <Container>

    </Container>
  )
}
Dashboard.propTypes = {}

export default Dashboard
