import React from 'react'
import PropTypes from 'prop-types'

import Form from './Form'

let initialState = {name: '', description: '', logo_uid: '', footer: ''}

const New = props => {
  return(
    <Form brand={initialState} history={props.history} />
  )
}

New.propTypes = { history: PropTypes.object.isRequired }

export default New