import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio,
    FormLabel,
    Grid,
    IconButton,
    Paper
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import ItemSelectedComponent from '@shared/ItemSelectedComponent'
import SearchInputWithView from '@shared/SearchInputWithView'
import Article from '@components/products/articles/Article'

import { ProductApi } from "@api";
import InlineLoader from "@components/common/Loaders/InlineLoader";
import { searchTypes } from '@config/constants'
import SuggestedProductsModal from '@components/supplier_products/SuggestedProductsModal'

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
}));

const ProductModal = ({ closeModal, selectedArticles, updateArticles, setAlert, suggestedProduct, suggestionProducts, supplierProductId }) => {
  const [selectedProduct, setSelectedProduct] = useState(suggestedProduct)
  const [stage, setStage] = useState(suggestionProducts?.length === 0 ? 'productSelection' : 'productSuggestion')
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [conflictingArticles, setConflictingArticles] = useState([])
  const [nonConflictingArticles, setNonConflictingArticles] = useState([])

  const updateViewType = async (value = 'mergeArticles') => {
    setStage(value)
    if (value === 'mergeArticles') {
      getConflictingArticlesForProduct()
    }
  }

  const getConflictingArticlesForProduct = async (productId=null) => {
    setLoading(true)
    let response = await ProductApi.getConflictingArticles(productId || selectedProduct.id, { article_ids: selectedArticles.map(a => a.id) })
    let _conflictingArticles = []
    let _nonConflictingArticles = []
    selectedArticles.map((article) => {
      const conflictingArticle = response.find(a => a.id === article.id)
      if (conflictingArticle) {
        _conflictingArticles.push(
          { ...article,
            conflictingArticle: conflictingArticle.conflicting_article,
            conflictAction: 'connect_conflicting'
          })
      } else {
        _nonConflictingArticles.push({ ...article })
      }
    })
    setConflictingArticles(_conflictingArticles)
    setNonConflictingArticles(_nonConflictingArticles)
    setLoading(false)
  }

  const params = useParams()

  const mergeArticles = () => {
    setShowBackdrop(true)
    let articlesData = {}

    const _conflictingArticles = conflictingArticles.filter(art => {
      !['new_article','discard_conflicting'].includes(art.conflictAction)
    })

    articlesData.conflicting_articles = _conflictingArticles.map((art)=>{
      return { id: art.id, conflicting_art_id: art.conflicting_article.id}
    })

    articlesData.non_conflicting_articles = nonConflictingArticles.map(art => art.id )

    const _non_conflicting_articles = conflictingArticles.filter(art => art.conflictAction === 'new_article').map(art => art.id)
    articlesData.non_conflicting_articles.push(..._non_conflicting_articles)

    ProductApi.mergeArticles(selectedProduct.id, {
      articles: articlesData,
      supplier_product_id: params.id
    }).then(() => {
      setShowBackdrop(false)
      closeModal()
      setAlert({ severity: 'success', text: 'Articles Merged Successfully' })
      updateArticles(selectedArticles.map(article => article.id))
    }).catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    if (!!suggestedProduct){
      updateViewType('mergeArticles')
    }
  },[suggestedProduct])

  const updateArticleMergeData = (id, value) => {
    let _conflictingArticleData = [...conflictingArticles]
    _conflictingArticleData = _conflictingArticleData.map( art => {
      if (art.id === id) {
        return { ...art, conflictAction: value }
      } else {
        return {...art}
      }
    })
    setConflictingArticles(_conflictingArticleData)
  }

  const handleSuggestionProductSelection = (product) => {
    setSelectedProduct({...product})
    setStage('mergeArticles')
    getConflictingArticlesForProduct(product.id)
  }

  return (
    <React.Fragment>
      {
        stage === 'productSuggestion' &&
        <SuggestedProductsModal
          closeModal={closeModal}
          page={'articles'}
          selectAction={handleSuggestionProductSelection}
          supplierProductId={supplierProductId}
          setModalStage={() => setStage('productSelection')}
        />
      }
      { stage === 'productSelection' &&
        <ProductSelectionStage
          closeModal={closeModal}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          updateViewType={updateViewType}
          suggestedProductsViewPresent={suggestionProducts.length > 0}
        />
      }
      { stage === 'mergeArticles' &&
        <MergeArticlesStage
          updateArticleMergeData={updateArticleMergeData}
          updateViewType={updateViewType}
          closeModal={closeModal}
          selectedProduct={selectedProduct}
          conflictingArticles={conflictingArticles}
          nonConflictingArticles={nonConflictingArticles}
          mergeArticles={mergeArticles}
          showBackdrop={showBackdrop}
          loading={loading}
        />
      }
    </React.Fragment>
  )
}

const ProductSelectionStage = ({ closeModal, selectedProduct, updateViewType, setSelectedProduct, suggestedProductsViewPresent }) => {
  return (
    <React.Fragment>
      <Grid container item alignItems="center">
        <Grid item xs={11}>
          {
            suggestedProductsViewPresent &&
            <Grid item xs={12}>
              <IconButton onClick={() => updateViewType('productSuggestion')} color="secondary">
                <ArrowBackIcon/>
              </IconButton>
            </Grid>
          }
          <Grid item xs={12}>
            <h3>Select Product</h3>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <IconButton sx={{ float: 'right' }} onClick={closeModal}><CloseIcon/></IconButton>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent={'center'} alignItems={'center'} spacing={2}>
        <SearchInputWithView
          type={searchTypes.supplierProductsIntegrationSearch}
          searchInputLabel={'Search Product by name'}
          selectedItem={selectedProduct}
          setSelectedItem={setSelectedProduct}
        />
      </Grid>
      <hr/>
      <Grid container justifyContent={'flex-end'}>
        <Grid item>
          <Button variant={'contained'} disabled={selectedProduct === null}
                  onClick={() => updateViewType('mergeArticles')}>Next</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const MergeArticlesStage = ({
  updateViewType,
  closeModal,
  selectedProduct,
  mergeArticles,
  showBackdrop,
  updateArticleMergeData,
  conflictingArticles,
  nonConflictingArticles,
  loading
}) => {

  const classes = useStyles();

  return (
    <div style={{ overflow: 'scroll' }}>
      <Backdrop open={showBackdrop} sx={{position: 'absolute', zIndex: 1}}>
        <InlineLoader size={40} color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item container justifyContent={'space-between'} alignItems={'center'} xs={12}>
          <Grid item>
            <IconButton onClick={() => updateViewType('productSelection')} color="secondary">
              <ArrowBackIcon/>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseIcon/>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <ItemSelectedComponent
            type={'products'}
            selectedItem={selectedProduct}
            handleUnselect={() => updateViewType('productSelection')}
          />
          {loading ?
            <Grid className={classes.center} container item xs={12}>
              <CircularProgress color="inherit"/>
            </Grid>
            :
            <Grid container item xs={12} sx={{height: '600px', overflowY: 'auto'}}>
              {
                nonConflictingArticles.length > 0 &&
                <ArticleView
                  nonConflictingArticles={nonConflictingArticles}
                  selectedProduct={selectedProduct}
                />
              }
              {
                conflictingArticles.map((article) => (
                  <>
                  <Grid container item mt={1} xs={12} alignItems="center" justifyContent="center">
                    <h4>Conflicting Articles found in the Selected Product</h4>
                  </Grid>
                  <ConflictingArticleView
                    article={article}
                    updateArticleMergeData={updateArticleMergeData}
                    mergeArticles={mergeArticles}
                  />
                  </>
                ))
              }
            </Grid>
          }
        </Grid>
      </Grid>
      <hr/>
      <Grid container justifyContent={'flex-end'}>
        <Grid item>
          <Button variant={'contained'} onClick={mergeArticles} disabled={loading}>Merge Articles</Button>
        </Grid>
      </Grid>
    </div>
  )
}

const ArticleView = ({ nonConflictingArticles, selectedProduct }) => {
  return (
    <Grid container xs={12}>
      <Grid item mt={1} xs={12} container alignItems="center" justifyContent="center">
        <h4>Following articles will be added to {selectedProduct.name}</h4>
      </Grid>
      <Grid container item xs={12} spacing={1} justifyContent={'center'}>
        {
          nonConflictingArticles.map((article) => (
            <Article type={'modal'} key={article.id} article={article}/>
          ))
        }
      </Grid>
    </Grid>
  )
}

const ConflictingArticleView = ({ article, updateArticleMergeData }) => {
  return(
    <Grid container justifyContent={'flex-end'} xs={12} mt={2}>
      <Paper variant={'outlined'} sx={{padding: '10px'}}elevation={3}>
        <Grid item container justifyContent={'center'} alignItems={'center'} xs={12} spacing={2}>
          <Article type={'modal'} key={article.id} article={article} gridSize={4}/>
          <Article type={'modal'} key={article.conflictingArticle.id} article={article.conflictingArticle} gridSize={4}/>
        </Grid>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={2}>
          <FormLabel component="legend">Actions</FormLabel>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Actions</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={article.conflictAction}
              name="radio-buttons-group"
              onChange={(e) => updateArticleMergeData(article.id, e.target.value)}
            >
              <FormControlLabel value="connect_conflicting" control={<Radio />} label="Connect them together" />
              <FormControlLabel value="new_article" control={<Radio />} label="New Article" />
              <FormControlLabel value="discard_conflicting" control={<Radio />} label="Discard Article" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Paper>
    </Grid>
  )
}


export default ProductModal
