import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const ScrollableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginTop: '10px',
  maxHeight: '400px',
  overflowY: 'scroll',
}));

export default ScrollableBox;
