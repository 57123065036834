import HttpClient from "@components/HttpClient";

const index = (productId, productResource = "products") => {
  return HttpClient.get(
    `/${productResource}/${productId}/product_reference_images`,
    {},
    true,
  );
};

const put = (productId, productResource = "products", id, data) => {
  return HttpClient.put(
    `/${productResource}/${productId}/product_reference_images/${id}`,
    {
      product_reference_image: data,
    },
    {},
    true,
  );
};

const addColor = (productId, productResource = "products", id, params) => {
  return HttpClient.post(
    `/${productResource}/${productId}/product_reference_images/${id}/colors`,
    params,
    {},
    true,
  );
};

const removeColor = (productId, productResource = "products", id, colorId) => {
  return HttpClient.delete(
    `/${productResource}/${productId}/product_reference_images/${id}/colors/${colorId}`,
    {},
    true,
  );
};

const addMarkingMethod = (
  productId,
  productResource = "products",
  id,
  params,
) => {
  return HttpClient.post(
    `/${productResource}/${productId}/product_reference_images/${id}/add_marking_method`,
    params,
    {},
    true,
  );
};

const removeMarkingMethod = (productId, productResource = "products", id) => {
  return HttpClient.delete(
    `/${productResource}/${productId}/product_reference_images/${id}/remove_marking_method`,
    {},
    true,
  );
};

const newReferenceImages = (
  productId,
  productResource = "products",
  params,
) => {
  return HttpClient.post(
    `/${productResource}/${productId}/product_reference_images`,
    params,
    {},
    true,
  );
};

const destroy = (productId, productResource = "products", id) => {
  return HttpClient.delete(
    `/${productResource}/${productId}/product_reference_images/${id}`,
    {},
    true,
  );
};

const ProductReferenceImagesApi = {
  put,
  index,
  addColor,
  removeColor,
  addMarkingMethod,
  removeMarkingMethod,
  newReferenceImages,
  destroy,
};

export { ProductReferenceImagesApi };
