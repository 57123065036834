import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import HttpClient from '../HttpClient'
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';
import useAlert from '@customHooks/useAlert'

const Quantity = props => {
  const [quantity, setQuantity] = useState(props.quantity)
  const [alert, setAlert] = useAlert(props.created)
  const { validationErrors, validate, setValidationErrors } = useValidation({
    quantity: { value: quantity.value, rules: { required: true } },
  });

  const saveQuantity = () => {
    if(props.quantity.value !== quantity.value) {
      const newQuantity = !quantity.hasOwnProperty('id')
      if(newQuantity) {
        HttpClient.post(`/products/${props.productId}/quantities`, { quantity: quantity })
          .then(response => {
            setQuantity(response)
            props.onCreateQuantity({ ...quantity, ...response })
          })
          .catch(error => handleValidationErrors(error, setValidationErrors, setAlert))
      } else {
        HttpClient.put(`/products/${props.productId}/quantities/${quantity.id}`, { quantity: quantity })
        .then(response => console.log("quantity updated"))
        .catch(error => handleValidationErrors(error, setValidationErrors, setAlert))
      }
    }
  }

  return (
    <Box flex="1 1 0" marginRight={0.5}>
      <TextField
        disabled={props.supplierProductsView}
        fullWidth
        name="quantity"
        value={quantity.value || ''}
        onChange={e => setQuantity({...quantity, value: e.currentTarget.value })}
        onBlur={saveQuantity}
        margin="normal"
        variant="outlined"
        error={!!validationErrors.quantity}
        helperText={validationErrors.quantity} />
    </Box>
  )
}

const stringOrIntegerValidator = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]).isRequired;

Quantity.propTypes = {
  productId: stringOrIntegerValidator,
  quantity: PropTypes.object.isRequired,
  onCreateQuantity: PropTypes.func.isRequired
}

export default Quantity
