import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

import List from '@components/tags/List'
import Edit from '@components/tags/Edit'
import TagProducts from '@components/tags/TagProducts'
import AddTagProducts from '@components/tags/AddTagProducts'

const Tags = props => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${props.match.path}/`} component={List}></Route>
        <Route exact path={`${props.match.path}/new`} component={Edit}></Route>
        <Route exact path={`${props.match.path}/:id/edit`} component={Edit}></Route>
        <Route exact path={`${props.match.path}/:id/products`} component={TagProducts}></Route>
        <Route exact path={`${props.match.path}/:id/add_products`} component={AddTagProducts}></Route>
      </Switch>
    </React.Fragment>
  )
}

Tags.propTypes = {
  match: PropTypes.object
}

export default Tags
