import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Chip from '@mui/material/Chip'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'

import HttpClient from '../../HttpClient'

const Standard = props => {
  const [value, setValue] = useState({ ...props.standard.standard_value })
  const { t, i18n } = useTranslation()

  const standardsLabel = useRef(null)
  const [standardsLabelWidth, setStandardsLabelWidth] = useState(0)
  useEffect(() => {
    setStandardsLabelWidth(standardsLabel.current.offsetWidth)
  }, [])
  const classes = makeStyles(theme => ({
    chip: {
      margin: theme.spacing(1),
      border: '1px solid #e0e0e0'
    },
    icon: {
      backgroundColor: '#000',
      color: '#fff',
      borderRadius: '50%',
      '&:hover': {
        color: '#fff'
      }
    }
  }))()

  const handleChange = e => {
    setValue({ ...value, value: e.currentTarget.value })
    HttpClient.put(`/attribute_values/${props.valueId}/standard_value`, { standard_value: { ...value, value: e.currentTarget.value }, standard_id: props.standard.id })
      .then(res => setValue({ ...res }))
  }

  const options = Array.from(new Array(100), (val,index) => index+1)
  let selectedOption = value.value ? value.value : options.first
  selectedOption = selectedOption || ' '

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Chip
          key={props.standard.id}
          label={props.standard.name}
          title={props.standard.name}
          onDelete={() => props.onDelete(props.standard.id)}
          className={classes.chip}
          deleteIcon={<CloseIcon className={classes.icon}/>}
        />
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth variant="outlined">
          <InputLabel ref={standardsLabel} htmlFor={`sv-${props.standard.id}`}>{t('products.standardValue')}</InputLabel>
          <Select
            native
            value={selectedOption}
            onChange={handleChange}
            input={
              <OutlinedInput
                name={`sv-${props.standard.id}`}
                id={`sv-${props.standard.id}`}
                labelWidth={standardsLabelWidth} />
            }
          >
            <option key={'yes'} value={true}>Ja</option>
            {options.map(o =>
              <option key={o} value={o}>{o}</option>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
Standard.propTypes = {
  valueId: PropTypes.number.isRequired,
  standard: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default Standard
