import React, { useState } from 'react'

import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { makeStyles } from '@mui/styles'

import NavTabs from './NavTabs'
import PanelLink from './panelLink'


const TopMenu = () => {

  const classes = makeStyles(() => ({
    toolbar: {
      padding: 0,
      backgroundColor: process.env.NODE_ENV !== 'production' && '#08016B', // Example: Different colors
    },
    appbar: {
      boxShadow: 'inset 0 1px 0 rgba(255,255,255,0.15), 0 1px 5px rgba(0,0,0,0.075)',
      backgroundColor: process.env.NODE_ENV !== 'production' && '#08016B',
      backgroundImage: process.env.NODE_ENV !== 'production' ? 'linear-gradient(to bottom, #08016B, #08016B)'
      : 'linear-gradient(to bottom, #3c3c3c 0%, #222 100%)',
    }
  }))()

  return (
    <>
      <AppBar position="sticky" title="Medtryck Panel" className={classes.appbar}>
        <Container style={{color: '#9d9d9d'}}>
          <Toolbar variant="dense" className={classes.toolbar}>
            <NavTabs />
          </Toolbar>
        </Container>
        <PanelLink />
      </AppBar>
    </>
  )
}

export default TopMenu
