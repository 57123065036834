import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const MarkingPrice = (props) => {
  const [price, setPrice] = useState(props.price);

  useEffect(() => {
    setPrice(props.price);
  }, [props.price]);

  const toClientPrice = (p) => (!!p ? String(p).replace(".", ",") : null);
  const handleChange = (e) => {
    const _price = { ...price, [e.currentTarget.name]: e.currentTarget.value };
    props.onChange(_price);
  };

  return (
    <Grid item xs={1}>
      <FormControl margin="normal" fullWidth variant="outlined">
        <TextField
          fullWidth
          label="Qty"
          name="quantity"
          value={price.quantity || ""}
          onChange={handleChange}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="normal" fullWidth variant="outlined">
        <TextField
          fullWidth
          label="In"
          name="our_price"
          value={toClientPrice(price.our_price) || ""}
          onChange={handleChange}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="normal" fullWidth variant="outlined">
        <TextField
          fullWidth
          label="Out"
          name="customer_price"
          value={toClientPrice(price.customer_price) || ""}
          onChange={handleChange}
          variant="outlined"
        />
      </FormControl>
    </Grid>
  );
};

MarkingPrice.propTypes = {
  price: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default MarkingPrice;
