import React from 'react'
import PropTypes from 'prop-types'

import FormContainer from './FormContainer'

let initialState = { name: '', type: '', unit: '', p_attribute_values: [], parent: {} }

const New = (props) => {
  return(
    <FormContainer attribute={initialState} history={props.history}/>
  )
}

New.propTypes = {
  history: PropTypes.object
}

export default New