import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Template from "./Template";
import TemplateForm from "./TemplateForm";
import LoaderList from "@common/Loaders/LoaderList";
import { parseErrorMessage } from "@src/utils/sharedHelper";
import PopupAlert from "@contexts/PopupAlert";
import ProductResourceContext from "@contexts/ProductResource";
import { ProductApi } from "@src/apis";

const Printing = (props) => {
  const [markingTemplates, setMarkingTemplates] = useState([]);
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);
  const addPopupAlert = useContext(PopupAlert);
  const productResource = useContext(ProductResourceContext);
  const { t } = useTranslation();
  const isSupplierProduct = productResource === "supplier_products";

  useEffect(() => {
    setLoading(true);
    ProductApi.getTemplates(props.match.params.id, productResource)
      .then((templates) => {
        setMarkingTemplates(templates);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  const addTemplate = (markingTemplateId) => {
    ProductApi.addTemplate(
      props.match.params.id,
      {
        product_type: productResource,
        marking_template_id: markingTemplateId,
      },
      productResource,
    )
      .then((templates) => {
        setMarkingTemplates(templates);
        setCreating(false);
        addPopupAlert(t("marking_templates.actions.add"), "success");
      })
      .catch((e) => {
        addPopupAlert(
          t("marking_templates.actions.add"),
          "success",
          parseErrorMessage(e.message),
        );
      });
  };

  const handleMarkingTemplateDelete = (id) => {
    if (window.confirm("Är du säker?")) {
      ProductApi.deleteTemplate(props.match.params.id, id, productResource)
        .then(() => {
          setMarkingTemplates(markingTemplates.filter((t) => t.id !== id));
          addPopupAlert(t("marking_templates.actions.delete"), "success");
        })
        .catch((e) => {
          addPopupAlert(
            t("marking_templates.actions.delete"),
            "error",
            parseErrorMessage(e.message),
          );
        });
    }
  };

  return (
    <>
      {loading ? (
        <LoaderList />
      ) : (
        <>
          {markingTemplates.map((template) => {
            return (
              <Template
                key={template.id}
                supplierProductsView={isSupplierProduct}
                markingTemplate={template}
                productId={props.match.params.id}
                onDelete={handleMarkingTemplateDelete}
              />
            );
          })}
          {creating ? (
            <TemplateForm
              productId={props.match.params.id}
              addTemplate={addTemplate}
              setCreating={setCreating}
            />
          ) : isSupplierProduct ? (
            <></>
          ) : (
            <Grid item xs={1}>
              <Fab color="primary" size="small" aria-label="add" onClick={() => setCreating(true)}>
                <AddIcon />
              </Fab>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
Printing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Printing;
