import HttpClient from "../components/HttpClient"
import { setUrlForGetRequest } from '@api/SharedFunctions'

const CAMPAIGN_API = '/campaigns'

const getCampaigns = (params) => {
  return HttpClient.get(setUrlForGetRequest(CAMPAIGN_API, params));
}

const getCampaign = (id) => {
  return HttpClient.get(`${CAMPAIGN_API}/${id}`)
}

const removeCampaign = (id) => {
  return HttpClient.delete(`${CAMPAIGN_API}/${id}`)
}

const createCampaign = (data) => {
  return HttpClient.post(`${CAMPAIGN_API}`, data)
}

const updateCampaign = (id, data) => {
  return HttpClient.put(`${CAMPAIGN_API}/${id}`, data)
}

const CampaignApi = {
  getCampaigns,
  getCampaign,
  removeCampaign,
  createCampaign,
  updateCampaign
}

export {CampaignApi}
