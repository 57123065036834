import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../HttpClient'
import Form from './Form'

const Edit = props => {
  const initialState = { id: props.match.params.id, name: '', symbol: '' }
  const [unit, updateUnit] = useState(initialState)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/units/${unit.id}`)
      .then(data => {
        updateUnit(data)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  },[unit.id])
  return(
    <Form unit={unit} created={props.location?.state?.created} loading={loading} history={props.history}/>
  )
}
Edit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default Edit
