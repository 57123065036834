import React from 'react'
import PropTypes from 'prop-types'
import { Grid, MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ListTableActions = ({ children, searchText, getData, sortOptions, sortOptionSelected, searchTerm, searchComponent }) => {
  const { t } = useTranslation()

  const handleChange = (event) => {
    getData(event.target.name, event.target.value)
  }

  return (
    <Grid container item xs={12} alignItems={'center'} spacing={1} style={{marginTop: '1rem'}}>
      <Grid item xs={6}>
        {
          searchComponent ?
            searchComponent
            :
            <TextField
              id="searchName"
              type="text"
              label={searchText}
              name="search"
              size="small"
              variant='outlined'
              value={searchTerm}
              fullWidth
              onChange={(event) => handleChange(event)}/>
        }
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="sort"
          label={t('sorting.title')}
          value={sortOptionSelected}
          placeholder={t('sorting.title')}
          variant='outlined'
          size='small'
          onChange={(event) => handleChange(event)}
          select
          fullWidth>
          {Object.keys(sortOptions).map(option => (
            <MenuItem key={option} value={sortOptions[option]}>{option}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        {children}
      </Grid>
    </Grid>
  )
}

ListTableActions.propTypes = {
  searchText: PropTypes.string,
  searchTerm: PropTypes.string,
  getSearchedProductVariants: PropTypes.func,
  sortOptionSelected: PropTypes.string,
  getSortedVariants: PropTypes.func,
  sortOptions: PropTypes.object
}

export default ListTableActions
