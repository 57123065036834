import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Grid, Box, Switch } from "@mui/material";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Chip,
  Checkbox,
} from "@mui/material";
import { FormControl, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import CheckIcon from "@mui/icons-material/Check";

import { invertHex } from "@utils";

const Article = (props) => {
  const [article, setArticle] = useState(props.article);
  const [stockPopoverEl, setStockPopoverEl] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(
    props.selectedArticle || false,
  );

  const { t } = useTranslation();

  useEffect(() => {
    setArticle(props.article);
  }, [props.article]);

  useEffect(() => {
    if (props.type !== "modal") {
      setSelectedArticle(props.selectAllArticles);
      props.setSelectedArticles(
        props.selectedArticles?.map((article) => props.selectAllArticles),
      );
    }
  }, [props.selectAllArticles]);

  const classes = makeStyles((theme) => ({
    media: {
      height: article?.color_group?.product_images ? 0 : 35,
      paddingTop: "56.25%", // 16:9
    },
    chip: {
      margin: theme.spacing(1),
      border: "1px solid #e0e0e0",
    },
    articleWraper: {
      position: "relative",
    },
    disabledOverlay: {
      position: "absolute",
      backgroundColor: "#dddddd",
      opacity: "0.4",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
  }))();

  const handleActiveChange = (e) => {
    setArticle({ ...article, active: e.target.checked });
    props.saveArticle({ ...article, active: e.target.checked });
  };

  const handleOnSelectArticle = (e) => {
    setSelectedArticle(e.target.checked);
    let _selectedArticles = [...props.selectedArticles];
    _selectedArticles[props.articleIndex] = e.target.checked;
    props.setSelectedArticles(_selectedArticles);
  };

  const handleEditArticleClick = () => {
    props.setSelectedArticle(props.article);
    props.setModalContent("mass_edit");
    props.setMassActionModalOpen(true);
  };

  const attributeLabel = (attributeValue) => {
    const unit = attributeValue.p_attribute.unit;
    if (attributeValue.p_attribute.type === "boolean_or_number")
      return `${attributeValue.p_attribute.name}${
        attributeValue.value !== true &&
        ` ${attributeValue.value}${unit && unit.symbol ? unit.symbol : ""}`
      }`;
    if (attributeValue.p_attribute.type === "switch")
      return attributeValue.value === true
        ? attributeValue.p_attribute.name
        : "";
    return `${attributeValue.p_attribute.name}: ${attributeValue.value} ${
      unit && unit.symbol ? unit.symbol : ""
    }`;
  };

  const disabledOverlay = article.active ? null : (
    <div className={classes.disabledOverlay}></div>
  );

  const sotckPopoverOpen = Boolean(stockPopoverEl);
  const sotckPopoverId = sotckPopoverOpen ? "stock-popover" : undefined;

  return (
    <Grid item key={article.id} xs={props.gridSize || 3}>
      <div className={classes.articleWraper}>
        {disabledOverlay}
        <Card key={article.id}>
          <CardHeader
            avatar={
              <React.Fragment>
                {article.article_number && <Chip label={article.article_number} />}
                {article.used ? (
                  <Chip color="primary" label={<DoneIcon />} />
                ) : null}
              </React.Fragment>
            }
            action={
              props.type !== "modal" && (
                <>
                  {props.supplierProductsView ? (
                    <Chip
                      label={article.state}
                      color={article.state === "merged" ? "success" : "primary"}
                    />
                  ) : (
                    <Switch
                      checked={String(article.active) === String(true)}
                      onChange={handleActiveChange}
                      color="primary"
                    />
                  )}
                  <Checkbox
                    disabled={
                      props.supplierProductsView && article.state === "merged"
                    }
                    checked={selectedArticle}
                    onChange={handleOnSelectArticle}
                    color="primary"
                  />
                </>
              )
            }
          />
          <CardMedia
            className={classes.media}
            image={
              article.color_group && article.color_group.product_images
                ? article.color_group.product_images[0].image_url
                : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
            title={`${article.id} bild`}
            style={{ backgroundSize: "contain" }}
          />
          <CardContent>
            {article.color_group &&
              article.color_group.colors &&
              article.color_group.colors.map((c) => (
                <Chip
                  key={c.id}
                  label={c.name}
                  style={{
                    backgroundColor: `#${c.code ? c.code : ""}`,
                    color: `#${c.code ? invertHex(c.code) : ""}`,
                  }}
                  className={classes.chip}
                />
              ))}
            {article.p_attribute_values &&
              article.p_attribute_values.map((av) => {
                return (
                  <Chip
                    key={av.id}
                    label={attributeLabel(av)}
                    className={classes.chip}
                  />
                );
              })}
            <Box mt={2}>{article.supplier && article.supplier.name}</Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Leverantörsnummer"
                margin="dense"
                disabled={
                  !article.active ||
                  props.supplierProductsView ||
                  props.type === "modal"
                }
                value={article.supplier_article_number || ""}
                onChange={(e) =>
                  setArticle({
                    ...article,
                    supplier_article_number: e.currentTarget.value,
                  })
                }
                onBlur={(e) =>
                  props.saveArticle({
                    ...article,
                    supplier_article_number: e.currentTarget.value,
                  })
                }
                variant="outlined"
              />
            </FormControl>
            <Grid container>
              <Grid item xs={10}>
                {article.stock_direct ? (
                  <Box mt={2}>
                    <Chip
                      icon={<Inventory2OutlinedIcon />}
                      label={
                        article.stock_always_available ? (
                          <CheckIcon />
                        ) : (
                          `${article.stock_direct} st`
                        )
                      }
                      onClick={(e) => setStockPopoverEl(e.currentTarget)}
                    />
                    <Popover
                      id={sotckPopoverId}
                      open={sotckPopoverOpen}
                      anchorEl={stockPopoverEl}
                      onClose={() => setStockPopoverEl(null)}
                      disablePortal
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Box p={2} color="#fff" bgcolor="info.main">
                        {article.stock_always_available ? (
                          <Typography variant="body2">
                            {t("articles.stockAlwaysAvailable")}
                          </Typography>
                        ) : (
                          <>
                            <Typography variant="body2">
                              Current stock: {article.stock_direct} st
                            </Typography>
                            <Typography variant="body2">
                              Next stock: {article.stock_next_po} st (by{" "}
                              {article.stock_date_next_po})
                            </Typography>
                            {article.stock_future ? (
                              <Typography variant="body2">
                                Future stock: {article.stock_future} st
                              </Typography>
                            ) : null}
                          </>
                        )}
                      </Box>
                    </Popover>
                  </Box>
                ) : null}
              </Grid>
              {!props.supplierProductsView && props.type !== "modal" && (
                <Grid item xs={2}>
                  <Box mt={2}>
                    <IconButton>
                      <EditIcon onClick={handleEditArticleClick} />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
};

Article.propTypes = {
  article: PropTypes.object.isRequired,
  saveArticle: PropTypes.func,
};

export default Article;
