import React from 'react'
import { Grid, Skeleton } from '@mui/material'
import Mdivider from '../Mdivider'

const LoaderShowSingle = () => (
  <React.Fragment>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
      </Grid>
      <Mdivider />
      <Grid container justifyContent="flex-end">
        <Skeleton variant="text" width={150} height={50} />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default LoaderShowSingle
