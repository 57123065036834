import React from "react";
import MuiColorPicker from "material-ui-color-picker";
import PropTypes from "prop-types";

const ColorPicker = ({ label, value, onChange }) => {
  if (!!value) {
    value = String(value);
    if (!value?.startsWith("#")) value = `#${value}`;
  }

  return (
    <MuiColorPicker
      label={label}
      value={value ?? ""}
      onChange={(code) => {
        if (!!code) onChange(code.split("#")[1]);
        else onChange();
      }}
      TextFieldProps={{
        variant: "outlined",
        value: value,
      }}
      convert="hex"
    />
  );
};

ColorPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
