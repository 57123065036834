import HttpClient from "@httpClient"
import { setUrlForGetRequest } from '@api/SharedFunctions'

const CATEGORY_API = '/categories'

const getCategories = (params) => {
  return HttpClient.get(setUrlForGetRequest(CATEGORY_API, params))
}

const getCategory = (id) => {
  return HttpClient.get(`${CATEGORY_API}/${id}`)
}

const checkPermalinkExists = (params) => {
  return HttpClient.get(setUrlForGetRequest(`${CATEGORY_API}/check_permalink`, params))
}

const removeCategory = (id) => {
  return HttpClient.delete(`${CATEGORY_API}/${id}`)
}

const createCategory = (data) => {
  return HttpClient.post(`${CATEGORY_API}`, data)
}

const updateCategory = (id, data) => {
  return HttpClient.put(`${CATEGORY_API}/${id}`, data)
}

const getParentOptions = () => {
  return HttpClient.get(`${CATEGORY_API}/parent_options`)
}

const searchCategories = (params) => {
  let url = setUrlForGetRequest(`${CATEGORY_API}/search`, params)
  return HttpClient.get(url)
}

const getSubCategories = (id) => {
  return HttpClient.get(`${CATEGORY_API}/${id}/sub_categories`)
}

const getProducts = (id) => {
  return HttpClient.get(`${CATEGORY_API}/${id}/products`)
}

//Encapsulating in a JSON object
const CategoryApi = {
  getCategories,
  getCategory,
  removeCategory,
  createCategory,
  updateCategory,
  getParentOptions,
  searchCategories,
  getSubCategories,
  getProducts,
  checkPermalinkExists
}

export { CategoryApi }
