import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import List from './List'
import Edit from './Edit'

const MarkingTemplates = (props) => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${props.match.path}/`} component={List}></Route>
        <Route exact path={`${props.match.path}/new`} component={Edit}></Route>
        <Route exact path={`${props.match.path}/:id/edit`} component={Edit}></Route>
      </Switch>
    </React.Fragment>
  )
}
MarkingTemplates.propTypes = {
  match: PropTypes.object
}

export default MarkingTemplates