import React from 'react'
import PropTypes from 'prop-types'
import ImageIcon from '@mui/icons-material/Image'

const styles = {
  imageContainer: {
    border: 'solid 0.06rem #ddd',
    borderRadius: '0.25rem',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  image:{
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}

const ImageThumbnail = ({ imageUrl, height, width, alt, imageHeight, imageWidth }) => {
  return (
    <div style={{ ...styles.imageContainer, width: width || styles.imageContainer.width, height: height || styles.imageContainer.height }}>
      {imageUrl ? (<img style={{ ...styles.image, width: imageWidth || styles.image.width, height: imageHeight || styles.image.height }} src={imageUrl ? imageUrl : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='} alt={alt || ''} />) : (<ImageIcon style={styles.image} fontSize="large" />)}
    </div>
  )
}

ImageThumbnail.propTypes = {
  imageUrl: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  alt: PropTypes.string,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string
}

export default ImageThumbnail
