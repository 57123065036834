import HttpClient from "@httpClient";
import { singularize } from "@src/utils/sharedHelper";

const savePrintOption = (id, productId, params, resource = "products") => {
  return HttpClient.put(
    `/${resource}/${productId}/print_options/${id}`,
    params,
  );
};

const addImage = (id, productId, params, resource = "products") => {
  return HttpClient.post(
    `/${resource}/${productId}/print_options/${id}/image`,
    params,
  );
};

const deleteImage = (id, productId, imageUid, resource = "products") => {
  return HttpClient.delete(
    `/${resource}/${productId}/print_options/${id}/image?product_type=${singularize(
      resource,
    )}&uid=${imageUid}`,
  );
};

const create = (productId, params, resource = "products") => {
  return HttpClient.post(`/${resource}/${productId}/print_options`, params);
};

const index = (productId, markingTemplateId, resource = "products") => {
  return HttpClient.get(
    `/${resource}/${productId}/print_options?product_type=${singularize(
      resource,
    )}&per_page=100&filter[marking_template_id]=${markingTemplateId}`,
  );
};

const deletePrintOption = (
  id,
  productId,
  markingTemplateId,
  resource = "products",
) => {
  const url = !!markingTemplateId
    ? `/marking_templates/${markingTemplateId}/print_options/${id}`
    : `/${resource}/${productId}/print_options/${id}`;
  return HttpClient.delete(url);
};

const PrintOptionApi = {
  savePrintOption,
  addImage,
  deleteImage,
  create,
  index,
  deletePrintOption,
};

export { PrintOptionApi };
