import HttpClient from "../components/HttpClient";
import { setUrlForGetRequest } from "@api/SharedFunctions";

const COLOR_API = "/colors";

const getColors = (params) => {
  return HttpClient.get(setUrlForGetRequest(COLOR_API, params));
};

const getColor = (id) => {
  return HttpClient.get(`${COLOR_API}/${id}`);
};

const removeColor = (id) => {
  return HttpClient.delete(`${COLOR_API}/${id}`);
};

const createColor = (data) => {
  return HttpClient.post(`${COLOR_API}`, data);
};

const updateColor = (id, data) => {
  return HttpClient.put(`${COLOR_API}/${id}`, data);
};

const getParentOptions = () => {
  return HttpClient.get(`${COLOR_API}/parent_options`);
};

//Encapsulating in a JSON object
const ColorApi = {
  getColor,
  getColors,
  removeColor,
  createColor,
  updateColor,
  getParentOptions,
};

export { ColorApi };
