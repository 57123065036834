import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, Grid, Paper, Box } from '@mui/material'
import { ScrollableBox } from '@components/mui-styled/components'

import { AsyncAutoComplete, Mdivider } from '@common/index'
import { InlineLoader } from '@common/Loaders'

import ItemSelectedComponent from '@shared/ItemSelectedComponent'
import { QuestionApi } from '@api'
import Form from '@components/questions/Form'

import PopupAlert from "@contexts/PopupAlert";

const QuestionModal = ({ ques, categories, types, closeModal, listingPage, tabValue, handleUpdateQuestion, productId  }) => {
  const { t } = useTranslation()
  const addPopupAlert = useContext(PopupAlert)

  const [question, setQuestion] = useState(ques)
  const [questionProducts, setQuestionProducts] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    setLoading(true)
    const products = await QuestionApi.getProducts(ques.id)
    setQuestionProducts(products)
    setLoading(false)
  }, [])

  const saveQuestion = async () => {
    // Add confirmation in case of product page to alert the user that it will effect all products.
    if (!listingPage && !window.confirm(t('alertTexts.questionDeletionConfirmation'))){
      return
    }
    const questionProductIds = questionProducts.map(p => p.id)

    if (listingPage && question.type !== tabValue){
      handleUpdateQuestion(question, true)
    } else if (!listingPage && !questionProductIds.includes(productId)){
      handleUpdateQuestion(question, true)
    } else {
      handleUpdateQuestion(question, false)
    }

    await QuestionApi.updateQuestion({ ...question, product_ids: questionProductIds })

    addPopupAlert(
      t("questions.messages.updated"),
      "success",
    );
    closeModal()
  }

  const handleRemoveProduct = (quesId) => {
    setQuestionProducts(questionProducts.filter(ques => ques.id !== quesId))
  }

  const handleAddProduct = (que) => {
    let filterQuestion = questionProducts.find(q => q.id === que.id)
    if (!filterQuestion) {
      let _questionProducts = [...questionProducts]
      _questionProducts.push(que)
      setQuestionProducts(_questionProducts)
    }
  }

  const handleChange = (e) => {
    if (e.target.type === 'checkbox'){
      setQuestion({ ...question, [e.target.name]: e.target.checked })
    } else {
      setQuestion({ ...question, [e.target.name]: e.target.value })
    }
  }

  return (
    <Grid container gap={2}>
      <Grid item xs={question.type === 'generic' ? 12 : 5}>
        <Paper sx={{ padding: '16px' }}>
          <Form
            types={types}
            setQuestion={handleChange}
            question={question}
            categories={categories}
          />
        </Paper>
      </Grid>
      {
        question.type !== 'generic' &&
        <Grid item xs={6}>
          <Paper sx={{ padding: '16px' }}>
            <h2>{t('products.title')}</h2>
            <AsyncAutoComplete onChange={handleAddProduct} optionsUrl={'/products?type=search&q'}/>
            <ScrollableBox>
              {
                loading
                  ?
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <InlineLoader/>
                  </Box>
                  :
                  questionProducts.map((product) => (
                    <ItemSelectedComponent
                      selectedItem={product}
                      type={'products'}
                      handleUnselect={handleRemoveProduct}
                      size={'full'}
                    />
                  ))
              }
            </ScrollableBox>
          </Paper>
        </Grid>
      }
      <Mdivider/>
      <Grid item xs={12} align="right">
        <Button variant="contained" color="primary" onClick={saveQuestion}>{t('actions.save')}</Button>
      </Grid>
    </Grid>
  )
}

QuestionModal.propTypes = {
  ques: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired
}

export default QuestionModal
