import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {Container, Grid} from '@mui/material'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import {TableRow, TableCell} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { makeStyles } from '@mui/styles'
import TitleBar from '@components/topbar/TitleBar'

import { AttributeApi } from '@api/AttributeApi'
import ListTable from '@common/ListingTable/ListTable'
import Loader from '@common/Loaders/LoaderList'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { NameSorting } from "@config/SortingLabels";
import { useDebounce } from '@customHooks/useDebouncing'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import HttpClient from '@httpClient'


const List = props => {
  const attributesPerPage = getUserPreference( 'page_size', 'attribute')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( attributesPerPage !== '' ? attributesPerPage : 20)
  const [totalCount, setTotalCount] = useState(0)
  const [attributes, setAttributes] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOptionSelected, setSortOptionSelected] = useState('name_ascending')
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    let didCancel = false
    fetchAttributes({ page: 1, per_page: perPage, query: searchTerm });
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const removeAttribute = id => {
    if(window.confirm('Är du säker?')) {
      AttributeApi.removeAttribute(id).then(() => {
        setAttributes(attributes.filter(a => a.id !== id))
      })
    }
  }

  const fetchAttributes = async (params) => {
    setLoading(true)
    if (!params.sort_by) {
      params['sort_by'] = sortOptionSelected
    }
    try {
      const response = await AttributeApi.getAttributes(params)
      if(response) {
        setAttributes(response.data)
        setTotalCount(response.total_count)
      }
    }
    catch(error) {
      console.log("error", error)
    }
    finally {
      setLoading(false)
    }
  }

  const handleSearchParamsChange = (type, value) => {
    switch (type) {
      case "page":
        setPage(value);
        fetchAttributes({ page: value, per_page: perPage})
        break;

      case "pageSize":
        setPerPage(value)
        setPage(1)
        setUserPreference('page_size', 'attribute', value)
        fetchAttributes({ page: 1, per_page: value})
        break;

      case "sort":
        setSortOptionSelected(value)
        fetchAttributes({ page: 1, per_page: perPage, sort_by: value });
        break;

      case "search":
        setSearchTerm(value);
        break;
    }
  }

  const handlePageAndPerPageChange = (type, value) => {
    if(type === 'page'){
      setPage(value)
      fetchAttributes(value, perPage)
    } else if (type === 'pageSize'){
      setPerPage(value)
      setPage(1)
      setUserPreference('page_size', 'attribute', value)
      fetchAttributes(1, value)
    }
  }

  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()
  const attributesTableContent = () => {
    const attributesBody = attributes?.map((attribute) => (
      <TableRow key={attribute.id}>
        <TableCell><Link to={`attributes/${attribute.id}/edit`}>{attribute.name}</Link></TableCell>
        <TableCell>{t(attribute.type)}</TableCell>
        <TableCell>{attribute.unit?.name}</TableCell>
        <TableCell>{attribute.parent?.name}</TableCell>
        <TableCell align="right">
          <IconButton
            component={Link}
            to={`attributes/${attribute.id}/edit`}
            aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => removeAttribute(attribute.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
    return { body: attributesBody, heading: ['Namn', 'Typ', 'Enht', 'Förälderattribut', ''] }
  }
  const tableContent = attributesTableContent()
  return (
    <React.Fragment>
      <TitleBar title="Attribut">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/attributes/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        <Grid container>
          <ListTableActions
            searchTerm={searchTerm}
            sortOptionSelected={sortOptionSelected}
            sortOptions={NameSorting}
            searchText={t('attributes.placeholders.search_label')}
            getData={handleSearchParamsChange}
          />
          {loading ? <Loader /> : (
            <Paper square className={classes.root}>
              <ListTable
                tableHeadings={tableContent.heading}
                tableContent={tableContent.body}
                count={totalCount}
                page_no={page}
                rowsPerPage={perPage}
                onPageChange={(event, page_no) => handlePageAndPerPageChange('page', page_no + 1)}
                handleChangeRowsPerPage={(event) => handlePageAndPerPageChange('pageSize', event.target.value)}
                rowsControl={true} />
            </Paper>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  )
}
List.propTypes = {
  history: PropTypes.object
}
export default List
