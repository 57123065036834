import HttpClient from "../components/HttpClient"
import { setUrlForGetRequest } from '@api/SharedFunctions'

const MARKING_METHOD_API = '/marking_methods'

const getMarkingMethods = (params) => {
  return HttpClient.get(setUrlForGetRequest(MARKING_METHOD_API, params))
}

const getMarkingMethod = (id) => {
  return HttpClient.get(`${MARKING_METHOD_API}/${id}`)
}

const removeMarkingMethod = (id) => {
  return HttpClient.delete(`${MARKING_METHOD_API}/${id}`)
}

const createMarkingMethod = (data) => {
  return HttpClient.post(`${MARKING_METHOD_API}`, data)
}

const updateMarkingMethod = (id, data) => {
  return HttpClient.put(`${MARKING_METHOD_API}/${id}`, data)
}

//Encapsulating in a JSON object
const MarkingMethodApi = {
  getMarkingMethod,
  getMarkingMethods,
  removeMarkingMethod,
  createMarkingMethod,
  updateMarkingMethod,
}

export {MarkingMethodApi}
