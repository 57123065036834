import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import makeStyles from '@mui/styles/makeStyles'

import AsyncAutocomplete from '../../common/AsyncAutoComplete'
import HttpClient from '../../HttpClient'
import Addon from './Addon'
import Mdivider from '../../common/Mdivider'
import LoaderList from '../../common/Loaders/LoaderList'

const Addons = ({ match }) => {
  const [addons, setAddons] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/products/${match.params.id}/addons`)
      .then(res => {
        setAddons(res)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [match.params.id])

  const addAddon = selected => {
    HttpClient.post(`/products/${match.params.id}/addons`, { id: selected.id })
      .then(res => {
        setAddons(res)
      })
  }
  
  const removeAddon = id => {
    window.confirm('Är du säker?') && HttpClient.delete(`/products/${match.params.id}/addons/${id}`, { id: id })
      .then(() => {
        setAddons([...addons.filter(a => a.id !== id)])
      })
  }

  return (
    <React.Fragment>
      {loading ? <LoaderList /> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <AsyncAutocomplete defaultInputValue='' optionsUrl='/products/search?addons=true&&term' onChange={addAddon} />
              </FormControl>
            </Grid>
          </Grid>
          <Mdivider/>
          <Paper square className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell><h3>Namn</h3></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { addons.map( addon =>
                  <TableRow key={addon.id}>
                    <TableCell><Link to={`products/${addon.id}/edit`}>{addon.name}</Link></TableCell>
                    <TableCell align="right">
                      <Typography noWrap>
                        <IconButton onClick={() => removeAddon(addon.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </>
      )}
    </React.Fragment>
  )
}
Addons.propsTypes = {
  match: PropTypes.object.isRequired
}

export default Addons
