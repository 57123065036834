import React from "react";

import { Grid, Divider } from "@mui/material";

const Mdivider = ({ children, ...rest }) => {
  const styles = {
    root: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider variant="fullWidth" sx={styles.root} {...rest}>
          {children}
        </Divider>
      </Grid>
    </Grid>
  );
};

export default Mdivider;
