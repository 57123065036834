import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'


import {Container, Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import ArrowBack from '@mui/icons-material/ArrowBack'

import TitleBar from '../topbar/TitleBar'
import Mdivider from '../common/Mdivider'
import HttpClient from '../HttpClient'
import LoaderShowSingle from '../common/Loaders/LoaderShowSingle'
import Alert from '@mui/material/Alert'
import useAlert from '@customHooks/useAlert'
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';

const Form = props => {
  const [currency, setCurrency] = useState(props.currency)
  const [alert, setAlert] = useAlert(props.created)

  useEffect(() => {
    const _currency = {...props.currency}
    setCurrency(_currency)
  },[props.currency])

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: currency.name, rules: { required: true } },
    symbol: { value: currency.symbol, rules: { required: true } },
    iso_code: { value: currency.iso_code, rules: { required: true } },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveCurrency();
    }
  };

  const saveCurrency = () => {
    if(currency.id) {
      HttpClient.put(`/currencies/${currency.id}`, {currency: currency})
        .then(() => setAlert({ severity: 'success', text: 'Updated successfully!' }))
        .catch(error => setAlert({ severity: 'error', text: error.message }))
    } else {
      HttpClient.post('/currencies', {currency: currency})
        .then((res) => {
          props.history.push({
            pathname: `/currencies/${res.id}/edit`,
            state: { created: true }
          })
        })
        .catch(error => setAlert({ severity: 'error', text: error.message }))
    }
  }

  return(
    <React.Fragment>
      <TitleBar title={`${currency.id ? 'Radigera' : 'Ny'} valuta`}>
        <Fab color="secondary" size="small" aria-label="add" onClick={() => { props.history.push('/currencies')}}>
          <ArrowBack />
        </Fab>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>{alert.text}</Alert>
        )}
        {props.loading ? <LoaderShowSingle /> : (
          <Grid container>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Namn"
                  value={currency.name || ''}
                  onChange={e => setCurrency({...currency, name: e.currentTarget.value})}
                  variant="outlined"
                  error={!!validationErrors.name}
                  helperText={validationErrors.name} />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Iso Code"
                  value={currency.iso_code || ''}
                  onChange={e => setCurrency({...currency, iso_code: e.currentTarget.value})}
                  variant="outlined"
                  error={!!validationErrors.iso_code}
                  helperText={validationErrors.iso_code} />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Unit"
                  value={currency.unit || ''}
                  onChange={e => setCurrency({...currency, unit: e.currentTarget.value})}
                  variant="outlined" />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Symbol"
                  value={currency.symbol || ''}
                  onChange={e => setCurrency({...currency, symbol: e.currentTarget.value})}
                  variant="outlined"
                  error={!!validationErrors.symbol}
                  helperText={validationErrors.symbol} />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Konverteringskurser"
                  value={currency.conversion_rate || ''}
                  onChange={e => setCurrency({...currency, conversion_rate: e.currentTarget.value})}
                  variant="outlined" />
              </FormControl>
            </Grid>
            <Mdivider />
            <Grid item xs={12} align="right">
              <Button variant="contained" color="primary" onClick={handleSubmit}>Spara</Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}

Form.propTypes = {
  currency: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool
}

export default Form
