import React, { useState } from 'react'
import { ListItemIcon, IconButton, Switch, TableCell, Link } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import ImageThumbnail from '@shared/ImageThumbnail'

const Products = ({ object: product }) => {
  return (
    <>
      <TableCell>
        <ListItemIcon edge="start">
          <IconButton color="primary" size="medium">
            <DragIndicatorIcon/>
          </IconButton>
        </ListItemIcon>
      </TableCell>
      <TableCell>
        <ListItemIcon>
          <ImageThumbnail imageUrl={product?.image_url}/>
        </ListItemIcon>
      </TableCell>
      <TableCell>
        <Link href={`/products/${product.id}/edit`} target='_blank'>
          {product.name}
        </Link>
      </TableCell>
    </>
  )
}

export default Products
