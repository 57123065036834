import React, { useContext } from "react";
import PropTypes from "prop-types";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupAlert from "@contexts/PopupAlert";
import { useTranslation } from "react-i18next";
import { ProductApi } from "@src/apis";
import { parseErrorMessage } from "@src/utils/sharedHelper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ActionsMenu = ({ attachment, productId, onDelete, onEdit }) => {
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    const actionTitle = `${t("actions.delete")} ${t(
      "productAttachments.name",
    )}`;

    if (window.confirm(t("alertTexts.confirmation"))) {
      const api = ProductApi.deleteAttachment(productId, attachment.id, true);
      return api
        .then(() => {
          addPopupAlert(actionTitle, "success");
          onDelete();
          handleClose();
        })
        .catch((error) => {
          addPopupAlert(actionTitle, "error", parseErrorMessage(error.message));
        });
    }
  };

  return (
    <div>
      <IconButton color="secondary" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            handleClose();
          }}
        >
          <EditIcon sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <DeleteIcon sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

ActionsMenu.propTypes = {
  productId: PropTypes.string.isRequired,
  attachment: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionsMenu;
