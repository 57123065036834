import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import {
  TableBody,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material";
import produce from "immer";

import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { parseErrorMessage } from "@src/utils/sharedHelper";
import PopupAlert from "@contexts/PopupAlert";

import HttpClient from "../../HttpClient";
import LoaderList from "../../common/Loaders/LoaderList";
import useLoadableResource from "@customHooks/useLoadableResource";
import { useTranslation } from "react-i18next";
import { ProductApi } from "@src/apis";
import { MarkingMethodApi } from "@src/apis/MarkingMethodApi";
import FormModal from "@components/products/attachments/FormModal";
import ActionsMenu from "@components/products/attachments/ActionsMenu";

const ProductAttachments = ({ match }) => {
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);

  const {
    data: attachments,
    load,
    setData: setProductAttachments,
  } = useLoadableResource([], t("productAttachments.name"));

  const { data: markingMethods, load: loadMarkingMethods } =
    useLoadableResource([], t("marking_methods.plural"));

  const [currentAttachmentId, setCurrentAttachmentId] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handlePublicCheckboxChange = (attachment, checked) => {
    const actionTitle = t("productAttachments.actions.public");

    const api = ProductApi.editAttachment(
      attachment.id,
      attachment.product.id,
      { public: checked },
      true,
    );

    return api
      .then((res) => {
        addPopupAlert(actionTitle, "success");
        setProductAttachments(
          produce((draft) => {
            draft.find((i) => i.id === attachment.id).public = checked;
          }),
        );
      })
      .catch((error) => {
        addPopupAlert(actionTitle, "error", parseErrorMessage(error.message));
      });
  };

  const handleFileDownload = (attachment) => {
    ProductApi.downloadAttachment(attachment).catch((error) => {
      addPopupAlert(
        t("productAttachments.actions.fileDownload"),
        "error",
        parseErrorMessage(error.message),
      );
    });
  };

  useEffect(() => {
    load(ProductApi.getAttachments(match.params.id));
    loadMarkingMethods(MarkingMethodApi.getMarkingMethods({}));
  }, [match.params.id]);

  return (
    <React.Fragment>
      <Grid container alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h5" element="h5">
          {t("productAttachments.name")}
        </Typography>

        <Grid item sx={{ ml: 1 }}>
          <IconButton
            color="primary"
            aria-label="add"
            style={{
              backgroundColor: "lightblue",
              borderRadius: "50%",
              width: "28px",
              height: "28px",
            }}
            onClick={() => {
              setCurrentAttachmentId("");
              setEditModalOpen(true);
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      {attachments.loading ? (
        <LoaderList />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table align="center">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t("productAttachments.attributes.name")}
                  </TableCell>
                  <TableCell>
                    {t("productAttachments.attributes.type")}
                  </TableCell>
                  <TableCell>
                    {t("productAttachments.attributes.file")}
                  </TableCell>
                  <TableCell>{t("marking_methods.singular")}</TableCell>
                  <TableCell>
                    {t("productAttachments.attributes.public")}
                  </TableCell>
                  <TableCell>{t("vocabulary.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attachments.data?.map((attachment) => (
                  <TableRow key={attachment.id}>
                    <TableCell>
                      <Typography variant="body1">{attachment.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {t(
                          `productAttachments.attributes.typeOptions.${attachment.type}`,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" columnGap={1}>
                        <Typography variant="body1">
                          {attachment.original_file_name}
                        </Typography>
                        <IconButton
                          onClick={() => handleFileDownload(attachment)}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {attachment.type === "printing_template"
                          ? attachment.marking_method?.name ?? "--"
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        onChange={(e) => {
                          handlePublicCheckboxChange(
                            attachment,
                            e.target.checked,
                          );
                        }}
                        color="primary"
                        checked={attachment.public}
                      />
                    </TableCell>

                    <TableCell>
                      <ActionsMenu
                        attachment={attachment}
                        onEdit={() => {
                          setCurrentAttachmentId(attachment.id);
                          setEditModalOpen(true);
                        }}
                        onDelete={() => {
                          setProductAttachments(
                            attachments.data.filter(
                              (a) => a.id !== attachment.id,
                            ),
                          );
                        }}
                        productId={match.params.id}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <FormModal
        open={editModalOpen}
        productId={match.params.id}
        attachment={attachments.data.find(
          (attachment) => attachment.id === currentAttachmentId,
        )}
        onSave={(attachment) => {
          const index = attachments.data.findIndex(
            (a) => a.id === attachment.id,
          );
          if (index > -1) {
            const newAttachments = [...attachments.data];
            newAttachments[index] = attachment;
            setProductAttachments(newAttachments);
          } else {
            setProductAttachments([...attachments.data, attachment]);
          }
          setEditModalOpen(false);
          setCurrentAttachmentId("");
        }}
        onClose={() => {
          setEditModalOpen(false);
          setCurrentAttachmentId("");
        }}
        markingMethods={markingMethods.data.data}
      />
    </React.Fragment>
  );
};
ProductAttachments.propsTypes = {
  match: PropTypes.object.isRequired,
};

export default ProductAttachments;
