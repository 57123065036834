import React, { useState } from "react";
import { Route } from "react-router-dom";

import TopMenu from "@components/topbar/TopMenu";
import Container from '@mui/material/Container'
import Products from "@components/products";
import Attributes from "@components/attributes";
import Colors from "@components/colors";
import Countries from "@components/countries";
import Categories from "@components/categories";
import Brands from "@components/brands";
import Campaigns from "@components/campaigns";
import MarkingMethods from "@components/marking_methods";
import StartingCosts from "@components/starting_costs";
import Currencies from "@components/currencies";
import MarkingTemplates from "@components/marking_templates";
import HomePage from "@components/homepage";
import Users from "@components/users";
import Units from "@components/units";
import Standards from "@components/standards";
import Dashboard from "@components/dashboard/";
import Tags from "@components/tags";
import SupplierProducts from "@components/supplier_products";
import "../scss/shared.scss";
import PopupAlertContext from "@contexts/PopupAlert";
import BackdropLoaderContext from "@contexts/BackdropLoader";
import PopupAlert from "@components/common/PopupAlert";
import BackdropLoader from "@components/common/BackdropLoader";
import { useTranslation } from "react-i18next";
import HttpClient from "@components/HttpClient";
import SupplierIntegrationJobStats from "@components/supplier_integration_job_stats";
import Questions from '@components/questions'

import "../scss/shared.scss";

const Admin = () => {
  const initialStateOfPopupAlert = {
    severity: "",
    action: "",
    children: <></>,
  };
  const { t } = useTranslation();
  const [popupAlert, setPopupAlert] = useState(initialStateOfPopupAlert);
  const [backdropLoader, setBackdropLoader] = useState(false);
  const addPopupAlert = (
    action,
    severity,
    children = null,
    autoHideDuration = null,
  ) => {
    if (autoHideDuration === null && severity !== "error")
      autoHideDuration = 6000;
    if (severity === "success" && !children) children = t("vocabulary.success");
    if (
      typeof children === "string" ||
      children instanceof String ||
      !!children
    ) {
      children = <>{children}</>;
    }
    setPopupAlert(initialStateOfPopupAlert);
    setPopupAlert({ action, severity, children, autoHideDuration });
  };

  HttpClient.setBackdropLoader(setBackdropLoader);

  return (
    <React.Fragment>
      <PopupAlertContext.Provider value={addPopupAlert}>
        <BackdropLoaderContext.Provider value={setBackdropLoader}>
          <TopMenu />
          <div
            style={{
              paddingBottom: "20px",
            }}
          >
            <Route exact path="/" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/products" component={Products} />
            <Route path="/attributes" component={Attributes} />
            <Route path="/tags" component={Tags} />
            <Route path="/colors" component={Colors} />
            <Route path="/countries" component={Countries} />
            <Route path="/categories" component={Categories} />
            <Route path="/brands" component={Brands} />
            <Route path="/campaigns" component={Campaigns} />
            <Route path="/marking_methods" component={MarkingMethods} />
            <Route path="/starting_costs" component={StartingCosts} />
            <Route path="/currencies" component={Currencies} />
            <Route path="/marking_templates" component={MarkingTemplates} />
            <Route path="/homepage" component={HomePage} />
            <Route path="/user" component={Users} />
            <Route path="/units" component={Units} />
            <Route path="/standards" component={Standards} />
            <Route path="/supplier-products" component={SupplierProducts} />
            <Route path="/questions" component={Questions} />
            <Route
              path="/supplier-integration-job-stats"
              component={SupplierIntegrationJobStats}
            />
          </div>
        </BackdropLoaderContext.Provider>
      </PopupAlertContext.Provider>

      {!!popupAlert?.severity && (
        <PopupAlert
          action={popupAlert.action}
          severity={popupAlert.severity}
          autoHideDuration={popupAlert.autoHideDuration}
        >
          {popupAlert.children}
        </PopupAlert>
      )}
      {backdropLoader && <BackdropLoader />}
    </React.Fragment>
  );
};

export default Admin;
