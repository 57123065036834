import React, { useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

import { FormControl, Grid, TextField } from "@mui/material";
import Fab from "@mui/material/Fab";
import ProductResourceContext from "@contexts/ProductResource";
import PopupAlert from "@contexts/PopupAlert";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ProductReferenceImagesApi } from "@src/apis/ProductReferenceImagesApi";
import CreatableAsyncAutoComplete from "@components/common/CreatableAsyncAutoComplete";
import ColorChip from "@components/colors/ColorChip";
import { parseErrorMessage } from "@src/utils/sharedHelper";
import LoadingButton from "@components/common/LoadingButton";
import Select from "react-select";
import CancelIcon from "@mui/icons-material/Cancel";

const ProductReferenceImage = ({
  image,
  addColor,
  removeColor,
  onEdit,
  onDelete,
  onColorEdit,
  onAddMarkingMethod,
  onRemoveMarkingMethod,
  markingMethods,
}) => {
  const productResource = useContext(ProductResourceContext);
  const isSupplierProduct = productResource === "supplier_products";
  const addPopupAlert = useContext(PopupAlert);

  const [selectedMarkingMethod, setSelectedMarkingMethod] = useState(null);

  const { t } = useTranslation();

  let { id } = useParams();

  const onImageFormSubmit = async (values) => {
    return ProductReferenceImagesApi.put(id, productResource, image.id, values)
      .then((res) => {
        onEdit(image, values);
        addPopupAlert(t("actions.edit"), "success");
      })
      .catch((error) => {
        addPopupAlert(
          t("actions.edit"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const handleAddColor = (color) => {
    ProductReferenceImagesApi.addColor(id, productResource, image.id, {
      color_id: color.id,
    })
      .then((res) => {
        addColor(image, color);
      })
      .catch((error) => {
        addPopupAlert(
          t("productReferenceImages.actions.addColor"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const handleDeleteColor = (color) => {
    ProductReferenceImagesApi.removeColor(
      id,
      productResource,
      image.id,
      color.id,
    )
      .then((res) => {
        removeColor(image, color);
      })
      .catch((error) => {
        addPopupAlert(
          t("productReferenceImages.actions.removeColor"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const handleMarkingMethodAdd = () => {
    if (!selectedMarkingMethod) return;

    ProductReferenceImagesApi.addMarkingMethod(id, productResource, image.id, {
      marking_method_id: selectedMarkingMethod.id,
    })
      .then((res) => {
        onAddMarkingMethod(image, selectedMarkingMethod);
      })
      .catch((error) => {
        addPopupAlert(
          t("productReferenceImages.actions.addMarkingMethod"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const handleMarkingMethodRemove = () => {
    ProductReferenceImagesApi.removeMarkingMethod(id, productResource, image.id)
      .then((res) => {
        onRemoveMarkingMethod(image);
      })
      .catch((error) => {
        addPopupAlert(
          t("productReferenceImages.actions.removeMarkingMethod"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const handleRemoveReferenceImage = (image) => {
    ProductReferenceImagesApi.destroy(id, productResource, image.id)
      .then((res) => {
        onDelete(image);
      })
      .catch((error) => {
        addPopupAlert(
          t("productReferenceImages.actions.removal"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const formik = useFormik({
    initialValues: { description: image.description ?? "" },
    onSubmit: onImageFormSubmit,
    validate: (values) => {
      return {};
    },
  });

  const { values, handleChange } = formik;

  return (
    <li className="list-group-item list-group-item-action">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <CreatableAsyncAutoComplete
                optionsUrl={`/colors?exclude[product_reference_image_id]=${image.id}&filter`}
                onChange={handleAddColor}
                onCreateOption={handleAddColor}
                createUrl="/colors"
                resource="color"
                showLoader={true}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: "5px" }}>
            {image.colors.map((color) => (
              <ColorChip
                key={color.id}
                supplierProductsView={isSupplierProduct}
                color={color}
                onSave={(color) => {
                  onColorEdit(image, color);
                }}
                onDelete={() => {
                  window.confirm("Är du säker?") && handleDeleteColor(color);
                }}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={2}>
          {image.url && (
            <a href={image.url} target="_blank" rel="noopener noreferrer">
              <img
                src={image.url}
                alt="saknas"
                style={{ height: "100%", width: "100%" }}
              />
            </a>
          )}
        </Grid>
        <Grid container item xs={2}>
          <FormControl fullWidth>
            <TextField
              disabled={isSupplierProduct}
              fullWidth
              value={values.description}
              onChange={handleChange}
              onBlur={formik.submitForm}
              name="description"
              variant="outlined"
              rows={5}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={4}>
          {!image.marking_method && (
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Select
                  options={markingMethods?.map((markingMethod) => {
                    return {
                      label: markingMethod.name,
                      value: markingMethod.id,
                    };
                  })}
                  onChange={(markingMethod) =>
                    setSelectedMarkingMethod(
                      markingMethods.find(
                        (mm) => mm.id === markingMethod.value,
                      ),
                    )
                  }
                  disabled={isSupplierProduct}
                />
              </Grid>
              <Grid item xs={1}>
                <LoadingButton
                  label={t("actions.save")}
                  onClick={handleMarkingMethodAdd}
                  disabled={isSupplierProduct}
                />
              </Grid>
            </Grid>
          )}

          {!!image.marking_method && (
            <>
              <Link to={`/marking_methods/${image.marking_method.id}`}>
                {image.marking_method.name}
              </Link>
              <CancelIcon
                onClick={() => {
                  !isSupplierProduct &&
                    window.confirm("Är du säker?") &&
                    handleMarkingMethodRemove();
                }}
              />
            </>
          )}
        </Grid>
        <Grid container item xs={1} justify="flex-end">
          <Fab
            onClick={() => {
              if (window.confirm("Är du säker?"))
                handleRemoveReferenceImage(image);
            }}
            disabled={isSupplierProduct}
            color="secondary"
            size="small"
          >
            <DeleteIcon />
          </Fab>
        </Grid>
      </Grid>
    </li>
  );
};

ProductReferenceImage.propTypes = {
  image: PropTypes.object.isRequired,
  addColor: PropTypes.func.isRequired,
  removeColor: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onColorEdit: PropTypes.func.isRequired,
  onAddMarkingMethod: PropTypes.func.isRequired,
  onRemoveMarkingMethod: PropTypes.func.isRequired,
  markingMethods: PropTypes.array.isRequired,
};

export default ProductReferenceImage;
