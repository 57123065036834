import React, { useState } from 'react'
import PropTypes from "prop-types";
import { Route, Switch, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Tabs, Tab, Button, Container, AppBar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Reply from "@mui/icons-material/Reply";

import Edit from "@components/products/Edit";
import ColorGroups from "@components/products/images/ColorGroups";
import Pricing from "@components/pricing";
import CategoryAttribute from "@components/products/CategoryAttribute";
import Articles from "@components/products/articles";
import Printing from "@components/products/printing";
import Addons from "@components/products/addons";
import RelatedProducts from "@components/products/related_products";
import ProductAttachments from "@components/products/attachments";
import ProductResourceContext from "@contexts/ProductResource";
import Questions from '@components/products/Questions'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  tab: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const Navigation = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const activeIndex = () => {
    let i = 0;
    switch (props.location.pathname) {
      case `${props.match.url}/edit`:
        i = 0;
        break;
      case `${props.match.url}/detaljer`:
        i = 1;
        break;
      case `${props.match.url}/pricing`:
        i = 2;
        break;
      case `${props.match.url}/images`:
        i = 3;
        break;
      case `${props.match.url}/articles`:
        i = 4;
        break;
      case `${props.match.url}/printing`:
        i = 5;
        break;
      case `${props.match.url}/addons`:
        i = 6;
        break;
      case `${props.match.url}/related_products`:
        i = 7;
        break;
      case `${props.match.url}/attachments`:
        i = 8;
        break;
      case `${props.match.url}/questions`:
        i = 9;
        break;
      default:
        i = 0;
    }
    return i;
  };

  const productResource = props.match.path.includes("supplier-products")
    ? "supplier_products"
    : "products";
  const isSupplierProduct = productResource === "supplier_products";

  return (
    <ProductResourceContext.Provider value={productResource}>
      <AppBar position="static" color="default" className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={11}>
              <Tabs
                value={activeIndex()}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                indicatorColor="primary">
                <Tab
                  label="Produkt"
                  component={Link}
                  to={`${props.match.url}/edit`}
                  className={classes.tab}
                />
                <Tab
                  label="Detaljer"
                  component={Link}
                  to={`${props.match.url}/detaljer`}
                  className={classes.tab}
                />
                <Tab
                  label="Priser"
                  component={Link}
                  to={`${props.match.url}/pricing`}
                  className={classes.tab}
                />
                <Tab
                  label="Bilder"
                  component={Link}
                  to={`${props.match.url}/images`}
                  className={classes.tab}
                />
                <Tab
                  label="Artiklar"
                  component={Link}
                  to={`${props.match.url}/articles`}
                  className={classes.tab}
                />
                <Tab
                  label="Markningpriser"
                  component={Link}
                  to={`${props.match.url}/printing`}
                  className={classes.tab}
                />
                {productResource !== "supplier_products" && (
                  <Tab
                    label="Addons"
                    component={Link}
                    to={`${props.match.url}/addons`}
                    className={classes.tab}
                  />
                )}
                {productResource !== "supplier_products" && (
                  <Tab
                    label={t("products.relatedProducts")}
                    component={Link}
                    to={`${props.match.url}/related_products`}
                    className={classes.tab}
                  />
                )}
                {productResource !== "supplier_products" && (
                  <Tab
                    label={t("productAttachments.name")}
                    component={Link}
                    to={`${props.match.url}/attachments`}
                    className={classes.tab}
                  />
                )}
                <Tab
                  label="Questions"
                  component={Link}
                  to={`${props.match.url}/questions`}
                  className={classes.tab}
                />
              </Tabs>
            </Grid>
            <Grid
              container
              item
              xs={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="outlined"
                size="small"
                endIcon={<Reply />}
                onClick={() => {
                  props.history.push(
                    `/${isSupplierProduct ? "supplier-products" : "products"}/${
                      props.match.params.id
                    }`,
                  );
                }}
              >
                Tillbaka
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Container sx={{ pb: 2 }}>
        <Switch>
          <Route exact path={`${props.match.path}`} component={Edit} />
          <Route path={`${props.match.path}detaljer`} component={CategoryAttribute}/>
          <Route path={`${props.match.path}edit`} component={Edit} />
          <Route path={`${props.match.path}pricing`} component={Pricing} />
          <Route path={`${props.match.path}images`} component={ColorGroups} />
          <Route path={`${props.match.path}articles`} component={Articles} />
          <Route path={`${props.match.path}printing`} component={Printing} />
          <Route path={`${props.match.path}questions`} component={Questions} />
          {productResource !== "supplier_products" && (
            <Route path={`${props.match.path}addons`} component={Addons} />
          )}
          {productResource !== "supplier_products" && (
            <Route
              path={`${props.match.path}related_products`}
              component={RelatedProducts}
            />
          )}
          {productResource !== "supplier_products" && (
            <Route
              path={`${props.match.path}attachments`}
              component={ProductAttachments}
            />
          )}
        </Switch>
      </Container>
    </ProductResourceContext.Provider>
  );
};

Navigation.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default Navigation;
