import i18n from '@src/i18n'

const nameSorting = {
  [i18n.t('sorting.name_ascending')]: 'name_ascending',
  [i18n.t('sorting.name_descending')]: 'name_descending'
}

const createdAtSorting = {
  [i18n.t('sorting.latest')]: 'latest',
  [i18n.t('sorting.oldest')]: 'oldest'
}

export const ProductSorting = Object.assign({}, createdAtSorting, nameSorting);
export const CampaignSorting = Object.assign({}, createdAtSorting, nameSorting);

export const NameSorting = Object.assign({}, nameSorting);
