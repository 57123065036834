import React from 'react'
import { TablePagination, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import LastPageIcon from '@mui/icons-material/LastPage'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

const perPageOptions = [10, 20, 50, 100]

const styles = {
  display: 'inline-block',
  '& 	.MuiTablePagination-root': {
    display: 'inline-block'
  },
  '& 	.MuiTablePagination-displayedRows': {
    fontSize: '1.0rem',
  },
  '& .MuiTablePagination-selectLabel': {
    fontSize: '1.0rem',
  },
  '& .MuiTablePagination-select': {
    fontSize: '1.0rem',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderRadius: '4px',
    paddingBottom: '0'
  }
}

const calcRowsPerPage = (rowsPerPage, rowsControl) => {
  if(!rowsControl || perPageOptions.includes(rowsPerPage)){
    return rowsPerPage
  }
  return perPageOptions[0]
}

const CustomTablePagination = ({ count, pageNo, onPageChange, rowsPerPage, handleChangeRowsPerPage, rowsControl }) => (
  <TablePagination
    component="div"
    count={count}
    page={pageNo - 1}
    onPageChange={onPageChange}
    rowsPerPage={calcRowsPerPage(rowsPerPage, rowsControl)}
    onRowsPerPageChange={handleChangeRowsPerPage}
    rowsPerPageOptions={rowsControl ? perPageOptions : []}
    sx={styles}
    nextIconButtonProps={{ disabled: pageNo === Math.ceil(count / rowsPerPage) }}
    backIconButtonProps={{ disabled: pageNo === 1 }}
    ActionsComponent={() => (
      <>
        <IconButton
          onClick={(e) => onPageChange(e, 0)}
          disabled={pageNo === 1}
          aria-label="first page" >
            <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={(e) => onPageChange(e, pageNo - 2)}
          disabled={pageNo === 1}
          aria-label="previous page" >
            <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={(e) => onPageChange(e, pageNo)}
          disabled={pageNo >= Math.ceil(count / rowsPerPage)}
          aria-label="next page" >
            <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={(e) => onPageChange(e, Math.ceil(count / rowsPerPage) - 1)}
          disabled={pageNo >= Math.ceil(count / rowsPerPage)}
          aria-label="last page" >
            <LastPageIcon />
        </IconButton>
      </>
    )} />
)

CustomTablePagination.propTypes = {
  count: PropTypes.number,
  pageNo: PropTypes.number,
  onPageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsControl: PropTypes.bool
}

export default CustomTablePagination
