import React, { useState } from 'react'

import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useAuth } from '../../AuthContext'
import { adminLink } from '@src/utils'
import Breadcrumbs from "@components/Breadcrumbs";
import { useTranslation } from "react-i18next";


const PanelLink = () => {
  const { t } = useTranslation();

  const classes = makeStyles((theme) => ({
    appbar: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[50], // set light grey as background
      boxShadow: "none",
      padding: "5px 0",
    },
    link: {
      color: "inherit",
      textDecoration: "none",
    }
  }))()

  return (
    <>
      <div className={classes.appbar}>
        <Container>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {/* "Till Panelen" Link */}
            <a className={classes.link} href={adminLink("/admin2/dashboard")}>
              <Typography variant="subtitle1">{t("TopMenu.panel")}{" >"}</Typography>
            </a>

            {/* Breadcrumbs */}
            <Breadcrumbs />
          </div>
        </Container>
      </div>
    </>
  )
}

export default PanelLink
