import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@mui/material'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

import HttpClient from '../HttpClient'
import Mdivider from '../common/Mdivider'
import ValueForm from './ValueForm'

const Values = props => {
  const [startingCost, setStartingCost] = useState(props.startingCost)
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    setStartingCost(props.startingCost)
  }, [props.startingCost])

  const addStartingCostValue = value => {
    setStartingCost({ ...startingCost, starting_cost_values: [...startingCost.starting_cost_values, value] })
    setCreating(false)
  }

  const removeStartingCostValue = id => {
    if(window.confirm('Är du säker?'))
      HttpClient.delete(`/starting_costs/${startingCost.id}/starting_cost_values/${id}`)
        .then(() => setStartingCost({...startingCost, starting_cost_values: [...startingCost.starting_cost_values.filter(v => v.id !== id)]}))
  }

  return (
    <React.Fragment>
      <Typography variant='subtitle1' element="h5">Costar</Typography>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><h3>Namn</h3></TableCell>
                <TableCell><h3>Leverantör</h3></TableCell>
                <TableCell><h3>Pris</h3></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {startingCost.starting_cost_values.map(v => 
                <TableRow key={v.id}>
                  <TableCell>{v.marking_method && v.marking_method.name}</TableCell>
                  <TableCell>{v.supplier && v.supplier.name}</TableCell>
                  <TableCell>{v.price}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => removeStartingCostValue(v.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Mdivider />
      {
        creating ?
          <ValueForm starting_cost_id={startingCost.id} setCreating={setCreating} onCreate={addStartingCostValue} />
          :
          <Grid item xs={1}>
            <Fab color="primary" size="small" aria-label="add" onClick={() => setCreating(true)}>
              <AddIcon />
            </Fab>
          </Grid>
      }
    </React.Fragment>
  )
}

Values.propTypes = {
  startingCost: PropTypes.object.isRequired
}

export default Values
