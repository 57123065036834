import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Button, FormControl, InputLabel, Select, OutlinedInput, TextField } from '@mui/material'

import TextEditor from '@helpers/RichTextEditor/TextEditor'
import Mdivider from '@common/Mdivider'

import { SupplierProductApi } from '@api'
import useAlert from '@customHooks/useAlert'
import Alert from '@mui/material/Alert'

const SupplierProductForm = (props) => {
  const [product, setProduct] = useState(props.product)
  const [alert, setAlert] = useAlert(props.created)
  const { t } = useTranslation()

  useEffect(() => {
    setProduct(props.product)
  }, [props.product])

  const countryLabel = useRef(null)
  const [countryLabelWidth, setCountryLabelWidth] = useState(0)

  useEffect(() => {
    setCountryLabelWidth(countryLabel.current.offsetWidth)
  }, [])

  const saveProduct = () => {
    if(product.brand.id !== '')
      product.brand_id = product.brand.id
    else
      product.brand_id = null
    if(product.id) {
      SupplierProductApi.updateProduct(product)
        .then(() => {
          setAlert({ severity: 'success', text: t('supplierProducts.alerts.update_success') })
        })
        .catch(error => {
          setAlert({ severity: 'error', text: error.message })
          console.error('Error updating product: ', error)
        })
    }
  }

  return (
    <>
      {alert && (
        <Alert severity={alert.severity} onClose={() => setAlert(null)}>{alert.text}</Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Namn"
              value={product?.name}
              onChange={e => setProduct({ ...product, name: e.currentTarget.value })}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t('supplierProducts.attributes.supplier')}
              value={product.suppliers?.length > 0 ? product?.suppliers[0].name : ''}
              disabled={true}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t('supplierProducts.attributes.brand')}
              value={product.brands?.length > 0 ? product?.brands[0].name : ''}
              disabled={true}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t('supplierProducts.attributes.category')}
              value={product.category?.name}
              disabled={true}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t('supplierProducts.attributes.size')}
              value={product.size || ''}
              onChange={e => setProduct({ ...product, size: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextEditor
            label={t('supplierProducts.attributes.description')}
            value={product?.description || ''}
            onChange={desc => setProduct({ ...product, description: desc })}
          />
          <FormControl fullWidth margin="normal">
            <TextField
              label={t('supplierProducts.attributes.keywords')}
              value={product.keywords || ''}
              onChange={e => setProduct({ ...product, keywords: e.currentTarget.value })}
              variant="outlined"/>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              minRows="5"
              label={t('supplierProducts.attributes.compliance')}
              value={product.compliance || ''}
              onChange={e => setProduct({ ...product, compliance: e.currentTarget.value })}
              variant="outlined"/>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel ref={countryLabel} htmlFor="country">Land</InputLabel>
            <Select
              native
              value={`${product.country.id}`}
              onChange={e => setProduct({ ...product, country: { id: e.currentTarget.value } })}
              input={<OutlinedInput name="country" id="country" labelWidth={countryLabelWidth}/>}
            >
              <option key="no-country" value=""></option>
              {props.countries.map(c =>
                <option key={c.id} value={c.id}>{c.name}</option>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Mdivider/>
        <Grid item xs={12} align="right">
          <Button variant="contained" color="primary" onClick={saveProduct}>{t('actions.save')}</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default SupplierProductForm
