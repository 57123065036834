import React, { useEffect, useState } from 'react'
import HttpClient from '@components/HttpClient'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'

import ProductCard from '@components/supplier_products/ProductCard'
import { SupplierProductApi } from '@src/apis'
import InlineLoader from '@common/Loaders/InlineLoader'

const useStyles = makeStyles({
  headerContainer: {
    paddingLeft: "2%",
    paddingRight: "1%",
  },
  float_r: {
    float: "right",
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
});

const SuggestedProductsModal = ({ supplierProductId, closeModal, page, selectAction, setModalStage }) => {
  const [suggestedProducts, setSuggestedProducts] = useState([])
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(async ()=>{
    setLoading(true)
    const _suggestedProducts = await SupplierProductApi.getSuggestedProducts(supplierProductId)
    setSuggestedProducts(_suggestedProducts)
    setLoading(false)

    return () => {
      HttpClient.abort()
    }

  },[])

  return (
    <>
      <Grid container item alignItems="center" className={classes.headerContainer}>
        <Grid item xs={11}>
          <Typography variant={"h5"}>{t("supplierProducts.modals.suggested_products_modal.heading")}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton className={classes.float_r} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="div">
            {t("supplierProducts.modals.suggested_products_modal.main_message")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        { loading ?
          <Grid className={classes.center} container item xs={12}>
            <InlineLoader/>
          </Grid>
          :
          <Grid container xs={12} spacing={1} justifyContent={'center'} sx={{ minHeight: '50vh', maxHeight: '50vh', overflowY: 'auto' }}>
            <ProductCard products={suggestedProducts} selectAction={selectAction}/>
          </Grid>
        }
      </Grid>
      <hr/>
      <Grid container justifyContent={"flex-end"}>
        <Grid item>
          <Button sx={{ marginRight: '10px' }} variant={"outlined"} color={'secondary'} onClick={closeModal}>
            {t("actions.close")}
          </Button>
          {
            page === 'listing' &&
            <Button
              onClick={setModalStage}
              variant={"contained"}
              color={'secondary'}>
              {t("supplierProducts.actions.create_new_product")}
            </Button>
          }
          {
            page !== 'listing' &&
              <Button variant={"contained"} color={'secondary'} onClick={setModalStage}>
                {t('supplierProducts.actions.select_another_product')}
              </Button>
          }
        </Grid>
      </Grid>
    </>
  )
}

SuggestedProductsModal.propTypes = {
  supplierProductId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  page: PropTypes.string,
  selectAction: PropTypes.func.isRequired,
  setModalStage: PropTypes.func.isRequired
}

export default SuggestedProductsModal
