import React from 'react'
import PropTypes from 'prop-types'

import Form from './Form'

let initialState = { name: '', code: '', color: {}}

const New = (props) => {
  return(
    <Form color={initialState} history={props.history}/>
  )
}
New.propTypes = {
  history: PropTypes.object
}

export default New