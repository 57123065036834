import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Form from './Form'
import { ColorApi } from '../../apis/ColorApi'

const Edit = props => {
  const initialState = { id: props.match.params.id, name: '', short_name: '', code: '', color: { id: '' } }
  const [color, updateColor] = useState(initialState)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    ColorApi.getColor(color.id)
      .then(data => {
        updateColor({ ...initialState, ...data })
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }, [color.id])
  return(
    <Form color={color} created={props.location?.state?.created} loading={loading} history={props.history}/>
  )
}
Edit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default Edit
