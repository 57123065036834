import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Box, Button, Grid, TextField } from "@mui/material";

import HttpClient from "@httpClient";
import { CategoryApi, ProductApi } from "@api";

import ItemSelectedComponent from "@shared/ItemSelectedComponent";
import ImageThumbnail from "@shared/ImageThumbnail";
import { useDebounce } from "@customHooks/useDebouncing";
import InlineLoader from "@components/common/Loaders/InlineLoader";
import { searchTypes } from '@config/constants'

const styles = {
  headerContainer: {
    paddingLeft: "2%",
    paddingRight: "1%",
  },
  sideCard: {
    maxHeight: "400px",
    overflowY: "auto",
    marginTop: "10px",
  },
  root: {
    cursor: "pointer",
  },
  border: {
    border: "1px solid black",
    padding: "10px",
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
};

const SearchInputWithView = ({
  type,
  searchInputLabel,
  setSelectedItem,
  selectedItem,
  size = 'small',
  multiSelect = false,
  multiSelectCallbackFunction
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchedResult, setSearchedResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedCategoryTerm = useDebounce(searchInputValue, 500);

  const searchCategories = async () => {
    return await CategoryApi.searchCategories({ query: searchInputValue });
  };

  const searchProducts = async () => {
    let data = { term: searchInputValue, search_type: type }
    return await ProductApi.searchProducts(data);
  };

  useEffect(async () => {
    let didCancel = false;
    let response;
    setIsLoading(true);
    if (type === searchTypes.categorySearch) {
      response = await searchCategories();
    } else {
      response = await searchProducts();
    }
    setIsLoading(false);
    setSearchedResult(response);
    return () => {
      didCancel = true;
      HttpClient.abort();
    };
  }, [debouncedCategoryTerm]);

  const handleSelection = (type, id) => {
    const selectedItem = searchedResult.find( obj => obj.id === id);
    switch (type) {
      case "select":
        if (multiSelect){
          multiSelectCallbackFunction(selectedItem, 'add')
        } else {
          setSelectedItem(selectedItem);
        }
        break;
      case "unselect":
        if (multiSelect){
          multiSelectCallbackFunction(selectedItem, 'remove')
        } else {
          setSelectedItem(null);
        }
        break;
      default:
        setSelectedItem(null);
    }
  };

  return (
    <>
      { !multiSelect && selectedItem && (
        <ItemSelectedComponent
          type={type === searchTypes.categorySearch ? "categories" : "products"}
          selectedItem={selectedItem}
          handleUnselect={(id) => handleSelection("unselect", id)}
        />
      )}
      <Grid container>
        <Grid container item xs={12} justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={size === 'small' ? 10 : 12}>
            <TextField
              fullWidth
              label={searchInputLabel}
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.currentTarget.value)}
              margin="normal"
              variant="outlined"
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"center"}
            sx={styles.center}
          >
            <InlineLoader size={40} />
          </Grid>
        ) : (
          <Grid container item xs={12} justifyContent={'center'} alignItems={'center'}>
            {searchedResult?.length > 0 ? (
              <Box
                xs={12}
                md={12}
                display="flex"
                flexDirection="column"
                alignItems={'space-between'}
                sx={{...styles.sideCard, width:  size === 'small' ? '80%' : '100%' }}
              >
                {searchedResult.map((item) => (
                  <Grid
                    container
                    mt={1}
                    xs={12}
                    md={12}
                    item
                    direction="row"
                    key={item?.id}
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      xs={2}
                      alignItems="center"
                      sx={{ wordBreak: "break-all" }}
                    >
                      <ImageThumbnail
                        imageUrl={item.image_url}
                        height={50}
                        width={50}
                      />
                    </Grid>
                    {
                      type === 'categorySearch' ?
                        <Grid container item xs={6}>
                          <Grid item xs={12}>
                            <Link to={`/categories/${item.id}`}>{item?.name}</Link>
                          </Grid>
                        </Grid>
                        :
                        <Grid container item xs={8}>
                          <Grid item xs={6}>
                            <Link to={`/products/${item.id}`}>{item?.name}</Link>
                          </Grid>
                          <Grid item xs={6}>
                            <Link to={`/suppliers/${item.supplier_id}`}>{item.supplier_name}</Link>
                          </Grid>
                        </Grid>
                    }
                    <Grid item xs={2} justifyContent={"flex-end"}>
                      { selectedItem?.id === item.id ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSelection("unselect", item.id)}
                        >
                          Selected
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSelection("select", item.id)}
                        >
                          Select
                        </Button>
                      )}
                    </Grid>
                    <hr />
                  </Grid>
                ))}
              </Box>
            ) : (
              <Grid item sx={{ margin: "5% 0 0 1%" }}>
                {type === searchTypes.categorySearch ? 'No categories found!' : 'No products found!'}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

SearchInputWithView.propTypes = {
  type: PropTypes.string,
  searchInputLabel: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
};

export default SearchInputWithView;
