import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'


import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  InputAdornment
} from '@mui/material'
import { AccountCircle, Fingerprint } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import { useAuth } from '../../AuthContext'
import HttpClient from '../HttpClient'
import { adminLink } from '../../utils'
const MEDTRYCK_HOST = process.env['REACT_APP_MEDTRYCK_HOST']

const New = ({ location, history }) => {
  const auth = useAuth()
  const [user, setUser] = useState({ login: '', password: '' })
  const { from } = location.state || { from: { pathname: adminLink('/admin2/dashboard') } }
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    login: false,
    password: false,
    message: ''
  })
  
  useEffect(() => {
    let didCancel = false // to cancel side effects
    if(!didCancel) {
      // state update goes here
      if(auth.user && auth.user.valid() && !auth.user.expired()) {
        window.location.replace(from.pathname)
      }
    }
  
    return () => { didCancel = true }
  }, [])

  // Authenticate from API server
  // const handleSubmit = event => {
  //   event.preventDefault()
  //   if(user.login !== '' && user.password !== '') {
  //     setLoading(true)
  //     HttpClient.post('/authentications', { user: user })
  //       .then(response => {
  //         auth.setAuth(response.token)
  //         setLoading(false)
  //         history.replace(from)
  //       })
  //       .catch(error => {
  //         console.error('Error logging in: ', error)
  //         setLoading(false)
  //       })
  //   }
  // }
  
  // Authenticate using medtryck login API
  const handleSubmit = event => {
    event.preventDefault()
    if(user.login !== '' && user.password !== '') {
      setLoading(true)
      HttpClient.post(`${MEDTRYCK_HOST}/api/neo4j/auth/authenticate`, { user: user })
        .then(response => {
          if(response.ok) {
            auth.setAuth(response.token)
            setLoading(false)
            window.location.replace(from.pathname)
          }
        })
        .catch(error => {
          console.error('Error logging in: ', error)
          setLoading(false)
          setError({
            login: true,
            password: true
          })
        })
    }
  }

  const classes = makeStyles(theme => ({
    root: {
      position: 'relative',
      padding: theme.spacing(3),
    }
  }))()

  return(
    <Container maxWidth="sm">
      <Grid
        container
        spacing={8}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Paper square className={classes.root}>
            <form onSubmit={handleSubmit}>
              <TextField
                error={error.login}
                id="login"
                label="Användarnamn"
                value={user.login}
                onChange={e => setUser({ ...user, login: e.currentTarget.value })}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>,
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                required />
              <TextField
                error={error.password}
                id="password"
                label="Lösenord"
                type="password"
                value={user.password}
                onChange={e => setUser({ ...user, password: e.currentTarget.value })}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><Fingerprint /></InputAdornment>,
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                required />
              <Button type="submit" variant="contained" disabled={loading} color="primary">Logga in</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

New.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default New
