import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Form from './Form'
import { BrandApi } from '../../apis/BrandApi'

const Edit = ({ history, match, location }) => {
  const initialState = { id: match.params.id, name: '', description: '', logo_uid: '', footer: '' }
  const [brand, updateBrand] = useState(initialState)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    BrandApi.getBrand(brand.id)
      .then(res => {
        updateBrand(res)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }, [brand.id])

  return(
    <Form created={location?.state?.created} brand={brand} loading={loading} history={history}/>
  )
}

Edit.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default Edit
