import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "./Form";
import { Dialog, DialogContent } from "@mui/material";

export default function ColorForm(props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Form
          color={props.color}
          isModal={true}
          onSave={props.onSave}
          onClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}

ColorForm.propTypes = {
  color: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
