import React from 'react';
import { Box, Modal, IconButton, Typography, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const NewModal = ({ open, onClose, size = 'medium', children, title }) => {
  // Styles for the modal container
  const getModalSize = () => {
    switch (size) {
      case 'small':
        return { width: '30%' };
      case 'medium':
        return { width: '50%' };
      case 'large':
        return { width: '70%' };
      default:
        return { width: '50%' };
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Fade in={open}>
        <Box
          sx={{
            ...getModalSize(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography component={'div'} id="modal-description" sx={{ mt: 2 }}>
            {children}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default NewModal;
