import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import List from '@components/supplier_products/List'
import Navigation from '@components/products/Navigation'
import Show from '@components/products/Show'

const SupplierProducts = props => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}`} component={List}></Route>
      <Route exact path={`${props.match.path}/:id`} component={Show}></Route>
      <Route path={`${props.match.path}/:id/`} component={Navigation}></Route>
    </Switch>
  )
}

SupplierProducts.propTypes = { match: PropTypes.object.isRequired }

export default SupplierProducts
