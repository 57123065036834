import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardMedia,
  Grid,
  FormControl,
  FormControlLabel,
  TextField,
  Switch,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import ColorPicker from "@components/common/ColorPicker";
import { makeStyles } from "@mui/styles";
import HttpClient from "@httpClient";
import Mdivider from "@common/Mdivider";
import TextEditor from "@helpers/RichTextEditor/TextEditor";
import { TagApi } from "@api/TagApi";
import UploadImageWidget from '@shared/UploadImageWidget'

const useStyles = makeStyles({
  input: {
    display: "none",
  },
  card: {
    maxWidth: 145,
  },
  media: {
    height: 0,
    paddingTop: "100%", // square
    backgroundSize: "contain",
  },
});

const Form = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const saveTag = (values) => {
    const formData = new FormData();
    for (const [field, value] of Object.entries(values)) {
      if (field !== "") {
        if (['image', 'mp4_video', 'webm_video', 'video_poster'].includes(field)) {
          value !== "" && formData.append(`tag[${field}]`, value);
        } else {
          formData.append(`tag[${field}]`, value);
        }
      }
    }
    if (props.tag.id) {
      HttpClient.put(`/tags/${props.tag.id}`, formData)
        .then(() => history.push("/tags"))
        .catch((error) => {
          console.error("Erro updating tag", error);
          formik.setErrors(error.errors);
        });
    } else {
      TagApi.createTag(formData)
        .then(() => history.push("/tags"))
        .catch((error) => {
          formik.setErrors(error.message);
          console.error("Error creating tags", error);
        });
    }
  };

  const formik = useFormik({
    initialValues: props.tag,
    onSubmit: (values) => saveTag(values),
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Name can't be blank";
      }
      return errors;
    },
  });

  const handleFileUpload = (event) => {
    if (event.target.files[0]){
      const file = event.target.files[0];
      const previewUrl = URL.createObjectURL(file);
      formik.setFieldValue(event.target.name, file);
      formik.setFieldValue(`${event.target.name}_preview_url`, previewUrl);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.name")}
            value={formik.values.name}
            name="name"
            error={formik.errors.name && formik.touched.name}
            helperText={formik.errors.name}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.permalink")}
            name="permalink"
            value={formik.values.permalink}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.name_plural")}
            name="name_plural"
            value={formik.values.name_plural}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.name_singular")}
            name="name_singular"
            value={formik.values.name_singular}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.custom_title")}
            name="custom_title"
            value={formik.values.custom_title}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.description")}
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.show_until")}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            name="show_until"
            value={formik.values.show_until}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.destroy_at")}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            name="destroy_at"
            value={formik.values.destroy_at}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <ColorPicker
            label={t("tags.fieldLabels.color_code")}
            value={formik.values.color_code}
            onChange={(code) => formik.setFieldValue("color_code", code)}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <FormControlLabel
            control={
              <Switch
                checked={String(formik.values.seo_index) === String(true)}
                name="seo_index"
                onChange={(e) =>
                  formik.setFieldValue("seo_index", e.target.checked)
                }
                value={true}
                color="primary"
              />
            }
            label={t("tags.fieldLabels.seo_index")}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <FormControlLabel
            control={
              <Switch
                checked={String(formik.values.big_image) === String(true)}
                name="big_image"
                onChange={(e) =>
                  formik.setFieldValue("big_image", e.target.checked)
                }
                value={true}
                color="primary"
              />
            }
            label={t("tags.fieldLabels.big_image")}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <FormControlLabel
            control={
              <Switch
                checked={String(formik.values.is_spec) === String(true)}
                name="is_spec"
                onChange={(e) =>
                  formik.setFieldValue("is_spec", e.target.checked)
                }
                value={true}
                color="primary"
              />
            }
            label={t("tags.fieldLabels.is_spec")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <FormControl fullWidth margin="normal">
            <Grid container alignItems={'end'} spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <UploadImageWidget type={'image'} onChange={handleFileUpload} object={formik.values} field={'image'} accept={'image/*'}/>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <UploadImageWidget type={'image'} onChange={handleFileUpload} object={formik.values} field={'video_poster'} accept={'image/*'}/>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Grid container alignItems={'end'} spacing={2}>
              <Grid item xs={6}>
                <UploadImageWidget type={'video'} onChange={handleFileUpload} object={formik.values} field={'webm_video'} accept={'video/webm'}/>
              </Grid>
              <Grid item xs={6}>
                <UploadImageWidget type={'video'} onChange={handleFileUpload} object={formik.values} field={'mp4_video'} accept={'video/mp4'}/>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.header")}
            name="header"
            value={formik.values.header}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
        <TextEditor
          label={t("tags.fieldLabels.footer")}
          name="footer"
          value={formik.values.footer}
          onChange={(footer) => formik.setFieldValue("footer", footer)}
        />
        <TextEditor
          label={t("tags.fieldLabels.info")}
          name="info"
          value={formik.values.info}
          onChange={(info) => formik.setFieldValue("info", info)}
        />
        <TextEditor
          label={t("tags.fieldLabels.footer_info")}
          name="footer_info"
          value={formik.values.footer_info}
          onChange={(footer_info) =>
            formik.setFieldValue("footer_info", footer_info)
          }
        />
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("tags.fieldLabels.keywords")}
            name="keywords"
            value={formik.values.keywords}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Mdivider />
      <Grid item xs={12} align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
        >
          Spara
        </Button>
      </Grid>
    </Grid>
  );
};

Form.propTypes = {
  tag: PropTypes.object.isRequired,
};

export default Form;
