import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Container, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import ArrowBack from '@mui/icons-material/ArrowBack'

import TitleBar from '../topbar/TitleBar'
import Mdivider from '../common/Mdivider'
import HttpClient from '../HttpClient'
import LoaderShowSingle from '../common/Loaders/LoaderShowSingle'
import Alert from '@mui/material/Alert'
import useAlert from '@customHooks/useAlert'
import SelectInput from '@common/SelectInput'

const Form = props => {
  const [country, setCountry] = useState(props.country)
  const [countries, setCountries] = useState([])
  const [alert, setAlert] = useAlert(props.created)

  useEffect(() => {
    const _country = { ...props.country }
    setCountry(_country)
  },[props.country])

  useEffect(() => {
    fetch('http://restcountries.eu/rest/v2/all?fields=name;alpha2Code')
      .then(res => res.json())
      .then(res => {
        setCountries(res.map(c => ({ id: c.alpha2Code, name: c.name }) ))
      })
  }, [])

  const selectLabel = useRef(null)
  const [selectLabelWidth, setParentLabelWidth] = useState(0)
  useEffect(() => {
    setParentLabelWidth(selectLabel.current.offsetWidth)
  }, [])

  const handleChange = e => {
    const c = countries.find(c => c.id === e.target.value)
    c ? setCountry({ name: c.name, code: c.id }) : setCountry({ name: '', code: '' })
  }

  const saveCountry = () => {
    // if(country.id) {
    //   HttpClient.put(`/countrys/${country.id}`, { country: country })
    //     .then(() => props.history.push('/countrys'))
    //     .catch(error => console.log(error))
    // } else {
    HttpClient.post('/countries', { country: country })
      .then(() => props.history.push('/countries'))
      .catch(error => console.log(error))
    // }
  }

  return(
    <React.Fragment>
      <TitleBar title={`${country.id ? 'Radigera' : 'Ny'} länd`}>
        <Fab color="secondary" size="small" aria-label="add" onClick={() => { props.history.push('/countries')}}>
          <ArrowBack />
        </Fab>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>{alert.text}</Alert>
        )}
        {props.loading ? <LoaderShowSingle /> :  (
          <Grid container>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel ref={selectLabel} htmlFor="select"></InputLabel>
                  <SelectInput
                    label={'Namn'}
                    value={country.code}
                    valuesArray={countries}
                    onChange={(e) => handleChange(e)}
                  />
              </FormControl>
            </Grid>
            <Mdivider />
            <Grid item xs={12} align="right">
              <Button variant="contained" color="primary" disabled={!country.code} onClick={saveCountry}>Spara</Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}

Form.propTypes = {
  country: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool
}

export default Form
