export const setUrlForGetRequest = (url, params) => {
  url = `${url}?`
  Object.keys(params).forEach((key, index) => {
    if(params[key] !== null){
      if(index === 0) {
        if (Array.isArray(params[key])){
          url += `${key}=${params[key].join(',')}`;
        } else {
          url += `${key}=${params[key]}`
        }
      }
      else {
        if (Array.isArray(params[key])){
          url += `&${key}=${params[key].join(',')}`;
        } else {
          url += `&${key}=${params[key]}`
        }
      }
    }
  })
  return url
}
