import { setUrlForGetRequest } from "@api/SharedFunctions";
import HttpClient from "@components/HttpClient";

const SUPPLIER_PRODUCTS_API = "/supplier_products";

const getSupplierProducts = (params) => {
  return HttpClient.get(setUrlForGetRequest(SUPPLIER_PRODUCTS_API, params));
};

const getProductQuantities = (id) => {
  return HttpClient.get(
    `${SUPPLIER_PRODUCTS_API}/${id}/quantities?product_type=supplier_product`,
  );
};

const getProductPriceLists = (id) => {
  return HttpClient.get(
    `${SUPPLIER_PRODUCTS_API}/${id}/price_lists?product_type=supplier_product`,
  );
};

const getProductPrintingPrices = (id) => {
  return HttpClient.get(
    `${SUPPLIER_PRODUCTS_API}/${id}/printing_prices?product_type=supplier_product`,
  );
};

const getProduct = (id) => {
  return HttpClient.get(`${SUPPLIER_PRODUCTS_API}/${id}`);
};

const createNewProduct = (id, data) => {
  return HttpClient.post(
    `${SUPPLIER_PRODUCTS_API}/${id}/create_new_product_linkage`,
    data,
  );
};

const updateProduct = (product) => {
  return HttpClient.put(`${SUPPLIER_PRODUCTS_API}/${product.id}`, {
    supplier_product: product,
  });
};

const updateStatus = (id, params) => {
  return HttpClient.post(
    `${SUPPLIER_PRODUCTS_API}/${id}/update_status`,
    params,
  );
};

const getSuppliersAndCategories = () => {
  return HttpClient.get(`${SUPPLIER_PRODUCTS_API}/suppliers_and_categories`)
}

const getSuggestedProducts = (id) => {
  return HttpClient.get(`${SUPPLIER_PRODUCTS_API}/${id}/suggested_products`)
}

const SupplierProductApi = {
  getSupplierProducts,
  getProductQuantities,
  getProductPriceLists,
  getProductPrintingPrices,
  getProduct,
  createNewProduct,
  updateProduct,
  updateStatus,
  getSuggestedProducts,
  getSuppliersAndCategories
}

export { SupplierProductApi };
