import React from 'react'
import PropTypes from 'prop-types'

import { Link, Chip, Divider, Button, Grid} from '@mui/material'
import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction} from '@mui/material'
import { makeStyles } from '@mui/styles'

import ImageThumbnail from '@shared/ImageThumbnail'

const useStyles = makeStyles((theme) => ({
  media: {
    height: 35,
    paddingTop: "56.25%",
    backgroundSize: "contain",
  },
  chip: {
    margin: theme.spacing(1),
    border: "1px solid #e0e0e0",
    fontSize: '0.6rem',
    height: '15px'
  },
  articleWraper: {
    position: "relative",
  },
  disabledOverlay: {
    position: "absolute",
    backgroundColor: "#dddddd",
    opacity: "0.4",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
}))

const ProductCard = ({ products, selectAction }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <List>
        { products?.map( product => (
          <ListItem component={Link} to={`/products/${product.id}`} divider={true} key={product.id}>
            <ListItemAvatar>
              <ImageThumbnail imageUrl={product.image_url} height='100px' width='100px'/>
            </ListItemAvatar>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <ListItemText
                  sx={{ marginLeft: '10px' }}
                  primary={
                    <Grid container xs={12} justifyContent={'center'} alignItems={'center'}>
                      <Grid item xs={8}>
                        <Link href={`/products/${product.id}`} target="_blank">
                          {product.name}
                        </Link>
                      </Grid>
                      <Grid item xs={4}>
                        <Link href={`/categories/${product.category?.id}/edit`} target="_blank" sx={{ cursor: 'pointer' }}>
                          <Chip color={"primary"} label={product.category?.name} className={classes.chip} sx={{ cursor: 'pointer' }}/>
                        </Link>
                      </Grid>
                    </Grid>
                  }
                  secondary={
                    <>
                      <Grid container xs={12}>
                        {product.suppliers.map((c) => (
                          <Chip size={'small'} label={c} className={classes.chip}/>
                        ))}
                      </Grid>
                      <Grid container xs={12}>
                        {
                          product.p_attributes.map((c) => (
                            <Chip size={'small'} label={c} className={classes.chip}/>
                          ))
                        }
                      </Grid>
                    </>}
                />
              </Grid>
              <Grid item xs={2}>
                <ListItemSecondaryAction onClick={() => selectAction(product)}>
                  <Button variant={'contained'}>Select</Button>
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        ))
        }
        <Divider variant="inset" component="li" />
      </List>
    </Grid>
  )
}

ProductCard.propTypes = {
  products: PropTypes.array.isRequired,
  selectAction: PropTypes.func.isRequired
}

export default ProductCard
