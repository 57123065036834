import { useState, useContext } from "react";
import PropTypes from "prop-types";
import PopupAlert from "@contexts/PopupAlert";
import { useTranslation } from "react-i18next";
import { parseErrorMessage } from "@src/utils/sharedHelper";

const useLoadableResource = (initialData, resourceName) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(initialData);
  const [canceled, setCanceled] = useState(false);
  const addPopupAlert = useContext(PopupAlert);
  const { t } = useTranslation();

  const cancel = () => {
    setCanceled(true);
  };

  const setDataWrapper = (_data, _loaded = false) => {
    setLoading(false);
    setLoaded(_loaded);
    setData(_data);
  };

  const load = async (source) => {
    try {
      setLoading(true);
      const data = await source;
      if (!canceled) {
        setLoading(false);

        setData(data);
        setLoaded(true);
      }
    } catch (e) {
      if (!canceled) {
        setLoading(false);
        addPopupAlert(
          `${t("actions.load")} ${resourceName}`,
          "error",
          parseErrorMessage(e.message),
        );
      }
    }
  };

  return {
    data: {
      loaded,
      loading,
      data,
    },
    load,
    cancel,
    setData: setDataWrapper,
  };
};

useLoadableResource.propTypes = {
  initialData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  resourceName: PropTypes.string.isRequired,
};

export default useLoadableResource;
