import React from 'react'
import QuestionTable from '@components/questions/QuestionTable'

const Questions = (props) => (
  <QuestionTable
    pageType={'productQuestions'}
    productId={props.match.params.id}
  />
)

export default Questions
