import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../HttpClient'
import Form from './Form'

const Edit = ({ match, history, location }) => {
  const initialState = {
    id: match.params.id,
    name: '',
    type: 'single',
    price: '',
    description: '',
    starting_cost_values: []
  }
  const [startingCost, setStartingCost] = useState(initialState)
  const [costTypes, setCostTypes] = useState([])
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    let didCancel = false
    setLoading(true)
    Promise
      .all([
        HttpClient.get(`/starting_costs/${startingCost.id}`),
        HttpClient.get('/starting_costs/form_options'),
      ])
      .then(([cost, types]) => {        
        if(!didCancel) {
          setStartingCost({ ...startingCost, ...cost })
          setCostTypes(types)
        }
        setLoading(false)
      })
      .catch(error => setLoading(false))
    return () => { didCancel = true }
  },[match.params.id])
  
  return(
    costTypes.length > 0 ?
      <Form created={location?.state?.created} startingCost={startingCost} loading={loading} costTypes={costTypes} history={history}/>
      :
      null
  )
}
Edit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default Edit
