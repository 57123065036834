import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";

import HttpClient from "@httpClient";
import TitleBar from "@components/topbar/TitleBar";
import { SupplierIntegrationJobStatsApi } from "@src/apis/SupplierIntegrationJobStatsApi";
import BasicCard from "@components/common/cards/BasicCard";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import StatusChip from "@components/supplier_integration_job_stats/StatusChip";
import ReactJson from "react-json-view";

const Show = ({ match }) => {
  const [jobStats, setJobStats] = useState({
    id: "",
    created_at: "",
    type: "",
    status: "",
    updated_at: "",
    restarted_at: "",
    no_of_tries: 0,
    total: 0,
    success: 0,
    failed: 0,
    unchanged: 0,
    stats: {},
  });
  const { t } = useTranslation();

  useEffect(() => {
    let didCancel = false;
    getStatsJob(match.params.id);

    return () => {
      didCancel = true;
      HttpClient.abort();
    };
  }, [match.params.id]);

  const getStatsJob = (id) => {
    SupplierIntegrationJobStatsApi.show(id, {}, true)
      .then((data) => {
        setJobStats(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <TitleBar title={t("supplierIntegrationJobStats.singular")}></TitleBar>
      <Container>
        <Grid container spacing={2}>
          {[
            "id",
            "created_at",
            "type",
            "status",
            "updated_at",
            "restarted_at",
            "no_of_tries",
            "total",
            "success",
            "failed",
            "unchanged",
          ].map((key) => {
            return (
              <Grid item xs={6} key={key}>
                <BasicCard>
                  <Typography variant="h5" component="div">
                    {t(`supplierIntegrationJobStats.attributes.${key}`)}
                  </Typography>
                  <Typography color="text.secondary">
                    {key === "status" ? (
                      <StatusChip status={jobStats[key]} />
                    ) : (
                      jobStats[key]
                    )}
                  </Typography>
                </BasicCard>
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <BasicCard>
              <Typography variant="h5" component="div">
                {t("supplierIntegrationJobStats.attributes.stats")}
              </Typography>
              <ReactJson src={jobStats.stats} />
            </BasicCard>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

Show.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Show;
