import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { makeStyles } from "@mui/styles";

import ProductImage from "./ProductImage";
import HttpClient from "@httpClient";
import Paper from "@mui/material/Paper";
import PopupAlert from "@contexts/PopupAlert";
import InlineLoader from "@components/common/Loaders/InlineLoader";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ProductImages = ({
  productId,
  colorGroupId,
  productImages,
  formOptions,
  supplierProductsView,
}) => {
  const classes = useStyles();
  const [images, setImages] = useState(productImages || []);
  const [updating, setUpdating] = useState(false);
  const apiType = supplierProductsView ? "supplier_products" : "products";
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);

  useEffect(() => {
    setImages(productImages || []);
  }, [colorGroupId, productImages]);

  const handleImageUpload = (event) => {
    const formData = new FormData();
    formData.append(
      `product_image[${event.target.name}]`,
      event.target.files[0],
    );
    setUpdating(true);
    HttpClient.post(
      `/${apiType}/${productId}/color_groups/${colorGroupId}/product_images`,
      formData,
    ).then((res) => {
      setUpdating(false);
      setImages([...images, res]);
      addPopupAlert(
        t("pictures.actions.add"),
        "success",
        t("pictures.actions.added_successfully"),
      );
    });
  };

  const handleDelete = (id) =>
    setImages((images) => images.filter((i) => i.id !== id));

  return (
    <Box display="flex" flexDirection="column" width={1}>
      {images.map((image, index) => (
        <Paper key={image.id} elevation={3} sx={{ mb: "5px", p: "10px" }}>
          <Draggable draggableId={image.id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                )}
              >
                <ProductImage
                  supplierProductsView={supplierProductsView}
                  image={image}
                  onDelete={handleDelete}
                  productId={productId}
                  colorGroupId={colorGroupId}
                  formOptions={formOptions}
                />
              </div>
            )}
          </Draggable>
        </Paper>
      ))}
      <Box>
        <input
          accept="image/*"
          disabled={supplierProductsView || updating}
          className={classes.input}
          id={`contained-button-file${colorGroupId}`}
          type="file"
          name="image"
          onChange={handleImageUpload}
        />
        <label htmlFor={`contained-button-file${colorGroupId}`}>
          {updating ? (
            <InlineLoader sx={{ mt: "5px" }} />
          ) : (
            <Tooltip title={t("pictures.actions.add")}>
              <Fab component="span" elevation={0} size="small">
                <CloudUploadIcon />
              </Fab>
            </Tooltip>
          )}
        </label>
      </Box>
    </Box>
  );
};

ProductImages.propTypes = {
  productId: PropTypes.number.isRequired,
  colorGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  productImages: PropTypes.array,
  formOptions: PropTypes.object.isRequired,
};

export default ProductImages;
