import HttpClient from '@components/HttpClient'

const updatePositions = (productId, productResource = "products", data) => {
  return HttpClient.post(
    `/${productResource}/${productId}/color_groups/update_positions`,
    {
      color_groups: data,
    },
    {},
    true,
  );
};

const ColorGroupApi = {
  updatePositions
}

export { ColorGroupApi }
