import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PropTypes from "prop-types";

const PopupAlert = ({
  action,
  severity = "success",
  alertProps = {},
  children,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      {...rest}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
        variant="filled"
        elevation={6}
        {...alertProps}
      >
        <AlertTitle>{action}</AlertTitle>
        {children ?? <></>}
      </Alert>
    </Snackbar>
  );
};

PopupAlert.propTypes = {
  action: PropTypes.string.isRequired,
  severity: PropTypes.string,
  alertProps: PropTypes.object,
  children: PropTypes.element,
};

export default PopupAlert;
