import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ClickAwayListener, Grid, List, ListItem, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'


const DropdownWithButton = ({ clickableComponent, listElements, aligned, rowId }) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)

  const classes = makeStyles(theme => ({
    ratingBox: {
      position: 'relative',
    },
    actionListStyle: {
      position: 'absolute !important',
      maxHeight: '400px',
      backgroundColor: 'white',
      borderRadius: '6px',
      [aligned]: '0%',
      padding: '10px',
      width: '300px',
      boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
      cursor: 'pointer',
      zIndex: '5',
    },
    listItem: {
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    }
  }))()

  let rowActions = listElements()

  const onCLickEvent = (element) => {
    setShowActionsDropdown(false)
    element.clickEvent()
  }

  return (
    <Grid item className={classes.ratingBox}>
      <div onClick={() => setShowActionsDropdown(true)}>
        {clickableComponent}
      </div>
      {
        showActionsDropdown &&
        <ClickAwayListener onClickAway={() => setShowActionsDropdown(false)}>
          <List disablePadding className={classes.actionListStyle}>
            {rowActions.map((element) => (
              <ListItem disabled={element.disabled} key={element.name} className={classes.listItem} alignItems="center" onClick={()=>onCLickEvent(element)}>
                <ListItemText primary={element.name}/>
              </ListItem>
            ))}
          </List>
        </ClickAwayListener>
      }
    </Grid>
  )
}

const stringOrIntegerValidator = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

DropdownWithButton.propTypes = {
  clickableComponent: PropTypes.element.isRequired,
  listElements: PropTypes.func.isRequired,
  aligned: PropTypes.string.isRequired,
  rowId: stringOrIntegerValidator
}

export default DropdownWithButton;
