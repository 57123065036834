import React from 'react'
import { Modal, Fade, Backdrop,} from '@mui/material'

const SharedModal = ({children, onCloseModal}) => {
  return (
    <Modal
      open={true}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }} >
      <Fade in={true}>
        {children}
      </Fade>
    </Modal>
  )
}

export default SharedModal;
