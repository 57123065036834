import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { Menu, MenuItem, ListItemIcon } from '@mui/material'

import { useAuth } from '../../AuthContext'
import { adminLink } from '../../utils'
import { makeStyles } from '@mui/styles';

const iconStyles = makeStyles(() => ({
  listIcon: {
      minWidth: "35px",
  },
}));

const UserMenu = props => {
  const auth = useAuth()
  const { t } = useTranslation();


  const logOut = () => {
    // Logout user by deleting from localStorage
    localStorage.clear('authToken')
    // also reset context to rerender app
    auth.setAuth('')
    // props.onClose(false)
    props.history.push('/login')
  }

  return (
    <Menu
      anchorEl={props.parentEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id="user-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={props.open}
      onClose={() => props.onClose(false)}
    >
      <MenuItem onClick={() => { props.onClose(false); window.location = adminLink(`/admin2/users/${auth.user.id}/edit`)}}>
        <ListItemIcon className={iconStyles.listIcon}>
          <i className="glyphicon glyphicon-user"></i>
        </ListItemIcon>
        {t("TopMenu.userProfile")}
      </MenuItem>
      <MenuItem onClick={() => (window.location = adminLink("/admin2/user_discounts"))}>
        <ListItemIcon className={iconStyles.listIcon}>
          <i className="glyphicon glyphicon-scissors"></i>
        </ListItemIcon>
        {t("TopMenu.personalDiscounts")}
      </MenuItem>
      <MenuItem onClick={() => (window.location = adminLink("/admin2/internal_pages"))}>
        <ListItemIcon className={iconStyles.listIcon}>
          <i className="glyphicon glyphicon-book-open"></i>
        </ListItemIcon>
        {t("TopMenu.internalPages")}
      </MenuItem>
      <MenuItem onClick={logOut}>
        <ListItemIcon className={iconStyles.listIcon}>
          <i className="glyphicon glyphicon-log-out"></i>
        </ListItemIcon>
        {t("TopMenu.logOut")}
      </MenuItem>
    </Menu>
  )
}

UserMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  parentEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(UserMenu)
