import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Alert, Container, Fab } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'

import { CampaignApi } from '@api/CampaignApi'
import { handleValidationErrors } from '@utils/errorUtils';

import Form from '@components/campaigns/Form'
import { useValidation } from '@customHooks/useValidation';
import TitleBar from '@topBar/TitleBar'
import useAlert from '@customHooks/useAlert'
import LoaderShowSingle from '@common/Loaders/LoaderShowSingle'

const Edit = ({ history, match, location }) => {
  const initialState = { id: match.params.id, content: '', description: '', url: '', name: '', label: '', layout: 'homepage', start_date: null, end_date: null, image: '', fixed: false, visible: true }

  const { t, i18n } = useTranslation()
  const [campaign, setCampaign] = useState(initialState)
  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: campaign.name, rules: { required: true } },
    url: { value: campaign.url, rules: { required: true } },
    layout: { value: campaign.layout, rules: { required: true } },
    image: { value: campaign.image, rules: { required: true } }
  });
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useAlert(location?.state?.created)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveCampaign();
    }
  };

  useEffect(() => {
    if (match.params.id) {
      setLoading(true)
      CampaignApi.getCampaign(campaign.id)
        .then(res => {
          setCampaign(res)
          setSelectedProducts(res.products)
          setSelectedCategories(res.categories)
          setSelectedTags(res.tags)
          setLoading(false)
        })
        .catch(error => setLoading(false))
    }
  }, [])

  const handleCampaignChange = (e) => {
    if (e.target.type === 'file') {
      if (e.target.files.length) {
        const file = e.target.files[0]
        if (file) {
          setCampaign({ ...campaign, image: file, image_preview_url: URL.createObjectURL(file) })
        }
      }
    } else if( e.target.type === 'checkbox') {
      setCampaign({ ...campaign, [e.target.name]: e.target.checked })

    } else {
      setCampaign({ ...campaign, [e.target.name]: e.target.value })
    }
  }

  const saveCampaign = () => {
    const formData = new FormData()
    for (const [field, value] of Object.entries(campaign)) {
      if (field === 'image') {
        // Append only if a new file is uploaded
        if (value instanceof File) {
          formData.append(`campaign[${field}]`, value);
        }
        continue; // Skip further processing for the image field
      } 
      if (value != null) {
        formData.append(`campaign[${field}]`, value)
      }
    }

    // Append product IDs
    selectedProducts.forEach(product => {
      formData.append('campaign[product_ids][]', product.id)
    })

    // Append category IDs
    selectedCategories.forEach(category => {
      formData.append('campaign[category_ids][]', category.id)
    })

    // Append tag IDs
    selectedTags.forEach(tag => {
      formData.append('campaign[tag_ids][]', tag.id)
    })

    if (campaign.id) {
      CampaignApi.updateCampaign(campaign.id, formData)
        .then(() =>
          setAlert({ severity: 'success', text: 'Updated successfully!' }),
        )
        .catch((error) => handleValidationErrors(error, setValidationErrors, setAlert))
    } else {
      CampaignApi.createCampaign(formData)
        .then(res => {
          setCampaign({ ...campaign, id: res.id })
          history.push({ pathname: `/campaigns/${res.id}/edit`, state: { created: true } })
        })
        .catch(error => handleValidationErrors(error, setValidationErrors, setAlert))
    }
  }

  const handleAssociationChange = (relType, res, action) => {
    const addToSelected = (selected, setSelected) => setSelected([...selected, res])

    const removeFromSelected = (selected, setSelected) => setSelected(selected.filter(item => item.id !== res))

    const updateSelection = (selected, setSelected) => {
      if (action === 'add') {
        addToSelected(selected, setSelected)
      } else {
        removeFromSelected(selected, setSelected)
      }
    }

    switch (relType) {
      case 'category':
        updateSelection(selectedCategories, setSelectedCategories)
        break
      case 'product':
        updateSelection(selectedProducts, setSelectedProducts)
        break
      case 'tag':
        updateSelection(selectedTags, setSelectedTags)
        break
      default:
        break
    }
  }

  const removeImage = () => {
    if (window.confirm("Are you sure?")){
      setCampaign({...campaign, image: null, image_preview_url: null, image_url: null})
    }
  }

  return (
    <>
      <TitleBar title={`${match.params.id ? 'Radigera' : 'Ny'} Kampanjer`}>
        <Fab size="small" aria-label="add" onClick={() => history.push('/campaigns')} color="secondary">
          <ArrowBack/>
        </Fab>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>
            {alert.text}
          </Alert>
        )}
        {loading ? <LoaderShowSingle/>
          :
          <Form
            campaign={campaign}
            removeImage={removeImage}
            handleChange={handleCampaignChange}
            selectedProducts={selectedProducts}
            selectedCategories={selectedCategories}
            selectedTags={selectedTags}
            handleAssociationChange={handleAssociationChange}
            saveCampaign={saveCampaign}
            validationErrors={validationErrors}
            onSubmit={handleSubmit}
          />
        }
      </Container>
    </>
  )
}

Edit.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default Edit
