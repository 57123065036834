import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { Grid, Box } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { makeStyles } from "@mui/styles";
import ProductResourceContext from "@contexts/ProductResource";
import { useTranslation } from "react-i18next";
import produce from "immer";
import PopupAlert from "@contexts/PopupAlert";

import ProductReferenceImage from "@components/products/images/ProductReferenceImage";
import { ProductReferenceImagesApi } from "@src/apis/ProductReferenceImagesApi";
import { parseErrorMessage } from "@src/utils/sharedHelper";
import { MarkingMethodApi } from "@src/apis/MarkingMethodApi";

const ProductReferenceImages = (props) => {
  const productResource = useContext(ProductResourceContext);
  const addPopupAlert = useContext(PopupAlert);
  const isSupplierProduct = productResource === "supplier_products";

  const [referenceImages, setReferenceImages] = useState([]);
  const [markingMethods, setMarkingMethods] = useState([]);

  const { t } = useTranslation();

  let { id } = useParams();

  useEffect(() => {
    let didCancel = false;
    ProductReferenceImagesApi.index(id, productResource)
      .then((data) => {
        if (!didCancel) setReferenceImages(data.data);
      })
      .catch((error) => {
        addPopupAlert(
          t("productReferenceImages.actions.index"),
          "error",
          parseErrorMessage(error.message),
        );
      });
    return () => {
      didCancel = true;
    };
  }, [id]);

  useEffect(() => {
    let didCancel = false;
    MarkingMethodApi.getMarkingMethods({ per_page: 100 })
      .then((data) => {
        if (!didCancel) setMarkingMethods(data.data);
      })
      .catch((error) => {
        addPopupAlert(
          t("marking_methods.actions.load"),
          "error",
          parseErrorMessage(error.message),
        );
      });
    return () => {
      didCancel = true;
    };
  }, []);

  const addColor = (image, color) => {
    setReferenceImages(
      produce((draft) => {
        draft.find((i) => i.id === image.id).colors.push(color);
      }),
    );
  };

  const removeColor = (image, color) => {
    setReferenceImages(
      produce((draft) => {
        const i = draft.find((i) => i.id === image.id);
        i.colors = i.colors.filter((c) => c.id !== color.id);
      }),
    );
  };

  const handleEdit = (image, updatedAttrs) => {
    setReferenceImages(
      produce((draft) => {
        const editedImage = draft.find((i) => i.id === image.id);
        editedImage.description = updatedAttrs.description;
      }),
    );
  };

  const handleColorEdit = (image, color) => {
    setReferenceImages(
      produce((draft) => {
        const i = draft.find((i) => i.id === image.id);
        const index = i.colors.findIndex((c) => c.id === color.id);
        i.colors[index] = color;
      }),
    );
  };

  const handleAddMarkingMethod = (image, markingMethod) => {
    setReferenceImages(
      produce((draft) => {
        draft.find((i) => i.id === image.id).marking_method = markingMethod;
      }),
    );
  };

  const handleRemoveMarkingMethod = (image) => {
    setReferenceImages(
      produce((draft) => {
        draft.find((i) => i.id === image.id).marking_method = null;
      }),
    );
  };

  const newReferenceImages = (event) => {
    let formData = new FormData();
    Array.from(event.target.files).forEach((file) => {
      formData.append("product_reference_image[images][]", file);
    });
    ProductReferenceImagesApi.newReferenceImages(id, productResource, formData)
      .then((res) => {
        setReferenceImages(referenceImages.concat(res.data));
      })
      .catch((error) => {
        addPopupAlert(
          t("productReferenceImages.actions.uploadNewImages"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const hanleDeleteReferenceImage = (image) => {
    setReferenceImages(referenceImages.filter((i) => i.id !== image.id));
  };

  const classes = makeStyles(() => ({
    input: { display: "none" },
  }))();

  return (
    <div className="product-images">
      <ul className="list-group header">
        <li className="list-group-item list-group-item-action">
          <Grid container spacing={1}>
            <Grid item xs={3}>
              {t("productReferenceImages.attributes.colors")}
            </Grid>
            <Grid item xs={2}>
              {t("productReferenceImages.attributes.image")}
            </Grid>
            <Grid item xs={2}>
              {t("productReferenceImages.attributes.description")}
            </Grid>
            <Grid item xs={4}>
              {t("productReferenceImages.attributes.marking_method")}
            </Grid>
          </Grid>
        </li>
      </ul>
      <Box>
        <ul className="list-group">
          {referenceImages.map((image) => (
            <ProductReferenceImage
              key={image.id}
              image={image}
              addColor={addColor}
              removeColor={removeColor}
              onEdit={handleEdit}
              onDelete={hanleDeleteReferenceImage}
              onColorEdit={handleColorEdit}
              onAddMarkingMethod={handleAddMarkingMethod}
              onRemoveMarkingMethod={handleRemoveMarkingMethod}
              markingMethods={markingMethods}
            />
          ))}
        </ul>
      </Box>
      <Grid container>
        <Grid item xs={1}>
          <input
            disabled={isSupplierProduct}
            accept="image/*"
            className={classes.input}
            id="multi-upload-2"
            type="file"
            multiple
            onChange={newReferenceImages}
          />
          <label htmlFor="multi-upload-2">
            <Fab
              color="primary"
              size="small"
              component="span"
              disabled={isSupplierProduct}
            >
              <AddToPhotosIcon disabled={isSupplierProduct} />
            </Fab>
          </label>
        </Grid>
      </Grid>
    </div>
  );
};

ProductReferenceImages.propTypes = {};

export default ProductReferenceImages;
