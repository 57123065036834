import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import AsyncCreatableSelect from "react-select/async-creatable";

import HttpClient from "@components/HttpClient";

const CreatableAsyncAutoComplete = ({
  onChange,
  resource,
  createUrl,
  optionsUrl,
  onCreateOption,
  onError,
  debouncingDelay,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const fetchResultsDebouncingTimer = useRef(null);

  const handleChange = (opt, meta) => {
    onChange(opt);
  };

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    const postData = resource
      ? { [resource]: { name: inputValue } }
      : { name: inputValue };
    HttpClient.post(createUrl, postData, {}, !!rest.showLoader)
      .then((response) => {
        setIsLoading(false);
        onCreateOption(response);
      })
      .catch((error) => (onError ? onError(error) : console.log(error)));
  };

  const promiseOptions = (inputValue, callback) => {
    if (!!fetchResultsDebouncingTimer.current)
      clearTimeout(fetchResultsDebouncingTimer.current);
    fetchResultsDebouncingTimer.current = setTimeout(() => {
      HttpClient.get(`${optionsUrl}=${inputValue}`).then((res) => {
        let data = res;
        if (Array.isArray(res.data)) data = res.data;
        callback(data.map((a) => ({ label: a.name, value: a.id, ...a })));
      });
    }, debouncingDelay ?? 300);
  };

  return (
    <AsyncCreatableSelect
      cacheOptions
      loadOptions={promiseOptions}
      onChange={handleChange}
      onCreateOption={handleCreate}
      {...rest}
    />
  );
};

CreatableAsyncAutoComplete.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  exclude: PropTypes.array, // if want to exclude some options
  onCreateOption: PropTypes.func.isRequired,
  createUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  debouncingDelay: PropTypes.number,
};

export default CreatableAsyncAutoComplete;
