import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Tab, TableCell, TableRow, Paper, IconButton, Container } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material'

import { SelectInput, ServerSideCreatableSelect } from '@common/index'
import { LoaderList } from '@common/Loaders'
import { ListTable, ListTableActions } from '@common/ListingTable'

import NewModal from '@shared/NewModal'
import QuestionModal from '@components/questions/QuestionModal'
import useListingActions from '@customHooks/useListingActions'

import { QuestionApi, ProductApi } from '@api'
import { ProductSorting } from '@config/SortingLabels'

import PopupAlert from "@contexts/PopupAlert";

// Configurations
const CATEGORIES = {
  frequently_asked: 'Frequently asked questions',
  logo_and_printing: 'Logo and Printing',
  delivery_and_shipping: 'Delivery and shipping',
  prices_and_payment: 'Prices and payment',
  other: 'Other',
}
const TYPES = { generic: 'Generic', product_question: 'Product Questions' }

// Utility functions to map options
const mapToOptions = (obj) =>
  Object.entries(obj).map(([id, name]) => ({ id, name }))

const selectCategoriesOptions = mapToOptions(CATEGORIES)
const selectTypesOptions = mapToOptions(TYPES)

const QuestionTable = ({ pageType, productId }) => {
  const { t } = useTranslation()
  const addPopupAlert = useContext(PopupAlert)

  // State Management
  const [tabValue, setTabValue] = useState('generic')
  const [filterCategory, setFilterCategory] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const handleExtraParameters = () => {
    return { ...(productId ? { product_id: productId } : { type: tabValue }), ...(filterCategory && { category: filterCategory }) }
  }

  const {
    data,
    setData,
    count,
    page,
    setPage,
    perPage,
    loading,
    sortOptionSelected,
    fetchData,
    handleSearchingAndSortingChange,
  } = useListingActions(
    pageType,
    QuestionApi.getQuestions,
    'latest',
    handleExtraParameters,
  )

  const setParamsAndFetchData = (extraParams) => {
    setPage(1)

    extraParams = { ...(productId ? { product_id: productId } : { type: tabValue }), ...extraParams}

    let params = {
      sort_by: sortOptionSelected,
      page: 1,
      per_page: perPage,
      category: filterCategory,
      ...extraParams,
    }

    fetchData(params, true)
  }

  const handleCategoryChange = (e) => {
    const category = e.target.value
    setFilterCategory(category)

    setParamsAndFetchData({ category })
  }

  const deleteQuestion = async (id) => {
    if (pageType === 'questionsListing') {
      if (window.confirm(t('alertTexts.questionDeletionConfirmation'))) {
        await QuestionApi.deleteQuestion(id)
        addPopupAlert(
          t("questions.messages.deleted"),
          "success",
        );
        setData(data.filter(row => row.id !== id))
      }
    } else {
      if (window.confirm(t('alertTexts.productQuestionConfirmation'))) {
        const updatedQuestions = data.filter(row => row.id !== id)
        const updatedIds = updatedQuestions.map(q => q.id)
        await ProductApi.associateQuestions(productId, updatedIds)
        addPopupAlert(
          t("questions.messages.productQuestionDeletion"),
          "success",
        );
        setData(updatedQuestions)
      }
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setParamsAndFetchData({ type: newValue })
  }

  const handleModalOpen = (question) => {
    setSelectedQuestion(question)
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
    setSelectedQuestion(null)
  }

  const questionsTableContent = () => {
    const questionBody = data?.map(question => (
      <TableRow key={question.id}>
        <TableCell>
          <div onClick={() => handleModalOpen(question)}>{question.question}</div>
        </TableCell>
        <TableCell>{question.answer}</TableCell>
        <TableCell>{TYPES[question.type]}</TableCell>
        <TableCell>{CATEGORIES[question.category]}</TableCell>
        <TableCell>{question.public ? <CheckIcon/> : <CloseIcon/>}</TableCell>
        <TableCell align="right" sx={{ display: 'flex' }}>
          <IconButton onClick={() => deleteQuestion(question.id)}>
            <DeleteIcon/>
          </IconButton>
          <IconButton onClick={() => handleModalOpen(question)}>
            <EditIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    ))
    return {
      body: questionBody,
      heading: [t('questions.tableHeadings.question'), t('questions.tableHeadings.answer'), t('questions.tableHeadings.type'), t('questions.tableHeadings.category'), t('questions.tableHeadings.public'), '']
    }
  }

  const tableContent = questionsTableContent()

  const handleCreate = (ques) => {
    let _questions = data
    _questions.push(ques)
    setData(_questions)
    handleModalOpen(ques)
    addPopupAlert(
      t("questions.messages.created"),
      "success",
    );
  }

  const handleUpdateQuestion = (ques, removeRecord) => {
    let updatedData;
    if (removeRecord){
      updatedData = data.filter(q => q.id !== ques.id)
    } else {
      updatedData = data.map( q => {
        if (q.id !== ques.id ) return q;
        return ques
      })
    }
    setData(updatedData)
  }

  return (
    <React.Fragment>
      <Container>
        {
          pageType === 'questionsListing' &&
          <Box sx={{ width: '100%', marginBottom: '10px' }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                  <Tab label={t('questions.tabs.generic')} value="generic"/>
                  <Tab label={t('questions.tabs.product_specific')} value="product_question"/>
                </TabList>
              </Box>
            </TabContext>
          </Box>
        }
        <ListTableActions
          sortOptionSelected={sortOptionSelected}
          sortOptions={ProductSorting}
          getData={handleSearchingAndSortingChange}
          searchComponent={
            <ServerSideCreatableSelect
              createApiFunc={QuestionApi.createQuestion}
              apiFunction={QuestionApi.getQuestions}
              resource={'question'}
              onCreateOption={handleCreate}
              onChange={handleModalOpen}
              resourceId={productId}
              extraListingParams={pageType === 'productQuestions' ? { product_id: productId } : {}}
              extraCreationParams={pageType === 'productQuestions' ? {
                type: 'product_question',
                product_ids: [productId]
              } : { type: 'generic' }}
            />}
          children={
            <SelectInput
              value={filterCategory}
              size={'small'}
              valuesArray={selectCategoriesOptions}
              label={t('questions.filters.category')}
              onChange={handleCategoryChange}
              margin={'none'}
            />
          }
        />
        {
          loading ?
            <LoaderList/>
            :
            <Paper square>
              <ListTable
                tableHeadings={tableContent.heading}
                tableContent={tableContent.body}
                count={count}
                pageNo={page}
                rowsPerPage={perPage}
                onPageChange={(event, pageNo) => handleSearchingAndSortingChange('page', pageNo + 1)}
                handleChangeRowsPerPage={(event) => handleSearchingAndSortingChange('pageSize', event.target.value)}
                rowsControl={true}
              />
            </Paper>
        }
      </Container>

      {
        openModal &&
        <NewModal title={t('questions.editPageTitle')} open={openModal} onClose={handleModalClose}>
          <QuestionModal
            tabValue={pageType === 'questionsListing' ? tabValue : null}
            ques={selectedQuestion}
            categories={selectCategoriesOptions}
            types={selectTypesOptions}
            closeModal={handleModalClose}
            listingPage={pageType === 'questionsListing'}
            handleUpdateQuestion={handleUpdateQuestion}
            productId={productId}
          />
        </NewModal>
      }
    </React.Fragment>
  )
}

export default QuestionTable
