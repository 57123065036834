import React from "react";
import { PropTypes } from 'prop-types'
import { Grid, Button } from "@mui/material";

import FileInput from '@common/FileInput'

const UploadImageWidget = ({ field, object, onChange, deleteImage, size, type = 'image', accept='image/*' }) => {
  const image = (field ? object[`${field}_url`] : object.image)
  const _delete_image = (field ? object[`_delete_${field}`] : object._delete_image)
  const new_image = (field ? object[`new_${field}`] : object.new_image)
  const image_preview_url = (field ? object[`${field}_preview_url`] : object.image_preview_url)

  const styles = {
    image: {
      maxHeight: (size === 'medium' ? '100px' : '200px'),
      width: (size === 'medium' ? '100px' : '200px'),
    }
  }

  return (
    <Grid container spacing={1}>
      { (( image_preview_url || image) && (!_delete_image || new_image)) ?
        <Grid container xs={12} item>
          <Grid item xs={12}>
            {
              type === 'image' ?
                <img style={styles.image} src={image_preview_url ? `${image_preview_url}` : `${image}`} alt=""/>
                :
                <video style={styles.image} controls>
                  <source src={image_preview_url ? `${image_preview_url}` : `${image}`}/>
                </video>
            }
          </Grid>
        </Grid>
        :
        <></>
      }
      <Grid item xs={7} sm={5} md={8}>
        <FileInput onChange={onChange} name={field ? field : 'image'} label={`Upload ${field}`} accept={accept}/>
      </Grid>
      {(deleteImage && (image_preview_url || image) && !_delete_image) &&
        <Grid item xs={5} md={4}>
          <Button variant="outlined" component="label" onClick={deleteImage}>
            Remove
          </Button>
        </Grid>
      }
    </Grid>
  )
}

UploadImageWidget.propTypes = {
  object: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  deleteImage: PropTypes.func,
  title: PropTypes.string,
  field: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.string
}

export default UploadImageWidget
