import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Container, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

import TitleBar from "../topbar/TitleBar";
import Mdivider from "../common/Mdivider";
import LoaderShowSingle from "../common/Loaders/LoaderShowSingle";
import Alert from "@mui/material/Alert";
import useAlert from "@customHooks/useAlert";
import { ColorApi } from "../../apis/ColorApi";
import InlineLoader from "@components/common/Loaders/InlineLoader";
import ColorPicker from "@components/common/ColorPicker";
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';

const Form = (props) => {
  const [color, setColor] = useState(props.color);
  const [parentOptions, setParentOptions] = useState([]);
  const [alert, setAlert] = useAlert(props.created);
  const [loading, setloading] = useAlert(false);

  useEffect(() => {
    setColor({ ...props.color });
  }, [props.color]);

  useEffect(() => {
    setloading(true);
    ColorApi.getParentOptions().then((res) => {
      setloading(false);
      setParentOptions([...parentOptions, ...res]);
    });
  }, []);

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: color.name, rules: { required: true } },
    code: { value: color.code, rules: { required: true } },
  });

  const parentColorLabel = useRef(null);
  const [parentColorLabelWidth, setParentColorLabelWidth] = useState(0);
  const aliasLabel = useRef(null);
  const [aliasLabelWidth, setAliasLabelWidth] = useState(0);
  useEffect(() => {
    setParentColorLabelWidth(parentColorLabel.current.offsetWidth);
    setAliasLabelWidth(aliasLabel.current.offsetWidth);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveColor();
    }
  };

  const saveColor = () => {
    if (color.id) {
      setloading(true);
      ColorApi.updateColor(color.id, { color })
        .then((response) => {
          setloading(false);
          props.isModal
            ? props.onSave(response)
            : setAlert({ severity: "success", text: "Updated successfully!" });
        })
        .catch((error) => {
          setloading(false);
          handleValidationErrors(error, setValidationErrors, setAlert)
        });
    } else {
      ColorApi.createColor({ color })
        .then((res) => {
          props.history.push({
            pathname: `/colors/${res.id}/edit`,
            state: { created: true },
          });
        })
        .catch((error) => handleValidationErrors(error, setValidationErrors, setAlert));
    }
  };

  // const updateColorCode = code => {
  //   setColor({ ...color, code })
  // }

  const parentElement = (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel
        shrink={!!color.color}
        ref={parentColorLabel}
        htmlFor="parent"
      >
        Förälderfärg
      </InputLabel>
      <NativeSelect
        value={(color.color && color.color.id) || ""}
        onChange={(e) =>
          setColor({ ...color, color: { id: e.currentTarget.value } })
        }
        input={
          <OutlinedInput
            name="parent"
            id="parent"
            labelWidth={parentColorLabelWidth}
            notched
          />
        }
      >
        <option key="" value=""></option>
        {parentOptions.map((po) => (
          <option key={po.id} value={po.id}>
            {po.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );

  return (
    <React.Fragment>
      <TitleBar title={`${color.id ? "Radigera" : "Ny"} färg`}>
        {loading && <InlineLoader sx={{ mr: "5px" }} />}
        <Fab
          color="secondary"
          size="small"
          aria-label="add"
          onClick={() => {
            props.isModal ? props.onClose() : props.history.push("/colors");
          }}
        >
          {props.isModal ? <CloseIcon /> : <ArrowBack />}
        </Fab>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>
            {alert.text}
          </Alert>
        )}
        {props.loading ? (
          <LoaderShowSingle />
        ) : (
          <Grid container>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Namn"
                  value={color.name || ""}
                  onChange={(e) =>
                    setColor({ ...color, name: e.currentTarget.value })
                  }
                  variant="outlined"
                  error={!!validationErrors.name}
                  helperText={validationErrors.name}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Kortnamn"
                  value={color.short_name || ""}
                  onChange={(e) =>
                    setColor({ ...color, short_name: e.currentTarget.value })
                  }
                  variant="outlined"
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <ColorPicker
                  label="Kod"
                  value={color.code}
                  onChange={(colorCode) => {
                    setColor({ ...color, code: colorCode });
                  }}
                  error={!!validationErrors.code}
                  helperText={validationErrors.code}
                />
              </FormControl>

              {parentElement}

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel ref={aliasLabel} htmlFor="alias_name">
                  Alias
                </InputLabel>
                <NativeSelect
                  value={(color.alias_name && color.alias_name.id) || ""}
                  onChange={(e) =>
                    setColor({
                      ...color,
                      alias_name: { id: e.currentTarget.value },
                    })
                  }
                  input={
                    <OutlinedInput
                      name="alias_name"
                      id="alias_name"
                      labelWidth={aliasLabelWidth}
                    />
                  }
                >
                  <option key="" value=""></option>
                  {parentOptions.map((po) => (
                    <option key={po.id} value={po.id}>
                      {po.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Mdivider />
            <Grid item xs={12} align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                Spara
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
};

Form.propTypes = {
  history: PropTypes.object,
  color: PropTypes.object,
  isModal: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
