import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

import Grid from '@mui/material/Grid'
import { FormControl, TextField, Select, OutlinedInput } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import AsyncAutocomplete from '../common/AsyncAutoComplete'
import HttpClient from '../HttpClient'
import Mdivider from '../common/Mdivider'

const InspirationProduct = props => {
  const [product, setProduct] = useState({...props.product})
  const [updating, setUpdating] = useState(false)

  useEffect(() => setProduct({ ...props.product }), [props.product])
  const saveChange = event => {
    event.stopPropagation()
    const prod = { ...product }
    const field = event.target.name
    switch (event.target.type) {
      case 'file':
        prod[field] = event.target.files[0]
        break
      default:
        prod[field] = event.target.value
    }
    const formData = new FormData()
    for (const [field, value] of Object.entries(prod)) {
      if(field === 'image') {
        if(value !== '') {
          formData.append(`inspiration_product[${field}]`, value)
        }
      } else {
        formData.append(`inspiration_product[${field}]`, value)
      }
    }
    // disable button while uploading
    setUpdating(true)
    if(product.id < 0) {
      HttpClient.post(`/inspirations/${props.inspirationId}/inspiration_products`, formData)
        .then(res => {
          setUpdating(false)
          props.onUpdate(prod.id, res)
        })
    } else {
      HttpClient.put(`/inspirations/${props.inspirationId}/inspiration_products/${prod.id}`, formData)
        .then(res => {
          setUpdating(false)
          props.onUpdate(prod.id, res)
        })
    }
  }

  const handleChange = event => {
    let prod = { ...product }
    const field = event.target.name
    switch (event.target.type) {
      case 'file':
        prod[field] = event.target.files[0]
        break
      default:
        prod[field] = event.target.value
    }
    setProduct(prod)
  }

  const searchedProductUpdate = selected => {
    const prod = { ...product }
    prod.product.id = selected.id
    
    setUpdating(true)
    if(product.id < 0) {
      HttpClient.post(`/inspirations/${props.inspirationId}/inspiration_products`, { inspiration_product: prod })
        .then(res => {
          setUpdating(false)
          props.onUpdate(prod.id, res)
        })
    } else {
      HttpClient.put(`/inspirations/${props.inspirationId}/inspiration_products/${prod.id}`, { inspiration_product: prod })
        .then(res => {
          setUpdating(false)
          props.onUpdate(prod.id, res)
        })
    }
  }

  const productImage = product.product && product.product.id ? (
    <a href={product.product.image_url} target='_blank' rel='noopener noreferrer'>
      <img src={product.product.thumbnail_admin} alt={product.name} />
    </a>
  ) : (
    product.image_url ? (
      <a href={product.image_url} target='_blank' rel='noopener noreferrer'>
        <img src={product.thumbnail_admin} alt={product.name} />
      </a>
    ) : 
      null
  )

  const productName = product.product && product.product.id ? (
    <a href={`/products/${product.product.id}`} target='_blank' rel='noopener noreferrer'>{product.product.name}</a>
  ) : (
    product.id < 0 ? (
      <FormControl fullWidth style={{ padding: '8px 0 4px' }}>
        <AsyncAutocomplete defaultInputValue={product.product.name || ''} optionsUrl='/products/search?term' onChange={searchedProductUpdate} />
      </FormControl>
    ) : (
      <FormControl fullWidth>
        <TextField
          value={product.name || ''}
          name="name"
          onChange={handleChange}
          onBlur={saveChange}
          margin="dense"
          variant="outlined"
        />
      </FormControl>
    )
  )
  const productUrl = product.product && product.product.id ? (
    <a href={`/products/${product.product.id}`} target='_blank' rel='noopener noreferrer'>{`${window.location.protocol}//${window.location.host}/products/${product.product.id}`}</a>
  ) : (
    <FormControl fullWidth>
      <TextField
        value={product.url || ''}
        name="url"
        onChange={handleChange}
        onBlur={saveChange}
        margin="dense"
        variant="outlined"
      />
    </FormControl>
  )

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          {productImage}
          { product.product && product.product.id ?
            null
            : 
            (
              <React.Fragment>
                <input
                  style={{ display: 'none' }}
                  id={`product-image-upload-${product.id}`}
                  type="file"
                  name="image"
                  onChange={saveChange}
                />
                <label htmlFor={`product-image-upload-${product.id}`}>
                  <Fab
                    color="primary"
                    size="small"
                    component="span"
                    disabled={updating}>
                    <AddIcon />
                  </Fab>
                </label>
              </React.Fragment>
            )
          }
        </Grid>
        <Grid item xs={3}>{productName}</Grid>
        <Grid item xs={3}>{productUrl}</Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              value={product.description || ''}
              name="description"
              onChange={handleChange}
              onBlur={saveChange}
              margin="dense"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <Fab
            onClick={() => {if(window.confirm('Är du säker?')) props.onDelete(product.id)}}
            color="secondary"
            size="small">
            <DeleteIcon />
          </Fab>
        </Grid>
      </Grid>
      <Mdivider />
    </React.Fragment>
  )
}

InspirationProduct.propTypes = {
  inspirationId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default InspirationProduct