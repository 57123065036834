import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import List from './List'
import Edit from './Edit'
import Navigation from './Navigation'
import Show from './Show'

const Products = props => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}`} component={List}></Route>
      <Route path={`${props.match.path}/new`} component={Edit}></Route>
      <Route exact path={`${props.match.path}/:id`} component={Show}></Route>
      <Route path={`${props.match.path}/:id/`} component={Navigation}></Route>
    </Switch>
  )
}

Products.propTypes = { match: PropTypes.object.isRequired }

export default Products
