import React from 'react'
import { MenuItem, TextField } from '@mui/material'

const SelectInput = ({
  label,
  value,
  onChange,
  valuesArray,
  identifier = 'id',
  propertyName = 'name',
  defaultItems = [],
  disabled = false,
  size = 'large',
  margin = 'normal',
  name
}) => {
  return (
    <TextField
      disabled={disabled}
      name={name}
      value={value}
      label={label}
      placeholder={label}
      variant="outlined"
      size={size}
      onChange={onChange}
      margin={margin}
      select
      fullWidth>
      {valuesArray.map((c) => (
        <MenuItem key={c[identifier]} value={c[identifier]}>
          {c[propertyName]}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectInput
