import HttpClient from "@httpClient";
import { singularize } from "@src/utils/sharedHelper";

const index = (id, apiType = "products") => {
  return HttpClient.get(
    `/${apiType}/${id}/articles?product_type=${singularize(apiType)}`,
  );
};

const ArticleApi = {
  index,
};

export { ArticleApi };
