/* eslint-disable react/prop-types */
import React from 'react'
import { CSS } from '@dnd-kit/utilities'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Box, TableRow } from '@mui/material'

// We can have DND on both list and table in case of table we would have
// to pass component prop to SortAbleItem component to be TableRow and use
// attributes and listeners on that parent container
// COMPONENT_TYPE = ['List', 'Table']

const SortingContext = ({ componentType, data, setData, DataComponent, optionalParams = {}, addDelay = false }) => {

  const sensors = useSensors(
    addDelay ? useSensor(PointerSensor, { activationConstraint: { delay: '200', tolerance: 0, } }) : useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = event => {
    const { active, over } = event
    if (active?.id !== over?.id) {
      const oldIndex = data.findIndex(v => v.id ? v.id === active?.id : v._id === active?.id)
      const newIndex = data.findIndex(v => v.id ? v.id === over?.id : v._id === over?.id)
      var reordered = arrayMove(data, oldIndex, newIndex)
      reordered = reordered.map((v, index) => { return { ...v, position: index } })
      var updatedIds = [active.id, over.id]
      const draggedItemIds = reordered?.filter(item => updatedIds?.includes(item.id))
      setData(reordered, draggedItemIds)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={data}
        strategy={verticalListSortingStrategy}
      >
        {data.map((row, index) => (
          <SortableItem key={`item-${(row?.id || row?._id || index)}`}
                        objects={data}
                        data={row}
                        componentType={componentType}
                        DataComponent={DataComponent}
                        object={row} optionalParams={optionalParams}
          />
        ))
        }
      </SortableContext>
    </DndContext>
  )
}

const SortableItem = ((props) => {
  const { data, DataComponent, objects, optionalParams, componentType } = props
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable(
    { id: (data.id) },
  )

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Box ref={setNodeRef} style={{ ...style, cursor: 'pointer' }} component={componentType && TableRow} hover {...(componentType ? attributes : {})} {...(componentType ? listeners : {})}>
      {
        <DataComponent
          objects={objects}
          object={data}
          attributes={attributes}
          listeners={listeners}
          optionalParams={optionalParams}
        />
      }
    </Box>
  )
})

export default SortingContext
