import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import ValueInput from "./ValueInput";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import HttpClient from "../HttpClient";

const Values = (props) => {
  const attributeValues = [...props.values];

  const removeValue = (id) => {
    props.setAttributeValues(attributeValues.filter((v) => v.id !== id));
  };

  const addAttributeValue = () => {
    let _attributeValues = [...attributeValues];
    _attributeValues.push({
      id: attributeValues.length + 1,
      key: attributeValues.length + 1,
      value: "",
      position: attributeValues.length + 1,
    });
    props.setAttributeValues([..._attributeValues]);
  };

  const setValue = (value) => {
    const index = attributeValues.findIndex((v) => v.id === value.id);
    if (index !== -1) {
      attributeValues[index].value = value.value;
      props.setAttributeValues(attributeValues);
    }
  };

  const shouldCancelStart = (e) => {
    // Cancel sorting if the event target is an `input`, `textarea`, `select`,`option` or `button`
    const disabledElements = [
      "input",
      "textarea",
      "select",
      "option",
      "button",
      "a",
      "svg",
      "span",
    ];
    if (
      disabledElements.indexOf(e.target.tagName.toLowerCase()) !== -1 ||
      disabledElements.indexOf(e.target.parentNode.tagName.toLowerCase()) !== -1
    ) {
      return true; // Return true to cancel sorting
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedValues = arrayMove(attributeValues, oldIndex, newIndex);
    props.setAttributeValues([...sortedValues]);
    HttpClient.post("/attribute_values/sort", {
      attribute_values: sortedValues.map((i) => i.id),
    }).then((res) => {});
  };

  return (
    <React.Fragment>
      <SortableList
        values={attributeValues}
        removeValue={removeValue}
        setValue={setValue}
        shouldCancelStart={shouldCancelStart}
        onSortEnd={onSortEnd}
      />
      <Fab
        style={{ marginTop: "10px" }}
        size="small"
        color="primary"
        aria-label="add"
        onClick={addAttributeValue}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

const SortableItem = SortableElement(({ value, removeValue, setValue }) => {
  return (
    <ValueInput
      attributeValue={value}
      key={value.id || value.key}
      removeValue={removeValue}
      setValue={setValue}
    />
  );
});

const SortableList = SortableContainer(({ values, removeValue, setValue }) => {
  return (
    <div>
      {values.map((value, index) => (
        <SortableItem
          key={`value-${value.id}`}
          index={index}
          disabled={value.id < 0}
          value={value}
          removeValue={removeValue}
          setValue={setValue}
        />
      ))}
    </div>
  );
});

Values.propTypes = {
  values: PropTypes.array,
  setAttributeValues: PropTypes.func,
};

export default Values;
