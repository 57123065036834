import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Card, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Fab from "@mui/material/Fab";
import ArrowBack from "@mui/icons-material/ArrowBack";

import TitleBar from "../topbar/TitleBar";
import Mdivider from "../common/Mdivider";
import LoaderShowSingle from "../common/Loaders/LoaderShowSingle";
import Alert from "@mui/material/Alert";
import useAlert from "@customHooks/useAlert";
import { BrandApi } from "../../apis/BrandApi";
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';

const Form = (props) => {
  const [brand, setBrand] = useState(props.brand);
  const [alert, setAlert] = useAlert(props.created);

  useEffect(() => {
    setBrand({ ...props.brand });
  }, [props.brand]);

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: brand.name, rules: { required: true } },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveBrand();
    }
  };

  const saveBrand = () => {
    const formData = new FormData();
    for (const [field, value] of Object.entries(brand)) {
      formData.append(`brand[${field}]`, value);
    }
    if (brand.id) {
      BrandApi.updateBrand(brand.id, formData)
        .then(() =>
          setAlert({ severity: "success", text: "Updated successfully!" }),
        )
        .catch((error) => handleValidationErrors(error, setValidationErrors, setAlert));
    } else {
      BrandApi.createBrand(formData)
        .then((res) => {
          props.history.push({
            pathname: `/brands/${res.id}/edit`,
            state: { created: true },
          });
        })
        .catch((error) => handleValidationErrors(error, setValidationErrors, setAlert));
    }
  };

  const classes = makeStyles(() => ({
    input: { display: "none" },
    card: {
      maxWidth: 145,
    },
    media: {
      height: 0,
      paddingTop: "100%", // square
      backgroundSize: "contain",
    },
  }))();

  return (
    <>
      <TitleBar title={`${brand.id ? "Radigera" : "Ny"} märke`}>
        <Fab
          color="primary"
          size="small"
          aria-label="add"
          onClick={() => {
            props.history.push("/brands");
          }}
          color="secondary"
        >
          <ArrowBack />
        </Fab>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>
            {alert.text}
          </Alert>
        )}
        {props.loading ? (
          <LoaderShowSingle />
        ) : (
          <Grid container>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Namn"
                value={brand.name}
                onChange={(e) =>
                  setBrand({ ...brand, name: e.currentTarget.value })
                }
                margin="normal"
                variant="outlined"
                error={!!validationErrors.name}
                helperText={validationErrors.name}
              />
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  minRows="4"
                  label="Beskrivning"
                  value={brand.description}
                  onChange={(e) =>
                    setBrand({ ...brand, description: e.currentTarget.value })
                  }
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                {brand.thumbnail_url ? (
                  <Card outlined="true" className={classes.card}>
                    <CardMedia
                      square="true"
                      className={classes.media}
                      image={brand.thumbnail_url}
                      title={brand.name}
                      style={{ backgroundSize: "contain" }}
                    />
                  </Card>
                ) : null}
                <div>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={(e) =>
                      setBrand({ ...brand, logo: e.currentTarget.files[0] })
                    }
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      component="span"
                    >
                      Välj fil
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  minRows="4"
                  label="Sidfot"
                  value={brand.footer || ""}
                  onChange={(e) =>
                    setBrand({ ...brand, footer: e.currentTarget.value })
                  }
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Mdivider />
            <Grid item xs={12} align="right">
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Spara
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

Form.propTypes = {
  brand: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default Form;
