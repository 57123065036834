import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { Container, Grid } from '@mui/material'
import { TextField, FormControl }  from '@mui/material'
import { Button, Fab } from '@mui/material'
import { Card, CardMedia } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Snackbar from '@mui/material/Snackbar'

import TitleBar from '../topbar/TitleBar'
import Mdivider from '../common/Mdivider'
import HttpClient from '../HttpClient'
import { useAuth } from '../../AuthContext'

const Form = props => {
  const auth = useAuth()
  const [valid, setValid] = useState(false)
  const [match, setMatch] = useState(false)
  const [open, setOpen] = useState(false)

  const [user, setUser] = useState({
    name: auth.user.name,
    mugshot_image: '',
    mugshot_image_url: auth.user.mugshot_image_url,
    password: '',
    passwordConfirmation: ''
  })

  const handleChange = e => {
    const field = e.target.name
    if(field === 'password') {
      e.target.value.length >= 8 ? setValid(true) : setValid(false)
      setUser({ ...user, password: e.target.value })
    } else {
      e.target.value === user.password ? setMatch(true) : setMatch(false)
      setUser({ ...user, passwordConfirmation: e.target.value })
    }
  }

  const saveUser = () => {
    if(valid && match) {
      const formData = new FormData()
      formData.append('user[mugshot_image]', user.mugshot_image)
      formData.append('user[password]', user.password)
      formData.append('user[passwordConfirmation]', user.passwordConfirmation)
      HttpClient.post('/users', formData)
        .then(res =>{
          setOpen(true)
          setValid(false)
          setMatch(false)
          setUser({ ...user, mugshot_image_url: res.mugshot_image_url, password: '', passwordConfirmation: '' })
        })
        .catch(error => console.log('Error updating user: ', error))
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const useStyles = makeStyles(() => ({
    input: { display: 'none' },
    card: {
      maxWidth: 145,
    },
    media: {
      height: 0,
      paddingTop: '100%', // square
      backgroundSize: 'contain'
    },
  }))()

  return(
    <>
      <TitleBar title="Redigera användare">
        <Fab color="secondary" size="small" aria-label="add" onClick={() => { props.history.push('/')}}>
          <ArrowBack />
        </Fab>
      </TitleBar>
      <Container>
        <Grid container>
          <Grid item xs={6}>
            {user.mugshot_image_url ? 
              (<Card outlined="true" className={classes.card}>
                <CardMedia square="true" className={classes.media} image={user.mugshot_image_url} title={user.name} />
              </Card>)
              :
              null
            }
            <div>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={e => setUser({ ...user, mugshot_image: e.currentTarget.files[0] })}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component="span">
                    Välj fil
                </Button>
              </label>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Namn"
                disabled={true}
                value={user.name || ''}
                onChange={e => setUser({ ...user, name: e.currentTarget.value })}
                variant="outlined" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Lösenord"
                type="password"
                name="password"
                value={user.password || ''}
                onChange={handleChange}
                variant="outlined"
                error={user.password.length > 0 && !valid}
                helperText={valid ? '' : '* lösenordet måste vara minst 8 tecken'}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Lösenordsbekräftelse"
                type="password"
                name="password_confirmation"
                value={user.passwordConfirmation || ''}
                onChange={handleChange}
                variant="outlined"
                error={user.password.length > 0 && !(valid && match)}
                helperText={valid && match ? '' : '* lösenordets bekräftelse måste matcha'}
              />
            </FormControl>
          </Grid>
          <Mdivider />
          <Grid item xs={12} align="right">
            <Button variant="contained" color="primary" disabled={!(valid && match)} onClick={saveUser}>Spara</Button>
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message='Password updated!' />
        </Grid>
      </Container>
    </>
  )
}

Form.propTypes = {
  history: PropTypes.object
}

export default Form
