import HttpClient from "../components/HttpClient"
import { setUrlForGetRequest } from '@api/SharedFunctions'

const BRAND_API = '/brands'

const getBrands = (params) => {
  return HttpClient.get(setUrlForGetRequest(BRAND_API, params))
}

const getBrand = (id) => {
  return HttpClient.get(`${BRAND_API}/${id}`)
}

const removeBrand = (id) => {
  return HttpClient.delete(`${BRAND_API}/${id}`)
}

const createBrand = (data) => {
  return HttpClient.post(`${BRAND_API}`, data)
}

const updateBrand = (id, data) => {
  return HttpClient.put(`${BRAND_API}/${id}`, data)
}

const BrandApi = {
  getBrand,
  getBrands,
  removeBrand,
  createBrand,
  updateBrand
}

export {BrandApi}
