import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonGroup, Grid } from '@mui/material'

const AlphabeticalFilter = props => {
  const letters = () => {
    return [...Array(26)].map((val, i) => String.fromCharCode(i + 65))
  }

  const classes ={
    button: { borderRadius: '0' },
    buttonGroup: { display: 'flex', flexWrap: 'wrap' }
  }

  return (
    <Grid container item xs={12}>
      <ButtonGroup color="primary" aria-label="alphabetical filtering" sx={classes.buttonGroup}>
        {letters().map((a, i) =>
          <Button
            key={i}
            href={`${props.resource}?filter=${a}`}
            color={a===props.currentFilter ? 'primary' : 'secondary'}
            sx={classes.button}>{a}</Button>
        )}
        <Button
          key={27}
          href={`${props.resource}?filter=Å`}
          color={'Å'===props.currentFilter ? 'primary' : 'secondary'}>Å</Button>
        <Button
          key={28}
          href={`${props.resource}?filter=Ä`}
          color={'Ä'===props.currentFilter ? 'primary' : 'secondary'}>Ä</Button>
        <Button
          key={29}
          href={`${props.resource}?filter=Ö`}
          color={'Ö'===props.currentFilter ? 'primary' : 'secondary'}>Ö</Button>
        <Button
          key={30}
          href={`${props.resource}?filter=num`}
          color={'#'===props.currentFilter ? 'primary' : 'secondary'}
          sx={classes.button}>#</Button>
      </ButtonGroup>
    </Grid>
  )
}

AlphabeticalFilter.propTypes = {
  resource: PropTypes.string.isRequired,
  currentFilter: PropTypes.string.isRequired
}

export default AlphabeticalFilter