import React, { useState, createContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Paper, Box, Tab, Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TabList, TabContext } from '@mui/lab';

import ListTableActions from '@common/ListingTable/ListTableActions'
import ListTable from '@common/ListingTable/ListTable'
import TitleBar from '@components/topbar/TitleBar'
import LoaderList from '@common/Loaders/LoaderList'
import SupplierProduct from '@components/supplier_products/SupplierProduct'
import ProductFilters from '@shared/ProductFilters'

import { SupplierProductApi } from '@api'
import { ProductSorting } from '@config/SortingLabels'
import { getSerialNumber } from '@components/SharedComponentFunctions'

import useAlert from '@customHooks/useAlert'
import useListingActions from '@customHooks/useListingActions'
import useProductFilters from '@customHooks/useProductFilters'

export const AlertContext = createContext();

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '15px'
  }
}))

const List = () => {
  const [tabValue, setTabValue] = useState('unmerged')
  const [alert, setAlert] = useAlert(null)
  const classes = useStyles()

  const { t } = useTranslation()

  const handleExtraParameters = () => {
     return { filter: tabValue, ...selectedSupplier && { supplier_id: selectedSupplier.id }, ...selectedCategory && { category_id: selectedCategory.id } }
  }

  const {
    data,
    setData,
    count,
    page,
    setPage,
    perPage,
    searchTerm,
    loading,
    sortOptionSelected,
    fetchData,
    handleSearchingAndSortingChange,
  } = useListingActions('supplierProduct', SupplierProductApi.getSupplierProducts, 'latest', handleExtraParameters );

  const setParamsAndFetchData = (extraParamObject) => {
    setPage(1)
    let params = { q: searchTerm, sort_by: sortOptionSelected, page: 1, per_page: perPage, filter: tabValue }
    params = { ...params, ...extraParamObject}
    fetchData(params, true)
  }

  const {
    suppliers,
    categories,
    selectedSupplier,
    selectedCategory,
    handleSupplierAndCategoryChange
  } = useProductFilters(SupplierProductApi.getSuppliersAndCategories, setParamsAndFetchData)

  const updateProductSupplierState = (supplierProductId, state) => {
    const _supplierProducts = data.map((sup_prod) => {
      if (sup_prod.id === supplierProductId) {
        return { ...sup_prod, state: state }
      } else {
        return {...sup_prod}
      }
    })
    setData(_supplierProducts)
  }

  const supplierProductsTableContent = () => {
    const supplierProductsBody = data?.map((supplierProduct, index) => (
      <SupplierProduct
        key={supplierProduct.id}
        tabValue={tabValue}
        updateProductSupplierState={updateProductSupplierState}
        supplierProduct={supplierProduct}
        index={getSerialNumber(page, perPage, index)}
      />
    ));

    let headings = [
      t('supplierProducts.tableHeaders.id'),
      t('supplierProducts.tableHeaders.name'),
      t('supplierProducts.tableHeaders.supplier'),
      t('supplierProducts.tableHeaders.category'),
      t('supplierProducts.tableHeaders.state'),
      ''
    ]
    tabValue === 'unmerged' && headings.splice(5, 0, t('supplierProducts.tableHeaders.suggested_products'));
    return {
      body: supplierProductsBody,
      heading: headings
    }
  }

  const tableContent = supplierProductsTableContent()

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setPage(1)
    let params = { q: searchTerm, sort_by: sortOptionSelected, page: 1, per_page: perPage, filter: newValue, ...selectedSupplier && { supplier_id: selectedSupplier.id }, ...selectedCategory && {category_id: selectedCategory.id}  }
    fetchData(params, true)
  }

  return (
    <AlertContext.Provider value={{ setAlert }}>
      <React.Fragment>
        <TitleBar title={t('supplierProducts.title')}>
        </TitleBar>
        <Container>
          {alert && (
            <Alert severity={alert.severity} onClose={() => setAlert(null)}>{alert.text}</Alert>
          )}
          <ListTableActions
            searchTerm={searchTerm}
            sortOptionSelected={sortOptionSelected}
            sortOptions={ProductSorting}
            searchText={t('supplierProducts.placeholders.search_by_name')}
            getData={handleSearchingAndSortingChange}
            children={
            <ProductFilters
              suppliers={suppliers}
              categories={categories}
              handleSupplierAndCategoryChange={handleSupplierAndCategoryChange}/>
          }
          />
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label={t('supplierProducts.tabs.unmerged')} value="unmerged" />
                  <Tab label={t('supplierProducts.tabs.merged')} value="merged" />
                  <Tab label={t('supplierProducts.tabs.muted')} value="muted" />
                  <Tab label={t('supplierProducts.tabs.all')} value="all" />
                </TabList>
              </Box>
            </TabContext>
          </Box>
          {
            loading ? <LoaderList/> :
              <Paper square className={classes.root}>
                <ListTable
                  tableHeadings={tableContent.heading}
                  tableContent={tableContent.body}
                  count={count}
                  pageNo={page}
                  rowsPerPage={perPage}
                  onPageChange={(event, pageNo) => handleSearchingAndSortingChange('page', pageNo + 1)}
                  handleChangeRowsPerPage={(event) => handleSearchingAndSortingChange('pageSize', event.target.value)}
                  rowsControl={true}/>
              </Paper>
          }
        </Container>
      </React.Fragment>
    </AlertContext.Provider>
  )
}

export default List;
