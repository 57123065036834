import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../HttpClient'
import Form from './Form'

let initialState = { name: '', type: 'single', description: '', cost: '', currency: {}, marking_method: {}}

const New = ({ history }) => {
  const [costTypes, setCostTypes] = useState([])
  
  useEffect(() => {
    let didCancel = false
    HttpClient.get('/starting_costs/form_options')
      .then(types => {        
        if(!didCancel) {
          setCostTypes(types)
        }
      })
    return () => { didCancel = true }
  },[])
  
  return(
    costTypes.length > 0 ?
      <Form startingCost={initialState} costTypes={costTypes} history={history}/>
      :
      null
  )
}

New.propTypes = {
  history: PropTypes.object
}

export default New
