import React from 'react'
import PropTypes from 'prop-types'

const CustomPrintingPricing = props => {
  return (
    null
  )
}
CustomPrintingPricing.propTypes = {
  product: PropTypes.object.isRequired
}
export default CustomPrintingPricing