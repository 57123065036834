import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Price from "@components/products/printing/Price";

const PriceList = (props) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <TextField
              fullWidth
              disabled={props.supplierProductsView || true}
              label="Namn"
              value={props.price_list.name}
              margin="normal"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <TextField
              fullWidth
              disabled={props.supplierProductsView || true}
              label="Färger"
              value={props.price_list.no_of_colors}
              margin="normal"
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {props.price_list.marking_prices.map((price) => (
          <Price
            key={price.id || price.kid}
            price={price}
            supplierProductsView={props.supplierProductsView}
          />
        ))}
      </Grid>
    </React.Fragment>
  );
};
PriceList.propTypes = {
  price_list: PropTypes.object.isRequired,
};

export default PriceList;
