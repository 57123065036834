import HttpClient from '@httpClient'
import { setUrlForGetRequest } from '@api/SharedFunctions'

const getTags = params => {
  let url = setUrlForGetRequest('/tags', params)
  return HttpClient.get(url)
}

const createTag = data => {
  return HttpClient.post('/tags', data)
}

const deleteTag = id => {
  return HttpClient.delete(`/tags/${id}`)
}

const convertTagToCategory = tagId => {
  return HttpClient.get(`/tags/${tagId}/convert_to_category`)
}

const tagProducts = (id, paginationParams) => {
  let url = setUrlForGetRequest(`/tags/${id}/products`, paginationParams)
  return HttpClient.get(url)
}

const allProducts = (id, params) => {
  let url = setUrlForGetRequest(`/tags/${id}/all_products`, params)
  return HttpClient.get(url)
}

const addTagProducts = (id, productIds) => {
  return HttpClient.put(`/tags/${id}/add_products`, {product_ids: productIds})
}

const removeTagProducts = (id, productIds) => {
  return HttpClient.put(`/tags/${id}/remove_products`, {product_ids: productIds})
}

const handleSearchedProducts = (data) => {
  return HttpClient.post(`/tags/handle_searched_products`, data)
}

const TagApi = {
  convertTagToCategory,
  addTagProducts,
  deleteTag,
  getTags,
  tagProducts,
  allProducts,
  removeTagProducts,
  createTag,
  handleSearchedProducts
}

export { TagApi }
