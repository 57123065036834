import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import ArrowBack from '@mui/icons-material/ArrowBack'

import TitleBar from '../topbar/TitleBar'
import Form from './Form'
import LoaderShowDouble from '../common/Loaders/LoaderShowDouble'
import useAlert from '@customHooks/useAlert'
import { AttributeApi } from '@api/AttributeApi'

const FormContainer = props => {
  const [attribute, setAttribute] = useState(props.attribute)
  const [alert, setAlert] = useAlert(props.created)

  useEffect(() => {
    setAttribute(props.attribute)
  },[props.attribute])

  const saveAttribute = attribute => {
    // filter out attributes values which don't have any data in 'value' attribute
    attribute.p_attribute_values = attribute.p_attribute_values.filter(v => v.value.length)
  
    if(attribute.id) {
      AttributeApi.updateAttribute(attribute.id, { p_attribute: attribute })
        .then(() => {
          setAlert({ severity: 'success', text: 'Upated successfully!' })
        })
        .catch(error => {
          setAlert({ severity: 'error', text: error.message })
        })
    } else {
      AttributeApi.createAttribute({ p_attribute: attribute })
        .then((res) => {
          props.history.push({
            pathname: `/attributes/${res.id}/edit`,
            state: { created: true }
          })
        })
        .catch(error => setAlert({ severity: 'error', text: error.message }))
    }
  }

  return(
    <React.Fragment>
      <TitleBar title={`${attribute.id ? 'Radigera' : 'Ny'} attribut`}>
        <IconButton
          component={Link}
          to="/attributes"
          aria-label="edit"
          color="secondary">
          <ArrowBack />
        </IconButton>
      </TitleBar>
      <Container>
        {props.loading ? <LoaderShowDouble /> : (
          <Form alert={alert} clearAlert={() => setAlert(null)} attribute={attribute} onSave={saveAttribute} parent={true}/>
        )}
      </Container>
    </React.Fragment>
  )
}

FormContainer.propTypes = {
  history: PropTypes.object,
  attribute: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  created: PropTypes.bool
}

export default FormContainer
