import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Paper, Fab, Typography, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import Attribute from "./attributes/Attribute";
import AttributeForm from "./attributes/AttributeForm";
import Packaging from "./packaging";

import Loader from "@common/Loaders/LoaderList";
import PrintOptions from "@components/products/printing/PrintOptions";
import ProductResourceContext from "@contexts/ProductResource";
import { ProductApi } from "@src/apis";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  formContainer: {
    margin: theme.spacing(2, 0, 6),
  },
}));

const CategoryAttribute = (props) => {
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const productResource = useContext(ProductResourceContext);
  const isSupplierProduct = productResource === "supplier_products";

  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    ProductApi.getCategoryAttributes(props.match.params.id, productResource)
      .then((data) => {
        const _productAttributes = data.product_attributes.map((a) => a.id);
        setAttributes(
          data.category_attributes.map((a) => {
            a.checked = _productAttributes.includes(a.id);
            return a;
          }),
        );
        setLoading(false);
      })
      .catch((error) => setLoading(false))
      .catch((error) => {
        setLoading(false);
        console.error("Error getting product details: ", error);
      });
  }, [props.match.params.id]);

  const handleCreateAttribute = (attribute) => {
    setAttributes([...attributes, attribute]);
    setCreating(false);
  };

  const handleAttributeDelete = (id) => {
    setAttributes(attributes.filter((a) => a.id !== id));
  };

  return (
    <>
      { loading ? (
        <Loader />
      ) : (
        <Grid container>
          <Paper sx={{ padding: '20px', marginTop: '20px', width: '100%'}} elevation={3}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h5" gutterBottom>
                {t("attributes.name")}
              </Typography>
            </div>

            <Grid container item spacing={3}>
              {attributes.map((attribute) => (
                <Grid item xs={6} key={`abox-${attribute.id}`}>
                  <Paper className={classes.root} elevation={3}>
                    <Attribute
                      key={attribute.id}
                      attribute={attribute}
                      productId={props.match.params.id}
                      onDelete={handleAttributeDelete}
                    />
                    {attribute.child_attributes.map((cattr) => (
                      <Attribute
                        key={cattr.id}
                        attribute={cattr}
                        productId={props.match.params.id}
                        onDelete={handleAttributeDelete}
                        child={true}
                      />
                    ))}
                  </Paper>
                </Grid>
              ))}
            </Grid>

            {
              !creating &&
              <Tooltip title={t("attributes.actions.add")}>
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  disabled={isSupplierProduct}
                  onClick={() => setCreating(true)}
                  sx={{ mt: "20px" }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            }

            <Grid container spacing={2} className={classes.formContainer}>
              <Grid item xs={12}>
                { creating &&
                  <Paper sx={{ padding: '20px', marginTop: '20px'}} elevation={1}>
                    <Grid container>
                      <Grid item xs={12} align="right">
                        <Fab
                          align="right"
                          size="small"
                          disabled={isSupplierProduct}
                          color="secondary"
                          aria-label="remove"
                          onClick={() => window.confirm("Är du säker?") && setCreating(false)}>
                          <CloseIcon />
                        </Fab>
                      </Grid>
                      <Grid item xs={12}>
                        <AttributeForm productId={props.match.params.id} onCreate={handleCreateAttribute}/>
                      </Grid>
                    </Grid>
                  </Paper>
                }
              </Grid>
            </Grid>
          </Paper>

          <Packaging productId={props.match.params.id} />

          <PrintOptions
            productId={props.match.params.id}
            heading={
              <Typography variant="h5" gutterBottom>
                {t("printOptions.name")}
              </Typography>
            }
          />
        </Grid>
      )}
    </>
  );
};

CategoryAttribute.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default CategoryAttribute;
