import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { TableCell, TableRow, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ImageThumbnail from '@shared/ImageThumbnail'

const Campaign = ({ campaign, deleteCampaign, index }) => {
  return (
    <TableRow key={campaign.id}>
      <TableCell><Link to={`campaigns/${campaign.id}/edit`}>{index}</Link></TableCell>
      <TableCell><Link to={`campaigns/${campaign.id}/edit`}>{campaign.name}</Link></TableCell>
      <TableCell>{campaign.url}</TableCell>
      <TableCell>{campaign.layout}</TableCell>
      <TableCell>
        <ImageThumbnail imageUrl={campaign.image_url} height={50} width={50}/>
      </TableCell>
      <TableCell>{campaign.visible.toString()}</TableCell>
      <TableCell>{campaign.fixed.toString()}</TableCell>
      <TableCell align="right">
        <IconButton component={Link} to={`campaigns/${campaign.id}/edit`} aria-label="edit">
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => deleteCampaign(campaign.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

Campaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  deleteCampaign: PropTypes.func.isRequired
}

export default Campaign
