import HttpClient from "@httpClient";
import { setUrlForGetRequest } from "@api/SharedFunctions";
import { singularize } from "@src/utils/sharedHelper";

const PRODUCT_API = "/products";

const getProductSuppliers = (id) => {
  return HttpClient.get(`${PRODUCT_API}/${id}/suppliers?page=-1`);
};

const getProducts = (params) => {
  return HttpClient.get(setUrlForGetRequest(PRODUCT_API, params));
};

const getProduct = (id) => {
  return HttpClient.get(`${PRODUCT_API}/${id}`);
};

const searchProducts = (params) => {
  return HttpClient.get(setUrlForGetRequest(`${PRODUCT_API}/search`, params));
};

const getProductQuantities = (id) => {
  return HttpClient.get(`${PRODUCT_API}/${id}/quantities`);
};

const getProductPriceLists = (id) => {
  return HttpClient.get(`${PRODUCT_API}/${id}/price_lists`);
};

const getProductPrintingPrices = (id) => {
  return HttpClient.get(`${PRODUCT_API}/${id}/printing_prices`);
};

const removeProduct = (id) => {
  return HttpClient.delete(`${PRODUCT_API}/${id}`);
};

const createProduct = (product, showLoader = false) => {
  return HttpClient.post(`${PRODUCT_API}/`, { product }, {}, showLoader);
};

const updateProduct = (product, showLoader = false) => {
  return HttpClient.put(
    `${PRODUCT_API}/${product.id}`,
    { product },
    {},
    showLoader,
  );
};

const updateProductAttributes = (id, data) => {
  return HttpClient.put(`${PRODUCT_API}/${id}`, data);
};

const mergeArticles = (id, params) => {
  return HttpClient.post(`${PRODUCT_API}/${id}/merge_articles`, params);
};

const getConflictingArticles = (id, params) => {
  return HttpClient.post(`${PRODUCT_API}/${id}/conflicting_articles`, params);
};

const addCategoryAttributeValue = (id, params, resource = "products") => {
  return HttpClient.post(
    `/${resource}/${id}/add_category_attribute_values?product_type=${singularize(
      resource,
    )}`,
    params,
    {},
    true,
  );
};

const addCategoryAttribute = (id, params, resource = "products") => {
  return HttpClient.post(
    `/${resource}/${id}/add_category_attribute`,
    params,
    {},
    true,
  );
};

const getCategoryAttributes = (id, resource = "products") => {
  return HttpClient.get(
    `/${resource}/${id}/category_attributes?product_type=${singularize(
      resource,
    )}`,
  );
};

const getColorGroups = (id, resource = "products") => {
  return HttpClient.get(
    `/${resource}/${id}/color_groups?product_type=${singularize(resource)}`,
  );
};

const getProductPackaging = (id, resource = "products") => {
  return HttpClient.get(
    `/${resource}/${id}/product_packagings?product_type=${singularize(
      resource,
    )}`,
  );
};

const saveProductPackaging = (
  id,
  params,
  resource = "products",
  showLoader = true,
) => {
  return HttpClient.post(
    `/${resource}/${id}/product_packagings`,
    params,
    {},
    showLoader,
  );
};

const getTemplates = (id, resource = "products") => {
  return HttpClient.get(
    `/${resource}/${id}/product_templates?product_type=${singularize(
      resource,
    )}`,
  );
};

const addTemplate = (id, params, resource = "products", showLoader = true) => {
  return HttpClient.post(
    `/${resource}/${id}/product_templates`,
    params,
    {},
    showLoader,
  );
};

const deleteTemplate = (
  id,
  templateId,
  resource = "products",
  showLoader = true,
) => {
  return HttpClient.delete(
    `/${resource}/${id}/product_templates/${templateId}?product_type=${singularize(
      resource,
    )}`,
    {},
    showLoader,
  );
};

const getColors = (id, resource = "products") => {
  return HttpClient.get(`/${resource}/${id}/colors?per_page=200`);
};

const getRelatedProducts = (id) => {
  return HttpClient.get(`/products/${id}/related_products`);
};

const getAttachments = (id) => {
  return HttpClient.get(`/products/${id}/product_attachments`);
};

const editAttachment = (id, productId, params, showLoader = true) => {
  return HttpClient.put(
    `${PRODUCT_API}/${productId}/product_attachments/${id}`,
    params,
    {},
    showLoader,
  );
};

const newAttachment = (productId, params, showLoader = true) => {
  return HttpClient.post(
    `${PRODUCT_API}/${productId}/product_attachments`,
    params,
    {},
    showLoader,
  );
};

const deleteAttachment = (productId, id, showLoader = true) => {
  return HttpClient.delete(
    `${PRODUCT_API}/${productId}/product_attachments/${id}`,
    {},
    showLoader,
  );
};

const downloadAttachment = (attachment) => {
  return HttpClient.getDownload(
    `${PRODUCT_API}/${attachment.product.id}/product_attachments/${attachment.id}/download`,
    attachment.original_file_name,
    {},
    true,
  );
};

const markSupplierAsPrimary = (productId, priceListId, priceListRowId) => {
  return HttpClient.put(
    `/products/${productId}/price_lists/${priceListId}/mark_primary_row`, 
    { price_list_row_id: priceListRowId }
  );
};

const associateQuestions = (id, question_ids, showLoader = true) => {
  return HttpClient.post(
    `/products/${id}/associate_questions`,
    {question_ids},
    {},
    showLoader,
  );
}

const ProductApi = {
  getProductSuppliers,
  getProducts,
  getProduct,
  getProductQuantities,
  getProductPriceLists,
  getProductPrintingPrices,
  removeProduct,
  createProduct,
  updateProduct,
  searchProducts,
  mergeArticles,
  getConflictingArticles,
  addCategoryAttributeValue,
  addCategoryAttribute,
  getCategoryAttributes,
  getColorGroups,
  getProductPackaging,
  saveProductPackaging,
  getTemplates,
  addTemplate,
  deleteTemplate,
  getColors,
  updateProductAttributes,
  getRelatedProducts,
  getAttachments,
  editAttachment,
  newAttachment,
  deleteAttachment,
  downloadAttachment,
  markSupplierAsPrimary,
  associateQuestions
};

export { ProductApi };
