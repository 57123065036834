import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import {Container, Grid} from '@mui/material'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material'
import { makeStyles } from '@mui/styles'

import TitleBar from '@components/topbar/TitleBar'
import HttpClient from '@httpClient'
import Loader from '@common/Loaders/LoaderList'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import ListTable from '@common/ListingTable/ListTable'

const List = props => {
  const currenciesPerPage = getUserPreference( 'page_size', 'currency')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( currenciesPerPage !== '' ? currenciesPerPage : 20)
  const [totalCount, setTotalCount] = useState(0)
  const [currencies, setCurrencies] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let didCancel = false
    fetchCurrencies(page, perPage)
    return () => { didCancel = true }
  }, [])

  const fetchCurrencies = async (_page, _perPage) => {
    setLoading(true)
    try {
      const response = await HttpClient.get(`/currencies?page=${_page}&per_page=${_perPage}`)
      if(response) {
        setCurrencies(response.data)
        setTotalCount(response.total_count)
      }
    }
    catch(error) {
      console.log("error", error)
    }
    finally {
      setLoading(false)
    }
  }

  const handlePageAndPerPageChange = (type, value) => {
    if(type === 'page'){
      setPage(value)
      fetchCurrencies(value, perPage)
    } else if (type === 'pageSize'){
      setPerPage(value)
      setPage(1)
      setUserPreference('page_size', 'currency', value)
      fetchCurrencies(1, value)
    }
  }

  const removeCurrency = (id) => {
    if(window.confirm('Är du säker?')) {
      HttpClient.delete(`/currencies/${id}`).then(() => {
        setCurrencies(currencies.filter(a => a.id !== id))
      })
    }
  }
  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  const currenciesTableContent = () => {
    const currenciesBody = currencies?.map((currency) => (
      <TableRow key={currency.id}>
        <TableCell><Link to={`currencies/${currency.id}/edit`}>{currency.name}</Link></TableCell>
        <TableCell>{currency.unit}</TableCell>
        <TableCell>{currency.symbol}</TableCell>
        <TableCell align="right">
          <IconButton
            component={Link}
            to={`currencies/${currency.id}/edit`}
            aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => removeCurrency(currency.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
    return { body: currenciesBody, heading: ['Namn', 'Unit', 'Symbol', ''] }
  }

  const tableContent = currenciesTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Valutor">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/currencies/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        {loading ? <Loader /> : (
          <Grid container>
            <Paper square className={classes.root}>
              <ListTable
                tableHeadings={tableContent.heading}
                tableContent={tableContent.body}
                count={totalCount}
                pageNo={page}
                rowsPerPage={perPage}
                onPageChange={(event, pageNo) => handlePageAndPerPageChange('page', pageNo + 1)}
                handleChangeRowsPerPage={(event) => handlePageAndPerPageChange('pageSize', event.target.value)}
                rowsControl={true} />
            </Paper>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}
List.propTypes = {
  history: PropTypes.object
}

export default List