import HttpClient from "../components/HttpClient";
import { setUrlForGetRequest } from "@api/SharedFunctions";

const ATTRIBUTE_API = "/attributes";

const getAttributes = (params) => {
  return HttpClient.get(setUrlForGetRequest(ATTRIBUTE_API, params));
};

const getAttribute = (id) => {
  return HttpClient.get(`${ATTRIBUTE_API}/${id}`);
};

const removeAttribute = (id) => {
  return HttpClient.delete(`${ATTRIBUTE_API}/${id}`);
};

const createAttribute = (data) => {
  return HttpClient.post(`${ATTRIBUTE_API}`, data);
};

const updateAttribute = (id, data) => {
  return HttpClient.put(`${ATTRIBUTE_API}/${id}`, data);
};

const getFormOptions = (showLoader = false) => {
  return HttpClient.get(`${ATTRIBUTE_API}/form_options`, {}, showLoader);
};

//Encapsulating in a JSON object
const AttributeApi = {
  getAttribute,
  getAttributes,
  removeAttribute,
  createAttribute,
  updateAttribute,
  getFormOptions,
};

export { AttributeApi };
