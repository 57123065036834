import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

import List from '@components/campaigns/List'
import Edit from '@components/campaigns/Edit'

const Campaigns = props => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${props.match.path}/`} component={List}></Route>
        <Route exact path={`${props.match.path}/new`} component={Edit}></Route>
        <Route exact path={`${props.match.path}/:id/edit`} component={Edit}></Route>
      </Switch>
    </React.Fragment>
  )
}

Campaigns.propTypes = { match: PropTypes.object.isRequired }

export default Campaigns
