import React from 'react'
import { Grid, Skeleton } from '@mui/material'

const LoaderShow = () => (
  <React.Fragment>
    <Grid container direction="row" spacing={5}>
      <Grid item xs={6}>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
        <Skeleton variant="text" height={60}/>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={50}/>
        <Skeleton variant="text" height={50}/>
        <Skeleton variant="text" height={50}/>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default LoaderShow
