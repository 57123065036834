import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Box, Container, Grid, Tab } from '@mui/material'
import {Paper, Fab, IconButton} from '@mui/material'
import {TableCell, TableRow} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { makeStyles } from '@mui/styles'
import { TabList, TabContext } from '@mui/lab';

import HttpClient from '@httpClient'
import TitleBar from '@components/topbar/TitleBar'
import Loader from '@common/Loaders/LoaderList'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import ListTable from '@common/ListingTable/ListTable'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { useDebounce } from '@customHooks/useDebouncing'
import { useTranslation } from 'react-i18next'
import { MarkingTemplateApi } from '@api/MarkingTemplateApi'
import { NameSorting } from '../../config/SortingLabels'

const List = props => {
  const markingTemplatesPerPage = getUserPreference( 'page_size', 'markingTemplate')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( markingTemplatesPerPage !== '' ? markingTemplatesPerPage : 20)
  const [totalCount, setTotalCount] = useState(0)
  const [markingTemplates, setMarkingTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOptionSelected, setSortOptionSelected] = useState('name_ascending')
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [systemTemplates, setSystemTemplates] = useState(false)

  useEffect(() => {
    let didCancel = false
    if (searchTerm) {
      fetchMarkingTemplates({ page: 1, per_page: perPage, query: searchTerm, system_generated: systemTemplates });
    }
    else {
      fetchMarkingTemplates({ page: 1, per_page: perPage, system_generated: systemTemplates });
    }
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const fetchMarkingTemplates = async (params) => {
    setLoading(true)
    if (!params.sort_by) {
      params['sort_by'] = sortOptionSelected
    }
    try {
      const response = await MarkingTemplateApi.getMarkingTemplates(params)
      if(response) {
        setMarkingTemplates(response.data)
        setTotalCount(response.total_count)
      }
    }
    catch(error) {
      console.log("error", error)
    }
    finally {
      setLoading(false)
    }
  }

  const handleSearchParamsChange = (type, value) => {
    switch (type) {
      case "page":
        setPage(value);
        fetchMarkingTemplates({ page: value, per_page: perPage, system_generated: systemTemplates, query: searchTerm });
        break;

      case "pageSize":
        setPerPage(value);
        setPage(1);
        setUserPreference("page_size", "markingMethod", value);
        fetchMarkingTemplates({ page: 1, per_page: value, system_generated: systemTemplates, query: searchTerm });
        break;

      case "sort":
        setSortOptionSelected(value)
        fetchMarkingTemplates({ page: 1, per_page: perPage, sort_by: value, system_generated: systemTemplates, query: searchTerm });
        break;

      case "search":
        setSearchTerm(value)
        break;
    }
  };

  const handleTabChange = (event, newValue) => {
    setSystemTemplates(newValue)
    setPage(1)
    fetchMarkingTemplates({ page: 1, per_page: perPage, system_generated: newValue, query: searchTerm });
  }

  const removeMarkingTemplate = (id) => {
    if(window.confirm('Är du säker?')) {
      MarkingTemplateApi.removeMarkingTemplate(id).then(() => {
        setMarkingTemplates(markingTemplates.filter(a => a.id !== id))
      })
    }
  }

  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  const markingTemplatesTableContent = () => {
    const markingTemplatesBody = markingTemplates?.map((markingTemplate) => (
      <TableRow key={markingTemplate.id}>
        <TableCell><Link to={`marking_templates/${markingTemplate.id}/edit`}>{markingTemplate.name}</Link></TableCell>
        <TableCell>{markingTemplate.marking_method && markingTemplate.marking_method.name}</TableCell>
        <TableCell>{markingTemplate.supplier && markingTemplate.supplier.name}</TableCell>
        <TableCell align="right">
          <Link to={`marking_templates/${markingTemplate.id}/edit`}>
            <IconButton><EditIcon/></IconButton>
          </Link>
          <IconButton onClick={() => removeMarkingTemplate(markingTemplate.id)}><DeleteIcon>Delete</DeleteIcon></IconButton>
        </TableCell>
      </TableRow>
    ))
    return { body: markingTemplatesBody, heading: ['Namn', 'Tryckmetod', 'Leverantör', ''] }
  }

  const tableContent = markingTemplatesTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Märkningar">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/marking_templates/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={NameSorting}
          searchText={t('marking_templates.placeholders.search_label')}
          getData={handleSearchParamsChange} />
        <Box sx={{ width: '100%', typography: 'body1', marginBottom: '20px' }}>
          <TabContext value={systemTemplates}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label={t('marking_templates.tabs.manual')} value={false} />
                <Tab label={t('marking_templates.tabs.system_generated')} value={true} />
              </TabList>
            </Box>
          </TabContext>
        </Box>
        {loading ? <Loader /> : (
          <Grid container>
            <Paper square className={classes.root}>
              <ListTable
                tableHeadings={tableContent.heading}
                tableContent={tableContent.body}
                count={totalCount}
                pageNo={page}
                rowsPerPage={perPage}
                onPageChange={(event, pageNo) => handleSearchParamsChange('page', pageNo + 1)}
                handleChangeRowsPerPage={(event) => handleSearchParamsChange('pageSize', event.target.value)}
                rowsControl={true} />
            </Paper>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}

List.propTypes = { history: PropTypes.object.isRequired }

export default List
