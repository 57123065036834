import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Container,
  Grid,
  InputLabel,
  Typography,
  Card,
  CardMedia,
  TextField,
  FormControl,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Edit from "@mui/icons-material/Edit";
import SyncedIcon from "@mui/icons-material/Sync";
import PartialSyncedIcon from "@mui/icons-material/SyncProblem";
import NotSyncedIcon from "@mui/icons-material/SyncDisabled";

import Mdivider from "@common/Mdivider";
import TitleBar from "@components/topbar/TitleBar";
import CustomPrintingPricing from "../pricing/CustomPrintingPricing";
import Skeleton from "@mui/material/Skeleton";
import SupplierList from "./detail_components/SupplierList";
import PriceLists from "./detail_components/PriceLists";
import { SupplierProductApi, ProductApi } from "@api";

const ProductLoading = () => (
  <React.Fragment>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rect" width="30%" height={180} />
        <br />
        <Skeleton variant="rect" height={140} />
      </Grid>
    </Grid>
    <Mdivider />
    <Skeleton variant="rect" height={300} />
    <Mdivider />
    <Typography variant="h5" gutterBottom>
      Markningpriser
    </Typography>
    <Skeleton variant="rect" height={300} />
  </React.Fragment>
);

const useStyles = makeStyles((theme) => ({
  attributeContainer: {
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
    backgroundSize: "contain",
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));

const Show = (props) => {
  const classes = useStyles()

  const { t, i18n } = useTranslation();

  const apiType = props.match.path.includes("supplier-products") ? SupplierProductApi : ProductApi;
  const moduleName = props.match.path.includes("supplier-products") ? "supplier-products" : "products";

  const initialState = {
    id: props.match.params.id,
    name: "",
    quantities: [
      { kid: 1, value: "", price: "" },
      { kid: 2, value: "", price: "" },
      { kid: 3, value: "", price: "" },
      { kid: 4, value: "", price: "" },
    ],
    price_lists: [],
    printing_prices: [],
    suppliers: [],
    printing_suppliers: [],
  };

  const [product, setProduct] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    Promise.all([
      apiType.getProduct(product.id),
      apiType.getProductQuantities(product.id),
      apiType.getProductPriceLists(product.id),
      apiType.getProductPrintingPrices(product.id),
    ]).then(([productData, quantities, priceLists, printingPrices]) => {
      if (!didCancel && productData) {
        let _product = { ...product, ...productData };
        if (quantities) {
          _product.quantities = quantities;
        }
        if (priceLists) {
          _product.price_lists = priceLists;
        }
        if (printingPrices) {
          _product.printing_prices = printingPrices;
        }

        setProduct(_product);
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, [product.id]);

  const renderSyncIcon = (syncStatus) => {
    let backgroundColor = "#FFFF";
    let icon = <NotSyncedIcon style={{ color: "#757575" }} />;
    let toolTipTitle = "Not Synced";
    if (syncStatus == "synced") {
      icon = <SyncedIcon style={{ color: "#4EBA4B" }} />;
      toolTipTitle = "Synced";
    } else if (syncStatus == "partially_synced") {
      icon = <PartialSyncedIcon style={{ color: "#FEBF3B" }} />;
      toolTipTitle = "Partially Synced";
    }

    return (
      <IconButton style={{ backgroundColor: backgroundColor }}>
        <Tooltip title={toolTipTitle} placement="top">
          {icon}
        </Tooltip>
      </IconButton>
    );
  };

  return (
    <React.Fragment>
      <TitleBar title={`${apiType === SupplierProductApi ? "Supplier" : ""} Produktor`}>
        <Button
          variant="outlined"
          size="small"
          endIcon={<Edit />}
          onClick={() => {
            props.history.push(`/${moduleName}/${product.id}/edit`);
          }}
        >
          {t("actions.edit")}
        </Button>
      </TitleBar>
      <Container>
        {loading ? (
          <ProductLoading />
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className={classes.attributeContainer}>
                  <InputLabel className={classes.label}>
                    {t("products.name")}
                  </InputLabel>
                  <Typography component="p">
                    <Link to={`/${moduleName}/${product.id}/edit`}>
                      {product.name}
                    </Link>
                    {renderSyncIcon(product.sync_status)}
                  </Typography>
                </div>
                <div className={classes.attributeContainer}>
                  <InputLabel className={classes.label}>
                    {t("products.suppliers")}
                  </InputLabel>
                  <SupplierList suppliers={product.suppliers} productId={product.id} listType="suppliers" />
                </div>
                <div className={classes.attributeContainer}>
                  <InputLabel className={classes.label}>
                    {t("products.printingSuppliers")}
                  </InputLabel>
                  <SupplierList suppliers={product.printing_suppliers} productId={product.id} listType="printingSuppliers" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6} md={4}>
                    <Card>
                      <CardMedia
                        className={classes.media}
                        sx={{backgroundSize: 'contain'}}
                        image={
                          product.image_url
                            ? product.image_url
                            : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        }
                        title={`${product.id} bild`}
                      />
                    </Card>
                  </Grid>
                </Grid>
                <FormControl fullWidth margin="normal">
                  <TextField
                    multiline
                    minRows="5"
                    label="Anteckningar (ej publika)"
                    value={product.notes || ""}
                    onChange={(e) =>
                      setProduct({ ...product, notes: e.currentTarget.value })
                    }
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Mdivider />
            <PriceLists
              quantities={product.quantities}
              priceLists={product.price_lists}
              printingPrices={product.printing_prices}
            />
            <CustomPrintingPricing product={product} />
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
};

Show.propTypes = {
  product: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Show;
