import HttpClient from "../components/HttpClient"
import { setUrlForGetRequest } from '@api/SharedFunctions'

const STANDARD_API = '/standards'

const getStandards = (params) => {
  return HttpClient.get(setUrlForGetRequest(STANDARD_API, params))
}

const getStandard = (id) => {
  return HttpClient.get(`${STANDARD_API}/${id}`)
}

const removeStandard = (id) => {
  return HttpClient.delete(`${STANDARD_API}/${id}`)
}

const createStandard = (data) => {
  return HttpClient.post(`${STANDARD_API}`, data)
}

const updateStandard = (id, data) => {
  return HttpClient.put(`${STANDARD_API}/${id}`, data)
}

//Encapsulating in a JSON object
const StandardApi = {
  getStandard,
  getStandards,
  removeStandard,
  createStandard,
  updateStandard,
}

export {StandardApi}
