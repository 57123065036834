import HttpClient from "@httpClient";
import { setUrlForGetRequest } from '@api/SharedFunctions'

const QUESTIONS_API = "/questions";

const getQuestions = (params) => {
  return HttpClient.get(setUrlForGetRequest(QUESTIONS_API, params));
};

const updateQuestion = (question, showLoader = true) => {
  return HttpClient.put(`${QUESTIONS_API}/${question.id}`, { question }, {}, showLoader);
};

const createQuestion = (question, showLoader = true) => {
  return HttpClient.post(`${QUESTIONS_API}`, question, {}, showLoader);
};

const deleteQuestion = (id) => {
  return HttpClient.delete(`${QUESTIONS_API}/${id}`);
};

const getQuestion = (id) => {
  return HttpClient.get(`${QUESTIONS_API}/${id}`);
};

const getProducts = (id) => {
  return HttpClient.get(`${QUESTIONS_API}/${id}/products`);
};

const QuestionApi = {
  getQuestions,
  updateQuestion,
  deleteQuestion,
  createQuestion,
  getQuestion,
  getProducts
};

export { QuestionApi };
