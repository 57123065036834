import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import ProductResourceContext from "@contexts/ProductResource";
import HttpClient from "../../HttpClient";
import Loader from "../../common/Loaders/LoaderList";
import { singularize } from "@src/utils/sharedHelper";
import { ProductApi } from "@src/apis";
import { Paper } from '@mui/material'

const Packaging = ({ productId }) => {
  const [packaging, setPackaging] = useState({
    gift_packaging: false,
    carton_length: "",
    carton_width: "",
    carton_height: "",
    quantity_per_carton: "",
    carton_net_weight: "",
    carton_gross_weight: "",
    custom_sleeve: false,
    gift_wrapping: false,
  });
  const [updating, setUpdating] = useState(false);
  const productResource = useContext(ProductResourceContext);
  const isSupplierProduct = productResource === "supplier_products";

  useEffect(() => {
    ProductApi.getProductPackaging(productId, productResource).then((res) => {
      if (res) setPackaging(res);
    });
  }, [productId]);

  const handleSave = () => {
    ProductApi.saveProductPackaging(
      productId,
      {
        product_type: singularize(productResource),
        product_packaging: packaging,
      },
      productResource,
    )
      .then((res) => {
        setPackaging({ ...packaging, ...res });
      })
      .catch((error) => {
        console.error("Error updating product packaging: ", error);
      });
  };

  return (
    <Paper sx={{ padding: '20px', marginTop: '20px'}} elevation={3}>
      <Typography variant="h5" gutterBottom>
        Packaging
      </Typography>
      <Grid container spacing={2}>
        {updating ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Length"
                  disabled={isSupplierProduct}
                  value={packaging.carton_length || ""}
                  onChange={(e) =>
                    setPackaging({
                      ...packaging,
                      carton_length: e.currentTarget.value,
                    })
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Width"
                  disabled={isSupplierProduct}
                  value={packaging.carton_width || ""}
                  onChange={(e) =>
                    setPackaging({
                      ...packaging,
                      carton_width: e.currentTarget.value,
                    })
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Height"
                  disabled={isSupplierProduct}
                  value={packaging.carton_height || ""}
                  onChange={(e) =>
                    setPackaging({
                      ...packaging,
                      carton_height: e.currentTarget.value,
                    })
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <TextField
                  disabled={isSupplierProduct}
                  label="Quantity per carton"
                  value={packaging.quantity_per_carton || ""}
                  onChange={(e) =>
                    setPackaging({
                      ...packaging,
                      quantity_per_carton: e.currentTarget.value,
                    })
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">st</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <TextField
                  disabled={isSupplierProduct}
                  label="Net weight"
                  value={packaging.carton_net_weight || ""}
                  onChange={(e) =>
                    setPackaging({
                      ...packaging,
                      carton_net_weight: e.currentTarget.value,
                    })
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <TextField
                  disabled={isSupplierProduct}
                  label="Gross weight"
                  value={packaging.carton_gross_weight || ""}
                  onChange={(e) =>
                    setPackaging({
                      ...packaging,
                      carton_gross_weight: e.currentTarget.value,
                    })
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isSupplierProduct}
                    checked={packaging.gift_packaging}
                    onChange={(e) =>
                      setPackaging({
                        ...packaging,
                        gift_packaging: e.target.checked,
                      })
                    }
                    name="giftPackaging"
                    color="primary"
                  />
                }
                label="Gift packaging"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isSupplierProduct}
                    checked={packaging.custom_sleeve}
                    onChange={(e) =>
                      setPackaging({
                        ...packaging,
                        custom_sleeve: e.target.checked,
                      })
                    }
                    name="customSleeve"
                    color="primary"
                  />
                }
                label="Custom sleeve"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isSupplierProduct}
                    checked={packaging.gift_wrapping}
                    onChange={(e) =>
                      setPackaging({
                        ...packaging,
                        gift_wrapping: e.target.checked,
                      })
                    }
                    name="giftWrapping"
                    color="primary"
                  />
                }
                label="Gift wrapping"
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isSupplierProduct}
              >
                Spara
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
Packaging.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default Packaging;
