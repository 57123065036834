import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";

import AutoComplete from "../../common/Autocomplete";
import HttpClient from "../../HttpClient";
import Standard from "./Standard";

const Sustainable = (props) => {
  const [attributeValue, setAttributeValue] = useState({
    standards: [],
    ...props.value,
  });

  useEffect(() => {
    let didCancel = false;
    HttpClient.get(`/attribute_values/${props.value.id}`).then((value) => {
      return !didCancel && setAttributeValue(value);
    });

    return () => {
      didCancel = true;
    };
  }, [props.value.id]);

  const addStandard = (standard) => {
    const value = {
      ...attributeValue,
      standards: [...attributeValue.standards, standard],
    };
    setAttributeValue(value);
    HttpClient.put(`/attribute_values/${value.id}`, {
      p_attribute_value: value,
    }).then((res) => console.log(res));
  };

  const onDelete = (id) => {
    if (window.confirm("Är du säker?")) {
      const value = {
        ...attributeValue,
        standards: [...attributeValue.standards.filter((s) => s.id !== id)],
      };
      setAttributeValue(value);
      HttpClient.put(`/attribute_values/${value.id}`, {
        p_attribute_value: value,
      }).then((res) => console.log(res));
    }
  };

  return (
    <Grid container>
      <Grid item xs={10}>
        {attributeValue.standards.map((s) => (
          <Standard
            key={s.id}
            valueId={attributeValue.id}
            standard={s}
            onDelete={onDelete}
          />
        ))}
        <FormControl fullWidth variant="outlined" margin="normal">
          <AutoComplete
            url="/standards"
            onChange={addStandard}
            exclude={attributeValue.standards}
            showLoader={true}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
Sustainable.propTypes = {
  value: PropTypes.object.isRequired,
};

export default Sustainable;
