import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import AddAPhoto from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

const FileInput = (props) => {
  const classes = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: "none",
    },
  }))();

  const [id] = useState(() => {
    return Math.random().toString(16).slice(-5);
  }); // Pass a func. to useState for lazy initialization

  const optionalProps = {};
  if (props.accept) optionalProps.accept = props.accept;

  return (
    <div>
      <input
        className={classes.input}
        id={`contained-button-file-${id}`}
        type="file"
        name={props.name || "image"}
        multiple={props.multiple}
        accept={props.accept}
        onChange={props.onChange}
        {...optionalProps}
      />
      <label htmlFor={`contained-button-file-${id}`}>
        {props.icon ? (
          <Fab
            color="primary"
            size="small"
            component="span"
            disabled={props.disabled}
          >
            <AddAPhoto />
          </Fab>
        ) : (
          <Button
            variant="contained"
            color="primary"
            component="span"
            disabled={props.disabled}
          >
            {props.label || "Välj fil"}
          </Button>
        )}
      </label>
    </div>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  icon: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default FileInput;
