const getUserPreference = (preferenceType, key) => {
  const currentUserId = JSON.parse(localStorage.getItem('user'))?.id
  let preferences = JSON.parse(localStorage.getItem('preferences'))
  if (preferences && preferences[currentUserId] && preferences[currentUserId][preferenceType] && preferences[currentUserId][preferenceType][key]){
    return preferences[currentUserId][preferenceType][key]
  } else {
    return ''
  }
}

const setUserPreference = (preferenceType, key, value) => {
  const currentUserId = JSON.parse(localStorage.getItem('user'))?.id
  let preferences = JSON.parse(localStorage.getItem('preferences'))
  !preferences && ( preferences = {} )
  let loggedInUserPreferences = preferences[currentUserId]
  if (loggedInUserPreferences && Object.entries(loggedInUserPreferences).length > 0){
    !loggedInUserPreferences[preferenceType] && (loggedInUserPreferences[preferenceType] = {})
    preferences = { ...preferences, [currentUserId]: { [preferenceType]: { ...loggedInUserPreferences[preferenceType], [key]: value}  }}
  } else {
    loggedInUserPreferences = { [currentUserId]: { [preferenceType]: { [key]: value} } }
    preferences = { ...preferences, ...loggedInUserPreferences }
  }
  localStorage.setItem('preferences', JSON.stringify(preferences))
}

export { setUserPreference, getUserPreference };
