import React, { useRef, useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import { useDebounce } from '@customHooks/useDebouncing'

const ServerSideCreatableSelect = ({ createApiFunc, apiFunction, onChange, placeholder = 'Select...', onCreateOption, resource, listAction = true, resourceId, extraCreationParams={}, extraListingParams={} }) => {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const isFirstRender = useRef(true)
  const debounceSearchTerm = useDebounce(inputValue, 500)

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const result = listAction ? await apiFunction({ q: inputValue, page: -1, ...extraListingParams }) : await apiFunction(resourceId, { q: inputValue, page: -1, ...extraListingParams })
      const formattedOptions = result.flatMap(a => [
        { label: a.questions, value: a.id, ...a },
        { label: a.answer, value: a.id, ...a }
      ])
      setOptions(formattedOptions)
      setIsLoading(false)
    } catch (error) {
      console.error('Failed to fetch options:', error)
    }
  }

  useEffect(() => {
    // Skip the first render
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    fetchData()
  }, [debounceSearchTerm])

  const handleCreate = async () => {
    setIsLoading(true)
    const createKey =  resource === 'question' ? 'question' : 'name'
    let postData = resource ? { [resource]: { [createKey]: inputValue, ...extraCreationParams } } : { name: inputValue, ...extraCreationParams }
    try {
      let response = await createApiFunc(postData)
      setOptions([...options, { label: response[createKey], value: response.id, ...response }])
      onCreateOption(response)
    } catch (error) {
      console.error('Failed to fetch options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CreatableSelect
      isClearable
      isLoading={isLoading}
      onChange={onChange}
      onInputChange={val => setInputValue(val)}
      options={options}
      value={inputValue}
      placeholder={placeholder}
      onCreateOption={handleCreate}
    />
  )
}

export default ServerSideCreatableSelect
