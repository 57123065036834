import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@mui/styles'
import { Container, Grid, Paper, AppBar, TableRow, TableCell } from '@mui/material'
import { Fab, IconButton, Tooltip, TextField, Menu, MenuItem } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SyncedIcon from '@mui/icons-material/Sync';
import PartialSyncedIcon from '@mui/icons-material/SyncProblem';
import NotSyncedIcon from '@mui/icons-material/SyncDisabled';
import MoreOptionIcon from '@mui/icons-material/MoreVert';

import { ProductSorting } from '@config/SortingLabels'
import { ProductApi } from '@api/ProductApi'
import HttpClient from '@httpClient'
import AlphabeticalFilter from '@common/AlphabeticalFilter'
import Loader from '@common/Loaders/LoaderList'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import ListTable from '@common/ListingTable/ListTable'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { useDebounce } from '@customHooks/useDebouncing'

const actionOptions = {
  synced: 'Mark Synced',
  unsynced: 'Mark Un-Synced',
  partially_synced: 'Mark Partial Synced',
};

const List = props => {
  const productsPerPage = getUserPreference( 'page_size', 'product')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( productsPerPage !== '' ? productsPerPage : 20)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOptionSelected, setSortOptionSelected] = useState('latest')
  const [filterOptionSelected, setFilterOptionSelected] = useState('all')
  const [actionAnchorEl, setActionAnchorEl] = useState(null)
  const [menuProduct, setMenuProduct] = useState(null)
  const query = new URLSearchParams(props.location.search)
  const filter = query.get('filter') || 'A'

  const { t } = useTranslation()

  const openActionMenu = Boolean(actionAnchorEl);

  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const filterOptions = { all: t('filter.product.all'), synced: t('filter.product.synced'), partially_synced: t('filter.product.partially_synced'), unsynced: t('filter.product.unsynced') }
  
  const fetchProducts = async (params) => {
    setLoading(true);
    if(!params.sort_by) {
      params['sort_by'] = sortOptionSelected;
    }
    try {
      const response = await ProductApi.getProducts(params);
      if (response) {
        setProducts(response.data);
        setTotalCount(response.total_count);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    let didCancel = false
    if (searchTerm) {
      fetchProducts({ page: 1, per_page: perPage, sort_by: sortOptionSelected, query: searchTerm });
    }
    else {
      fetchProducts({ filter, page: 1, per_page: perPage, sort_by: sortOptionSelected });
    }
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const removeProduct = id => {   
    if(window.confirm(t('alertTexts.confirmation'))) {
      HttpClient.delete(`/products/${id}`).then(() => {
        setProducts(products.filter(a => a.id !== id))
      })
    }
  };

  const handleActionMenuClick = (event, product) => {
    setMenuProduct(product)
    setActionAnchorEl(event.currentTarget)
  };

  const handleActionMenuClose = () => {
    setActionAnchorEl(null)
  };

  const handleActionMenuSelect = async (option) => {
    setLoading(true)
    const response = await ProductApi.updateProduct({...menuProduct, sync_status: option, sync_type: 'manual'})
    if (response) {
      let _products = [...products]
      for(let product in _products){
        if (_products[product].id === menuProduct.id){
          _products[product].sync_status = option
          break
        }
      }
      setProducts(_products)
    } 
    setMenuProduct(null)
    setActionAnchorEl(null)
    setLoading(false)
  }

  const handleSearchParamsChange = (type, value) => {
    switch (type) {
      case "page":
        setPage(value);
        fetchProducts({ filter, page: value, per_page: perPage });
        break;

      case "pageSize":
        setPerPage(value);
        setPage(1);
        setUserPreference("page_size", "product", value);
        fetchProducts({ filter, page: 1, per_page: value});
        break;

      case "sort":
        setSortOptionSelected(value)
        fetchProducts({ filter, page: 1, per_page: perPage, sort_by: value });
        break;

      case "search":
        setSearchTerm(value)
        break;
      
      case "sync":
        fetchProducts({ filter: 'sync', sync_status: value, page: 1, per_page: perPage });
    }
  };

  const handleFilterChange = (event) => {
    setFilterOptionSelected(event.target.value)

    handleSearchParamsChange('sync', event.target.value)
  }

  const renderSyncIcon = (syncStatus) => {
    let backgroundColor = '#FFFF'
    let icon = <NotSyncedIcon style={{ color: '#757575' }} />;
    let toolTipTitle = 'Not Synced'
    if (syncStatus == 'synced') {
      icon = <SyncedIcon style={{ color: '#4EBA4B' }} />
      toolTipTitle = 'Synced'
    } else if (syncStatus == 'partially_synced') {
      icon = <PartialSyncedIcon style={{ color: '#FEBF3B' }} />
      toolTipTitle = 'Partially Synced'
    }

    return(
      <IconButton
        style={{ backgroundColor: backgroundColor }}
      >
        <Tooltip title={toolTipTitle} placement="top">
          {icon}
        </Tooltip>
      </IconButton>
    )
  }

  const classes = makeStyles((theme) => ({
    root: {
      marginBottom: theme.spacing(4),
    },
    tab: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    paper: {
      width: "100%",
      overflowX: "auto",
    },
    table: { width: "100%" },
  }))();

  const renderProductsFilter = () => {
    return(
      <TextField
        name="filter"
        label={t('filter.title')}
        value={filterOptionSelected}
        placeholder={t('filter.title')}
        variant='outlined'
        size='small'
        onChange={(event) => handleFilterChange(event)}
        select
        fullWidth>
        {Object.keys(filterOptions).map(option => (
          <MenuItem key={option} value={option}>{filterOptions[option]}</MenuItem>
        ))}
      </TextField>
    )
  }

  const productsTableContent = () => {
    const productsBody = products?.map((product) => (
      <TableRow key={product.id}>
        <TableCell>
          <Link to={`products/${product.id}`}>{product.name}</Link>{renderSyncIcon(product.sync_status)}
        </TableCell>
        <TableCell>
          {product.category ? (
            <Link to={`categories/${product.category.id}/edit`}>
              {product.category.name}
            </Link>
          ) : null}
        </TableCell>
        <TableCell align="right">
          <IconButton
            component={Link}
            to={`products/${product.id}/edit`}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => removeProduct(product.id)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
          {product.sync_type !== 'system' && <IconButton
            aria-label="more"
            id="action-menu"
            aria-haspopup="true"
            onClick={(e) => handleActionMenuClick(e, product)}
          >
            <MoreOptionIcon />
          </IconButton>}
        </TableCell>
      </TableRow>
    ));
    return { body: productsBody, heading: ["Namn", "Kategori", ""] };
  };

  const tableContent = productsTableContent()

  return (
    <>
      <AppBar position="static" color="default" className={classes.root}>
        <Container>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Fab
                component={Link}
                to={"/products/new"}
                color="primary"
                size="small"
                aria-label="add"
                sx={{margin: '10px'}}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Container>
        {(searchTerm) ? null : (
          <AlphabeticalFilter resource="products" currentFilter={filter} />
        )}
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={ProductSorting}
          searchText={t('products.placeholders.search_products')}
          getData={handleSearchParamsChange}
        >
          {renderProductsFilter()}
        </ListTableActions>
        {loading ? (
          <Loader />
        ) : (
          <Paper square className={classes.root} style={{ marginTop: "15px" }}>
            <Menu
              id="action-long-menu"
              MenuListProps={{
                'aria-labelledby': 'action-menu',
              }}
              anchorEl={actionAnchorEl}
              open={openActionMenu}
              onClose={handleActionMenuClose}
            >
              {
                Object.keys(actionOptions).map((option) => (
                  <MenuItem key={option} value={option} onClick={() => handleActionMenuSelect(option)}>
                    {actionOptions[option]}
                  </MenuItem>
                ))
              }
            </Menu>
            <ListTable
              tableHeadings={tableContent?.heading ?? []}
              tableContent={tableContent?.body?? []}
              count={totalCount}
              pageNo={page}
              rowsPerPage={perPage}
              onPageChange={(event, page_no) =>
                handleSearchParamsChange("page", page_no + 1)
              }
              handleChangeRowsPerPage={(event) =>
                handleSearchParamsChange("pageSize", event.target.value)
              }
              rowsControl={true}
            />
          </Paper>
        )}
      </Container>
    </>
  );
};

List.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default List;
