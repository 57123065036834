import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  Grid
} from '@mui/material'

import CustomTablePagination from '@common/CustomTablePagination';

const styles = {
  myCellHead: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '15px'
  },
  table: { width: '100%' }
}

const ListTable = ({ tableActions, tableHeadings, tableContent, count, pageNo, onPageChange, rowsPerPage, handleChangeRowsPerPage, rowsControl }) => {
  return (
    <Paper square sx={styles.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          {tableActions}
        </Grid>
        {(parseInt(count) > 0 ) ? (
          <Grid item>
            <CustomTablePagination
              count={count}
              pageNo={pageNo}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsControl={rowsControl} />
          </Grid>
        ): (<br />)}
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              { tableHeadings?.map((heading, index) => (
                <TableCell sx={{ ...styles.myCellHead, minWidth: (index === 1 && '50px')}} key={index}>{heading}</TableCell>)
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            { tableContent?.map((row) => (row)) }
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="flex-end">
        <Grid item>
          {parseInt(count) > 0 && (
            <CustomTablePagination
              count={count}
              pageNo={pageNo}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsControl={rowsControl} />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

ListTable.propTypes = {
  tableHeadings: PropTypes.array.isRequired,
  tableContent: PropTypes.array.isRequired,
  count: PropTypes.number,
  pageNo: PropTypes.number,
  onPageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsControl: PropTypes.bool
}

export default ListTable
