import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Container, Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {Table, TableHead, TableBody, TableRow, TableCell, Typography} from '@mui/material'
import { makeStyles } from '@mui/styles'

import TitleBar from '../topbar/TitleBar'
import HttpClient from '../HttpClient'
import Loader from '../common/Loaders/LoaderList'

const List = props => {
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    let didCancel = false
    setLoading(true)
    HttpClient.get('/countries').then(data => {
      if(!didCancel)
        setCountries(data)
        setLoading(false)
    })

    return ()=>{
      didCancel = true
    }
  }, [])
  const removeCountry = id => {
    if(window.confirm('Är du säker?')) {
      HttpClient.delete(`/countries/${id}`).then(() => {
        setCountries(countries.filter(a => a.id !== id))
      })
    }
  }

  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  return (
    <React.Fragment>
      <TitleBar title="Länder">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/countries/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        {loading ? <Loader /> : (
          <Grid container>
            <Paper square className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell><h3>Namn</h3></TableCell>
                    <TableCell><h3>Code</h3></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { countries.map( m => 
                    <TableRow key={m.id}>
                      <TableCell><Link to={`countries/${m.id}/edit`}>{m.name}</Link></TableCell>
                      <TableCell>{m.code}</TableCell>
                      <TableCell align="right">
                        <Typography noWrap>
                          <IconButton onClick={() => removeCountry(m.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Typography>  
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}
List.propTypes = {
  history: PropTypes.object.isRequired
}

export default List
