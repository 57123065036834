import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Box, TextField, InputAdornment } from '@mui/material'

const Price = props => {
  const { t } = useTranslation()

  const formatPrice = val => t('formattedNum', { num: val })
  const reverseFormatPrice = val => t('reverseFormattedNum', { num: val })

  return(
    <Box flex="1 1 0" marginRight={0.5}>
      <TextField
        fullWidth
        disabled={props.supplierProductsView}
        label={props.margin || ''}
        value={props.price.value || ' '}
        onChange={e => props.setPrice({ ...props.price, value: e.currentTarget.value })}
        InputProps={props.currency ? {
          endAdornment: <InputAdornment position="end">{props.currency}</InputAdornment>,
        } : null}
        margin="normal"
        variant="outlined" />
    </Box>
  )
}

Price.propTypes = {
  price: PropTypes.object.isRequired,
  setPrice: PropTypes.func.isRequired,
  margin: PropTypes.string,
  currency: PropTypes.string
}

export default Price
