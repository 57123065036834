import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { Container, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import HttpClient from '@httpClient'
import TitleBar from '@components/topbar/TitleBar'
import LoaderList from '@common/Loaders/LoaderList'
import Form from '@components/tags/Form';

const Edit = () => {
  const initialState = {
    name: '',
    permalink: '',
    name_plural: '',
    name_singular: '',
    custom_title: '',
    description: '',
    header: '',
    footer: '',
    info: '',
    footer_info: '',
    keywords: '',
    image: '',
    big_image: false,
    parent: {},
    is_spec: false,
    show_until: '',
    color_code: '',
    seo_index: false,
    destroy_at: '',
    mp4_video: '',
    webm_video: '',
    video_poster: '',
  }
  const [loading, setLoading] = useState(true)

  const params = useParams()
  const { t } = useTranslation()
  const [tag, setTag] = useState(initialState)

  useEffect(() => {
    if(params.id){
      setLoading(true)
      HttpClient.get(`/tags/${params.id}`)
        .then(data => {
          const keys = Object.keys(data)
          for ( const key of keys){
            data[key] = data[key] === null ? '' : data[key]
          }
          setTag({ ...data })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }

  }, [params])

  const titleBar = (
    <TitleBar title={(params.id ? t('actions.edit') : t('actions.new')) + ' ' + tag.name} alignItems="center">
      <IconButton
        component={Link}
        to={'/tags'}
        size="medium"
        aria-label="back"
        color="secondary"
      >
        <ArrowBackIcon />
      </IconButton>
    </TitleBar>
  )

  return(
    <>
      {titleBar}
      <Container>
        { loading ? <LoaderList/> : <Form tag={tag} loading={loading}/> }
      </Container>
    </>
  )
}

export default Edit
