import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Link, Route, Switch } from 'react-router-dom'

import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import {Tabs, Tab} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'

import SlideImages from './SlideImages'
import Products from './Products'
import Inspirations from './Inspirations'


const HomePage = props => {
  const [value, setValue] = useState(0)

  const handleActiveIndexUpdate = (event, newValue) => setValue(newValue)

  const activeIndex = () => {
    let i = 0
    switch (props.location.pathname) {
      case `${props.match.url}/`:
        i = 0
        break
      case `${props.match.url}/products`:
        i = 1
        break
      case `${props.match.url}/inspirations`:
        i = 2
        break
      default:
        i = 0
    }
    return i
  }

  const classes = makeStyles(theme => ({
    root: {
      marginBottom: theme.spacing(4)
    },
    tab: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
  }))()

  return(
    <React.Fragment>
      <AppBar position="static" color="default" className={classes.root}>
        <Container className={classes.container}>
          <Grid container alignItems="center">
            <Grid item xs={11}>
              <Tabs value={activeIndex()} indicatorColor="primary" className={classes.tabs}>
                <Tab label="Bildspel" component={Link} to={`${props.match.url}`} className={classes.tab} />
                <Tab label="Produkter" component={Link} to={`${props.match.url}/products`} className={classes.tab} />
                <Tab label="Inspiration" component={Link} to={`${props.match.url}/inspirations`} className={classes.tab} />
              </Tabs>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Switch>
        <Route exact path={`${props.match.path}/`} component={SlideImages}></Route>
        <Route exact path={`${props.match.path}/products`} component={Products}></Route>
        <Route exact path={`${props.match.path}/inspirations`} component={Inspirations}></Route>
      </Switch>
    </React.Fragment>
  )
}

HomePage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object
}

export default HomePage
