import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import List from '@components/questions/List'

const Questions = props => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${props.match.path}/`} component={List}></Route>
      </Switch>
    </React.Fragment>
  )
}

Questions.propTypes = {
  match: PropTypes.object.isRequired
}

export default Questions
