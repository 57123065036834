import React from 'react'
import { Grid, Paper, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

const paperStyle = {
  borderRadius: '4px',
  padding: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box'
}

const SelectedList = ({ list, handleAction, type }) => (
  <Grid container sx={{ marginTop: '16px' }} spacing={2}>
      { list.map(listItem => (
        <Grid item xs={12} sm={6} md={4} key={listItem.id} zeroMinWidth>
          <Paper sx={paperStyle}>
            <a href={`/${type}/${listItem.id}/edit`}>{listItem.name}</a>
            <IconButton onClick={() => handleAction(listItem.id)}>
              <DeleteIcon/>
            </IconButton>
          </Paper>
        </Grid>
      ))}
  </Grid>
)

export default SelectedList;
