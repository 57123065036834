import React from 'react'
import { useTranslation } from 'react-i18next'

import TitleBar from '@topBar/TitleBar'
import QuestionTable from '@components/questions/QuestionTable'

const List = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <TitleBar title={t('questions.title')}></TitleBar>
      <QuestionTable pageType={'questionsListing'}/>
    </React.Fragment>
  )
}

export default List;
