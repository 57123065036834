import React, {useState, useEffect, useRef, createRef} from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Button from '@mui/material/Button'

import HttpClient from '../../HttpClient'
import { ProductApi } from '@api/ProductApi'

const Filters = props => {
  const [filters, setFilters] = useState({
    supplierId: '',
    colorGroupId: ''
  })

  const [suppliers, setSuppliers] = useState([])
  const [colorGroups, setColorGroups] = useState([])
  useEffect(() => {
    let didCancel = false
    if (props.productType === 'products'){
      Promise.all([ProductApi.getProductSuppliers(props.productId), HttpClient.get(`/products/${props.productId}/color_groups?filter=has_color`)])
        .then(([_suppliers, colors]) => {
          if (!didCancel) {
            setSuppliers(_suppliers.data)
            setColorGroups(colors)
          }
        })

      return () => {
        didCancel=true
      }
    } else {
      HttpClient.get(`/supplier_products/${props.productId}/color_groups?filter=has_color&product_type=supplier_product`).then((colors)=>{
        setColorGroups(colors)
      })
    }
  }, [props.productId])

  const selectLabels = useRef([...Array(2)].map(() => createRef()))
  const [selectLabelsWidth, setSelectLabelsWidth] = useState([...Array(2)])

  useEffect(() => {
    setSelectLabelsWidth(selectLabels.current.map(innerElRef => innerElRef.current.offsetWidth))
  }, [])

  const handleOnClearFilter = () => {
    props.replaceArticlesWithOriginalArticles()
    setFilters({
      supplierId: '',
      colorGroupId: ''
    })
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <FormControl margin='none' fullWidth variant="outlined">
          <InputLabel ref={selectLabels.current[0]} htmlFor="supplier">Leverantör</InputLabel>
          <Select
            native
            disabled={props.productType === 'supplier_products'}
            value={`${filters.supplierId}`}
            onChange={e => setFilters({...filters, supplierId: e.currentTarget.value})}
            input={<OutlinedInput name="supplier" id="supplier" labelWidth={selectLabelsWidth[0]} />}
          >
            <option></option>
            {suppliers?.map(c =>
              <option key={c.id} value={c.id}>{c.name}</option>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl margin="none" fullWidth variant="outlined">
          <InputLabel ref={selectLabels.current[1]} htmlFor="color_group">Färger</InputLabel>
          <Select
            native
            value={`${filters.colorGroupId}`}
            onChange={e => setFilters({...filters, colorGroupId: e.currentTarget.value})}
            input={<OutlinedInput name="color_group" id="color_group" labelWidth={selectLabelsWidth[1]} />}
          >
            <option></option>
            {colorGroups.map(c =>
              <option key={c.id} value={c.id}>{c.colors.length && c.colors[0].name}</option>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item container xs={4}>
        <Grid container item spacing={2} alignItems={'center'}>
          <Grid item xs={2}>
            <FormControl margin="none" variant="outlined">
              <Button variant='contained' color='primary' onClick={() => props.onFilter(filters)}>Filter</Button>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl margin='none' variant="outlined" style={{ marginLeft: 35 }}>
              <Button variant='contained' color='primary' onClick={handleOnClearFilter}>Rensa</Button>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
Filters.propTypes = {
  productId: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  replaceArticlesWithOriginalArticles: PropTypes.func.isRequired,
  onBulkAction: PropTypes.func
}

export default Filters
