import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#337ab7',
    },
    secondary: {
      main: '#808080'
    }
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Remove uppercase
        },
      },
    },
  },
})

function MuiWrapper(props) {
  const { children } = props

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

MuiWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MuiWrapper
