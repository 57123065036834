import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Fab from '@mui/material/Fab'
import DeleteIcon from '@mui/icons-material/Delete'
import Checkbox from '@mui/material/Checkbox'
import AddIcon from '@mui/icons-material/Add'
import Paper from '@mui/material/Paper'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import FileInput from '../common/FileInput'
import HttpClient from '../HttpClient'
import Mdivider from '../common/Mdivider'

import InspirationProduct from './InspirationProduct'

const Inspiration = props => {
  
  const [inspiration, setInspiration] = useState({...props.inspiration})
  const [productsVisible, setProductsVisible] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [creating, setCreating] = useState(false)

  const classes = makeStyles(theme => ({
    collapseHeader: {
      padding: '10px',
      borderBottom: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer'
    },
    collapseDetails: {
      padding: '10px'
    }
  }))()

  const saveChange = event => {
    let insprtn = { ...inspiration }
    const field = event.target.name
    console.log(event.target.type)
    switch (event.target.type) {
      case 'file':
        insprtn[field] = event.target.files[0]
        break
      case 'checkbox':
        insprtn[field] = event.target.checked
        break
      default:
        insprtn[field] = event.target.value
    }
    const formData = new FormData()
    for (const [field, value] of Object.entries(insprtn)) {
      if(field === 'image') {
        if(value !== '') {
          formData.append(`inspiration[${field}]`, value)
        }
      } else {
        formData.append(`inspiration[${field}]`, value)
      }
    }
    // disable button while uploading
    setUpdating(true)
    HttpClient.put(`/inspirations/${insprtn.id}`, formData)
      .then(res => {
        setInspiration({ ...res, inspiration_products: inspiration.inspiration_products })
        setUpdating(false)
      })
  }

  const handleChange = event => {
    let insprtn = { ...inspiration }
    const field = event.target.name
    switch (event.target.type) {
      case 'file':
        insprtn[field] = event.target.files[0]
        break
      default:
        insprtn[field] = event.target.value
    }
    setInspiration(insprtn)
  }

  const toggleProducts = () => {
    setProductsVisible(!productsVisible)
  }

  const newProduct = () => {
    let newProduct = {
      inspirationId: inspiration.id,
      name: '',
      description: '',
      url: '',
      product: {
        id: ''
      }
    }
    newProduct.id = Math.min(...inspiration.inspiration_products.filter(i => i < 0).map(i => i.id), 0) - 1
    setInspiration({...inspiration, inspiration_products: [...inspiration.inspiration_products, newProduct]})
    setProductsVisible(true)
  }

  const updateProduct = (id, updatedProduct) => {
    const _inspiration = {...inspiration}
    const productIndex = _inspiration.inspiration_products.findIndex(i => i.id === id)
    _inspiration.inspiration_products[productIndex] = updatedProduct
    setInspiration(_inspiration)
  }

  const deleteProduct = id => {
    const products = inspiration.inspiration_products.filter(i => i.id !== id)
    if(id) {
      HttpClient.delete(`/inspirations/${inspiration.id}/inspiration_products/${id}`)
        .then(setInspiration({ ...inspiration, inspiration_products: products }))
    } else {
      setInspiration({ ...inspiration, inspiration_products: products })
    }
  }

  const shouldCancelStart = e => {
    // Cancel sorting if the event target is an `input`, `textarea`, `select`,`option` or `button`
    const disabledElements = ['input', 'textarea', 'select', 'option', 'button', 'a', 'svg', 'span']
    if (disabledElements.indexOf(e.target.tagName.toLowerCase()) !== -1 || disabledElements.indexOf(e.target.parentNode.tagName.toLowerCase()) !== -1) {
      return true // Return true to cancel sorting
    }
  }

  const onProductsSortEnd = ({ oldIndex, newIndex }) => {
    const sortedInspirationProducts = arrayMove(inspiration.inspiration_products, oldIndex, newIndex)
    if( JSON.stringify(inspiration.inspiration_products) !== JSON.stringify(sortedInspirationProducts) ) {
      HttpClient.post(`/inspirations/${inspiration.id}/inspiration_products/sort`, { inspiration_products: sortedInspirationProducts.map(i => i.id) })
        .then()
      setInspiration({ ...inspiration, inspiration_products: sortedInspirationProducts })
    }
  }

  const imageHtml = (inspiration.image_url) ? (
    <a href={inspiration.image_url} target='_blank' rel='noopener noreferrer'>
      <img src={inspiration.thumbnail_admin} alt={inspiration.title} />
    </a>
  ) : null

  const productsHeader = (inspiration.inspiration_products.length) ? (
    <Grid container >
      <Grid container spacing={2}>
        <Grid item xs={1}><Typography varient='h3'>Bild</Typography></Grid>
        <Grid item xs={3}><Typography varient='h3'>Namn</Typography></Grid>
        <Grid item xs={3}><Typography varient='h3'>URL</Typography></Grid>
        <Grid item xs={4}><Typography varient='h3'>Beskrivning</Typography></Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Mdivider />
    </Grid>
  ) : null
  const productsBody = (inspiration.inspiration_products.length > 0) ? (
    <SortableList
      items={inspiration.inspiration_products}
      updateProduct={updateProduct}
      deleteProduct={deleteProduct}
      inspirationId={inspiration.id}
      shouldCancelStart={shouldCancelStart}
      onSortEnd={onProductsSortEnd} />
  ) : null

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <div>{imageHtml}</div>
          <FileInput
            icon={true}
            onChange={saveChange}
            name='image'
            disabled={updating}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              value={inspiration.name || ''}
              name="name"
              onChange={handleChange}
              onBlur={saveChange}
              margin="dense"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <TextField
              value={inspiration.description || ''}
              name="description"
              onChange={handleChange}
              onBlur={saveChange}
              margin="dense"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <Checkbox
            name="visible"
            checked={String(inspiration.visible) === String(true)}
            onChange={saveChange}
            value={true}
            color="primary"
          />
        </Grid>
        <Grid item xs={1}>
          <Fab
            onClick={() => {if(window.confirm('Är du säker?')) props.onDelete(inspiration.id)}}
            color="secondary"
            size="small">
            <DeleteIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Paper elevation={2}>
            <Grid container className={classes.collapseHeader} onClick={toggleProducts}>
              <Grid item xs={11}>
                <Typography varient='h3'>Produkter</Typography>
              </Grid>
              <Grid item xs={1} align='right'>
                { productsVisible ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
              </Grid>
            </Grid>
            {productsVisible ? (
              <div className={classes.collapseDetails}>
                {productsHeader}
                {productsBody}
                <Fab
                  onClick={newProduct}
                  color="primary"
                  size="small"
                  component="span"
                  disabled={creating}>
                  <AddIcon />
                </Fab>
              </div>
            )
              :
              null
            }
          </Paper>
        </Grid>
      </Grid>
      <Mdivider/>
    </React.Fragment>
  )
}

const SortableItem = SortableElement(({product, updateProduct, deleteProduct, inspirationId}) => {
  return (
    <InspirationProduct
      product={product}
      inspirationId={ inspirationId }
      key={product.id}
      onUpdate={updateProduct}
      onDelete={deleteProduct} />
  )
})

const SortableList = SortableContainer(({items, updateProduct, deleteProduct, inspirationId}) => {
  return (
    <Grid container>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          disabled={value.id < 0}
          product={value}
          inspirationId={inspirationId}
          updateProduct={updateProduct}
          deleteProduct={deleteProduct}/>
      ))}
    </Grid>
  )
})

Inspiration.propTypes = {
  inspiration: PropTypes.object.isRequired,
  onUpdateImage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default Inspiration
