import React, {useRef, useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import {FormControl, InputLabel, Select, OutlinedInput} from '@mui/material'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

import HttpClient from '../HttpClient'
import Attribute from './Attribute'

const AttributeGroup = props => {
  const initialState= (props.category.category_attribute_group && props.category.category_attribute_group.p_attributes) || []
  const [attributes, setAttributes] = useState(initialState)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState('')

  useEffect(() => {
    HttpClient.get(`/categories/${props.category.id}/attribute_options`).then(data => {
      setOptions(data)
      data.length && setSelected(data[0].id)
    })
  },[])

  const attributeLabel = useRef(null)
  const [attributeLabelWidth, setAttributeLabelWidth] = useState(0)
  useEffect(() => {
    setAttributeLabelWidth(attributeLabel.current.offsetWidth)
  }, [])

  const addAttribute = () => {
    if(selected) {
      HttpClient.post(`/categories/${props.category.id}/add_attribute`, { attribute_id: selected })
        .then(res => {
          if(res.success) {
            setAttributes([...attributes, res.data])
          }
        })
      const _options = options.filter(o => o.id !== selected)
      setOptions(_options)
      _options.length ? setSelected(_options[0].id) : setSelected('')
    }
  }

  const updateShowOnFilters = (attr, showOnFilters) => {
    HttpClient.put(`/attributes/${attr.id}/show_on_filters/${props.category.id}`, { show_on_filters: showOnFilters })
      .then(() => {
        const index = attributes.findIndex(a => a.id === attr.id)
        const _attributes = [...attributes]
        _attributes[index] = { ...attributes[index], show_on_filters: showOnFilters }
        setAttributes(_attributes)
      }).catch (() => {
        console.error("Error udpating show on attributes for the attribute")
      })
  }

  const updateAttribute = attr => {
    HttpClient.put(`/attributes/${attr.id}`, { p_attribute: attr })
      .then(() => {
        const index = attributes.findIndex(a => a.id === attr.id)
        const _attributes = [...attributes]
        _attributes[index] = attr
        setAttributes(_attributes)
      })
  }

  const removeAttribute = id => {
    const _attributes = attributes.filter(a => a.id !== id)
    const _toRemove = attributes.find(a => a.id === id)
    HttpClient.post(`/categories/${props.category.id}/remove_attribute`,
      {
        attribute_id: id
      }
    ).then(res => {
      if(res.success) {
        setAttributes(_attributes)
        setOptions([...options, _toRemove])
      }
    })
  }

  return (
    <React.Fragment>
      {attributes.map(a =>
        <Attribute key={a.id} attribute={a} updateAttribute={updateAttribute} updateShowOnFilters={updateShowOnFilters} removeAttribute={removeAttribute} />
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel ref={attributeLabel} htmlFor="attribute">Attribut</InputLabel>
            <Select
              native
              value={selected}
              onChange={e => setSelected(e.currentTarget.value)}
              input={<OutlinedInput name="attribute" id="attribute" labelWidth={attributeLabelWidth} />}
            >
              {options.map(o =>
                <option key={o.id} value={o.id}>{o.name}</option>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={addAttribute}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

AttributeGroup.propTypes = {
  category: PropTypes.object.isRequired,
}

export default AttributeGroup

