import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Container } from '@mui/material'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import {TableRow, TableCell} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import LensIcon from '@mui/icons-material/Lens'
import { makeStyles } from '@mui/styles'

import HttpClient from '@httpClient'
import TitleBar from '@components/topbar/TitleBar'
import AlphabeticalFilter from '@common/AlphabeticalFilter'
import Loader from '@common/Loaders/LoaderList'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import ListTable from '@common/ListingTable/ListTable'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { useDebounce } from '@customHooks/useDebouncing'
import { useTranslation } from 'react-i18next'
import { ColorApi } from '@api/ColorApi'
import { NameSorting } from '../../config/SortingLabels'

const List = props => {
  const colorsPerPage = getUserPreference( 'page_size', 'color')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( colorsPerPage !== '' ? colorsPerPage : 20)
  const [totalCount, setTotalCount] = useState(0)
  const [colors, setColors] = useState([])
  const [loading, setLoading] = useState(false)
  const filter = new URLSearchParams(props.location.search).get('filter') || 'A'
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOptionSelected, setSortOptionSelected] = useState('name_ascending')
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    let didCancel = false
    if (searchTerm) {
      fetchColors({ page: 1, per_page: perPage, query: searchTerm });
    }
    else {
      fetchColors({ filter, page: 1, per_page: perPage });
    }
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const fetchColors = async (params) => {
    setLoading(true)
    if (!params.sort_by) {
      params['sort_by'] = sortOptionSelected
    }
    try {
      const response = await ColorApi.getColors(params)
      if(response) {
        setColors(response.data)
        setTotalCount(response.total_count)
      }
    }
    catch(error) {
      console.log("error", error)
    }
    finally {
      setLoading(false)
    }
  }

  const removeColor = id => {
    if(window.confirm('Är du säker?')) {
      ColorApi.removeColor(id).then(() => {
        setColors(colors.filter(a => a.id !== id))
      })
    }
  }

  const handleSearchParamsChange = (type, value) => {
    switch (type) {
      case "page":
        setPage(value);
        fetchColors({ filter, page: value, per_page: perPage });
        break;

      case "pageSize":
        setPerPage(value);
        setPage(1);
        setUserPreference("page_size", "color", value);
        fetchColors({ filter, page: 1, per_page: value });
        break;

      case "sort":
        setSortOptionSelected(value)
        fetchColors({ filter, page: 1, per_page: perPage, sort_by: value });
        break;

      case "search":
        setSearchTerm(value)
        break;
    }
  };

  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  const colorsTableContent = () => {
    const colorsBody = colors?.map((color) => (
      <TableRow key={color.id}>
        <TableCell><Link to={`colors/${color.id}/edit`}>{color.name}</Link></TableCell>
        <TableCell><LensIcon fontSize="large" style={{color: `${color.code||'white'}`}} /></TableCell>
        <TableCell>{color.color && color.color.name}</TableCell>
        <TableCell align="right">
          <IconButton
            component={Link}
            to={`colors/${color.id}/edit`}
            aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => removeColor(color.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
    return { body: colorsBody, heading: ['Namn', 'Kod', 'Förälderfärg', ''] }
  }

  const tableContent = colorsTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Färger">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/colors/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        {!searchTerm && <AlphabeticalFilter resource="colors" currentFilter={filter} />}
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={NameSorting}
          searchText={t('colors.placeholders.search_label')}
          getData={handleSearchParamsChange} />
        {loading ? <Loader /> : 
          <Paper square className={classes.root}>
            <ListTable
              tableHeadings={tableContent.heading}
              tableContent={tableContent.body}
              count={totalCount}
              pageNo={page}
              rowsPerPage={perPage}
              onPageChange={(event, pageNo) => handleSearchParamsChange('page', pageNo + 1)}
              handleChangeRowsPerPage={(event) => handleSearchParamsChange('pageSize', event.target.value)}
              rowsControl={true} />
          </Paper>
        }
      </Container>
    </React.Fragment>
  )
}

List.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default List