import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HttpClient from "@httpClient";

import { Container, Fab } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";

import TitleBar from "@components/topbar/TitleBar";
import Form from "@components/products/Form";
import SupplierProductForm from "@components/supplier_products/SupplierProductForm";
import LoaderShowDouble from "@common/Loaders/LoaderShowDouble";

import { SupplierProductApi, ProductApi } from "@api";
import ProductResourceContext from "@contexts/ProductResource";
import PopupAlert from "@contexts/PopupAlert";
import { parseErrorMessage } from "@src/utils/sharedHelper";

const Edit = (props) => {
  const initialState = {};
  initialState.product = {
    id: props.match.params.id,
    name: "",
    priority: 3,
    category: { id: "" },
    country: { id: "" },
    colors: [],
    brand: { id: "" },
    badges: [],
  };
  initialState.categories = [];
  initialState.countries = [];
  initialState.brands = [];
  initialState.badges = [];

  const [state, updateState] = useState(initialState);
  const [loading, setLoading] = useState(true);

  const productResource = useContext(ProductResourceContext);
  const addPopupAlert = useContext(PopupAlert);
  const { t } = useTranslation();

  const isSupplierProduct = productResource === "supplier_products";

  useEffect(() => {
    let didCancel = false;
    if (isSupplierProduct) {
      getSupplierProductData();
    } else {
      fetchProductsData();
    }
    return () => {
      didCancel = true;
    };
  }, [props.match.params.id]);

  const getSupplierProductData = () => {
    Promise.all([
      HttpClient.get("/countries"),
      SupplierProductApi.getProduct(state.product.id),
    ])
      .then(([countries, _product]) => {
        let _state = { ...state, product: { ...state.product, ..._product }, countries: countries };
        if (_state.product.country.id === "" && _state.countries.length)
          _state.product.country.id = _state.countries[0].id;
        updateState({ state, ..._state });
        setLoading(false);
      })
      .catch((e) => {
        addPopupAlert(
          t("products.actions.load"),
          "error",
          parseErrorMessage(e.message),
        );
      });
  };

  const fetchProductsData = () => {
    Promise.all([
      HttpClient.get("/categories/product_categories"),
      HttpClient.get("/brands?page=-1"),
      HttpClient.get("/countries"),
      state.product.id ? ProductApi.getProduct(state.product.id) : Promise.resolve(null),
    ])
      .then(([categories, brands, countries, p]) => {
        let _state = { ...state, product: { ...state.product, ...p } };
        _state.categories = categories;
        _state.countries = countries;
        _state.brands = brands;
        if (_state.product.category.id === "" && _state.categories.length)
          _state.product.category.id = _state.categories[0].id;
        if (_state.product.country.id === "" && _state.countries.length)
          _state.product.country.id = _state.countries[0].id;
        if (_state.product.brand.id === "" && _state.brands.length)
          _state.product.brand.id = _state.brands[0].id;
        updateState({ state, ..._state });
        setLoading(false);
      })
      .catch((e) => {
        addPopupAlert(
          t("products.actions.load"),
          "error",
          parseErrorMessage(e.message),
        );
      });
  };

  return (
    <>
      {state.product.id === "" ? (
        <TitleBar title={t("products.newProductTitle")}>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={props.history.goBack}
          >
            <ArrowBack />
          </Fab>
        </TitleBar>
      ) : null}

      <Container>
        { loading && <LoaderShowDouble/> }
        { !loading && isSupplierProduct && <SupplierProductForm product={state.product} countries={state.countries}/> }
        { !loading && !isSupplierProduct &&
          <Form
            created={props.location?.state?.created}
            product={state.product}
            categories={state.categories}
            countries={state.countries}
            brands={state.brands}
            history={props.history}
          />
        }
      </Container>
    </>
  );
};

Edit.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Edit;
