import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Container } from '@mui/material'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import {TableRow, TableCell} from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { makeStyles } from '@mui/styles'
import ListTable from '@common/ListingTable/ListTable'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import HttpClient from '@httpClient'
import TitleBar from '@components/topbar/TitleBar'
import AlphabeticalFilter from '@common/AlphabeticalFilter'
import Loader from '@common/Loaders/LoaderList'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { useDebounce } from '@customHooks/useDebouncing'
import { useTranslation } from 'react-i18next'
import { CategoryApi } from '@api/CategoryApi'
import { ProductSorting } from '../../config/SortingLabels'

const List = props => {
  const categoriesPerPage = getUserPreference( 'page_size', 'category')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( categoriesPerPage !== '' ? categoriesPerPage : 20)
  const [totalCount, setTotalCount] = useState(0)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const filter = new URLSearchParams(props.location.search).get('filter') || 'A'
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOptionSelected, setSortOptionSelected] = useState('latest')
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    let didCancel = false
    if (searchTerm) {
      fetchCategories({ page: 1, per_page: perPage, query: searchTerm });
    }
    else {
      fetchCategories({ filter, page: 1, per_page: perPage });
    }
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const fetchCategories = async (params) => {
    setLoading(true)
    if (!params.sort_by) {
      params['sort_by'] = sortOptionSelected
    }
    try {
      const response = await CategoryApi.getCategories(params)
      if(response) {
        setCategories(response.data)
        setTotalCount(response.total_count)
      }
    }
    catch(error) {
      console.log("error", error)
    }
    finally {
      setLoading(false)
    }
  }

  const removeCategory = id => {
    if(window.confirm('Är du säker?')) {
      HttpClient.delete(`/categories/${id}`).then(() => {
        setCategories(categories.filter(c => c.id !== id))
      })
    }
  }

  const handleSearchParamsChange = (type, value) => {
    switch (type) {
      case "page":
        setPage(value);
        fetchCategories({ filter, page: value, per_page: perPage });
        break;

      case "pageSize":
        setPerPage(value);
        setPage(1);
        setUserPreference("page_size", "category", value);
        fetchCategories({ filter, page: 1, per_page: value });
        break;

      case "sort":
        setSortOptionSelected(value)
        fetchCategories({ filter, page: 1, per_page: perPage, sort_by: value });
        break;

      case "search":
        setSearchTerm(value)
        break;
    }
  };

  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  const categoriesTableContent = () => {
    const categoriesBody = categories?.map((category) => (
      <TableRow key={category.id}>
        <TableCell><Link to={`categories/${category.id}/edit`}>{category.name}</Link></TableCell>
        <TableCell>{category.main ? <DoneIcon /> : null}</TableCell>
        <TableCell>{category.product_category ? <DoneIcon /> : null}</TableCell>
        <TableCell>
          {category.redirect ?
            <a href={category.redirect} target="_blank" rel="noopener noreferrer">{category.redirect}</a>
            : null
          }
        </TableCell>
        <TableCell>{category.parent && category.parent.name}</TableCell>
        <TableCell align="right">
          <Typography noWrap>
            <IconButton
              component={Link}
              to={`categories/${category.id}/edit`}
              aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => removeCategory(category.id)}>
              <DeleteIcon />
            </IconButton>
          </Typography>
        </TableCell>
      </TableRow>
    ))
    return { body: categoriesBody, heading: ['Namn', 'Huvud', 'Produktkategori', 'Redirect', 'Föräldrakategori', ''] }
  }

  const tableContent = categoriesTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Kategorier">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/categories/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        {!searchTerm && <AlphabeticalFilter resource="categories" currentFilter={filter} />}
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={ProductSorting}
          searchText={t('categories.placeholders.search_label')}
          getData={handleSearchParamsChange} />
        {loading ? <Loader /> : 
          <Paper square className={classes.root}>
            <ListTable
              tableHeadings={tableContent.heading}
              tableContent={tableContent.body}
              count={totalCount}
              pageNo={page}
              rowsPerPage={perPage}
              onPageChange={(event, pageNo) => handleSearchParamsChange('page', pageNo + 1)}
              handleChangeRowsPerPage={(event) => handleSearchParamsChange('pageSize', event.target.value)}
              rowsControl={true} />
          </Paper>
        }
      </Container>
    </React.Fragment>
  )
}

List.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default List
