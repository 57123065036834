import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const routeBreadcrumbs = (t) => ({
  "/products": t("breadcrumbs.products"),
  "/attributes": t("breadcrumbs.attributes"),
  "/tags": t("breadcrumbs.tags"),
  "/colors": t("breadcrumbs.colors"),
  "/countries": t("breadcrumbs.countries"),
  "/suppliers": t("breadcrumbs.suppliers"),
  "/categories": t("breadcrumbs.categories"),
  "/brands": t("breadcrumbs.brands"),
  "/campaigns": t("breadcrumbs.campaigns"),
  "/marking_methods": t("breadcrumbs.markingMethods"),
  "/starting_costs": t("breadcrumbs.startingCosts"),
  "/currencies": t("breadcrumbs.currencies"),
  "/marking_templates": t("breadcrumbs.markingTemplates"),
  "/homepage": t("breadcrumbs.homepage"),
  "/user": t("breadcrumbs.user"),
  "/units": t("breadcrumbs.units"),
  "/standards": t("breadcrumbs.standards"),
  "/supplier-products": t("breadcrumbs.supplierProducts"),
  "/questions": t("breadcrumbs.questions"),
  "/supplier-integration-job-stats": t("breadcrumbs.supplierIntegrationJobStats"),
});

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
}));

const Breadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const breadcrumbs = routeBreadcrumbs(t);
  const classes = useStyles();

  // Get the top-level route
  const topLevelPath = `/${location.pathname.split("/")[1] || ""}`;
  const breadcrumbText = breadcrumbs[topLevelPath];

  if (!breadcrumbText) return null; // Hide breadcrumbs for undefined routes

  return (
    <nav aria-label="breadcrumb" className="breadcrumbs">
      <span className="breadcrumb-list twd-text-sm twd-font-medium">
        <Link to={topLevelPath} className={classes.link}>
          <Typography variant="subtitle1">{breadcrumbText}</Typography>
        </Link>
      </span>
    </nav>
  );
};

export default Breadcrumbs;
