import React from 'react'
import PropTypes from 'prop-types'

import Select from 'react-select'
import FormControl from '@mui/material/FormControl'

import SelectInput from '@common/SelectInput'

const AttributesList = props => {
  return (
    <React.Fragment>
        <SelectInput
          label="Välj Attributes"
          onChange={(event) => {
            const selectedOption = props.attributes.find(
              (a) => a.id === event.target.value
            );
            if (selectedOption) {
              props.addValue(selectedOption); // Pass the whole object
            }
          }}
          valuesArray={props.attributes.map((a) => ({
            id: a.id,
            name: `${a.name || a.value}${
              a.p_attribute && a.p_attribute.unit ? ` ${a.p_attribute.unit.symbol}` : ""
            }`,
          }))}
        />
    </React.Fragment>
  )
}

AttributesList.propTypes = {
  attributes: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  addValue: PropTypes.func.isRequired,
  removeValue:  PropTypes.func.isRequired,
  history: PropTypes.object
}

export default AttributesList
