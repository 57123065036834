import React, { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  TableCell,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Button,
  ClickAwayListener,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TitleBar from '@components/topbar/TitleBar'
import LoaderList from '@common/Loaders/LoaderList'
import ListTableActions from '@common/ListingTable/ListTableActions'
import ListTable from '@common/ListingTable/ListTable'
import { useDebounce } from '@customHooks/useDebouncing'
import { TagApi } from '@api/TagApi'
import ImageThumbnail from '@shared/ImageThumbnail'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import { ProductSorting } from '@config/SortingLabels'
import LoadingButton from '@common/LoadingButton'
import SharedModal from '@shared/Modal'
import { AddRemoveActionButtons } from '@common/AddRemoveActionButtons'
import NewTagModal from '@components/tags/NewTagModal'

const useStyles = makeStyles({
  listItem: {
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  ratingBox: {
    position: 'relative',
  },
  listStyle: {
    position: 'absolute',
    maxHeight: '400px',
    backgroundColor: 'white',
    borderRadius: '6px',
    left: '-78%',
    padding: '10px',
    width: '300px',
    boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
    cursor: 'pointer',
    zIndex: '5'
  },
  actionListStyle: {
    position: 'absolute',
    maxHeight: '400px',
    backgroundColor: 'white',
    borderRadius: '6px',
    left: '0%',
    padding: '10px',
    width: '300px',
    boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
    cursor: 'pointer',
    zIndex: '5'
  },
  checkboxColumn: {
    width: '200px'
  }
});

const AddTagProducts = () => {
  const tagProductsPerPage = getUserPreference( 'page_size', 'addProductsToTag')
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( tagProductsPerPage !== '' ? tagProductsPerPage : 20)
  const [productsCount, setProductsCount] = useState(0)
  const [sortOptionSelected, setSortOptionSelected] = useState('latest')
  const [showDropdown, setShowDropdown] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [newTagName, setNewTagName] = useState('')
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [removedSelection, setRemovedSelection] = useState([])
  const [addedSelection, setAddedSelection] = useState([])
  const [tagProducts, setTagProducts] = useState([])
  const [tagName, setTagName] = useState('')
  const [actionLoading, setActionLoading] = useState([])
  const [actionType, setActionType] = useState('tableFilterAction')
  const [tagNameError, setTagNameError] = useState('')
  const [backdrop, setBackdrop] = useState(false)

  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation()
  const classes = useStyles()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    getProducts(debouncedSearchTerm, sortOptionSelected, 1, perPage)
  },[debouncedSearchTerm])

  const getProducts = (searchQuery, sortOption, pageNo, perPageCount) => {
    setLoading(true)
    TagApi.allProducts(params.id, { query: searchQuery, page: pageNo, per_page: perPageCount, sort_by: sortOption }).then((response)=>{
      setProducts(response.all_products)
      setSelectedProducts(response.tag_product_ids)
      setTagProducts(response.tag_product_ids)
      setProductsCount(response.all_products_count)
      setTagName(response.tag.name)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  const handleSearchingAndSortingChange = (type, value) => {
    if (type === 'sort'){
      setSortOptionSelected(value)
      getProducts(searchTerm, value, 1, perPage)
    } else if(type === 'search') {
      setSearchTerm(value)
    } else if(type === 'page'){
      setPage(value)
      getProducts(searchTerm, sortOptionSelected, value, perPage)
    } else if (type === 'pageSize'){
      setPerPage(value)
      setPage(1)
      setUserPreference('page_size', 'addProductsToTag', value)
      getProducts(searchTerm, sortOptionSelected, 1, value)
    }
  }

  const handleSelectedProducts = (event, product) => {
    let products = [...selectedProducts]
    let productsToAdd = [...addedSelection]
    let productsToRemove = [...removedSelection]
    if (event.target.checked) {
      products.push(product.id)
      if (tagProducts.includes(product.id)){
        productsToRemove = productsToRemove.filter((a) => a !== product.id)
      } else {
        productsToAdd.push(product.id)
      }
    } else {
      products = products.filter(prod => prod !== product.id)
      if (tagProducts.includes(product.id)){
        productsToRemove.push(product.id)
      } else {
        productsToAdd = productsToAdd.filter((a) => a !== product.id)
      }
    }
    setAddedSelection(productsToAdd)
    setRemovedSelection(productsToRemove)
    setSelectedProducts([...products])
  }

  const allCheckBox = () => (
    (addedSelection.length > 0 || removedSelection.length > 0) &&
      <Grid container alignItems='center'>
        <Grid item className={classes.ratingBox}>
          <LoadingButton
            label={t('actions.action')}
            onClick={() => setShowActionsDropdown(true)}
            isLoading={actionLoading.length > 1}
          />
          {
            showActionsDropdown &&
            <ClickAwayListener onClickAway={() => setShowActionsDropdown(false)}>
              <List disablePadding className={classes.actionListStyle}>
                <ListItem className={classes.listItem} alignItems="center" onClick={handleBulkAction}>
                  <ListItemText primary={`${t('tags.actions.save_to_existing_tag')} ${t('tags.actions.confirm_added')} (${addedSelection.length}), ${t('tags.actions.removal')} (${removedSelection.length})`}/>
                </ListItem>
                <ListItem className={classes.listItem} alignItems="center" onClick={() => handleBulkAction('new')}>
                  <ListItemText primary={`${t('tags.actions.save_to_new_tag')} (${selectedProducts.length})`}/>
                </ListItem>
              </List>
            </ClickAwayListener>
          }
        </Grid>
      </Grid>
  )

  const saveProductsInTag = (check) => {
    setShowDropdown(false)
    if (check === 'new'){
      setShowModal(true)
    } else {
      const data = { id: params.id, query: searchTerm, sort_products_by: sortOptionSelected, type: 'existing' }
      setLoading(true)
      TagApi.handleSearchedProducts(data).then((res)=>{
        setLoading(false)
        setTagProducts(res.tag_products)
        setSelectedProducts(res.tag_products)
      }).catch(err => {
        setLoading(false)
        console.log(err)
      })
    }
  }

  const removeProductsFromTag = async (productIds = selectedProducts) => {
    try {
      await TagApi.removeTagProducts(params.id, productIds)
      const productIdsToRemove = selectedProducts.filter((id) => !productIds.includes(id))
      setTagProducts(productIdsToRemove)
      setSelectedProducts(productIdsToRemove)
      const _removedCollection = removedSelection.filter(id => !productIds.includes(id))
      setRemovedSelection(_removedCollection)
    } catch (err) {
      console.log(err)
    }
  }

  const addProductsToTag = async (productIds = selectedProducts) => {
    try {
      await TagApi.addTagProducts(params.id, productIds)
      const productIdsToAdd = [...selectedProducts, ...productIds]
      setTagProducts(productIdsToAdd)
      setSelectedProducts(productIdsToAdd)
      const _addedCollection = addedSelection.filter(id => !productIds.includes(id))
      setAddedSelection(_addedCollection)
    } catch (err) {
      console.log(err)
    }
  }

  const handleBulkAction = async (action='existing') => {
    if (action === 'existing'){
      setActionLoading([...addedSelection, ...removedSelection])
      setShowActionsDropdown(false)
      Promise.all([addProductsToTag(addedSelection), removeProductsFromTag(removedSelection)]).then(() => {
        setActionLoading([])
      });
    } else {
      setActionType('bulkAction')
      setShowModal(true)
    }
  }

  const handleAddOrRemoveProducts = async (type, productIds) => {
    setActionLoading(productIds)
    if (type === 'add') {
      await addProductsToTag(productIds)
    } else {
      await removeProductsFromTag(productIds)
    }
    setActionLoading([])
  }

  const tagsTableContent = () => {
    const tagsBody = products?.map((product) => (
      <TableRow key={product.id}>
        <TableCell className={classes.checkboxColumn}>
          <input
            type='checkbox'
            name="visible"
            style={{ cursor: 'pointer' }}
            checked={selectedProducts.indexOf(product.id) !== -1}
            onChange={(event) => {
              handleSelectedProducts(event, product)
            }}/>
        </TableCell>
        <TableCell><ImageThumbnail imageUrl={product.image_url}/></TableCell>
        <TableCell><Link to={`/products/${product.id}`}>{product.name}</Link></TableCell>
        <TableCell align="right">
          <AddRemoveActionButtons
            addFunction={() => {if(window.confirm(t('alertTexts.confirmation'))) handleAddOrRemoveProducts('add', [product.id])}}
            RemoveFunction={() => {if(window.confirm(t('alertTexts.confirmation'))) handleAddOrRemoveProducts('remove', [product.id])}}
            itemCollection={tagProducts}
            item={product.id}
            loading={actionLoading.includes(product.id)}
          />
        </TableCell>
      </TableRow>
    ));
    return { body: tagsBody, heading: [ allCheckBox(), t('tags.tableHeadings.image'), t('tags.tableHeadings.name'), ''] }
  }

  const createNewTag = () => {
    if (newTagName !== ''){
      setBackdrop(true)
      if (actionType === 'bulkAction'){
         const data = { tag: { name: newTagName }, product_ids: selectedProducts}
         TagApi.createTag(data).then(res => {
           history.push(`/tags/${res.id}/edit`)
         }).catch(err =>{
           setBackdrop(false)
           if (err.message && err.message.name){
             setTagNameError(err.message.name)
           } else {
             setShowModal(false)
             console.log(err)
           }
         })
      } else {
        const data = { query: searchTerm, sort_products_by: sortOptionSelected, type: 'new', tag_name: newTagName }
        TagApi.handleSearchedProducts(data).then(res => {
          history.push(`/tags/${res.id}/edit`)
        }).catch(err => {
          setBackdrop(false)
          if (err.message && err.message.name){
            setTagNameError(err.message.name)
          } else {
            console.log(err)
          }
        })
      }
    } else {
      setBackdrop(false)
      setTagNameError(t('tags.error_messages.name_blank'))
    }
  }

  const handleTagNameChange = (event) => {
    setNewTagName(event.target.value)
    if (event.target.value === ''){
      setTagNameError(t('tags.error_messages.name_blank'))
    } else {
      setTagNameError('')
    }
  }

  const tableContent = tagsTableContent()

  return (
    <div style={{ position: 'relative' }}>
      { showModal &&
        <SharedModal onCloseModal={() => setShowModal(false)}>
          <div>
            <NewTagModal
              tagName={newTagName}
              tagNameError={tagNameError}
              handleTagNameChange={handleTagNameChange}
              closeModal={() => setShowModal(false)}
              handleSave={createNewTag}
              backdrop={backdrop}
            />
          </div>
        </SharedModal>
      }
      <TitleBar title={tagName !== '' ? `${t('tags.pageTitles.add_products_to_tag')} ${tagName}` : ''}></TitleBar>
      <Container>
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={ProductSorting}
          searchText={t('products.placeholders.search_products')}
          getData={handleSearchingAndSortingChange}>
            <div className={classes.ratingBox}>
              <Button color="primary" variant='contained' onClick={() => setShowDropdown(!showDropdown)}>{t('actions.save')+ ' ' + productsCount}</Button>
              {
                showDropdown &&
                <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
                  <List disablePadding className={classes.listStyle}>
                    <ListItem className={classes.listItem} alignItems="center" onClick={() => saveProductsInTag('existing')}>
                      <ListItemText primary={t('tags.actions.save_to_existing_tag')}/>
                    </ListItem>
                    <ListItem className={classes.listItem} alignItems="center" onClick={() => saveProductsInTag('new')}>
                      <ListItemText primary={t('tags.actions.save_to_new_tag')}/>
                    </ListItem>
                  </List>
                </ClickAwayListener>
              }
            </div>
        </ListTableActions>
        {loading ? <LoaderList/> :
          <ListTable
            tableHeadings={tableContent.heading}
            tableContent={tableContent.body}
            count={productsCount}
            pageNo={page}
            rowsPerPage={perPage}
            onPageChange={(event, pageNo) => handleSearchingAndSortingChange('page', pageNo + 1)}
            handleChangeRowsPerPage={(event) => handleSearchingAndSortingChange('pageSize', event.target.value)}
            rowsControl={true}
          />
        }
      </Container>
    </div>
  )
}

export default AddTagProducts
