import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FormContainer from './FormContainer'
import { AttributeApi } from '@api/AttributeApi'

const Edit = props => {
  const initialState = { id: props.match.params.id, name: '', type: '', in_article_number: false, parent: { id: '' }, p_attribute_values: [], unit: { id: '' } }
  const [loading, setLoading] = useState(false)
  const [attribute, updateAttribute] = useState(initialState)
  useEffect(() => {
    setLoading(true)
    AttributeApi.getAttribute(attribute.id)
      .then(data => {
        updateAttribute({ ...initialState, ...data })
        setLoading(false)
      })
      .catch(error => setLoading(false))
  },[attribute.id])
  return(
    <FormContainer created={props.location?.state?.created} attribute={attribute} loading={loading} history={props.history}/>
  )
}
Edit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default Edit
