import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { parseErrorMessage } from "@src/utils/sharedHelper";
import PopupAlert from "@contexts/PopupAlert";

import AsyncAutocomplete from "../../common/AsyncAutoComplete";
import HttpClient from "../../HttpClient";
import Mdivider from "../../common/Mdivider";
import LoaderList from "../../common/Loaders/LoaderList";
import useLoadableResource from "@customHooks/useLoadableResource";
import { useTranslation } from "react-i18next";
import { ProductApi } from "@src/apis";

const RelatedProducts = ({ match }) => {
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);

  const {
    data: relatedProducts,
    load,
    setData: setRelatedProducts,
  } = useLoadableResource([], t("products.relatedProducts"));

  const handleRelatedProductAddition = async (product) => {
    try {
      await HttpClient.put(
        `/products/${match.params.id}/related_products/${product.id}`,
        {},
        {},
        true,
      );
      setRelatedProducts([...relatedProducts.data, product], true);
      addPopupAlert(
        `${t("actions.add")} ${t("products.relatedProducts")}`,
        "success",
      );
    } catch (e) {
      addPopupAlert(
        `${t("actions.add")} ${t("products.relatedProducts")}`,
        "error",
        parseErrorMessage(e.message),
      );
    }
  };

  const handleRelatedProductDeletion = async (product) => {
    try {
      if (window.confirm(t("alertTexts.confirmation"))) {
        await HttpClient.delete(
          `/products/${match.params.id}/related_products/${product.id}`,
          {},
          true,
        );
        setRelatedProducts(
          relatedProducts.data?.filter((p) => p.id !== product.id),
          true,
        );
        addPopupAlert(
          `${t("actions.delete")} ${t("products.relatedProducts")}`,
          "success",
        );
      }
    } catch (e) {
      addPopupAlert(
        `${t("actions.delete")} ${t("products.relatedProducts")}`,
        "error",
        parseErrorMessage(e.message),
      );
    }
  };

  useEffect(() => {
    load(ProductApi.getRelatedProducts(match.params.id));
  }, [match.params.id]);

  return (
    <React.Fragment>
      {relatedProducts.loading ? (
        <LoaderList />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t("actions.add")} {t("products.relatedProducts")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <AsyncAutocomplete
                  optionsUrl={`/products/search?search_type=related_products&base_product_id=${match.params.id}&term`}
                  onChange={handleRelatedProductAddition}
                  persistInputOnMenuClose={true}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Mdivider />

          <Grid container spacing={2}>
            {relatedProducts.data?.map((product) => (
              <Grid item xs={12} md={6} lg={4}>
                <Paper
                  key={product.id}
                  sx={{
                    boxSizing: "border-box",
                    padding: 1,
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid xs={2} item>
                      <Avatar
                        alt={product.name}
                        src={product.image_url}
                        variant="rounded"
                        imgProps={{
                          style: {
                            objectFit: "contain",
                          },
                        }}
                      />
                    </Grid>
                    <Grid xs={8} item>
                      <Typography variant="body1">{product.name}</Typography>
                    </Grid>
                    <Grid xs={2} item justifyContent="end" display="flex">
                      <IconButton
                        onClick={() => handleRelatedProductDeletion(product)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};
RelatedProducts.propsTypes = {
  match: PropTypes.object.isRequired,
};

export default RelatedProducts;
