import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  tab: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  table: { width: '100%' },
  heading: {
    marginBlockStart: '0.65rem',
    marginBlockEnd: '0.65rem'
  }
}))

const LoaderList = () => {
  const classes = useStyles()

  return(
    <Paper square className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell><Skeleton className={classes.heading} height={50} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(8)].map((_, index) => (
            <TableRow key={index}>
              <TableCell><Skeleton className={classes.heading} height={30} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default LoaderList
