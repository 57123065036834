import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDebounce } from "@customHooks/useDebouncing";
import UserMenu from './UserMenu'
import { useAuth } from '../../AuthContext'
import AccountCircle from '@mui/icons-material/AccountCircle'

import {
  Menu,
  MenuItem,
  ListItemIcon,
  TextField,
  InputAdornment,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import WidgetsIcon from "@mui/icons-material/Widgets";
import LayersIcon from "@mui/icons-material/Layers";
import SecurityIcon from "@mui/icons-material/Security";
import PrintIcon from "@mui/icons-material/Print";
import InvertColorsOffIcon from "@mui/icons-material/InvertColorsOff";
import PaletteIcon from "@mui/icons-material/Palette";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HomeIcon from "@mui/icons-material/Home";
import RecycleIcon from "@mui/icons-material/Loop";
import FlagIcon from "@mui/icons-material/Flag";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/DashboardTwoTone";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import HttpClient from "@httpClient";
import { makeStyles } from "@mui/styles";
import { adminLink } from '@src/utils'
import Stack from "@mui/material/Stack";
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'

const debouncingTimeOut = 500;

const NavTabs = () => {
  const auth = useAuth()
  const [registerEl, setRegisterEl] = useState(null);
  const [editEl, setEditEl] = useState(null);
  const [economyEl, setEconomyEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState({});
  const searchContainerRef = useRef(null);
  const [userMenuStatus, setUserMenuStatus] = useState(false)
  const [accountEl, setAccountEl] = useState(null)

  const debouncedSearchTerm = useDebounce(searchTerm, debouncingTimeOut);

  const { t } = useTranslation();

  const isRegisterMenuOpen = Boolean(registerEl);
  const isEditMenuOpen = Boolean(editEl);
  const isEconomyMenuOpen = Boolean(economyEl);

  const handleRegisterMenuOpen = (event) => setRegisterEl(event.currentTarget);
  const handleRegisterMenuClose = () => setRegisterEl(null);

  const handleEditMenuOpen = (event) => setEditEl(event.currentTarget);
  const handleEditMenuClose = () => setEditEl(null);

  const handleEconomyMenuOpen = (event) => setEconomyEl(event.currentTarget);
  const handleEconomyMenuClose = () => setEconomyEl(null);

  const handleSearchShowAll = () => {
    window.location = adminLink(`/admin2/search?term=${searchTerm}`);
  };

  const handleSearch = async () => {
    if (searchTerm.replace(/\s/g, "").length > 0) {
      const query = `/searches?term=${searchTerm}`;
      const response = await HttpClient.get(query);
      if (response) {
        setSearchResult(response.data);
      }
    }
  };

  const handleOutsideClick = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setSearchTerm("");
    }
  };

  const renderQuickSearch = () => {
    return (
      <Paper elevation={3} className={classes.searchBox}>
        <Grid container spacing={2}>
          {Object.keys(searchResult).map((keyTitle) => {
            return (
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">{keyTitle.toUpperCase()}</Typography>
                <Divider />
                <List>
                  {searchResult[keyTitle].map((val, index) => (
                    <ListItem key={index}>
                      <a href={`/${keyTitle}/${val.id}`}>
                        <ListItemText primary={val.name} />
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            );
          })}
        </Grid>
        <Typography
          variant="h6"
          style={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button variant="small" onClick={handleSearchShowAll}>
            {t("actions.show_all")}
          </Button>
        </Typography>
      </Paper>
    );
  };

  // To close search box upon clicking outside
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    handleSearch();
  }, [debouncedSearchTerm]);

  const classes = makeStyles(() => ({
    menus: {
      flexGrow: 1,
      "& li": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    menuItem: {
      display: "inline-block",
      "&:hover": {
        color: "white",  // Change text color on hover
        "& svg g polygon, & svg g path": {
          fill: "white", // Change SVG color to white on hover
        },
      },
    },
    listIcon: {
      minWidth: "35px",
    },
    searchInput: {
      background: "#fff",
      borderRadius: "5px",
    },
    searchBox: {
      position: "absolute",
      top: "100%",
      left: "0",
      width: "100%",
      minHeight: "500px",
      padding: "1rem",
    },
  }))();

  return (
    <Stack direction="row" className={classes.menus} ref={searchContainerRef}>
      <MenuItem
        onClick={() => (window.location = process.env.REACT_APP_WEB_BASE_URL)}
        className={classes.menuItem}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 56.7 38.8"
          style={{ enableBackground: "new 0 0 56.7 38.8" }}
          width="40"
          height="25"
        >
          <g>
            <polygon
              points="39.2,0.3 33.7,9.8 50.3,38.6 55.9,29"
              fill="#9d9d9d"
            />
            <polygon
              points="17,0.3 11.5,9.8 28.1,38.6 33.7,29"
              fill="#9d9d9d"
            />
            <path
              d="M6.4,26.1c-3.3,0-6,2.8-6,6.2c0,3.4,2.7,6.2,6,6.2c3.3,0,6-2.8,6-6.2C12.4,28.9,9.7,26.1,6.4,26.1"
              fill="#9d9d9d"
            />
          </g>
        </svg>
      </MenuItem>


      <MenuItem
        onClick={() => (window.location = adminLink("/admin2/dashboard"))}
        className={classes.menuItem}
      >
        <i className="glyphicon glyphicon-dashboard"></i>
      </MenuItem>
      <MenuItem
        onClick={() => (window.location = adminLink("/admin2/offers"))}
        className={classes.menuItem}
      >
        <i className="glyphicon glyphicon-list"></i>
      </MenuItem>
      <MenuItem
        onClick={() => (window.location = adminLink("/admin2/sales"))}
        className={classes.menuItem}
      >
        <i className="glyphicon glyphicon-stats"></i>
      </MenuItem>
      <MenuItem
        onClick={() => (window.location = adminLink("/admin2/customers/my"))}
        className={classes.menuItem}
      >
        <i className="glyphicon glyphicon-group"></i>
      </MenuItem>
      <MenuItem
        onClick={() => (window.location = adminLink("/admin2/email_messages"))}
        className={classes.menuItem}
      >
        <i className="glyphicon glyphicon-envelope"></i>
      </MenuItem>
      <MenuItem onClick={handleRegisterMenuOpen} className={classes.menuItem}>
        <p>Register</p>
      </MenuItem>
      <MenuItem onClick={handleEconomyMenuOpen} className={classes.menuItem}>
        <p>Visa</p>
      </MenuItem>
      <MenuItem onClick={handleEditMenuOpen} className={classes.menuItem}>
        <p>Redigera</p>
      </MenuItem>
      <MenuItem className={classes.menuItem}>
        <TextField
          variant="outlined"
          placeholder="Sök..."
          className={classes.searchInput}
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </MenuItem>

      <Menu
        anchorEl={registerEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        id="register-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isRegisterMenuOpen}
        onClose={handleRegisterMenuClose}
      >
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/customers"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.customers")} {/* Add the corresponding key */}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/suppliers"
          onClick={handleRegisterMenuClose}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.suppliers")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/products"
          onClick={handleRegisterMenuClose}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.products")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/products/hidden"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-eye-close"></i>
          </ListItemIcon>
          {t("TopMenu.hiddenProducts")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/supplier-products"
          onClick={handleRegisterMenuClose}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.supplierProducts")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/categories"
          onClick={handleRegisterMenuClose}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.categories")} {/* Add this key in the translations */}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/attributes"
          onClick={handleRegisterMenuClose}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.attributes")}
        </MenuItem>
        <MenuItem component={Link} to="/tags" onClick={handleRegisterMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.tags")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/tickets"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.improvementReports")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/logistic_carriers"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-plus"></i>
          </ListItemIcon>
          {t("TopMenu.logisticCarriers")}
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={economyEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        id="economy-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isEconomyMenuOpen}
        onClose={handleEconomyMenuClose}
      >
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/delegation"))}
        >
          {t("TopMenu.queueManagement")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/invoices"))}
        >
          {t("TopMenu.invoices")}
        </MenuItem>
        <MenuItem
          onClick={() =>
            (window.location = adminLink("/admin2/invoice_followups"))
          }
        >
          {t("TopMenu.invoiceFollowUp")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/feedbacks"))}
        >
          {t("TopMenu.feedback")}
        </MenuItem>
        <MenuItem
          onClick={() =>
            (window.location = adminLink("/admin2/supplier_orders"))
          }
        >
          {t("TopMenu.supplierOrders")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/offers"))}
        >
          {t("TopMenu.offers")}
        </MenuItem>
        <MenuItem
          onClick={() =>
            (window.location = adminLink("/admin2/sales/unordered?mode=ready"))
          }
        >
          {t("TopMenu.unorderedSales")}
        </MenuItem>
        <MenuItem
          onClick={() =>
            (window.location = adminLink("/admin2/invoices/confirmations"))
          }
        >
          {t("TopMenu.orderConfirmations")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/performances"))}
        >
          {t("TopMenu.sellerStatistics")}
        </MenuItem>
        <MenuItem
          onClick={() =>
            (window.location = adminLink("/admin2/suppliers/reclaims"))
          }
        >
          {t("TopMenu.claimStatistics")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/customers/my"))}
        >
          {t("TopMenu.myCustomers")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/supplier-integration-job-stats"
          onClick={handleRegisterMenuClose}
        >
          {t("supplierIntegrationJobStats.plural")}
        </MenuItem>
      </Menu>


      <Menu
        anchorEl={editEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        id="edit-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isEditMenuOpen}
        onClose={handleEditMenuClose}
      >
        <MenuItem component={Link} to="/colors" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-tint"></i>
          </ListItemIcon>
          {t("TopMenu.colors")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/assets"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-folder-close"></i>
          </ListItemIcon>
          {t("TopMenu.fileArchive")}
        </MenuItem>
        <hr />
        <MenuItem component={Link} to="/starting_costs" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-tags"></i>
          </ListItemIcon>
          {t("TopMenu.startingCosts")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/price_lists"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-th-list"></i>
          </ListItemIcon>
          {t("TopMenu.priceLists")}
        </MenuItem>
        <MenuItem component={Link} to="/marking_templates" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-eye-close"></i>
          </ListItemIcon>
          {t("TopMenu.markings")}
        </MenuItem>
        <MenuItem component={Link} to="/brands" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-shield"></i>
          </ListItemIcon>
          {t("TopMenu.brands")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/certifications"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-globe"></i>
          </ListItemIcon>
          {t("TopMenu.certifications")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/awards"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-cup"></i>
          </ListItemIcon>
          {t("TopMenu.awards")}
        </MenuItem>
        <MenuItem component={Link} to="/marking_methods" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-print"></i>
          </ListItemIcon>
          {t("TopMenu.printMethods")}
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/internal_announcements"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-bullhorn"></i>
          </ListItemIcon>
          {t("TopMenu.announcements")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/email_templates"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-envelope"></i>
          </ListItemIcon>
          {t("TopMenu.emailTemplates")}
        </MenuItem>
        <MenuItem component={Link} to="/questions" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <QuestionAnswerIcon />
          </ListItemIcon>
          {t("TopMenu.questionsAnswers")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/supplier_order_templates"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-envelope"></i>
          </ListItemIcon>
          {t("TopMenu.orderTemplates")}
        </MenuItem>
        <hr />
        <MenuItem component={Link} to="/homepage" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-home"></i>
          </ListItemIcon>
          {t("TopMenu.homepage")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/posts"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-book"></i>
          </ListItemIcon>
          {t("TopMenu.blog")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/pages"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-book"></i>
          </ListItemIcon>
          {t("TopMenu.pages")}
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/slack_bots"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-hash"></i>
          </ListItemIcon>
          {t("TopMenu.slackBots")}
        </MenuItem>
        <MenuItem component={Link} to="/currencies" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-euro"></i>
          </ListItemIcon>
          {t("TopMenu.currencies")}
        </MenuItem>
        <MenuItem component={Link} to="/units" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-adjust"></i>
          </ListItemIcon>
          {t("TopMenu.units")}
        </MenuItem>
        <MenuItem component={Link} to="/standards" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-refresh"></i>
          </ListItemIcon>
          {t("TopMenu.standards")}
        </MenuItem>
        <MenuItem component={Link} to="/countries" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-flag"></i>
          </ListItemIcon>
          {t("TopMenu.countries")}
        </MenuItem>
        <MenuItem component={Link} to="/campaigns" onClick={handleEditMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <SecurityIcon />
          </ListItemIcon>
          {t("TopMenu.campaigns")}
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/rating_questions"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-thumbs-up"></i>
          </ListItemIcon>
          {t("TopMenu.feedbackQuestions")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/sales_targets"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-stats"></i>
          </ListItemIcon>
          {t("TopMenu.salesTargets")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location = adminLink("/admin2/users"))}
        >
          <ListItemIcon className={classes.listIcon}>
            <i className="glyphicon glyphicon-user"></i>
          </ListItemIcon>
          {t("TopMenu.users")}
        </MenuItem>
      </Menu>

      {searchTerm.replace(/\s/g, "").length > 0 && renderQuickSearch()}
      <IconButton
                aria-label="account current user"
                color="inherit"
                onClick={ event => {
                  setUserMenuStatus(!userMenuStatus)
                  setAccountEl(event.currentTarget)
                }}
                style={{position: 'absolute', right: 0}}
              >
                {auth.user.mugshot_image_url ?
                  <>
                    <Avatar
                      style={{ backgroundColor: '#9d9d9d', position: 'relative' }}
                      alt={auth.user.name}
                      src={auth.user.mugshot_image_url} />
                  </>

                  :
                  <AccountCircle style={{ backgroundColor: '#9d9d9d' }} />
                }
        </IconButton>
        <UserMenu
          open={userMenuStatus}
          parentEl={accountEl}
          onClose={setUserMenuStatus}/>
    </Stack>
  );
};

NavTabs.propTypes = { location: PropTypes.object.isRequired };

export default withRouter(NavTabs);

// <Tabs value={`/${props.location.pathname.split('/')[1]}`}>
//   <Tab label="Produkter" value="/products" component={Link} to="/products" />
//   <Tab label="Kategorier" value="/categories" component={Link} to="/categories" />
//   <Tab label="Attribut" value="/attributes" component={Link} to="/attributes" />
//   <Tab label="Leverantörer" value="/suppliers" component={Link} to="/suppliers" />
//   <Tab label="Färger" value="/colors" component={Link} to="/colors" />
//   <Tab label="Märken" value="/brands" component={Link} to="/brands" />
//   <Tab label="Tryckmetoder" value="/marking_methods" />
//   <Tab label="Startkostnader" value="/starting_costs" />
//   <Tab label="Valutor" value="/currencies" />
//   <Tab label="Märkningar" value="/marking_templates" />
// </Tabs>
