import React from 'react'
import PropTypes from 'prop-types'

import { Typography, FormControl, FormControlLabel } from '@mui/material'
import { Grid, TextField, Checkbox, Button, RadioGroup, Radio } from '@mui/material'

import AsyncAutocomplete from '@common/AsyncAutoComplete'
import Mdivider from '@common/Mdivider'

import TextEditor from '@helpers/RichTextEditor/TextEditor'
import CampaignCard from '@components/campaigns/CampaignCard'
import SelectedList from '@common/SelectedList'
import UploadImageWidget from '@shared/UploadImageWidget'

const Form = ({
  campaign,
  handleChange,
  onSubmit,
  handleAssociationChange,
  selectedTags,
  selectedProducts,
  selectedCategories,
  removeImage,
  validationErrors
}) => {

  const handleTextEditorChange = (name, text) => {
    handleChange({ target: { type: 'text', value: text, name: name } })
  }

  return (
    <Grid container>
      <Grid container item xs={5}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={campaign.name || ''}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={campaign.description || ''}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextEditor
            label="Content"
            value={campaign.content || ''}
            onChange={content =>handleTextEditorChange('content', content)}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '15px' }}>
          <TextField
            fullWidth
            label="Url"
            name="url"
            value={campaign.url || ''}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            error={!!validationErrors.url}
            helperText={validationErrors.url}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth style={{ marginTop: '15px' }}>
            <RadioGroup
              aria-label="layout"
              name="layout"
              value={campaign.layout || ''}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="homepage"
                control={<Radio/>}
                label="Homepage"
              />
              <FormControlLabel
                value="banner"
                control={<Radio/>}
                label="Banner"
              />
              <FormControlLabel
                value="grid"
                control={<Radio/>}
                label="Grid"
              />
            </RadioGroup>
            {validationErrors.layout && <FormHelperText>{validationErrors.layout}</FormHelperText>}
          </FormControl>
        </Grid>
        {campaign.layout === 'homepage' && (
          <Grid item container xs={12} alignItems="center">
            <Grid item>
              <Typography variant="body1">Fixed</Typography>
            </Grid>
            <Grid item>
              <Checkbox
                name="fixed"
                checked={String(campaign.fixed) === String(true)}
                onChange={handleChange}
                value={campaign.fixed}
              />
            </Grid>
          </Grid>
        )}
        {campaign.layout === 'banner' && (
          <Grid item xs={12} style={{ marginBottom: '15px' }}>
            <TextEditor
              label="Label"
              value={campaign.label || ''}
              onChange={label => handleTextEditorChange('label', label)}
            />
          </Grid>
        )}
        <Grid item container xs={12} alignItems="center" style={{ marginBottom: '15px' }}>
          <UploadImageWidget
            onChange={handleChange}
            object={campaign}
            type={'image'}
            accept={'image/*'}
            field={'image'}
            deleteImage={removeImage}
            error={!!validationErrors.image} // Pass error state
            helperText={validationErrors.image} // Pass helper text for error
          />
        </Grid>
        {validationErrors.image && <Typography color="error">{validationErrors.image}</Typography>}
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Duration
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '15px' }}>
          <Typography variant="subtitle2" color="textSecondary" style={{ fontSize: 'x-small' }}>
            Time duration for which campaign will be active. This is optional section.
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            id="start-date"
            type="date"
            label="Start"
            name="start_date"
            value={campaign.start_date}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}/>
        <Grid item xs={5}>
          <TextField
            id="end-date"
            name="end_date"
            type="date"
            label="End"
            value={campaign.end_date || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item container xs={12} alignItems="center" style={{ marginBottom: '15px' }}>
          <Grid item>
            <Typography variant="body1">Visible</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="visible"
              checked={String(campaign.visible) === String(true)}
              onChange={handleChange}
              value={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={1}/>
      <Grid container item xs={6}><CampaignCard campaign={campaign}/></Grid>
      <Mdivider/>
      {campaign.layout === 'grid' && (
        <Grid container item xs={12} style={{ marginBottom: '60px' }}>
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="h6">Connect to Products</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <AsyncAutocomplete
                defaultInputValue=""
                optionsUrl="/products/search?term"
                label="Products"
                onChange={product => handleAssociationChange('product', product, 'add')}
              />
            </Grid>
          </Grid>
          {selectedProducts && <SelectedList list={selectedProducts}
                                             handleAction={productId => handleAssociationChange('product', productId, 'remove')}
                                             type={'product'}/>}
          <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="h6">Connect to Categories</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <AsyncAutocomplete
                defaultInputValue=""
                optionsUrl="/categories/search?query"
                label="Categories"
                onChange={category => handleAssociationChange('category', category, 'add')}
              />
            </Grid>
          </Grid>
          {selectedCategories &&
            <SelectedList
              list={selectedCategories}
              handleAction={categoryId => handleAssociationChange('category', categoryId, 'remove')}
              type={'category'}/>
          }
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="h6">Connect to Tags</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <AsyncAutocomplete
                defaultInputValue=""
                optionsUrl="/tags/search?query"
                label="Tags"
                onChange={tag => handleAssociationChange('tag', tag, 'add')}
              />
            </Grid>
          </Grid>
          {selectedTags &&
            <SelectedList list={selectedTags} handleAction={tagId => handleAssociationChange('tag', tagId, 'remove')}
                          type={'tag'}/>}
        </Grid>
      )}
      <Grid item xs={12} align="right">
        <Button variant="contained" onClick={onSubmit}>
          Spara
        </Button>
      </Grid>
    </Grid>
  )
}

Form.propTypes = {
  campaign: PropTypes.object.isRequired,
  selectedProducts: PropTypes.array,
  selectedCategories: PropTypes.array,
  selectedTags: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  handleAssociationChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object // PropType for errors
}

export default Form
