import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import HttpClient from './components/HttpClient'

const jwt = require('jsonwebtoken')
const AuthContext = createContext([{},() => {}])

export const useAuth = () => useContext(AuthContext)

const refreshToken = () => {
  HttpClient.get('/authentications/refresh')
    .then(data => {
      localStorage.setItem('authToken', data.token)
    })
    .catch(error => console.error('Error refreshing Auth token', error))
}

const getUser = auth => {
  let user = {
    expired: () => true,
    valid: () => false
  }

  if( auth && auth.data ) {
    user = auth && auth.data
    user.exp = auth.exp
    user.expired = () => auth.exp && auth.exp <= Date.now()
    user.valid = () => {
      const remainingTime = auth.exp - Date.now()
      // if it will expire in less than 5 minutes
      // ** Disabled as we're currently athenticating through medtryck
      // if (remainingTime < 300000) {
      //   refreshToken()
      // }
      return auth.exp && auth.exp > Date.now()
    }
  }
  return user
}

const AuthContextProvider = ({ children }) => {
  const qs = queryString.parse(window.location.search)
  let authToken = ''
  let user = {}
  let auth
  try {
    authToken = localStorage.getItem('authToken')
  } catch (error) {
    console.error(error)
  }
  if(qs.token !== undefined) {
    authToken = qs.token
  }

  try {
    auth = jwt.decode(authToken)
    user = getUser(auth)
    localStorage.setItem('authToken', authToken)
  } catch (error) {
    console.error('Error decoding JWT Auth token', error)
  }


  const [authUser, setAuthUser] = useState(user)

  const setAuth = token => {
    localStorage.setItem('authToken', token)
    auth = jwt.decode(token)
    setAuthUser(getUser(token))
  }

  return (
    <AuthContext.Provider value={{ user: authUser, setAuth }}>
      {children}
    </AuthContext.Provider>
  )

}

AuthContextProvider.propTypes = {
  children: PropTypes.node
}

export { AuthContextProvider }
