// Create a Http Client using Fetch API
let controller;
let setBackdropLoaderMethod = null;
const authToken = () => localStorage.getItem("authToken");
const baseUrl = process.env["REACT_APP_API_BASE_URL"];

const setLoading = (showLoader) => {
  if (setBackdropLoaderMethod && showLoader) setBackdropLoaderMethod(true);
};
const unsetLoading = () => {
  if (setBackdropLoaderMethod) setBackdropLoaderMethod(false);
};

const apiUrl = (url) => {
  return url.startsWith("http") ? url : baseUrl + url;
};

const getControllerSignal = () => {
  controller = new AbortController();
  return controller.signal;
};

const fetchError = (response) => {
  unsetLoading();
  if (!response.ok) {
    if (response.status === 401) {
      // TODO-- redirect properly
      // If it is login page then let it handle the error
      if (response.url.indexOf("/auth/authenticate") < 0) {
        window.location.href = "/login";
      }
      throw Error(response);
    } else {
      return response.json().then((error) => {
        // validation_errors is a key that will contain hash of attributes and errors
        throw { status: response.status, message: error.errors, validation_errors: error };
      });
    }
  }
  return response;
};

const handleErrors = (err) => {
  unsetLoading();
  if (err.name === "AbortError") {
    console.log("Fetch aborted");
  } else {
    console.error("An error!", err);
    throw err;
  }
};

const post = (url = "", data = "", headers = {}, showLoader = false) => {
  headers["Authorization"] = `Bearer ${authToken()}`;
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
    data = JSON.stringify(data);
  }
  setLoading(showLoader);
  return fetch(apiUrl(url), {
    method: "POST",
    body: data,
    headers: headers,
  })
    .then(fetchError)
    .then((res) => {
      unsetLoading();
      return res.json();
    })
    .catch(handleErrors);
};

const get = (url, headers = {}, showLoader = false) => {
  headers["Authorization"] = `Bearer ${authToken()}`;
  setLoading(showLoader);
  return fetch(apiUrl(url), {
    headers: headers,
    signal: getControllerSignal(),
  })
    .then(fetchError)
    .then((res) => {
      unsetLoading();
      return res.json();
    })
    .catch(handleErrors);
};

const getDownload = async (url, filename, headers = {}, showLoader = false) => {
  headers["Authorization"] = `Bearer ${authToken()}`;
  setLoading(showLoader);
  return fetch(apiUrl(url), {
    headers: headers,
    signal: getControllerSignal(),
  })
    .then(fetchError)
    .then((res) => {
      unsetLoading();

      const contentType = res.headers.get("content-type");

      if (contentType.includes("application/json")) {
        return res.json();
      } else {
        res.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        });
      }
    })
    .catch(handleErrors);
};

const put = (url = "", data = "", headers = {}, showLoader = false) => {
  headers["Authorization"] = `Bearer ${authToken()}`;
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
    data = JSON.stringify(data);
  }
  setLoading(showLoader);
  return fetch(apiUrl(url), {
    method: "PUT",
    body: data,
    headers: headers,
  })
    .then(fetchError)
    .then((res) => {
      unsetLoading();
      return res.json();
    })
    .catch(handleErrors);
};

const patch = (url = "", data = "", headers = {}, showLoader = false) => {
  headers["Authorization"] = `Bearer ${authToken()}`;
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
    data = JSON.stringify(data);
  }
  setLoading(showLoader);
  return fetch(apiUrl(url), {
    method: "PATCH",
    body: data,
    headers: headers,
  })
    .then(fetchError)
    .then((res) => {
      unsetLoading();
      return res.json();
    })
    .catch(handleErrors);
};

//Cannot contain a delete method - Cause delete is a keyword.
const del = (url = "", headers = {}, showLoader = false) => {
  headers["Authorization"] = `Bearer ${authToken()}`;
  setLoading(showLoader);
  return fetch(apiUrl(url), {
    method: "DELETE",
    headers: headers,
  })
    .then(fetchError)
    .then((res) => {
      unsetLoading();
      return res.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      });
    })
    .catch(handleErrors);
};

//Encapsulating in a JSON object

const HttpClient = {
  post,
  get,
  put,
  patch,
  delete: del,
  abort: () => controller.abort(),
  setBackdropLoader: (setBackdropLoader) => {
    setBackdropLoaderMethod = setBackdropLoader;
  },
  getDownload,
};

export default HttpClient;
