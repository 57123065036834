import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TextField, Button, Grid, Box, CardHeader, Card, IconButton, CardContent, CardActions, Typography, Chip } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import HttpClient from '@httpClient'
import Mdivider from '@common/Mdivider'
import AttributesList from './AttributesList'
import PriceListRow from './PriceListRow'
import QuantityPrice from './QuantityPrice'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
    border: '1px solid #e0e0e0'
  },
}))

const PriceList = (props) => {
  const [priceList, setPriceList] = useState(props.priceList)
  const [isLabelEditing, setIsLabelEditing] = useState(false)

  const { t } = useTranslation()
  const classes = useStyles()

  const setPriceListRow = (priceListRow) => {
    let _priceListRows = [...priceList.price_list_rows]
    const index = _priceListRows.findIndex((plr) => {
      if (priceListRow.hasOwnProperty('id')) {
        return plr.id === priceListRow.id
      }
      return plr.kid === priceListRow.kid
    })
    if (index !== -1) {
      _priceListRows[index] = priceListRow
      setPriceList({ ...priceList, price_list_rows: _priceListRows })
    }
  }

  const setQuantityPrice = (quantityPrice) => {
    let _quantityPrices = [...priceList.quantity_prices]
    const index = _quantityPrices.findIndex((qp) => {
      if (quantityPrice.hasOwnProperty('id')) {
        return qp.id === quantityPrice.id
      }
      return qp.kid === quantityPrice.kid
    })
    if (index !== -1) {
      _quantityPrices[index] = quantityPrice
      setPriceList({ ...priceList, quantity_prices: _quantityPrices })
    }
  }

  const addPriceListRow = () => {
    let _priceListRows = [...priceList.price_list_rows]
    const nextKey =
      Math.max(
        ..._priceListRows.map((plr) =>
          plr.hasOwnProperty('kid') ? plr.kid : 0
        ),
        0
      ) + 1
    let newPriceListRow = {
      kid: nextKey,
      prices: [],
      supplier: { id: props.suppliers[0].id },
    }
    newPriceListRow.prices = priceList.quantity_prices.map((qp, index) => {
      return { kid: index + 1, value: '', quantity: { id: qp.quantity.id } }
    })
    _priceListRows.push(newPriceListRow)
    setPriceList({ ...priceList, price_list_rows: _priceListRows })
  }

  const addValue = (val) => {
    let _values = [...priceList.p_attribute_values]
    let newValue = _values.find(value => value.id === val.id);
    if (!newValue){
      _values.push(val)
    }
    setPriceList({ ...priceList, p_attribute_values: _values })
  }

  const removeValue = (valId) => {
    // Update state immediately in both cases
    setPriceList({
      ...priceList,
      p_attribute_values: priceList.p_attribute_values.filter(
        (v) => v.id !== valId
      ),
    });

    // Only send the request if priceList.id exists
    if (priceList.id) {
      HttpClient.post(
        `/products/${props.productId}/price_lists/${priceList.id}/remove_p_attribute_values`,
        { p_attr_val_id: valId }
      ).catch((error) => console.error("Error removing product badge: ", error));
    }
  };

  const savePriceList = () => {
    if (priceList.id) {
      HttpClient.put(
        `/products/${props.productId}/price_lists/${priceList.id}`,
        { price_list: priceList },
        {},
        true
      )
        .then((response) => {
          setPriceList({ ...priceList, ...response })
        })
        .catch((error) =>
          console.error(
            `Error getting product(${props.productId}) price list: `,
            error
          )
        )
    } else {
      HttpClient.post(
        `/products/${props.productId}/price_lists`,
        {
          price_list: priceList,
        },
        {},
        true
      )
        .then((response) => setPriceList({ ...priceList, ...response }))
        .catch((error) =>
          console.error(
            `Error getting product(${props.productId}) price lists: `,
            error
          )
        )
    }
  }

  const attributesHtml = props.showAttributes ? (
    <div>
      <AttributesList
        attributes={props.productAttributes}
        values={[...priceList.p_attribute_values, ...(priceList.colors || [])]}
        addValue={addValue}
        removeValue={removeValue}
      />
    </div>
  ) : null

  return (
    <Card sx={{marginTop: '20px'}}>
      <CardHeader
        title={
        <>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item xs={3}>
              {
                priceList.kid || isLabelEditing ?
                    <TextField
                      disabled={props.supplierProductsView}
                      fullWidth
                      label="Label"
                      value={priceList.label || ''}
                      onChange={(e) => setPriceList({ ...priceList, label: e.target.value })}
                      onBlur={() => setIsLabelEditing(false)}
                      margin="dense"
                      variant="outlined"
                    />
                  :
                  <Box>
                    { priceList.label || '' }
                    <IconButton
                      color="primary"
                      aria-label="edit"
                      style={{
                        backgroundColor: "lightblue",
                        borderRadius: "50%",
                        width: "28px",
                        height: "28px",
                        marginLeft: '15px'
                      }}
                      onClick={() => setIsLabelEditing(true)}
                      disabled={props.supplierProductsView}
                    >
                      <Edit/>
                    </IconButton>
                  </Box>
              }
            </Grid>
            <Grid container item xs={5}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  {attributesHtml}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  {[...priceList.p_attribute_values, ...(priceList.colors || [])].map(v =>
                    <Chip
                      key={v.id}
                      label={v.name || `${v.value}${(v.p_attribute && v.p_attribute.unit) ? v.p_attribute.unit.symbol : ''}`}
                      onDelete={() => removeValue(v.id)}
                      style={{ background: `${v.code ? v.code : ''}` }}
                      className={classes.chip}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>}
        action={<Button
          disabled={props.supplierProductsView}
          variant={'contained'}
          color="primary"
          onClick={savePriceList}
        >
          Spara
        </Button>
        }
      />
      <Mdivider/>
      <CardContent>
        {priceList.price_list_rows.map((plr) => {
            return (
              <PriceListRow
                key={plr.kid || plr.id}
                priceListRow={plr}
                onUpdatePriceListRow={setPriceListRow}
                quantities={props.quantities}
                supplierProductsView={props.supplierProductsView}
                quantityPrices={priceList.quantity_prices}
                suppliers={props.suppliers}
              />
          )
        }
        )}
        <Button disabled={props.supplierProductsView} variant={'outlined'} onClick={addPriceListRow} sx={{marginTop: '20px'}}>
          Add Supplier
        </Button>
        <Mdivider/>
        <Grid container>
          <Grid item xs={2}>
            <Box display="flex" mt={2} justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
              <Typography variant="h5" gutterBottom>
                Out Pricing
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box display="flex">
              {props.quantities
                .filter((q) => q.hasOwnProperty('id'))
                .map((q) => {
                  const quantityPrice = priceList.quantity_prices.find(
                    (qp) => qp.quantity?.id === q.id
                  )
                  return quantityPrice ? (
                    <QuantityPrice
                      key={quantityPrice.kid || quantityPrice.id}
                      supplierProductsView={props.supplierProductsView}
                      quantityPrice={quantityPrice}
                      setQuantityPrice={setQuantityPrice}
                    />
                  ) : null
                })}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Mdivider/>
      <CardActions sx={{float: 'right'}}>
        <Button
          disabled={props.supplierProductsView}
          variant={'contained'}
          color="primary"
          onClick={savePriceList}
        >
          Spara
        </Button>
      </CardActions>
    </Card>
  )
}

PriceList.propTypes = {
  priceList: PropTypes.object.isRequired,
  quantities: PropTypes.array,
  suppliers: PropTypes.array.isRequired,
  showAttributes: PropTypes.bool,
}

export default PriceList
