import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Grid, Typography, Button, IconButton, TextField, FormControl, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CreatableAsyncAutocomplete from "@common/CreatableAsyncAutoComplete";
import HttpClient from "@components/HttpClient";
import EditPrintOptionModal from "@components/products/printing/EditPrintOptionModal";
import PrintOptionChip from "@components/products/printing/PrintOptionChip";
import InlineLoader from "@components/common/Loaders/InlineLoader";

import PopupAlert from "@contexts/PopupAlert";
import ProductResourceContext from "@contexts/ProductResource";

import { parseErrorMessage, singularize } from "@src/utils/sharedHelper";
import { PrintOptionApi } from "@src/apis/PrintOptionApi";
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';

const PrintOptions = ({ markingTemplate, productId, heading }) => {
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);
  const productResource = useContext(ProductResourceContext);
  const isSupplierProduct = productResource === "supplier_products";

  const [printOptions, setPrintOptions] = useState({
    loaded: false,
    loading: false,
    data: [],
  });

  const [creationMode, setCreationMode] = useState(false);
  const [printOptionActionLoading, setPrintOptionActionLoading] =
    useState(false);
  const [editPrintOptionModalInfo, setEditPrintOptionModalInfo] = useState({
    open: false,
    printOption: null,
  });

  const createPrintingOption = (values) => {
    setPrintOptionActionLoading(true);
    PrintOptionApi.create(
      productId,
      {
        product_type: singularize(productResource),
        print_option: {
          name: values.name,
        },
      },
      productResource,
    )
      .then((res) => {
        setPrintOptionActionLoading(false);
        setPrintOptions({
          ...printOptions,
          data: [...printOptions.data, { ...res, new: true }],
        });
        formik.resetForm();
      })
      .catch((error) => {
        setPrintOptionActionLoading(false);
        addPopupAlert(
          t("printOptions.errors.printOptionCreation"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const formik = useFormik({
    initialValues: { name: "" },
    onSubmit: createPrintingOption,
    validate: (values) => {
      const errors = {};
      if (!values.name)
        errors.name = [t("printOptions.formValidation.name.presence")];
      return errors;
    },
  });
  const { values, errors, handleChange } = formik;

  const markingTemplateId = () =>
    !!markingTemplate?.id ? markingTemplate.id : "";

  const loadPrintOptions = () => {
    setPrintOptions({ loaded: false, loading: true, data: [] });
    PrintOptionApi.index(productId, markingTemplateId(), productResource).then(
      (res) => {
        setPrintOptions({ loaded: true, loading: false, data: res.data });
      },
    );
  };

  useEffect(loadPrintOptions, []);

  const addPrintOptionToMarkingTemplate = (printOption) => {
    setPrintOptionActionLoading(true);
    setCreationMode(false);
    HttpClient.post(`/marking_templates/${markingTemplate.id}/print_options`, {
      print_option_id: printOption.id,
    })
      .then((res) => {
        setPrintOptionActionLoading(false);
        setPrintOptions({
          ...printOptions,
          data: [...printOptions.data, { ...printOption, new: true }],
        });
      })
      .catch((error) => {
        setPrintOptionActionLoading(false);
        addPopupAlert(
          t("printOptions.errors.additionOfPrintOptionToMarkingTemplate"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const deletePrintOption = (printOption) => {
    setPrintOptionActionLoading(true);
    PrintOptionApi.deletePrintOption(
      printOption.id,
      productId,
      markingTemplateId(),
      productResource,
    )
      .then((res) => {
        setPrintOptionActionLoading(false);
        setPrintOptions({
          ...printOptions,
          data: printOptions.data.filter((po) => po.id !== printOption.id),
        });
      })
      .catch((error) => {
        setPrintOptionActionLoading(false);
        addPopupAlert(
          t("printOptions.errors.deletePrintOption"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  return (
    <Paper sx={{ padding: '20px', marginTop: '20px', width: '100%'}} elevation={3}>
      <Grid container alignItems="center">
        {heading ?? (
          <Typography variant="subtitle1" element="h5">
            {t("printOptions.name")}
          </Typography>
        )}

        {creationMode && !!markingTemplateId() && (
          <Grid item sx={{ ml: 1 }} xs={4}>
            <CreatableAsyncAutocomplete
              optionsUrl={`/${productResource}/${productId}/print_options?product_type=${singularize(
                productResource,
              )}&exclude[marking_template_id]=${markingTemplateId()}&q`}
              onChange={addPrintOptionToMarkingTemplate}
              onCreateOption={addPrintOptionToMarkingTemplate}
              createUrl={`/${productResource}/${productId}/print_options?product_type=${singularize(
                productResource,
              )}`}
              resource="print_option"
              onError={(error) => {
                addPopupAlert(
                  t("printOptions.errors.printOptionCreation"),
                  "error",
                  parseErrorMessage(error.message),
                );
              }}
            />
          </Grid>
        )}

        {creationMode && !markingTemplateId() && (
          <Grid item sx={{ ml: 1 }} xs={4}>
            <FormControl fullWidth variant="outlined">
              <TextField
                fullWidth
                label={t("printOptions.attributes.name")}
                value={values.name}
                error={errors.name}
                onChange={handleChange}
                name="name"
                variant="outlined"
                size="small"
              />
            </FormControl>
          </Grid>
        )}

        <Grid item sx={{ ml: 1 }}>
          {creationMode && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={() =>
                !!markingTemplateId()
                  ? setCreationMode(false)
                  : formik.submitForm()
              }
              disabled={printOptionActionLoading || isSupplierProduct}
            >
              {t("actions.save")}
            </Button>
          )}
          {!creationMode && (
            <IconButton
              color="primary"
              aria-label="add"
              style={{
                backgroundColor: "lightblue",
                borderRadius: "50%",
                width: "28px",
                height: "28px",
              }}
              onClick={() => {
                setCreationMode(true);
              }}
              disabled={isSupplierProduct}
            >
              <AddIcon />
            </IconButton>
          )}
        </Grid>

        {printOptionActionLoading && (
          <Grid item sx={{ ml: 1 }}>
            <InlineLoader />
          </Grid>
        )}
      </Grid>

      {printOptions.loading && (
        <Grid container sx={{ mt: 1.5 }}>
          <InlineLoader />
        </Grid>
      )}

      {printOptions.loaded && !printOptions.data.length && (
        <Grid container sx={{ mt: 1.5 }}>
          <Typography
            variant="body2"
            paragraph={true}
            align="center"
            color="textSecondary"
            fontStyle="italic"
          >
            {t("printOptions.feedback.noPrintOptions")}
          </Typography>
        </Grid>
      )}

      {printOptions.loaded && !!printOptions.data.length && (
        <Grid container spacing={1} sx={{ mt: 0.3 }}>
          {printOptions.data.map((printOption) => (
            <PrintOptionChip
              key={printOption.id}
              printOption={printOption}
              onDelete={() => deletePrintOption(printOption)}
              onClick={() =>
                setEditPrintOptionModalInfo({
                  open: true,
                  printOption: printOption,
                })
              }
            />
          ))}
        </Grid>
      )}

      <EditPrintOptionModal
        open={editPrintOptionModalInfo.open}
        printOption={editPrintOptionModalInfo.printOption}
        productId={productId}
        onEdit={(updatedPrintOption) => {
          const index = printOptions.data.findIndex(
            (po) => po.id === updatedPrintOption.id,
          );
          const newPrintOptions = printOptions.data
            .slice(0, index)
            .concat([updatedPrintOption], printOptions.data.slice(index + 1));

          setPrintOptions({
            ...printOptions,
            data: newPrintOptions,
          });
          setEditPrintOptionModalInfo({ open: false });
        }}
        addImage={(printOption, newImage) => {
          const index = printOptions.data.findIndex(
            (po) => po.id === printOption.id,
          );
          const newPrintOptions = [...printOptions.data];
          newPrintOptions[index] = {
            ...newPrintOptions[index],
            images: [...newPrintOptions[index].images, newImage],
          };
          setPrintOptions({
            ...printOptions,
            data: newPrintOptions,
          });
          setEditPrintOptionModalInfo({
            ...editPrintOptionModalInfo,
            printOption: newPrintOptions[index],
          });
        }}
        deleteImage={(printOption, uid) => {
          const index = printOptions.data.findIndex(
            (po) => po.id === printOption.id,
          );
          const newPrintOptions = [...printOptions.data];
          newPrintOptions[index] = {
            ...newPrintOptions[index],
            images: newPrintOptions[index].images.filter((i) => i.uid !== uid),
          };
          setPrintOptions({
            ...printOptions,
            data: newPrintOptions,
          });
          setEditPrintOptionModalInfo({
            ...editPrintOptionModalInfo,
            printOption: newPrintOptions[index],
          });
        }}
        onClose={() => setEditPrintOptionModalInfo({ open: false })}
      />
    </Paper>
  );
};

PrintOptions.propTypes = {
  markingTemplate: PropTypes.object,
  productId: PropTypes.string.isRequired,
};

export default PrintOptions;
