import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";

import { InputLabel, Select, Grid, Backdrop, FormControl, FormControlLabel, TextField } from '@mui/material'
import { OutlinedInput, Switch, Button, Alert } from '@mui/material'

import useAlert from '@customHooks/useAlert'

import Mdivider from '@common/Mdivider'
import TextEditor from '@helpers/RichTextEditor/TextEditor'
import { CategoryApi } from '@api/CategoryApi'
import UploadImageWidget from '@shared/UploadImageWidget'
import InlineLoader from '@common/Loaders/InlineLoader'
import PopupAlert from "@contexts/PopupAlert";
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';
import SelectInput from '@common/SelectInput'

const Form = props => {
  const { t } = useTranslation();
  const [category, setCategory] = useState(props.category)
  const [categories, setCategories] = useState([])
  const [alert, setAlert] = useAlert(props.created)
  const [backdrop, setBackdrop] = useState(false)
  const [permalinkError, setPermalinkError] = useState('');

  useEffect(() => {
    const _category = { ...props.category }
    setCategory(_category)
  },[props.category])

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: category.name, rules: { required: true } },
    permalink: { value: category.permalink, rules: { required: true } },
  });

  useEffect(() => {
    CategoryApi.getParentOptions()
      .then(res => setCategories(res.filter(c => c.id !== category.id)))
  }, [category.id])

  const parentLabel = useRef(null)
  const [parentLabelWidth, setParentLabelWidth] = useState(0)
  useEffect(() => {
    setParentLabelWidth(parentLabel.current.offsetWidth)
  }, [])

  const handlePermalinkBlur = async () => {
    const permalink = category.permalink;
    if (permalink) {
      try {
        // Call the API with the permalink as a parameter
        const response = await CategoryApi.checkPermalinkExists({ permalink });
        
        if (response.exists) {
          setPermalinkError(response.message);
        } else {
          setPermalinkError('');
        }
      } catch (error) {
        console.error('Error checking permalink:', error);

      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveCategory();
    }
  };

  const saveCategory = () => {
    const formData = new FormData()
    for(const [field, value] of Object.entries(category)) {
      if(field !== '') {
        if(['image', 'mp4_video', 'webm_video', 'video_poster'].includes(field)) {
          if(value !== '') {
            formData.append(`category[${field}]`, value)
          }
        } else if(field === 'parent') {
          formData.append('category[parent_id]', Object.entries(value).length ? value.id : null)
        } else {
          formData.append(`category[${field}]`, value)
        }
      }
    }
    if(category.id) {
      setBackdrop(true)
      CategoryApi.updateCategory(category.id, formData)
        .then(() => {
          setBackdrop(false)
          setAlert({ severity: 'success', text: 'Updated successfully!' })
        })
        .catch(error => {
          setBackdrop(false)
          handleValidationErrors(error, setValidationErrors, setAlert)
        })
    } else {
      CategoryApi.createCategory(formData)
        .then((res) => {
          props.history.push({
            pathname: `/categories/${res.id}/edit`,
            state: { created: true }
          })
        })
        .catch(error => handleValidationErrors(error, setValidationErrors, setAlert))
    }
  }

  const handleFileUpload = (e) => {
    if (e.target.files[0]){
      const _category = {...category, [e.target.name]: e.target.files[0]}
      _category[`${e.target.name}_preview_url`] = URL.createObjectURL(e.target.files[0])
      setCategory({..._category})
    }
  }

  return(
    <Grid container>
      {alert && (
        <Grid item xs={12}><Alert severity={alert.severity} onClose={() => setAlert(null)}>{alert.text}</Alert></Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Namn"
              value={category.name || ''}
              onChange={e => setCategory({ ...category, name: e.currentTarget.value })}
              variant="outlined"
              error={!!validationErrors.name}
              helperText={validationErrors.name}
              />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Namn plural"
              value={category.name_plural || ''}
              onChange={e => setCategory({ ...category, name_plural: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Namn singular"
              value={category.name_singular || ''}
              onChange={e => setCategory({ ...category, name_singular: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Permalänk"
              value={category.permalink || ''}
              onChange={e => setCategory({ ...category, permalink: e.currentTarget.value })}
              onBlur={handlePermalinkBlur}
              variant="outlined"
              disabled={!!category.id}
              error={!!permalinkError || !!validationErrors.permalink} // true if there is an error
              helperText={permalinkError || validationErrors.permalink} // display the error message
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Anpassad title"
              value={category.custom_title || ''}
              onChange={e => setCategory({ ...category, custom_title: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel ref={parentLabel} htmlFor="parent"></InputLabel>
              <SelectInput
                label={'Föräldrakategori'}
                onChange={(e) => setCategory({ ...category, parent: { id: e.target.value } })}
                valuesArray={categories}
                value={category.parent.id}
              />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Beskrivning"
              value={category.description || ''}
              onChange={e => setCategory({ ...category, description: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Priority"
              value={category.priority || ''}
              onChange={e => setCategory({ ...category, priority: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Position"
              value={category.position || ''}
              onChange={e => setCategory({ ...category, position: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Redirect"
              value={category.redirect || ''}
              onChange={e => setCategory({ ...category, redirect: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <FormControlLabel
                  control={
                    <Switch
                      checked={String(category.main) === String(true)}
                      onChange={e => setCategory({ ...category, main: e.target.checked })}
                      value={true}
                      color="primary"
                    />
                  }
                  label="Huvudkategori"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <FormControlLabel
                  control={
                    <Switch
                      checked={String(category.a_o) === String(true)}
                      onChange={e => setCategory({ ...category, a_o: e.target.checked })}
                      value={true}
                      color="primary"
                    />
                  }
                  label="Visa kategori under A - Ö"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <FormControlLabel
                  control={
                    <Switch
                      checked={String(category.product_category) === String(true)}
                      onChange={e => setCategory({ ...category, product_category: e.target.checked })}
                      value={true}
                      color="primary"
                    />
                  }
                  label="Produktkategori"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <FormControlLabel
                  control={
                    <Switch
                      checked={String(category.removed) === String(true)}
                      onChange={e => setCategory({ ...category, removed: e.target.checked })}
                      value={true}
                      color="secondary"
                    />
                  }
                  label="STRYK KATEGORY"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Grid container alignItems={'end'} spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <UploadImageWidget type={'image'} onChange={handleFileUpload} object={category} field={'image'} accept={'image/*'}/>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <UploadImageWidget type={'image'} onChange={handleFileUpload} object={category} field={'video_poster'} accept={'image/*'}/>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Grid container alignItems={'end'} spacing={2}>
              <Grid item xs={6}>
                <UploadImageWidget type={'video'} onChange={handleFileUpload} object={category} field={'webm_video'} accept={'video/webm'}/>
              </Grid>
              <Grid item xs={6}>
                <UploadImageWidget type={'video'} onChange={handleFileUpload} object={category} field={'mp4_video'} accept={'video/mp4'}/>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Header"
              value={category.header || ''}
              onChange={e => setCategory({ ...category, header: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
          <TextEditor
            label='Footer'
            value={category.footer || ''}
            onChange={footer => setCategory({ ...category, footer: footer })}
          />
          <TextEditor
            label='Info'
            value={category.info || ''}
            onChange={info => setCategory({ ...category, info: info })}
          />
          <TextEditor
            label='Sidfot'
            value={category.footer_info || ''}
            onChange={footerInfo => setCategory({ ...category, footer_info: footerInfo })}
          />
          <FormControl fullWidth margin="normal">
            <TextField
              label="Nykelord"
              value={category.keywords || ''}
              onChange={e => setCategory({ ...category, keywords: e.currentTarget.value })}
              variant="outlined" />
          </FormControl>
        </Grid>
        <Mdivider />
        <Grid item xs={12} align="right">
          <Button variant="contained" color="primary" onClick={handleSubmit}>Spara</Button>
        </Grid>
      </Grid>
      <Backdrop open={backdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <InlineLoader size={40} color="inherit" />
      </Backdrop>
    </Grid>
  )
}

Form.propTypes = {
  category: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default Form
