import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './app.scss'

import { AuthContextProvider } from './AuthContext'
import PrivateRoute from './PrivateRoute'

import MuiWrapper from './MuiThemeWrapper'
import Admin from './components/Admin'
import Login from './components/authentication/New'

const App = () => {
  return (
    <Router>
      <AuthContextProvider>
        <MuiWrapper>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" component={Admin} />
          </Switch>
        </MuiWrapper>
      </AuthContextProvider>
    </Router>
  )
}

export default App
