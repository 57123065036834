import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import LoadingButton from '@common/LoadingButton'

export const AddRemoveActionButtons = ({ addFunction, RemoveFunction, itemCollection, item, loading }) => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems='center'>
      <Grid item>
      { itemCollection.includes(item) ?
        <LoadingButton
          label={t('tags.actions.remove')}
          onClick={RemoveFunction}
          isLoading={loading}
        />
        :
        <LoadingButton
          label={t('tags.actions.add')}
          onClick={addFunction}
          isLoading={loading}
        />
      }
      </Grid>
    </Grid>
  )
}
