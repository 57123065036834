import React, { createRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import CloseIcon from '@mui/icons-material/Close'

import HttpClient from '../HttpClient'

const ValueForm = props => {
  const initialState = {
    price: '',
    marking_method: {},
    supplier: {},
    starting_cost: { id: props.starting_cost_id }
  }
  const [costValue, setCostValue] = useState(initialState)
  const [formOptions, setFormOptions] = useState({
    marking_methods: [],
    suppliers: []
  })

  useEffect(() => {
    let didCancel = false
    Promise.all([
      HttpClient.get('/marking_methods?page=-1'),
      HttpClient.get('/suppliers?select=true&page=-1'),
    ]).then(([markingMethods, suppliers]) => {
      if (!didCancel) {
        const cost = { ...costValue }
        if(markingMethods.data.length)
          cost.marking_method.id = markingMethods.data[0].id
        if(suppliers.length)
          cost.supplier.id = suppliers[0].id
        setCostValue(cost)
        setFormOptions({
          marking_methods: markingMethods.data,
          suppliers: suppliers
        })
      }
    })

    return () => { didCancel = true }
  }, [])

  const selectLabels = useRef([...Array(2)].map(() => createRef()))
  const [selectLabelsWidth, setSelectLabelsWidth] = useState([...Array(2)])
  useEffect(() => {
    setSelectLabelsWidth(selectLabels.current.map(innerElRef => innerElRef.current.offsetWidth))
  }, [])

  const handleSave = () => {
    if(costValue.marking_method.id && costValue.supplier.id && costValue.price) {
      HttpClient.post(
        `/starting_costs/${props.starting_cost_id}/starting_cost_values`,
        { starting_cost_value: costValue }
      )
        .then(cost => props.onCreate(cost))
    }
  }

  return (
    <Grid container spacing={4} alignItems={'center'}>
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel ref={selectLabels.current[0]} htmlFor="marking_method">Tryckmetod</InputLabel>
          <Select
            native
            value={`${costValue.marking_method && costValue.marking_method.id}`}
            onChange={e => {setCostValue({ ...costValue, marking_method: { id: e.currentTarget.value } })}}
            input={<OutlinedInput name="marking_method" id="marking_method" labelWidth={selectLabelsWidth[0]} />}
          >
            {formOptions.marking_methods.map(c =>
              <option key={c.id} value={c.id}>{c.name}</option>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel ref={selectLabels.current[1]} htmlFor="supplier">Leverantör</InputLabel>
          <Select
            native
            value={`${costValue.supplier && costValue.supplier.id}`}
            onChange={e => setCostValue({ ...costValue, supplier: { id: e.currentTarget.value } })}
            input={<OutlinedInput name="supplier" id="supplier" labelWidth={selectLabelsWidth[1]} />}
          >
            {formOptions.suppliers.map(c =>
              <option key={c.id} value={c.id}>{c.name}</option>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="In"
            value={costValue.price || ''}
            onChange={e => setCostValue({ ...costValue, price: e.currentTarget.value })}
            variant="outlined" />
        </FormControl>
      </Grid>
      <Grid item xs={1} align='right'>
        <FormControl margin="normal" fullWidth variant="outlined">
          <Button variant="contained" color="primary" onClick={handleSave}>Spara</Button>
        </FormControl>
      </Grid>
      <Grid item xs={1} align='right'>
        <FormControl margin="normal" fullWidth variant="outlined">
          <Fab color="secondary" size="small" aria-label="add" onClick={() => props.setCreating(false)}>
            <CloseIcon />
          </Fab>
        </FormControl>
      </Grid>
    </Grid>
  )
}
ValueForm.propTypes = {
  starting_cost_id: PropTypes.number.isRequired,
  onCreate: PropTypes.func.isRequired,
  setCreating: PropTypes.func.isRequired,
}

export default ValueForm
