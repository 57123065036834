import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'

import HttpClient from '../HttpClient'
import Mdivider from '../common/Mdivider'

import Inspiration from './Inspiration'

const Inspirations = () => {
    
  const [inspirations, setInspirations] = useState([])
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    HttpClient.get('/inspirations')
      .then(res => setInspirations(res))
  }, [])

  const newInspirations = event => {
    const files = event.target.files
    for (var i = 0; i < files.length; i++) {
      const formData = new FormData()
      formData.append('inspiration[image]', files.item(i))
      setCreating(true)
      HttpClient.post('/inspirations', formData)
        .then(res => {
          setInspirations([...inspirations, res])
          setCreating(false)
        })
    }
  }

  const deleteInspiration = id => HttpClient.delete(`/inspirations/${id}`).then(setInspirations(inspirations.filter(i => i.id !== id)))

  const updateInspiration = (id, updatedInspiration) => {
    const _inspirations = [...inspirations]
    const inspirationIndex = _inspirations.findIndex(i => i.id === id)
    _inspirations[inspirationIndex] = updatedInspiration
    setInspirations({ _inspirations })
  }

  const shouldCancelStart = e => {
    // Cancel sorting if the event target is an `input`, `textarea`, `select`,`option` or `button`
    const disabledElements = ['input', 'textarea', 'select', 'option', 'button', 'a', 'svg', 'span']
    if (disabledElements.indexOf(e.target.tagName.toLowerCase()) !== -1 || disabledElements.indexOf(e.target.parentNode.tagName.toLowerCase()) !== -1) {
      return true // Return true to cancel sorting
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedInspirations = arrayMove(inspirations, oldIndex, newIndex)
    HttpClient.post('/inspirations/sort', { inspirations: sortedInspirations.map(i => i.id) } ).then(setInspirations(sortedInspirations))
  }

  return (
    <Container>
      <Grid container >
        <Grid container spacing={2}>
          <Grid item xs={1}><Typography varient='h3'>Bild</Typography></Grid>
          <Grid item xs={4}><Typography varient='h3'>Namn</Typography></Grid>
          <Grid item xs={5}><Typography varient='h3'>Beskrivning</Typography></Grid>
          <Grid item xs={1}><Typography varient='h3'>Publik</Typography></Grid>
        </Grid>
        <Mdivider />
        <SortableList
          items={inspirations}
          deleteInspiration={deleteInspiration}
          updateInspiration={updateInspiration}
          shouldCancelStart={shouldCancelStart}
          onSortEnd={onSortEnd} />
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <input
            style={{ display: 'none' }}
            id="multi-upload"
            type="file"
            onChange={newInspirations}
          />
          <label htmlFor="multi-upload">
            <Fab
              color="primary"
              size="small"
              component="span"
              disabled={creating}>
              <AddIcon />
            </Fab>
          </label>
        </Grid>
      </Grid>
    </Container>
  )
}

const SortableItem = SortableElement(({ inspiration, deleteInspiration, updateInspiration }) => {
  return (
    <Inspiration
      inspiration={inspiration}
      key={inspiration.id}
      onDelete={deleteInspiration}
      onUpdateImage={updateInspiration} />
  )
})

const SortableList = SortableContainer(({ items, deleteInspiration, updateInspiration }) => {
  return (
    <Grid container>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          disabled={value.id < 0}
          inspiration={value}
          deleteInspiration={deleteInspiration}
          updateInspiration={updateInspiration}/>
      ))}
    </Grid>
  )
})

export default Inspirations