import { useState, useEffect } from 'react'

const useProductFilters = (apiFunction, setParamsAndFetchData) => {
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiFunction()
      setSuppliers(result.suppliers)
      setCategories(result.categories)
    }
    fetchData()
  }, [])

  const handleSupplierAndCategoryChange = (value, type) => {
    let params = {}
    if (type === 'supplier'){
      setSelectedSupplier(value)
      value && (params.supplier_id = value.id)
      selectedCategory && (params.category_id = selectedCategory.id)
    } else {
      setSelectedCategory(value)
      value && (params.category_id = value.id)
      selectedSupplier && (params.supplier_id = selectedSupplier.id)
    }
    setParamsAndFetchData(params)
  }

  return {
    selectedSupplier,
    suppliers,
    categories,
    selectedCategory,
    handleSupplierAndCategoryChange
  }
}

export default useProductFilters
