import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'
import HttpClient from "@httpClient";

import { Grid, TextField, FormControl, InputLabel, InputAdornment, FormControlLabel,
         Checkbox, Button, IconButton, Alert } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";

import Mdivider from "@common/Mdivider";
import SelectInput from '@common/SelectInput'
import useAlert from "@customHooks/useAlert";
import CreatableAutocomplete from "@common/CreatableAutoComplete";
import TextEditor from "@helpers/RichTextEditor/TextEditor";

import Badge from "../badges/Badge";
import SupplierAttribute from "./SupplierAttribute";

import { ProductApi } from "@api/ProductApi";
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';

const priorities = [
  { id: 0, name: '0 - Finns ej i lager' },
  { id: 1, name: '1 - låg prio' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5 - hög prio' },
]

const Form = (props) => {
  const [product, setProduct] = useState(props.product);
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [alert, setAlert] = useAlert(props.created);
  const [deletedBadges, setDeletedBadges] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (product.updated_by_suppliers)
      HttpClient.get(`/products/${product.id}/supplier_products`).then((res) =>
        setSupplierProducts(res)
      );
  }, [product.id, product.updated_by_suppliers]);

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: product.name, rules: { required: true } },
    category: { value: product.category.id, rules: { required: true } },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveProduct();
    }
  };
  const handleAddBadge = (updatedBadge) => {
    const updatedBadges = product.badges.length
      ? product.badges.map((existingBadge) =>
          existingBadge.id === updatedBadge.id ? updatedBadge : existingBadge
        )
      : [updatedBadge]; // If array is empty, initialize with the new badge

    // If badge wasn't found in the map, add it to the end of the array
    if (!updatedBadges.some((badge) => badge.id === updatedBadge.id)) {
      updatedBadges.push(updatedBadge);
    }

    setProduct({ ...product, badges: updatedBadges });
  };

  const getVisibleDisabled = (badgeId) => {
    const visibleBadges = product.badges.filter((b) => b.visible === true);
    if (visibleBadges.length === 0) {
      // No badge is visible, so no badge should be disabled
      return false;
    }
    return visibleBadges.length > 0 && !product.badges.find((b) => b.id === badgeId).visible;
  };

  const handleBadgeDelete = (id) => {
    if (window.confirm("Är du säker?")) {
      if (product.id) {
        // Product exists, send delete request to backend
        HttpClient.post(`/products/${product.id}/remove_badges`, { badge_ids: [id] })
          .then(() => {
            setProduct({
              ...product,
              badges: product.badges.filter((b) => b.id !== id),
            });
          })
          .catch((error) =>
            console.error("Error removing product badge: ", error)
          );
      } else {
        setDeletedBadges([...deletedBadges, id]);
        setProduct({
          ...product,
          badges: product.badges.filter((b) => b.id !== id),
        });
      }
    }
  };

  const saveProduct = () => {
    setUpdating(true);
    product.category_id = product.category.id;
    if (product.brand.id !== "") product.brand_id = product.brand.id;
    else product.brand_id = null;
    if (product.id) {
      ProductApi.updateProduct(product, true)
        .then((res) => {
          setProduct({ ...product, ...res });
          setAlert({ severity: "success", text: "Updated successfully!" });
          setUpdating(false);
        })
        .catch((error) => {
          setUpdating(false);
          handleValidationErrors(error, setValidationErrors, setAlert)
          console.error("Error updating product: ", error);
        });
    } else {
      ProductApi.createProduct(product, true)
        .then((res) => {
          props.history.push({
            pathname: `/products/${res.id}/edit`,
            state: { created: true },
          });
          if (deletedBadges.length > 0) {
            HttpClient.post(`/products/${newProduct.id}/remove_badges`, { badge_ids: deletedBadges })
              .catch((error) => console.error("Error removing delayed badge: ", error));
            setDeletedBadges([]); // Clear the list after deletion
          }
        })
        .catch((error) => {
          setUpdating(false);
          handleValidationErrors(error, setValidationErrors, setAlert)
          console.error("Error creating product: ", error);
        });
    }
  };

  const handleChange = e => setProduct({...product, [e.target.name]: e.target.value})

  return (
    <>
      {alert && (
        <Alert severity={alert.severity} onClose={() => setAlert(null)}>
          {alert.text}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            {product.permalink !== "" ? (
              <TextField
                label={t("products.attributeLabels.name")}
                value={product.name}
                name={'name'}
                error={!!validationErrors.name}
                helperText={validationErrors.name}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      component="a"
                      href={`${process.env.REACT_APP_WEB_BASE_URL}/${product.category_permalink}/${product.permalink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton
                        color="primary"
                        aria-label="go to public URL"
                        edge="end"
                      >
                        <DirectionsIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <TextField
                label={t("products.attributeLabels.name")}
                value={product.name}
                name={'name'}
                onChange={handleChange}
                variant="outlined"
                error={!!validationErrors.name}
                helperText={validationErrors.name}
              />
            )}
          </FormControl>
          {supplierProducts.map((sp) => (
            <SupplierAttribute
              key={`${sp.id}-name`}
              value={sp.name}
              supplier={sp.supplier.name}
            />
          ))}
          <SelectInput
            label={t("products.attributeLabels.category")}
            onChange={(e) => { setProduct({ ...product, category: { id: e.target.value } })}}
            value={product.category.id}
            valuesArray={props.categories}
            name="category"
            error={!!validationErrors.category}
            helperText={validationErrors.category}
          />
          {supplierProducts.map((sp) => (
            <SupplierAttribute
              key={`${sp.id}-category`}
              value={sp.category}
              supplier={sp.supplier.name}
            />
          ))}
          <FormControl fullWidth margin="normal">
            <TextField
              label={t("products.attributeLabels.delivery_time")}
              name={'delivery_time'}
              value={product.delivery_time || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t("products.attributeLabels.size")}
              name={'size'}
              value={product.size || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          {supplierProducts.map((sp) => (
            <SupplierAttribute
              key={`${sp.id}-size`}
              value={sp.size}
              supplier={sp.supplier.name}
            />
          ))}
          <TextEditor
            label={t("products.attributeLabels.description")}
            value={product.description || ""}
            onChange={(desc) => setProduct({ ...product, description: desc })}
          />
          <TextEditor
            label={t("products.attributeLabels.long_description")}
            value={product.long_description || ""}
            onChange={(desc) => setProduct({ ...product, long_description: desc })}
          />
          {supplierProducts.map((sp) => (
            <SupplierAttribute
              key={`${sp.id}-description`}
              value={sp.description}
              supplier={sp.supplier.name}
            />
          ))}
          <SelectInput
            label={t("products.attributeLabels.brand")}
            onChange={(e) => { setProduct({ ...product, brand: { id: e.target.value } })}}
            value={product.brand ? product.brand.id : ""}
            valuesArray={props.brands}
          />

          {supplierProducts.map((sp) => (
            <SupplierAttribute
              key={`${sp.id}-brand`}
              value={sp.brand}
              supplier={sp.supplier.name}
            />
          ))}
          <SelectInput
            label={t("products.attributeLabels.country")}
            onChange={(e) => { setProduct({ ...product, country: { id: e.target.value }})}}
            value={product.country.id}
            valuesArray={props.countries}
          />
          {supplierProducts.map((sp) => (
            <SupplierAttribute
              key={`${sp.id}-country`}
              value={sp.country}
              supplier={sp.supplier.name}
            />
          ))}
          <TextEditor
            label={t("products.attributeLabels.product_details")}
            value={product.product_details || ""}
            onChange={ details => setProduct({ ...product, product_details: details })}
          />
          <TextEditor
            label={t("products.attributeLabels.marking_details")}
            value={product.marking_details || ""}
            onChange={ details => setProduct({ ...product, marking_details: details })}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControl margin="normal">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!product.visible}
                      name={'visible'}
                      onChange={(e) => setProduct({ ...product, visible: e.target.checked })}
                      color="primary"
                    />
                  }
                  label={t("products.attributeLabels.visible")}
                  labelPlacement="end"
                />
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label={t("products.attributeLabels.permalink")}
                  value={product.permalink || ""}
                  onChange={(e) =>
                    setProduct({ ...product, permalink: e.currentTarget.value })
                  }
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{`/${
                        product.category_permalink || ""
                      }/`}</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TextEditor
            label={t("products.attributeLabels.seo_text")}
            value={product.seo_text || ""}
            onChange={(seoText) =>
              setProduct({ ...product, seo_text: seoText })
            }
          />
          <FormControl fullWidth margin="normal">
            <TextField
              label={t("products.attributeLabels.custom_title")}
              name={'custom_title'}
              value={product.custom_title || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              name={'seo_description'}
              minRows="5"
              maxRows="10"
              label={t("products.attributeLabels.seo_description")}
              value={product.seo_description || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t("products.attributeLabels.keywords")}
              name={'keywords'}
              value={product.keywords || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              minRows="5"
              maxRows="10"
              name={'compliance'}
              label={t("products.attributeLabels.compliance")}
              value={product.compliance || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          {supplierProducts.map((sp) => (
            <SupplierAttribute
              key={`${sp.id}-keywords`}
              value={sp.keywords}
              supplier={sp.supplier.name}
            />
          ))}
          <FormControl fullWidth margin="normal">
            <TextField
              label={t("products.attributeLabels.redirect")}
              name={'redirect'}
              value={product.redirect || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>

          <SelectInput
            label={t("products.attributeLabels.priority")}
            onChange={(e) => { setProduct({ ...product, priority: e.target.value })}}
            value={product.priority}
            valuesArray={priorities}
          />

          <TextEditor
            label={t("products.attributeLabels.notes")}
            value={product.notes || ""}
            onChange={(notes) => setProduct({ ...product, notes: notes })}
          />
          <InputLabel style={{ marginTop: "16px" }}>{t("products.attributeLabels.badges")}</InputLabel>
          <FormControl fullWidth variant="outlined" margin="normal">
            <CreatableAutocomplete
              optionsUrl="/badges"
              onChange={handleAddBadge}
              exclude={product.badges}
              onCreateOption={handleAddBadge}
              createUrl="/badges"
              resource="badge"
            />
            {product.badges.map((b) => (
              <Badge 
                key={b.id} 
                badge={b} 
                onDelete={handleBadgeDelete}
                handleAddBadge={handleAddBadge}
                visibleDisabled={getVisibleDisabled(b.id)}
              />
            ))}
          </FormControl>

          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={String(product.addon) === String(true)}
                  onChange={(e) =>
                    setProduct({ ...product, addon: e.target.checked })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label={t("products.attributeLabels.addon")}
            />
          </Grid>
        </Grid>
        <Mdivider />
        <Grid item xs={12} align="right">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Spara
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

Form.propTypes = {
  product: PropTypes.object.isRequired,
  categories: PropTypes.array,
  countries: PropTypes.array,
  brands: PropTypes.array,
  history: PropTypes.object,
};

export default Form;
