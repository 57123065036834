import React from 'react'
import { Modal } from '@mui/material'

const CustomModal = props => {
  const { open, body, handleClose } = props;
  return(
    <Modal
      open={open}
      onClose={handleClose}
    >
      {body()}
    </Modal>
  )
}

export default CustomModal;