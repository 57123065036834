import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Select from "react-select";

import HttpClient from "../HttpClient";

const Autocomplete = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    HttpClient.get(props.url, {}, !!props.showLoader).then((res) => {
      let _res = !!res.data ? res.data : res;
      let _options = _res.map((a) => {
        return { label: a.name, value: a.id, ...a };
      });
      setOptions([...options, ..._options]);
    });
  }, []);

  const handleChange = (opt, meta) => {
    props.onChange(opt);
  };

  const filteredOptions = () => {
    return props.exclude && props.exclude.length
      ? options.filter((o) => !props.exclude.some((e) => e.id === o.id))
      : options;
  };

  return (
    <Select
      placeholder={"Välj..."}
      options={filteredOptions()}
      onChange={handleChange}
    />
  );
};

Autocomplete.propTypes = {
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  exclude: PropTypes.array, // if want to exclude some options
};

export default Autocomplete;
