import React from 'react'
import PropTypes from 'prop-types'

import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import AssignmentIcon from '@mui/icons-material/Assignment'

const SupplierAttribute = ({ value, supplier }) => {
  return (
    <List dense disablePadding>
      <ListItem>
        <ListItemText primary={value} secondary={supplier} />
      </ListItem>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="copy to clipboard"
          onClick={() => { navigator.clipboard.writeText(value) }}
        >
          <AssignmentIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </List>
  )
}

SupplierAttribute.propTypes = {
  value: PropTypes.string.isRequired,
  supplier: PropTypes.string.isRequired
}

export default SupplierAttribute
