import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { FormControl, TextField, Select, OutlinedInput } from '@mui/material'
import Fab from '@mui/material/Fab'
import DeleteIcon from '@mui/icons-material/Delete'

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import Checkbox from '@mui/material/Checkbox'

import Mdivider from '../common/Mdivider'
import FileInput from '../common/FileInput'
import HttpClient from '../HttpClient'

const SlideImage = props => {
  
  const [state, setState] = useState({
    image: props.image,
    updating: false
  })

  const classes = makeStyles(() =>({
    select: {
      marginTop: '8px',
      marginBottom: '4px'
    }
  }))()

  const saveChange = event => {
    let img = { ...state.image }
    const field = event.target.name
    switch (event.target.type) {
      case 'file':
        img[field] = event.target.files[0]
        break
      case 'checkbox':
        img[field] = event.target.checked
        break
      default:
        img[field] = event.target.value
    }
    let formData = new FormData()
    for (const [field, value] of Object.entries(img)) {
      if(field === 'image') {
        if(value !== '') {
          formData.append(`slide_image[${field}]`, value)
        }
      } else {
        formData.append(`slide_image[${field}]`, value)
      }
    }
    // disable button while uploading
    // setState({updating: true})
    
    if(img.id) {
      HttpClient.put(`/slide_images/${img.id}`, formData).then(res =>{
        setState({ ...state, image: res })
      })
    } else {
      HttpClient.post('/slide_images', formData).then(res => {
        setState({ ...state, image: res })
      })
    }
  }

  const handleChange = event => {
    let img = { ...state.image }
    const field = event.target.name
    switch (event.target.type) {
      case 'file':
        img[field] = event.target.files[0]
        break
      default:
        img[field] = event.target.value
    }
    setState({ image: img })
  }

  const imageHtml = (state.image.id && state.image.image_url) ? (
    (state.image['video?']) ? (
      <a href={state.image.image_url} target='_blank' rel='noopener noreferrer'>
        <OndemandVideoIcon fontSize='large'/>
      </a>
    ) : (
      <a href={state.image.image_url} target='_blank' rel='noopener noreferrer'>
        <img src={state.image.thumbnail_admin} alt={state.image.title} />
      </a>
    )
    
  ) : null
  
  const videoPoster = (state.image.id && state.image.video_poster_url) ? (
    <a href={state.image.video_poster_url} target='_blank' rel='noopener noreferrer'>
      <img src={state.image.video_poster_thumbnail} alt={state.image.title} />
    </a>
  ) : null
  const videoWebm = (state.image.id && state.image.video_webm_url) ? (
    <a href={state.image.video_webm_url} target='_blank' rel='noopener noreferrer'>
      <OndemandVideoIcon fontSize='large'/>
    </a>
  ) : null

  const additionalFiles = (state.image['video?']) ? (
    <Grid container spacing={2} className="additional-attachments">
      <Grid item xs={1}>
        {videoWebm}
        <FileInput
          label='Webm'
          onChange={saveChange}
          name='video_webm'
        />
      </Grid>
      <Grid item xs={1}>
        {videoPoster}
        <FileInput
          label='Poster'
          onChange={saveChange}
          name='video_poster'
        />
      </Grid>
    </Grid>
  ) : null

  const btnCheckbox = (state.image.url) ? (
    <Checkbox
      name="btn"
      checked={String(state.image.btn) === String(true)}
      onChange={saveChange}
      value={true}
      color="primary"
    />
  ) : null
  const btnTextInput = (state.image.btn) ? (
    <FormControl fullWidth>
      <TextField
        value={state.image.btn_text || ''}
        name="btn_text"
        onChange={handleChange}
        onBlur={saveChange}
        margin="dense"
        variant="outlined"
      />
    </FormControl>
  ) : null

  return (
    <Grid container spacing={2}>
      <Grid item xs={1} className={`${state.image.image_url ? 'image' : ''}`}>
        <div>{imageHtml}</div>
        <FileInput
          icon={true}
          onChange={saveChange}
          name='image'
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            value={state.image.url || ''}
            name="url"
            onChange={handleChange}
            onBlur={saveChange}
            margin="dense"
            variant="outlined"
            InputProps={{
              endAdornment: btnCheckbox
            }}
          />
        </FormControl>
        {btnTextInput}
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            value={state.image.title || ''}
            name="title"
            onChange={handleChange}
            onBlur={saveChange}
            margin="dense"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            value={state.image.title_secondary || ''}
            name="title_secondary"
            onChange={handleChange}
            onBlur={saveChange}
            margin="dense"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <Select
            native
            name='color'
            className={classes.select}
            value={state.image.color || ''}
            onChange={saveChange}
            input={<OutlinedInput name="color" margin='dense' id="color" />}
          >
            <option value="0">Vit</option>
            <option value="1">Svart</option>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            value={state.image.duration || ''}
            name="duration"
            onChange={handleChange}
            onBlur={saveChange}
            margin="dense"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <Checkbox
          name="visible"
          checked={String(state.image.visible) === String(true)}
          onChange={saveChange}
          value={true}
          color="primary"
        />
      </Grid>
      <Grid item xs={1}>
        <Fab
          onClick={() => {if(window.confirm('Är du säker?')) props.onDelete(state.image.id)}}
          color="secondary"
          size="small">
          <DeleteIcon />
        </Fab>
      </Grid>
      {additionalFiles}
      <Mdivider/>
    </Grid>
  )
}

SlideImage.propTypes = {
  image: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default SlideImage
