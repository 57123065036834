import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {Container, Grid} from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import ArrowBack from '@mui/icons-material/ArrowBack'

import TitleBar from '../topbar/TitleBar'
import Mdivider from '../common/Mdivider'
import LoaderShowSingle from '../common/Loaders/LoaderShowSingle'
import Alert from '@mui/material/Alert'
import useAlert from '@customHooks/useAlert'
import { MarkingMethodApi } from '@api/MarkingMethodApi'
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';

const Form = (props) => {
  const [marking_method, setMarkingMethod] = useState(props.marking_method)
  const [alert, setAlert] = useAlert(props.created)

  useEffect(() => {
    const _marking_method = {...props.marking_method}
    setMarkingMethod(_marking_method)
  },[props.marking_method])

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: marking_method.name, rules: { required: true } },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveMarkingMethod();
    }
  };

  const saveMarkingMethod = () => {
    if(marking_method.id) {
      MarkingMethodApi.updateMarkingMethod(marking_method.id, marking_method)
        .then(() => setAlert({ severity: 'success', text: 'Updated successfully!' }))
        .catch(error => setAlert({ severity: 'error', text: error.message }))
    } else {
      MarkingMethodApi.createMarkingMethod(marking_method)
        .then((res) => {
          props.history.push({
            pathname: `/marking_methods/${res.id}/edit`,
            state: { created: true }
          })
        })
        .catch(error => setAlert({ severity: 'error', text: error.message }))
    }
  }

  return(
    <React.Fragment>
      <TitleBar title={`${marking_method.id ? 'Radigera' : 'Ny'} tryckmetod`}>
        <Fab color="secondary" size="small" aria-label="add" onClick={() => { props.history.push('/marking_methods')}}>
          <ArrowBack />
        </Fab>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>{alert.text}</Alert>
        )}
        {props.loading ? <LoaderShowSingle /> : (
          <Grid container>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Namn"
                  value={marking_method.name || ''}
                  onChange={e => setMarkingMethod({...marking_method, name: e.currentTarget.value})}
                  variant="outlined"
                  error={!!validationErrors.name}
                  helperText={validationErrors.name} />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Beskrivning"
                  value={marking_method.description || ''}
                  onChange={e => setMarkingMethod({...marking_method, description: e.currentTarget.value})}
                  variant="outlined" />
              </FormControl>
            </Grid>
            <Mdivider />
            <Grid item xs={12} align="right">
              <Button variant="contained" color="primary" onClick={handleSubmit}>Spara</Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}

Form.propTypes = {
  marking_method: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool
}

export default Form
