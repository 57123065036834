import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import ProductResourceContext from "@contexts/ProductResource";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PrintOptionImages from "@components/products/printing/PrintOptionImages";
import LoadingButton from "@components/common/LoadingButton";
import PopupAlert from "@contexts/PopupAlert";
import { parseErrorMessage, singularize } from "@src/utils/sharedHelper";
import { PrintOptionApi } from "@src/apis/PrintOptionApi";

const EditPrintOptionModal = ({
  open,
  productId,
  printOption,
  onEdit,
  onClose,
  addImage,
  deleteImage,
}) => {
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);
  const productResource = useContext(ProductResourceContext);
  const isSupplierProduct = productResource === "supplier_products";

  const savePrintOption = async (values) => {
    return PrintOptionApi.savePrintOption(
      printOption.id,
      productId,
      {
        product_type: singularize(productResource),
        print_option: values,
      },
      productResource,
    )
      .then((res) => {
        onEdit(res);
      })
      .catch((error) => {
        addPopupAlert(
          t("printOptions.errors.printOptionEdit"),
          "error",
          parseErrorMessage(error.message),
        );
      });
  };

  const formik = useFormik({
    initialValues: printOption ?? {},
    onSubmit: savePrintOption,
    validate: (values) => {
      const errors = {};
      if (!values.name)
        errors.name = t("printOptions.formValidation.name.presence");
      return errors;
    },
  });

  const { values, errors, handleChange, touched, isSubmitting } = formik;

  useEffect(() => {
    formik.resetForm({
      values: printOption ?? {},
    });
  }, [printOption]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        {t("actions.edit")} {t("printOptions.name")}
      </DialogTitle>
      <DialogContent>
        <FormControl margin="normal" fullWidth>
          <TextField
            disabled={isSupplierProduct}
            fullWidth
            label={t("printOptions.attributes.name")}
            value={values.name}
            error={errors.name && touched.name}
            onChange={handleChange}
            name="name"
            variant="outlined"
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <TextField
            disabled={isSupplierProduct}
            fullWidth
            label={t("printOptions.attributes.min_area_cm2")}
            value={values.min_area_cm2}
            onChange={handleChange}
            type="number"
            name="min_area_cm2"
            variant="outlined"
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <TextField
            disabled={isSupplierProduct}
            fullWidth
            label={t("printOptions.attributes.max_area_cm2")}
            value={values.max_area_cm2}
            onChange={handleChange}
            type="number"
            name="max_area_cm2"
            variant="outlined"
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <TextField
            disabled={isSupplierProduct}
            fullWidth
            label={t("printOptions.attributes.position")}
            value={values.position}
            onChange={handleChange}
            name="position"
            variant="outlined"
          />
        </FormControl>

        <PrintOptionImages
          productId={productId}
          printOption={printOption}
          addImage={addImage}
          deleteImage={deleteImage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <span>{t("actions.close")}</span>
        </Button>
        <LoadingButton
          onClick={formik.submitForm}
          isLoading={isSubmitting}
          variant="contained"
          label={t("actions.save")}
        />
      </DialogActions>
    </Dialog>
  );
};

EditPrintOptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  productId: PropTypes.string.isRequired,
  printOption: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
};

export default EditPrintOptionModal;
