import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import {Container, Grid} from '@mui/material'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {TableRow, TableCell, Typography} from '@mui/material'
import { makeStyles } from '@mui/styles'

import TitleBar from '@components/topbar/TitleBar'
import HttpClient from '@httpClient'
import Loader from '@common/Loaders/LoaderList'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import ListTable from '@common/ListingTable/ListTable'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { useDebounce } from '@customHooks/useDebouncing'
import { useTranslation } from 'react-i18next'
import { StandardApi } from '@api/StandardApi'
import { NameSorting } from '../../config/SortingLabels'

const List = props => {
  const standardsPerPage = getUserPreference( 'page_size', 'standard')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( standardsPerPage !== '' ? standardsPerPage : 20)
  const [totalCount, setTotalCount] = useState(0)
  const [standards, setSustainableStandards] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOptionSelected, setSortOptionSelected] = useState('name_ascending')
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    let didCancel = false
    if (searchTerm) {
      fetchStandards({ page: 1, per_page: perPage, query: searchTerm });
    }
    else {
      fetchStandards({ page: 1, per_page: perPage });
    }
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const fetchStandards = async (params) => {
    setLoading(true)
    if (!params.sort_by) {
      params['sort_by'] = sortOptionSelected
    }
    try {
      const response = await StandardApi.getStandards(params)
      if(response) {
        setSustainableStandards(response.data)
        setTotalCount(response.total_count)
      }
    }
    catch(error) {
      console.log("error", error)
    }
    finally {
      setLoading(false)
    }
  }

  const handleSearchParamsChange = (type, value) => {
    switch (type) {
      case "page":
        setPage(value);
        fetchStandards({ page: value, per_page: perPage });
        break;

      case "pageSize":
        setPerPage(value);
        setPage(1);
        setUserPreference("page_size", "standard", value);
        fetchStandards({ page: 1, per_page: value });
        break;

      case "sort":
        setSortOptionSelected(value)
        fetchStandards({ page: 1, per_page: perPage, sort_by: value });
        break;

      case "search":
        setSearchTerm(value)
        break;
    }
  };

  const removeSustainableStandard = id => {
    if(window.confirm('Är du säker?')) {
      StandardApi.removeStandard(id).then(() => {
        setSustainableStandards(standards.filter(a => a.id !== id))
      })
    }
  }

  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  const standardsTableContent = () => {
    const standardsBody = standards?.map((standard) => (
      <TableRow key={standard.id}>
        <TableCell><Link to={`standards/${standard.id}/edit`}>{standard.name}</Link></TableCell>
        <TableCell align="right">
          <Typography noWrap>
            <IconButton
              component={Link}
              to={`standards/${standard.id}/edit`}
              aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => removeSustainableStandard(standard.id)}>
              <DeleteIcon />
            </IconButton>
          </Typography>  
        </TableCell>
      </TableRow>
    ))
    return { body: standardsBody, heading: ['Namn', ''] }
  }

  const tableContent = standardsTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Standarder">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/standards/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={NameSorting}
          searchText={t('standards.placeholders.search_label')}
          getData={handleSearchParamsChange} />
        {loading ? <Loader /> : (
          <Grid container>
            <Paper square className={classes.root}>
              <ListTable
                tableHeadings={tableContent.heading}
                tableContent={tableContent.body}
                count={totalCount}
                pageNo={page}
                rowsPerPage={perPage}
                onPageChange={(event, pageNo) => handleSearchParamsChange('page', pageNo + 1)}
                handleChangeRowsPerPage={(event) => handleSearchParamsChange('pageSize', event.target.value)}
                rowsControl={true} />
            </Paper>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}
List.propTypes = {
  history: PropTypes.object.isRequired
}

export default List
