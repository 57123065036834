import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, TextField, FormControl, Select, InputLabel, OutlinedInput, Switch,
         Checkbox, FormControlLabel, InputAdornment, Typography, Fab } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ProductResourceContext from "@contexts/ProductResource";

import HttpClient from "@httpClient";
import Sustainable from "./Sustainable";
import { ProductApi } from "@src/apis";
import SelectInput from '@common/SelectInput'

const styles = {
  attributeContainer: {
    border: "1px solid rgb(228, 228, 228)",
    borderRadius: "5px",
    padding: "8px",
    marginBottom: "8px",
  },
};

const Attribute = (props) => {
  const productResource = useContext(ProductResourceContext);
  const { t } = useTranslation();

  const _attr = { ...props.attribute };

  if (!_attr.child_attributes) _attr.child_attributes = [];

  if (_attr.p_attribute_values.length < 1) {
    _attr.selected = "";
    if (props.attribute.type === "multiselect") _attr.selected = [];
    if (props.attribute.type === "switch") _attr.selected = true;
  } else if (_attr.selected === "" || !_attr.selected) {
    // if attribute values are present then use them as default values
    if (props.attribute.type === "multiselect") {
      // if attribute is multiselect then we will keep a list in root element which will contain the ids of selected values, bydefault all values should be selected
      _attr.selected = props.attribute.p_attribute_values.map((v) => v.id);
    } else if (props.attribute.type === "select") {
      _attr.selected = props.attribute.p_attribute_values[0].id;
    } // else if(props.attribute.type !== 'boolean_or_number') {
    // _attr.selected = props.attribute.p_attribute_values[0].value
    // }
  }

  const [attribute, setAttribute] = useState(_attr);
  const [isSustainable, setSustainable] = useState(false);
  const attributeLabel = useRef(null);
  const [attributeLabelWidth, setAttributeLabelWidth] = useState(0);

  const isSupplierProduct = productResource === "supplier_products";

  useEffect(() => {
    if (
      attribute.type !== "text" ||
      attribute.type !== "number" ||
      attribute.type !== "switch"
    )
      setAttributeLabelWidth(
        attributeLabel.current && attributeLabel.current.offsetWidth,
      );
  }, [attribute.type]);

  const handleChange = (e) => {
    let _attribute = { ...attribute };
    if (_attribute.type === "boolean_or_number") {
      if (e.target.type === "select-one") {
        _attribute.selected =
          e.currentTarget.value == "true" ? true : e.currentTarget.value;
      }
    } else if (e.target.type === "checkbox") {
      _attribute.selected = e.target.checked;
    } else {
      _attribute.selected = e.target.value;
    }
    setAttribute(_attribute);
    // In case of text input we will save onBlur rather than onChange
    if (e.target.type !== "text") {
      handleValueChange(_attribute);
    }
  };

  const handleMultiSelectChange = (e) => {
    let _attribute = { ...attribute }
    if (e.target.checked){
      _attribute.selected.push(e.target.value)
    } else {
      _attribute.selected = _attribute.selected.filter( val => val !== e.target.value);
    }
    setAttribute(_attribute);
    handleValueChange(_attribute);
  }

  const handleValueChange = (_attribute = attribute) => {
    if (_attribute.type === "boolean_or_number" || _attribute.selected !== "") {
      ProductApi.addCategoryAttributeValue(
        props.productId,
        { attribute: _attribute },
        productResource,
      ).then(() => {
        attribute.type !== "multiselect" &&
          HttpClient.delete(`/attributes/${attribute.id}/clear_standards`).then(
            () => {},
          );
      });
    }
  };

  const handleAttributeSelect = (e) => {
    const _attribute = { ...attribute, checked: e.target.checked };
    ProductApi.addCategoryAttribute(
      props.productId,
      { attribute: _attribute },
      productResource,
    ).then(() => {
      setAttribute(_attribute);
      handleValueChange();
    });
  };

  const addToCategory = (e) => {
    if (
      e.target.checked &&
      window.confirm(t("products.addToCategoryMessage"))
    ) {
      // if add to category selected then we set product_specific flag to false
      const _attribute = { ...attribute, product_specific: false };
      HttpClient.put(`/attributes/${attribute.id}/add_to_category`, {
        p_attribute: { product_specific: false },
      })
        .then(() => setAttribute(_attribute))
        .catch((error) =>
          console.error("Error adding category attribute ", error),
        );
    }
  };

  const deleteAttribute = () => {
    if (window.confirm("Är du säker?")) {
      HttpClient.delete(`/attributes/${attribute.id}`, {}, true)
        .then(() => props.onDelete(attribute.id))
        .catch((error) => console.error("Error deleting attribute ", error));
    }
  };

  const renderAttribute = () => {
    let _component = "";
    let inputProps = {};
    if (attribute.unit) {
      inputProps["endAdornment"] = (
        <InputAdornment position="end">{attribute.unit.symbol}</InputAdornment>
      );
    }
    switch (attribute.type) {
      case "text":
      case "number":
        _component = (
          <FormControl fullWidth margin="normal">
            <TextField
              label={attribute.name}
              value={attribute.selected}
              onChange={handleChange}
              onBlur={() => handleValueChange()}
              variant="outlined"
              disabled={isSupplierProduct}
              InputProps={inputProps}
            />
          </FormControl>
        );
        break;
      case "switch":
        _component = (
          <FormControlLabel
            control={
              <Checkbox
                disabled={isSupplierProduct}
                checked={String(attribute.selected) === String(true)}
                onChange={handleChange}
                value={true}
                color="primary"
              />
            }
            label={attribute.name}
          />
        );
        break;
      case "select":
        _component = (
          <SelectInput
            value={attribute.selected}
            valuesArray={attribute.p_attribute_values}
            onChange={handleChange}
            label={attribute.name}
            propertyName={'value'}
            disabled={isSupplierProduct}
          />
        );
        break;
      case "multiselect":
        _component = (
          <>
            <Typography variant="h6" gutterBottom>
              {attribute.name}
            </Typography>
            {
              attribute.p_attribute_values.map(value => (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isSupplierProduct}
                      checked={attribute.selected.includes(value.id)}
                      onChange={handleMultiSelectChange}
                      value={value.id}
                      color="primary"
                    />
                  }
                  key={value.id}
                  label={value.value}
                />
              ))
            }
          </>
        );
        break;
      case "boolean_or_number": {
        const options = Array.from(new Array(100), (val, index) => index + 1);
        let selectedOption = attribute.selected
          ? attribute.selected
          : options.find((o) => attribute.selected === String(o));
        selectedOption = selectedOption || " ";
        _component = (
          <React.Fragment>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                ref={attributeLabel}
                htmlFor={`attribute-${attribute.id}`}
              >
                {attribute.name}
              </InputLabel>
              <Select
                native
                disabled={isSupplierProduct}
                value={selectedOption}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    name={`attribute-${attribute.id}`}
                    id={`attribute-${attribute.id}`}
                    labelWidth={attributeLabelWidth}
                  />
                }
              >
                <option key={"empty"} value=" ">
                  Nej
                </option>
                <option key={"yes"} value={true}>
                  Ja
                </option>
                {options.map((o) => (
                  <option key={o} value={o}>{`${o} ${
                    attribute.unit && attribute.unit.symbol
                      ? attribute.unit.symbol
                      : ""
                  }`}</option>
                ))}
              </Select>
            </FormControl>
          </React.Fragment>
        );
        break;
      }
    }
    return _component;
  };

  const productSpecific = attribute.product_specific ? (
    <Grid item xs={12} align="right">
      <FormControlLabel
        control={
          <Checkbox
            disabled={isSupplierProduct}
            checked={String(attribute.product_specific) !== String(true)}
            onChange={addToCategory}
            value={true}
            color="primary"
          />
        }
        label={t("products.addToCategory")}
      />
      <Fab
        size="small"
        color="secondary"
        aria-label="add"
        disabled={isSupplierProduct}
        onClick={deleteAttribute}
      >
        <DeleteIcon />
      </Fab>
    </Grid>
  ) : null;

  // in case 'select' attributes 'selected' contains the id of selected value
  const selected = attribute.selected
    ? attribute.p_attribute_values.find((v) =>
        attribute.type === "select"
          ? v.id == attribute.selected
          : v.value == attribute.selected,
      )
    : false;
  const standardsHtml =
    selected && attribute.type !== "multiselect" ? (
      <>
        <FormControlLabel
          disabled={isSupplierProduct}
          control={isSustainable ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          label={t("products.isSustainable")}
          onClick={() => setSustainable(!isSustainable)}
        />
        {isSustainable && <Sustainable value={selected} />}
      </>
    ) : null;
  return (
    <div sx={props.child && styles.attributeContainer}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {attribute.child_attributes && attribute.child_attributes.length ? (
            <Typography variant="h5" component="h3">
              {attribute.name}
            </Typography>
          ) : null}
          {attribute.child_attributes && attribute.child_attributes.length
            ? null
            : renderAttribute()}
        </Grid>
        {attribute.type !== "boolean_or_number" ? (
          <Grid item xs={2}>
            <Switch
              checked={attribute.checked}
              onChange={handleAttributeSelect}
              disabled={isSupplierProduct}
              value="yes"
              color="primary"
            />
          </Grid>
        ) : null}
        {productSpecific}
        {attribute.child_attributes &&
        attribute.child_attributes.length ? null : (
          <Grid item xs={12}>
            {standardsHtml}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

Attribute.propTypes = {
  attribute: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  child: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

export default Attribute;
