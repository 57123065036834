import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import HttpClient from "@httpClient";

import { Grid, FormControl, Button, Fab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import SelectInput from '@common/SelectInput'

const TemplateForm = (props) => {
  const [state, setState] = useState({
    marking_methods: [],
    marking_method_id: "",
  });
  const [suppliers, setSuppliers] = useState([]);
  const [supplier_id, setSupplierId] = useState("");
  const [supplierTemplates, setSupplierTemplates] = useState([]);
  const [marking_template_id, setMarkingTemplateId] = useState("");

  useEffect(() => {
    HttpClient.get("/marking_methods?page=-1", {}, true).then((response) => {
      const _state = {
        ...state,
        marking_methods: response.data,
      };
      if (response.total_count > 0) {
        _state.marking_method_id = response.data[0].id;
        loadSuppliers(response.data[0].id);
      }
      setState(_state);
    });
  }, [props.productId]);

  const handleMethodChange = (e) => {
    setState({ ...state, marking_method_id: e.target.value });
    loadSuppliers(e.target.value);
  };

  const handleSupplierChange = (e) => {
    setSupplierId(e.target.value);
    loadTemplates(e.target.value, state.marking_method_id);
  };

  const loadSuppliers = (marking_method_id) => {
    HttpClient.get(
      `/marking_methods/${marking_method_id}/suppliers`,
      {},
      true,
    ).then((res) => {
      setSuppliers(res);
      if (res.length) {
        setSupplierId(res[0].id);
        loadTemplates(res[0].id, marking_method_id);
      }
    });
  };

  const loadTemplates = (supplier_id, marking_method_id) => {
    HttpClient.get(
      `/marking_templates?system_generated=${false}&supplier_id=${supplier_id}&marking_method_id=${marking_method_id}`,
      {},
      true,
    ).then((templates) => {
      setSupplierTemplates(templates.data);
      if (templates.data.length) setMarkingTemplateId(templates.data[0].id);
    });
  };

  const handleSave = () => {
    if (marking_template_id) props.addTemplate(marking_template_id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectInput
          label={'Tryckmetod'}
          value={state.marking_method_id}
          valuesArray={state.marking_methods}
          onChange={handleMethodChange}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          label={'Leverantör'}
          value={supplier_id}
          valuesArray={suppliers}
          onChange={handleSupplierChange}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          label={'Markning'}
          value={state.marking_template_id}
          valuesArray={supplierTemplates}
          onChange={(e) => setState({ ...state, marking_template_id: e.target.value })}
        />
      </Grid>
      <Grid item container alignItems={'center'} xs={1} align="right">
        <FormControl margin="normal" fullWidth variant="outlined">
          <Button variant="contained" color="primary" onClick={handleSave}>
            Spara
          </Button>
        </FormControl>
      </Grid>
      <Grid container alignItems={'center'} item xs={1} align="right">
        <FormControl margin="normal" fullWidth variant="outlined">
          <Fab color="secondary" size="small" aria-label="add" onClick={() => props.setCreating(false)}>
            <CloseIcon/>
          </Fab>
        </FormControl>
      </Grid>
    </Grid>
  );
};
TemplateForm.propTypes = {
  productId: PropTypes.string.isRequired,
  addTemplate: PropTypes.func.isRequired,
  setCreating: PropTypes.func.isRequired,
};

export default TemplateForm;
