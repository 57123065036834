import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import HttpClient from "../HttpClient";
import Form from "./Form";
import { MarkingTemplateApi } from "@api/MarkingTemplateApi";
import { MarkingMethodApi } from "@api/MarkingMethodApi";
import { SupplierApi } from "@api/SupplierApi";

const initialState = {
  marking_template: {
    name: "",
    label: "",
    maximum_colors: 4,
    code: "",
    starting_cost: {
      in_price: "",
      out_price: "",
    },
    supplier: {},
    marking_price_lists: [],
  },
  currencies: [],
  marking_methods: [],
  suppliers: [],
};

const Edit = (props) => {
  if (props.match.params.id)
    initialState.marking_template.id = props.match.params.id;

  const [state, updateState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const [selectedNoOfColors, setSelectedNoOfColors] = useState([]);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setLoading(true);
      const currencies = await HttpClient.get("/currencies");
      const markingMethods = await MarkingMethodApi.getMarkingMethods({
        page: -1,
      });
      const suppliers = await SupplierApi.getSuppliers({
        select: true,
        page: -1,
      });

      let _markingTemplate = { ...initialState.marking_template };
      if (props.match.params.id)
        _markingTemplate = {
          ..._markingTemplate,
          ...(await MarkingTemplateApi.getMarkingTemplate(
            props.match.params.id,
          )),
        };

      if (suppliers.length)
        _markingTemplate.supplier =
          _markingTemplate.supplier && _markingTemplate.supplier.id
            ? _markingTemplate.supplier
            : { id: suppliers[0].id };
      if (currencies.length)
        _markingTemplate.currency =
          _markingTemplate.currency && _markingTemplate.currency.id
            ? _markingTemplate.currency
            : { id: currencies[0].id };
      if (markingMethods.data.length)
        _markingTemplate.marking_method =
          _markingTemplate.marking_method && _markingTemplate.marking_method.id
            ? _markingTemplate.marking_method
            : { id: markingMethods.data[0].id };
      if (!didCancel) {
        updateState({
          currencies: currencies,
          marking_methods: markingMethods.data,
          marking_template: _markingTemplate,
          suppliers: suppliers,
        });
        setSelectedNoOfColors(
          _markingTemplate.marking_price_lists
            ?.map((mpl) =>
              !!mpl.no_of_colors ? parseInt(mpl.no_of_colors) : null,
            )
            .filter((c) => c !== null) || [],
        );
      }
      setLoading(false);
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [props.match.params.id]);

  return (
    <Form
      created={props.location?.state?.created}
      state={state}
      loading={loading}
      history={props.history}
      selectedNoOfColors={selectedNoOfColors}
      setSelectedNoOfColors={setSelectedNoOfColors}
    />
  );
};

Edit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default Edit;
