import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { MarkingMethodApi } from '@api/MarkingMethodApi'
import Form from './Form'

const Edit = props => {
  const initialState = { id: props.match.params.id, name: '', description: '' }
  const [marking_method, updateMarkingMethod] = useState(initialState)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    MarkingMethodApi.getMarkingMethod(marking_method.id)
      .then(data => {
        updateMarkingMethod(data)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  },[marking_method.id])
  return(
    <Form created={props.location?.state?.created} marking_method={marking_method} loading={loading} history={props.history}/>
  )
}
Edit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default Edit
