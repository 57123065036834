import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FormContainer from './FormContainer'
import { CategoryApi } from '@api/CategoryApi'

const Edit = props => {
  const initialState = {
    name: '',
    name_plural: '',
    name_singular: '',
    permalink: '',
    custom_title: '',
    description: '',
    main: false,
    a_o: false,
    product_category: false,
    removed: false,
    priority: '',
    position: '',
    redirect: '',
    header: '',
    footer: '',
    info: '',
    footer_info: '',
    keywords: '',
    image: '',
    mp4_video: '',
    webm_video: '',
    video_poster: '',
    parent: {}
  }

  if(props.match.params.id)
    initialState.id = props.match.params.id
  const [category, updateCategory] = useState(initialState)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(props.match.params.id)
      setLoading(true)
      CategoryApi.getCategory(category.id)
        .then(data => {
          const keys = Object.keys(data)
          for ( const key of keys){
            data[key] = data[key] === null ? '' : data[key]
          }
          updateCategory({ ...category, ...data })
          setLoading(false)
        })
        .catch(error => setLoading(false))
  }, [])
  return(
    <FormContainer created={props.location?.state?.created} category={category} loading={loading} history={props.history}/>
  )
}

Edit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}
export default Edit
