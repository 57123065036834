import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import HttpClient from "@httpClient";

import { Grid, MenuItem, FormControl, TextField, Select, InputLabel, Button, FormControlLabel,
         Checkbox, Alert  } from '@mui/material'

import Mdivider from "@common/Mdivider";
import Values from "./Values";
import SelectInput from '@common/SelectInput'

import { AttributeApi } from "@api/AttributeApi";
import { useValidation } from '@customHooks/useValidation';
import useAlert from '@customHooks/useAlert'
import { handleValidationErrors } from '@utils/errorUtils';

const Form = (props) => {
  const [attribute, setAttribute] = useState(props.attribute);
  const [parentAttributes, setParentAttributes] = useState([]);
  const [inputTypes, setInputTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [alert, setAlert] = useAlert(props.created);

  const parentSelectDefaultItems = [ { key: "", value: "" }]

  const { t } = useTranslation();

  useEffect(() => {
    AttributeApi.getFormOptions(true).then((res) => {
      setParentAttributes([...parentAttributes, ...res.parents]);
      setInputTypes([...res.input_types]);
      res.input_types.length &&
        setAttribute({ ...attribute, type: res.input_types[0] });
    });
    HttpClient.get("/units", {}, true).then((res) => setUnits(res));
  }, []);

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: attribute.name, rules: { required: true } },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleSave();
    }
  };

  const setAttributeValues = (values) => {
    setAttribute({ ...attribute, p_attribute_values: values });
  };

  const handleSave = () => {
    attribute.type && handleCreate()
  };

  const handleCreate = () => {
    let _attribute = { ...attribute }
    _attribute.product = { id: props.productId };
    _attribute.product_specific = true;
    HttpClient.post("/attributes", { p_attribute: _attribute }, {}, true)
      .then(response => props.onSave(response))
      .catch((error) => handleValidationErrors(error, setValidationErrors, setAlert));
  };

  const parentElem = props.parent ? (
    <Grid item xs={4}>
      <SelectInput
        label={'Förälderattribut'}
        valuesArray={parentAttributes}
        defaultItems={parentSelectDefaultItems}
        value={attribute.parent.id}
        onChange={(e) => setAttribute({ ...attribute, parent: { id: e.target.value } })
      }/>
    </Grid>
  ) : null;

  return (
    <>
      {props.alert && (
        <Alert severity={props.alert.severity} onClose={props.clearAlert}>
          {props.alert.text}
        </Alert>
      )}
      <Grid container spacing={2}>
        {parentElem}
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Namn"
              value={attribute.name || ""}
              onChange={(e) => setAttribute({ ...attribute, name: e.currentTarget.value })}
              error={!!validationErrors.name}
              helperText={validationErrors.name}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="type">
              Typ
            </InputLabel>
            <Select
              labelId={'type'}
              label={'Typ'}
              value={`${attribute.type}`}
              onChange={(e) => setAttribute({ ...attribute, type: e.target.value })}>
              {inputTypes.map( typ => (
                <MenuItem key={typ} value={typ}>
                  {t(typ)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="unit">
              Enhet
            </InputLabel>
            <Select
              labelId={'unit'}
              label={'Enhet'}
              value={`${attribute.unit.id}`}
              onChange={(e) => setAttribute({ ...attribute, unit: { id: e.target.value } })}>
              <MenuItem key="0" value=""></MenuItem>
              {units.map((unit) => (
                <MenuItem key={unit.id} value={unit.id}>
                  {`${unit.name}(${unit.symbol})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={attribute.in_article_number}
                onChange={(e) =>
                  setAttribute({
                    ...attribute,
                    in_article_number: e.target.checked,
                  })
                }
                name="checkedB"
                color="primary"
              />
            }
            label={t("attributes.inArticleNum")}
          />
        </Grid>
      </Grid>
      {!attribute.type ||
      attribute.type === "text" ||
      attribute.type === "number" ||
      attribute.type === "switch" ? (
        <></>
      ) : (
        <>
          <Mdivider>
            <span style={{ textTransform: "capitalize" }}>
              {t("attributes.values")}
            </span>
          </Mdivider>
          <Values
            values={attribute.p_attribute_values}
            setAttributeValues={setAttributeValues}
          />
        </>
      )}
      <Grid container>
        <Grid item xs={12} align="right">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Spara
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

Form.propTypes = {
  attribute: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  parent: PropTypes.bool,
  alert: PropTypes.object,
  clearAlert: PropTypes.func,
};

export default Form;
