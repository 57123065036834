import i18n from "@src/i18n";


// validationUtils.js
export const validateField = (name, value, rules) => {
  const errors = [];
  
  if (rules.required && !value) {
    errors.push(i18n.t("validations.required"));
  }
  
  if (rules.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    errors.push(i18n.t("validations.email"));
  }

  if (rules.greather_than_0 && value <= 0) {
    errors.push(i18n.t("validations.greater_than_zero"));
  }

  return errors.length ? errors.join(" ") : null;
};
