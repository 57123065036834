import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Box, Grid, Typography, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Mdivider from '@common/Mdivider'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  table: { width: '100%' },
  priceListLabel: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200]
  },
  priceList: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  priceRow: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200]
  },
  outPrice: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  }
}))

const PriceLists = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant='h5' gutterBottom>{t("products.prices")}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Box display="flex" justifyContent={'space-between'}>
            <Grid container>
              { props.quantities.map(q => <Grid item xs={props.quantities.length > 6 ? 1 : 2} sx={{textAlign: 'center'}}><Typography variant="body1">{q.value}</Typography></Grid>) }
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Mdivider />
      <Box mb={1}>
        { props.priceLists.map(pricelist => (
          <Paper key={pricelist.id} mb={1} className={classes.priceList}>
            {pricelist.label && <Box className={classes.priceListLabel}>
              <Typography variant="subtitle1">{pricelist.label}</Typography>
            </Box>}

            {pricelist.price_list_rows.map(row => (
              <Box key={row.id} className={classes.priceRow}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    {row.supplier && <Link target="_blank" to={`/suppliers/${row.supplier.id}`}>
                      <Typography variant="body2">{row.supplier.name}</Typography></Link>}
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container>
                      {row.prices.map(price => <Grid item xs={row.prices.length > 6 ? 1 : 2} sx={{textAlign: 'center'}}><Typography variant="body2">{price.value}</Typography></Grid>) }
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Box className={classes.outPrice}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="body1">{t("products.price")}</Typography>
                </Grid>
                <Grid item container xs={10}>
                  {pricelist.quantity_prices.map(price => <Grid item xs={pricelist.quantity_prices.length > 6 ? 1 : 2} sx={{textAlign: 'center'}} key={price.id}><Typography variant="body1">{price.price}</Typography></Grid>) }
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )) }
      </Box>
      <Mdivider />
      <Typography variant='h5' gutterBottom>{t("products.markingPrices")}</Typography>
      <Box mb={1}>
        { props.printingPrices.map(printingTemplate => (
          <Paper key={printingTemplate.id} mb={1} className={classes.priceList}>
            {printingTemplate.name && <Box className={classes.priceListLabel}>
              <Typography variant="subtitle1">{printingTemplate.name}</Typography>
            </Box>}
            {printingTemplate.lists.map(row => (
              <Box key={row.id} className={classes.priceRow}>
                <Grid container>
                  <Grid item xs={2}><Typography variant="body2">{row.name}</Typography></Grid>
                  <Grid item container xs={10}>
                    {row.prices.map(price => <Grid item xs={row.prices.length > 6 ? 1 : 2} sx={{textAlign: 'center'}} key={price.id}><Typography variant="body2">{price.price}</Typography></Grid>) }
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Paper>
        )) }
      </Box>
    </div>
  )
}

PriceLists.propTypes = {
  priceLists: PropTypes.array.isRequired,
  printingPrices: PropTypes.array.isRequired,
  quantities: PropTypes.array.isRequired
}

export default PriceLists
