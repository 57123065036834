import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import { Container, Paper, Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'

import HttpClient from '@httpClient'
import TitleBar from '@components/topbar/TitleBar'
import LoaderList from '@common/Loaders/LoaderList'
import { TagApi } from '@api/TagApi'
import ListTable from '@common/ListingTable/ListTable'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import { getSerialNumber } from '@components/SharedComponentFunctions'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { ProductSorting } from '@config/SortingLabels'
import { useDebounce } from '@customHooks/useDebouncing'
import Tag from '@components/tags/Tag'
import { makeStyles } from '@mui/styles'

const List = props => {
  const tagsPerPage = getUserPreference( 'page_size', 'tagList')
  const [tags, setTags] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( tagsPerPage !== '' ? tagsPerPage : 20)
  const [loading, setLoading] = useState(false)
  const [tagsCount, setTagsCount] = useState(0)
  const [sortOptionSelected, setSortOptionSelected] = useState('latest')
  const [searchTerm, setSearchTerm] = useState('')
  const [actionLoading, setActionLoading] = useState(0)
  const { t } = useTranslation()

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const classes = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    table: { width: '100%' },
  }))()

  const handleSearchingAndSortingChange = (type, value) => {
    if (type === 'sort'){
      setSortOptionSelected(value)
      getTags(searchTerm, value, 1, perPage)
    } else if(type === 'search') {
      setSearchTerm(value)
    } else if(type === 'page'){
      setPage(value)
      getTags(searchTerm, sortOptionSelected, value, perPage)
    } else if (type === 'pageSize'){
      setPerPage(value)
      setPage(1)
      setUserPreference('page_size', 'tagList', value)
      getTags(searchTerm, sortOptionSelected, 1, value)
    }
  }

  useEffect(() => {
    let didCancel = false
    getTags(searchTerm, sortOptionSelected, 1, perPage)
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const getTags = (searchQuery, sortOption, pageNo, perPageCount) => {
    setLoading(true)
    TagApi.getTags({ query: searchQuery, page: pageNo, per_page: perPageCount, sort_by: sortOption }).then(data => {
      setTags(data.tags)
      setTagsCount(data.total_count)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  const convertToCategory = async tagId => {
    setActionLoading(tagId)
    TagApi.convertTagToCategory(tagId).then(()=>{
      setActionLoading('')
      setTags(tags.filter(c => c.id !== tagId))
    }).catch(err => console.log(err))
  }

  const removeTag = id => {
    HttpClient.delete(`/tags/${id}`).then(() => {
      setTags(tags.filter(c => c.id !== id))
    }).catch(err => console.log(err))
  }

  const tagsTableContent = () => {
    const tagsBody = tags?.map((tag, index) => (
      <Tag
        key={tag.id}
        {...tag}
        deleteTag={removeTag}
        index={getSerialNumber(page, perPage, index)}
        convertToCategory={convertToCategory}
        actionLoading={actionLoading}
      />
    ));
    return { body: tagsBody, heading: ['#', t('tags.tableHeadings.name'), t('tags.tableHeadings.products'), '', ''] }
  }

  const tableContent = tagsTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Tags">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/tags/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={ProductSorting}
          searchText={t('tags.placeholders.search_tag')}
          getData={handleSearchingAndSortingChange}
        />
        {loading ? <LoaderList/> :
          <Paper square className={classes.root} style={{marginTop: '15px'}}>
            <ListTable
              tableHeadings={tableContent.heading}
              tableContent={tableContent.body}
              count={tagsCount}
              pageNo={page}
              rowsPerPage={perPage}
              onPageChange={(event, pageNo) => handleSearchingAndSortingChange('page', pageNo + 1)}
              handleChangeRowsPerPage={(event) => handleSearchingAndSortingChange('pageSize', event.target.value)}
              rowsControl={true} />
          </Paper>
        }
      </Container>
    </React.Fragment>
  )
}

List.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default List
