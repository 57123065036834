import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MarkingPrice from "./MarkingPrice";
import Fab from "@mui/material/Fab";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import HttpClient from "../HttpClient";
import OutlinedInput from "@mui/material/OutlinedInput";
import Mdivider from "../common/Mdivider";

const MarkingPriceList = (props) => {
  const noOfColorsLabel = useRef(null);
  const [noOfColorsLabelWidth, setNoOfColorsLabelWidth] = useState(0);
  useEffect(() => {
    setNoOfColorsLabelWidth(noOfColorsLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    setPriceList({
      ...markingPriceList,
      no_of_colors:
        markingPriceList.no_of_colors > props.maximum_colors
          ? ""
          : markingPriceList.no_of_colors,
    });
  }, [props.selectedNoOfColors.join(",")]);

  const [markingPriceList, setPriceList] = useState(props.markingPriceList);

  const addMarkingPrice = () => {
    const markingPrices = [
      ...markingPriceList.marking_prices,
      {
        kid: `id-${markingPriceList.marking_prices.length + 1}`,
        our_price: "",
        customer_price: "",
        quantity: "",
      },
    ];
    setPriceList({
      ...markingPriceList,
      marking_prices: markingPrices,
    });
  };

  const saveMarkingPrice = (price) => {
    const index = markingPriceList.marking_prices.findIndex((p) =>
      p.id ? p.id === price.id : p.kid === price.kid,
    );
    markingPriceList.marking_prices[index] = price;
    setPriceList({ ...markingPriceList });
  };

  const toSystemPrice = (p) => ({
    ...p,
    our_price: parseFloat(String(p.our_price).replace(",", ".")),
    customer_price: parseFloat(String(p.customer_price).replace(",", ".")),
  });

  const savePriceList = () => {
    const list = {
      ...markingPriceList,
      marking_prices: markingPriceList.marking_prices.map((p) =>
        toSystemPrice(p),
      ),
    };
    if (markingPriceList.id) {
      HttpClient.put(
        `/marking_templates/${props.markingTemplateId}/marking_price_lists/${markingPriceList.id}`,
        { marking_price_list: list },
      )
        .then((response) => setPriceList(response))
        .catch((error) =>
          console.log("Error updating marking price lists: ", error),
        );
    } else {
      HttpClient.post(
        `/marking_templates/${props.markingTemplateId}/marking_price_lists`,
        { marking_price_list: list },
      )
        .then((response) => setPriceList(response))
        .catch((error) =>
          console.log("Error creating marking price lists: ", error),
        );
    }
  };

  return (
    <React.Fragment>
      <Mdivider />
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <TextField
              fullWidth
              label="Namn"
              value={markingPriceList.name}
              onChange={(e) =>
                setPriceList({
                  ...markingPriceList,
                  name: e.currentTarget.value,
                })
              }
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <InputLabel ref={noOfColorsLabel} htmlFor="attribute">
              Färger
            </InputLabel>
            <Select
              native
              value={`${markingPriceList.no_of_colors}`}
              onChange={(e) => {
                const { selectedNoOfColors, setSelectedNoOfColors } = props;

                const currentNoOfColors = !!markingPriceList.no_of_colors
                  ? parseInt(markingPriceList.no_of_colors)
                  : "";
                const newList = selectedNoOfColors.filter(
                  (n) => n !== currentNoOfColors,
                );

                let noOfColors = "";
                if (!!e.currentTarget.value) {
                  noOfColors = parseInt(e.currentTarget.value);
                  newList.push(noOfColors);
                }

                setSelectedNoOfColors(newList);
                setPriceList({ ...markingPriceList, no_of_colors: noOfColors });
              }}
              input={
                <OutlinedInput
                  name="no_of_colors"
                  id="no_of_colors"
                  labelWidth={noOfColorsLabelWidth}
                />
              }
            >
              <option value=""></option>
              {Array.from(
                { length: props.maximum_colors },
                (_, i) => i + 1,
              ).map((c) => (
                <option
                  key={c}
                  value={c}
                  disabled={props.selectedNoOfColors.includes(c)}
                >
                  {c}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <Switch
              checked={String(markingPriceList.active) === String(true)}
              onChange={(e) =>
                setPriceList({ ...markingPriceList, active: e.target.checked })
              }
              value={true}
              color="primary"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right">
          <Button variant="contained" color="primary" onClick={savePriceList}>
            Spara
          </Button>
          <IconButton
            color="secondary"
            onClick={() => props.onDelete(markingPriceList.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {markingPriceList.marking_prices.map((price) => (
          <MarkingPrice
            key={price.id || price.kid}
            price={price}
            onChange={saveMarkingPrice}
          />
        ))}
        <Grid item xs={1}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <Fab size="small" aria-label="add" onClick={addMarkingPrice}>
              <AddIcon />
            </Fab>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

MarkingPriceList.propTypes = {
  markingPriceList: PropTypes.object.isRequired,
  markingTemplateId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedNoOfColors: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedNoOfColors: PropTypes.func,
  maximum_colors: PropTypes.number,
};

export default MarkingPriceList;
