import { useState, useEffect } from "react"
import { setCreatedAlert } from "@helpers/helperMethods"

const useAlert = (created) => {
  const [alert, setAlert] = useState(created)

  useEffect(() => {
    setCreatedAlert(created, setAlert)
  },[created])

  return [alert, setAlert];
};

export default useAlert;
