import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Fab from '@mui/material/Fab'
import DeleteIcon from '@mui/icons-material/Delete'

import AsyncAutocomplete from '../common/AsyncAutoComplete'
import FileInput from '../common/FileInput'
import Mdivider from '../common/Mdivider'
import HttpClient from '../HttpClient'

const Product = props => {
  
  const [product, setProduct] = useState({ ...props.product })
  const [updating, setUpdating] = useState(false)

  const saveChange = event => {
    const prod = { ...product }
    const field = event.target.name
    switch (event.target.type) {
      case 'file':
        prod[field] = event.target.files[0]
        break
      default:
        prod[field] = event.target.value
    }

    const formData = new FormData()
    for (const [field, value] of Object.entries(prod)) {
      if(field === 'image') {
        if(value !== '') {
          formData.append(`homepage_product[${field}]`, value)
        }
      } else {
        formData.append(`homepage_product[${field}]`, value)
      }
    }
    // disable button while uploading
    setUpdating(true)
    HttpClient.put(`/homepage_products/${product.id}`,  formData)
      .then(res => {
        setProduct(res)
        setUpdating(false)
      })
  }

  const searchedProductUpdate = selected => {
    setUpdating(true)
    HttpClient.put(`/homepage_products/${product.id}`, { homepage_product: { ...product, product: { id: selected.id } } })
      .then(res => {
        setProduct(res)
        setUpdating(false)
      })
  }

  const imageHtml = (product.id && product.image_url) ? (
    <a href={product.image_url} target='_blank' rel='noopener noreferrer'>
      <img src={product.thumbnail_admin} alt={product.name} />
    </a>
  ) : null

  const productLink = (product.product && product.product.id) ? (
    <a href={`/homepage/products/${product.product.id}`} target='_blank' rel='noopener noreferrer'>{product.product.id}</a>
  ): null
  return (
    <Grid container spacing={2}>
      <Grid item xs={1}>
        {productLink}
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <AsyncAutocomplete defaultInputValue={product.product && product.product.name || ''} optionsUrl='/products/search?term' onChange={searchedProductUpdate} />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <div>{imageHtml}</div>
        <FileInput
          icon={true}
          onChange={saveChange}
          name='image'
          disabled={updating}
        />
      </Grid>
      <Grid item xs={1}>
        <Fab
          onClick={() => {if(window.confirm('Är du säker?')) props.onDelete(product.id)}}
          color="secondary"
          size="small">
          <DeleteIcon />
        </Fab>
      </Grid>
      <Mdivider/>
    </Grid>
  )
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default Product
