import HttpClient from "../components/HttpClient"
import { setUrlForGetRequest } from '@api/SharedFunctions'

const MARKING_TEMPLATE_API = '/marking_templates'

const getMarkingTemplates = (params) => {
  return HttpClient.get(setUrlForGetRequest(MARKING_TEMPLATE_API, params))
}

const getMarkingTemplate = (id) => {
  return HttpClient.get(`${MARKING_TEMPLATE_API}/${id}`)
}

const removeMarkingTemplate = (id) => {
  return HttpClient.delete(`${MARKING_TEMPLATE_API}/${id}`)
}

const createMarkingTemplate = (data) => {
  return HttpClient.post(`${MARKING_TEMPLATE_API}`, data)
}

const updateMarkingTemplate = (id, data) => {
  return HttpClient.put(`${MARKING_TEMPLATE_API}/${id}`, data)
}

//Encapsulating in a JSON object
const MarkingTemplateApi = {
  getMarkingTemplate,
  getMarkingTemplates,
  removeMarkingTemplate,
  createMarkingTemplate,
  updateMarkingTemplate,
}

export {MarkingTemplateApi}
