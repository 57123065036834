import React, { useState, useEffect, useContext, useReducer } from 'react'
import PropTypes from "prop-types";

import { Grid, Box, Card, CardContent, Button, Typography, Fab } from '@mui/material'
import AddIcon from "@mui/icons-material/Add";

import HttpClient from "@httpClient";

import Quantity from "./Quantity";
import PriceList from "../price_lists/PriceList";
import FilteredPrintingPricing from "./FilteredPrintingPricing";
import CustomPrintingPricing from "./CustomPrintingPricing";
import Loader from "@common/Loaders/LoaderList";
import { SupplierProductApi, ProductApi } from "@api";
import ProductResourceContext from "@contexts/ProductResource";
import { initialState, pricingReducer } from '@components/pricing/pricingReducer'

const Pricing = (props) => {
  const productResource = useContext(ProductResourceContext);
  const apiType = productResource === "supplier_products" ? SupplierProductApi : ProductApi;
  const supplierProductsView = apiType === SupplierProductApi;
  const [product, dispatch] = useReducer(pricingReducer, initialState(props.match.params.id))
  const [suppliers, setSuppliers] = useState([]);
  const [printingPrices, setPrintingPrices] = useState([]);
  const [productAttributes, setProductAttributes] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    Promise.all([
      apiType.getProductQuantities(product.id),
      apiType.getProductPriceLists(product.id),
      apiType.getProductPrintingPrices(product.id),
      HttpClient.get("/suppliers?page=-1"),
      HttpClient.get(
        `/${props.supplierProductsView ? 'supplier_products' : 'products'}/${
          product.id
        }/product_attributes?product_type=${
          props.supplierProductsView ? 'supplier_product' : 'product'
        }`
      )
    ]).then(([quantities, priceLists, printingData, suppliersResponse, productAttributesResponse]) => {
      if (!didCancel) {
        dispatch({ type: 'INITIAL_REQUEST', quantities: quantities, priceLists: priceLists})
        setProductAttributes(productAttributesResponse)
        setPrintingPrices(printingData);
        setSuppliers((s) => [...s, ...suppliersResponse.data]);
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, [product.id]);

  const addNewQuantity = () => dispatch({ type: 'ADD_QUANTITY' })

  const addPriceList = () => dispatch({ type: 'ADD_PRICELIST', payload: { suppliers } })

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card sx={{ marginBottom: '20px' }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={2} >
                  <Box display="flex" justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
                    <Typography variant="h5" gutterBottom>
                      Quantities
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box display="flex">
                    {product.quantities.map((q) => (
                      <Quantity
                        supplierProductsView={supplierProductsView}
                        key={q.kid || q.id}
                        productId={product.id}
                        quantity={q}
                        onCreateQuantity={ newQuantity => dispatch({ type: 'NEW_QUANTITY_ADDED', newQuantity: newQuantity })}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid container item xs={1} alignItems="center" justify="center">
                  <Fab
                    disabled={supplierProductsView}
                    color="primary"
                    size="small"
                    aria-label="add"
                    onClick={addNewQuantity}
                    style={{ justifyContent: "center", marginLeft: '10px' }}>
                    <AddIcon />
                  </Fab>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {product.price_lists.map((pl, index) => (
            <PriceList
              key={pl.id || pl.kid}
              supplierProductsView={supplierProductsView}
              productId={product.id}
              priceList={pl}
              quantities={product.quantities}
              suppliers={suppliers}
              showAttributes={index > 0}
              productAttributes={productAttributes}
            />
          ))}

          <Button disabled={supplierProductsView} variant={'contained'} color={'primary'} onClick={addPriceList} sx={{margin: '20px 0px'}}>
            Add Price List
          </Button>

          <Typography variant="h5" gutterBottom>
            Markningpriser
          </Typography>

          {printingPrices.map((template) => (
            <FilteredPrintingPricing key={template.id} template={template} />
          ))}

          <CustomPrintingPricing product={product} />
        </>
      )}
    </>
  );
};

Pricing.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Pricing;
