import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Container } from '@mui/material'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import { TableRow, TableCell } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import HttpClient from '@httpClient'
import TitleBar from '@components/topbar/TitleBar'
import AlphabeticalFilter from '@common/AlphabeticalFilter'
import Loader from '@common/Loaders/LoaderList'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import ListTable from '@common/ListingTable/ListTable'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { useDebounce } from '@customHooks/useDebouncing'
import { useTranslation } from 'react-i18next'
import { BrandApi } from '@api/BrandApi'
import { ProductSorting } from '../../config/SortingLabels'

const List = props => {
  const brandsPerPage = getUserPreference( 'page_size', 'brand')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( brandsPerPage !== '' ? brandsPerPage : 20)
  const [totalCount, setTotalCount] = useState(0)
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const filter = new URLSearchParams(props.location.search).get('filter') || 'A'
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOptionSelected, setSortOptionSelected] = useState('latest')
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    let didCancel = false
    if (searchTerm) {
      fetchBrands({ page: 1, per_page: perPage, query: searchTerm });
    }
    else {
      fetchBrands({ filter, page: 1, per_page: perPage });
    }
    return () => {
      didCancel = true
      HttpClient.abort()
    }
  }, [debouncedSearchTerm])

  const fetchBrands = async (params) => {
    setLoading(true)
    if (!params.sort_by) {
      params['sort_by'] = sortOptionSelected
    }
    try {
      const response = await BrandApi.getBrands(params)
      if(response) {
        setBrands(response.data)
        setTotalCount(response.total_count)
      }
    }
    catch(error) {
      console.log("error", error)
    }
    finally {
      setLoading(false)
    }
  }

  const handleSearchParamsChange = (type, value) => {
    switch (type) {
      case "page":
        setPage(value);
        fetchBrands({ filter, page: value, per_page: perPage });
        break;

      case "pageSize":
        setPerPage(value);
        setPage(1);
        setUserPreference("page_size", "brand", value);
        fetchBrands({ filter, page: 1, per_page: value });
        break;

      case "sort":
        setSortOptionSelected(value)
        fetchBrands({ filter, page: 1, per_page: perPage, sort_by: value });
        break;

      case "search":
        setSearchTerm(value)
        break;
    }
  };

  const removeBrand = id => {
    if(window.confirm('Är du säker?')) {
      BrandApi.removeBrand(id).then(() => {
        setBrands(brands.filter(b => b.id !== id))
      })
    }
  }

  const classes = {
    root: {
      width: '100%',
      overflowX: 'auto'
    },
  }


  const brandsTableContent = () => {
    const brandsBody = brands?.map((brand) => (
      <TableRow key={brand.id}>
        <TableCell>
          <Typography noWrap>
            <Link to={`brands/${brand.id}/edit`}>{brand.name}</Link>
          </Typography>
        </TableCell>
        <TableCell>{brand.description}</TableCell>
        <TableCell>{brand.thumbnail_url ? (<img src={brand.thumbnail_url} alt={brand.name} />) :null}</TableCell>
        <TableCell align="right">
          <IconButton onClick={() => removeBrand(brand.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
    return { body: brandsBody, heading: ['Namn', 'Beskrivning', 'Logo', ''] }
  }

  const tableContent = brandsTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Märken">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/brands/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        {!searchTerm && <AlphabeticalFilter resource="brands" currentFilter={filter} />}
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={ProductSorting}
          searchText={t('brands.placeholders.search_label')}
          getData={handleSearchParamsChange} />
        {loading ? <Loader /> : 
          <Paper square sx={classes.root}>
            <ListTable
              tableHeadings={tableContent.heading}
              tableContent={tableContent.body}
              count={totalCount}
              pageNo={page}
              rowsPerPage={perPage}
              onPageChange={(event, pageNo) => handleSearchParamsChange('page', pageNo + 1)}
              handleChangeRowsPerPage={(event) => handleSearchParamsChange('pageSize', event.target.value)}
              rowsControl={true} />
          </Paper>
        }
      </Container>
    </React.Fragment>
  )
}

List.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default List
