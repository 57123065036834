import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import AsyncSelect from "react-select/async";

import HttpClient from "../HttpClient";

const AsyncAutoComplete = (props) => {
  const fetchResultsDebouncingTimer = useRef(null);
  const [inputValue, setInputValue] = useState(props.defaultInputValue ?? "");

  const handleChange = (opt) => {
    setInputValue("");
    props.onChange(opt);
  };

  const promiseOptions = (inputValue, callback) => {
    if (fetchResultsDebouncingTimer.current)
      clearTimeout(fetchResultsDebouncingTimer.current);
    fetchResultsDebouncingTimer.current = setTimeout(async () => {
      if (!!props.asyncOptions) {
        props.asyncOptions(inputValue).then((res) => callback(res));
      } else {
        _promiseOptions(inputValue).then((res) => callback(res));
      }
    }, props.debouncingDelay ?? 500);
  };

  const _promiseOptions = async (inputValue) => {
    const res = await HttpClient.get(`${props.optionsUrl}=${inputValue}`);
    return res.map((a) => ({ label: a.name, value: a.id, ...a }));
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={promiseOptions}
      defaultInputValue={props.defaultInputValue}
      onChange={handleChange}
      inputValue={inputValue}
      onMenuClose={() => {
        props.persistInputOnMenuClose
          ? setInputValue(inputValue)
          : setInputValue("");
      }}
      onInputChange={(newValue) => setInputValue(newValue)}
    />
  );
};

AsyncAutoComplete.propTypes = {
  optionsUrl: PropTypes.string,
  defaultInputValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  exclude: PropTypes.array, // if want to exclude some options
  asyncOptions: PropTypes.func,
  persistInputOnMenuClose: PropTypes.bool,
};

export default AsyncAutoComplete;
