import { useState, useEffect } from 'react';
import { useDebounce } from './useDebouncing'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'

const useListingActions = (pageType, apiFunction, defaultSortOption='latest', getExtraParmsList = () => ({}), listingPage = true, editPageId= null) => {
  const defaultPerPage = getUserPreference( 'page_size', pageType) || 20;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOptionSelected, setSortOptionSelected] = useState(defaultSortOption);
  const [loading, setLoading] = useState(false)

  const debounceSearchTerm = useDebounce(searchTerm, 500)

  const fetchData = async (params, parentCall=false) => {
    setLoading(true)
    if (!parentCall){
      // If the call is not from the parent itself then fetch extra parameters to send if there are any
      const extraParams = getExtraParmsList()
      params = {...params, ...extraParams}
    }

    const result = listingPage ? await apiFunction(params) : await apiFunction(editPageId, params)

    setData(result.data);
    setCount(result.count);
    setLoading(false)
  };

  useEffect(() => {
    setPage(1)
    let params = { page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected }
    fetchData(params);
  }, [debounceSearchTerm]);

  const handleSearchingAndSortingChange = (type, value) => {
    let params = {}
    if (type === 'sort'){
      setPage(1)
      params = { page: 1, per_page: perPage, q: searchTerm, sort_by: value }
      setSortOptionSelected(value)
      fetchData(params)
    } else if(type === 'search') {
      setSearchTerm(value)
    } else if(type === 'page'){
      setPage(value)
      params = { page: value, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected }
      fetchData(params)
    } else if (type === 'pageSize'){
      setPerPage(value)
      setPage(1)
      setUserPreference('page_size', pageType, value)
      params = { page: 1, per_page: value, q: searchTerm, sort_by: sortOptionSelected }
      fetchData(params)
    }
  }

  return {
    data,
    setData,
    count,
    setCount,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    setPage,
    loading,
    setLoading,
    handleSearchingAndSortingChange,
    fetchData,
  };
};

export default useListingActions;
