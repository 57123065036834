import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import FileInput from "@components/common/FileInput";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ColorChip from "@components/colors/ColorChip";
import Select from "react-select";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InlineLoader from "@components/common/Loaders/InlineLoader";
import { parseErrorMessage, singularize } from "@src/utils/sharedHelper";
import PopupAlert from "@contexts/PopupAlert";
import ProductResourceContext from "@contexts/ProductResource";
import { ProductApi } from "@src/apis";
import { PrintOptionApi } from "@src/apis/PrintOptionApi";

const PrintOptionImages = ({
  productId,
  printOption,
  addImage: addImageInPrintOption,
  deleteImage: deleteImageFromPrintOption,
}) => {
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);
  const productResource = useContext(ProductResourceContext);
  const isSupplierProduct = productResource === "supplier_products";

  const initialFormState = { color_id: "", file: null };
  const [creationMode, setCreationMode] = useState(false);
  const [colors, setColors] = useState([]);

  const formik = useFormik({
    initialValues: initialFormState,
    onSubmit: addImage,
    validate: (values) => {
      const errors = {};
      if (!values.color_id) {
        errors.color_id = [t("printOptions.formValidation.color.presence")];
      }
      return errors;
    },
  });
  const {
    values,
    errors,
    isSubmitting,
    setFieldValue,
    setErrors,
    setSubmitting,
  } = formik;

  useEffect(() => {
    ProductApi.getColors(productId, productResource)
      .then((res) => setColors(res.data))
      .catch((res) => {
        addPopupAlert(
          t("actions.loadColors"),
          "error",
          parseErrorMessage(res.message),
        );
      });
  }, []);

  async function addImage(values) {
    const formData = new FormData();
    for (const [field, value] of Object.entries({
      ...values,
      product_type: singularize(productResource),
    })) {
      formData.append(field, value);
    }
    return PrintOptionApi.addImage(
      printOption.id,
      productId,
      formData,
      productResource,
    )
      .then((res) => {
        setCreationMode(false);
        formik.resetForm({
          values: initialFormState,
        });
        addImageInPrintOption(printOption, res.image);
        addPopupAlert(
          t("printOptions.errors.imageAddition"),
          "success",
          parseErrorMessage(t("printOptions.feedback.imageAdditionSuccessful")),
        );
      })
      .catch((res) => {
        addPopupAlert(
          t("printOptions.errors.imageAddition"),
          "error",
          parseErrorMessage(res.message),
        );
      });
  }

  const removeImage = (uid) => {
    setSubmitting(true);
    PrintOptionApi.deleteImage(printOption.id, productId, uid, productResource)
      .then((res) => {
        setSubmitting(false);
        deleteImageFromPrintOption(printOption, uid);
      })
      .catch((res) => {
        setSubmitting(false);
        addPopupAlert(
          t("printOptions.errors.imageRemoval"),
          "error",
          parseErrorMessage(res.message),
        );
      });
  };

  return (
    <>
      <Grid container alignItems="center">
        <Typography variant="h6" element="h6">
          {t("vocabulary.pictures")}
        </Typography>

        {!creationMode && (
          <Grid item sx={{ ml: 0.5 }}>
            <IconButton
              color="primary"
              aria-label="add"
              style={{
                backgroundColor: "lightblue",
                borderRadius: "50%",
                width: "28px",
                height: "28px",
              }}
              onClick={() => {
                setCreationMode(true);
              }}
              disabled={isSupplierProduct}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        )}

        {isSubmitting && (
          <Grid item sx={{ ml: 1 }}>
            <InlineLoader size={20} />
          </Grid>
        )}
      </Grid>

      {!!errors.color_id?.length && (
        <Alert onClose={() => setErrors({})} severity="error">
          <AlertTitle>{t("vocabulary.error")}</AlertTitle>
          <ul>
            {errors.color_id.map((err_msg) => (
              <li key={err_msg}>{err_msg}</li>
            ))}
          </ul>
        </Alert>
      )}

      {creationMode && (
        <Grid container alignItems="center" spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={5}>
            <Select
              options={colors?.map((color) => {
                return {
                  label: `${color.name}${
                    !!color.code ? ` - #${color.code}` : ""
                  }`,
                  value: color.id,
                };
              })}
              onChange={(color) => setFieldValue("color_id", color.value)}
            />
          </Grid>

          <Grid item xs={3}>
            {!values.file && (
              <FileInput
                onChange={(e) => setFieldValue("file", e.target.files[0])}
                accept="image/*"
              />
            )}

            {!!values.file && (
              <Grid container alignItems="center">
                <span>{values.file.name}</span>
                <IconButton
                  color="secondary"
                  onClick={() => setFieldValue("file", null)}
                  sx={{ height: "15px", width: "15px", ml: 2 }}
                  disabled={isSubmitting}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={formik.submitForm}
              disabled={isSubmitting}
            >
              {t("actions.save")}
            </Button>
          </Grid>
        </Grid>
      )}

      {!!printOption?.images.length && (
        <TableContainer sx={{ mt: 1 }} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Bild</TableCell>
                <TableCell align="center">Färger</TableCell>
                <TableCell align="center">Märkningsmetod</TableCell>
                <TableCell align="center">Handlingar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {printOption?.images.map((image) => (
                <TableRow
                  key={image.url}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" scope="image">
                    <a
                      href={image.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "80px",
                        }}
                        alt={t("printOptions.feedback.imageForPrintOption")}
                        src={image.thumbnail_url}
                      />
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    {image.colors?.map((color) => (
                      <ColorChip
                        key={color.id}
                        color={color}
                        deleteIcon={<></>}
                        onDelete={() => {}}
                        onSave={() => {}}
                        onClick={() => {}}
                      />
                    ))}
                  </TableCell>
                  <TableCell align="center">
                    {image.marking_method?.name ?? "--"}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        if (
                          window.confirm(
                            t("printOptions.confirmation.deleteImage"),
                          )
                        ) {
                          removeImage(image.uid);
                        }
                      }}
                      aria-label="delete"
                      disabled={isSupplierProduct}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

PrintOptionImages.propTypes = {
  productId: PropTypes.string.isRequired,
  printOption: PropTypes.object,
  addImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
};

export default PrintOptionImages;
