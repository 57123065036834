import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import Fab from '@mui/material/Fab'

import SlideImage from './SlideImage'
import HttpClient from '../HttpClient'
import Mdivider from '../common/Mdivider'

const SlideImages = props => {
  const [state, setState] = useState({
    images: [],
    creating: false
  })

  useEffect(() => {
    let didCancel = false
    HttpClient.get('/slide_images').then((data) => {
      if(!didCancel) {
        setState({...state, images: data})
      }
    })

    return () => { didCancel = true }
  }, [])

  const newImages = event => {
    const files = event.target.files
    for (var i = 0; i < files.length; i++) {
      const file = files.item(i)
      const formData = new FormData()
      formData.append('slide_image[image]', file)
      HttpClient.post('/slide_images', formData).then(res =>{
        setState({...state, images: [...state.images, res]})
      })
    }
  }

  const deleteImage = id => {
    const images = state.images.filter(i => i.id !== id)
    if(id) {
      HttpClient.delete(`/slide_images/${id}`).then(res => {
        setState({...state, images: images })
      })
    } else {
      setState({...state, images: images })
    }
  }

  const updateImage = (id, updatedImage) => {
    let images = [...state.images]
    const imageIndex = images.findIndex(i => i.id === id)
    images[imageIndex] = updatedImage
    setState({ images })
  }

  const shouldCancelStart = (e) => {
    // Cancel sorting if the event target is an `input`, `textarea`, `select`,`option` or `button`
    const disabledElements = ['input', 'textarea', 'select', 'option', 'button', 'a', 'svg', 'span']
    if (disabledElements.indexOf(e.target.tagName.toLowerCase()) !== -1 || disabledElements.indexOf(e.target.parentNode.tagName.toLowerCase()) !== -1) {
      return true // Return true to cancel sorting
    }
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    // console.log(event.target)
    const sortedImages = arrayMove(state.images, oldIndex, newIndex)
    HttpClient.post('/slide_images/sort', {slide_images: sortedImages.map(i => i.id)}).then(res => {
      setState({...state, images: sortedImages})
    })
  }

  return (
    <Container>
      <Grid container >
        <Grid container spacing={2}>
          <Grid item xs={1}><Typography varient='h3'>Bild</Typography></Grid>
          <Grid item xs={3}><Typography varient='h3'>URL</Typography></Grid>
          <Grid item xs={2}><Typography varient='h3'>Titel</Typography></Grid>
          <Grid item xs={2}><Typography varient='h3'>Sekundär titel</Typography></Grid>
          <Grid item xs={1}><Typography varient='h3'>Färg</Typography></Grid>
          <Grid item xs={1}><Typography varient='h3'>Tid</Typography></Grid>
          <Grid item xs={1}><Typography varient='h3'>Publik</Typography></Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Mdivider />
        <SortableList
          items={state.images}
          deleteImage={deleteImage}
          updateImage={updateImage}
          shouldCancelStart={shouldCancelStart}
          onSortEnd={onSortEnd} />
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <input
            style={{ display: 'none' }}
            id="multi-upload"
            type="file"
            onChange={newImages}
          />
          <label htmlFor="multi-upload">
            <Fab
              color="primary"
              size="small"
              component="span"
              disabled={state.creating}>
              <AddToPhotosIcon />
            </Fab>
          </label>
        </Grid>
      </Grid>
    </Container>
  )
}

const SortableItem = SortableElement(({image, deleteImage, updateImage}) => {
  return (
    <SlideImage
      image={image}
      key={image.id}
      onDelete={deleteImage}
      onUpdateImage={updateImage} />
  )
})

const SortableList = SortableContainer(({items, deleteImage, updateImage}) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          disabled={value.id < 0}
          image={value}
          deleteImage={deleteImage}
          updateImage={updateImage}/>
      ))}
    </div>
  )
})

SlideImages.propTypes = {
  images: PropTypes.array
}

export default SlideImages
