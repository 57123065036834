import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'


import Form from './Form'

const Currencies = props => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${props.match.path}/`} component={Form}></Route>
      </Switch>
    </React.Fragment>
  )
}
Currencies.propTypes = {
  match: PropTypes.object
}
export default Currencies