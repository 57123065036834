import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

import List from './List'
import New from './New'
import Edit from './Edit'

const Brands = props => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${props.match.path}/`} component={List}></Route>
        <Route exact path={`${props.match.path}/new`} component={New}></Route>
        <Route exact path={`${props.match.path}/:id/edit`} component={Edit}></Route>
      </Switch>
    </React.Fragment>
  )
}

Brands.propTypes = { match: PropTypes.object.isRequired }

export default Brands