import React from 'react'
import PropTypes from 'prop-types'

import Form from './Form'

let initialState = { name: '' }

const New = props => {
  return(
    <Form standard={initialState} history={props.history}/>
  )
}
New.propTypes = {
  history: PropTypes.object
}

export default New
