import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import { Card, CardHeader, CardContent, Typography, FormControlLabel, Checkbox } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DeleteIcon from '@mui/icons-material/Delete'
import Fab from '@mui/material/Fab'

const Attribute = memo(props => {
  const { t } = useTranslation()

  const classes = makeStyles(theme => ({
    card: { marginBottom: theme.spacing(2) }
  }))()

  const attributeValues = a => {
    const tooManyValues = a.p_attribute_values && a.p_attribute_values.length > 7
    const values = tooManyValues ? a.p_attribute_values.splice(0, 7) : a.p_attribute_values
    return values.length ?
      a.p_attribute_values.map((v, i) =>
        <React.Fragment  key={i}>
          <div>{i+1}: {String(v.value)}</div>
        </React.Fragment>
      )
      :
      null
  }

  const childAttributes = props.attribute.child_attributes ?
    (props.attribute.child_attributes.map(a => (
      <Grid item xs={4} key={a.id}>
        <Typography variant='h5'>{a.name}</Typography>
        {attributeValues(a)}
      </Grid>
    )))
    :
    <Grid item xs={12}>
      { attributeValues(props.attribute) }
    </Grid>
  return (
    <Card key={props.attribute.id} className={classes.card}>
      <CardHeader
        title={props.attribute.name}
        action={
          <Fab
            onClick={() => {
              if(window.confirm('Är du säker?')) {
                props.removeAttribute(props.attribute.id)
              }
            }}
            color="secondary"
            size="small">
            <DeleteIcon />
          </Fab>
        }
      />
      <CardContent>
        <Grid container spacing={4}>
          {childAttributes}
        </Grid>
        <Grid container spacing={4}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={String(props.attribute.order_attribute) === String(true)}
                  onChange={e => props.updateAttribute({ ...props.attribute, order_attribute: e.target.checked })}
                  name="orderAttribute"
                  color="primary"
                />
              }
              label="Order Attribute"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={String(props.attribute.show_on_filters) === String(true)}
                  onChange={e => props.updateShowOnFilters(props.attribute, e.target.checked)}
                  name="showOnFilters"
                  color="primary"
                />
              }
              label={t('attributes.showOnFilters')}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
})
Attribute.propTypes = {
  attribute: PropTypes.object.isRequired,
  removeAttribute: PropTypes.func.isRequired,
  updateAttribute: PropTypes.func.isRequired
}
export default Attribute
