import React from "react";

import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

const PrintOptionChip = ({ printOption, onDelete, onClick }) => {
  const { t } = useTranslation();

  return (
    <Zoom
      key={printOption.id}
      in={true}
      appear={!!printOption.new}
      style={{
        transitionDuration: "500ms",
      }}
    >
      <Grid item>
        <Chip
          label={printOption.name || printOption.position}
          onClick={onClick}
          onDelete={() => {
            if (
              window.confirm(
                t("printOptions.confirmation.deletePrintOption", {
                  printOptionName: printOption?.name,
                }),
              )
            ) {
              onDelete();
            }
          }}
        />
      </Grid>
    </Zoom>
  );
};

PrintOptionChip.propTypes = {
  printOption: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PrintOptionChip;
