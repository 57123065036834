import React, { useState } from "react";
import PropTypes from "prop-types";

import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import ColorForm from "./ColorForm";
import { invertHex } from "../../utils";

const ColorChip = (props) => {
  const [editing, setEditing] = useState(false);

  const handleSave = (color) => {
    props.onSave(color);
    setEditing(false);
  };

  const classes = makeStyles((theme) => ({
    chip: {
      margin: theme.spacing(1),
      border: "1px solid #e0e0e0",
    },
    icon: {
      backgroundColor: "#000",
      color: "#fff !important",
      borderRadius: "50%",
      "&:hover": {
        color: "#fff",
      },
    },
  }))();
  let backgroundColor = props.color.code
    ? props.color.code
    : props.color.alias_name
    ? props.color.alias_name.code
    : "";
  if (!backgroundColor?.startsWith("#")) backgroundColor = `#${backgroundColor}`;

  return (
    <React.Fragment>
      <Chip
        disabled={props.supplierProductsView || !!props.disabled}
        label={props.color.name}
        title={props.color.name}
        onDelete={() => props.onDelete(props.color.id)}
        onClick={!!props.onClick ? props.onClick : () => setEditing(true)}
        style={{
          backgroundColor: backgroundColor,
          color: `#${invertHex(backgroundColor.replace("#", ""))}`,
        }}
        className={classes.chip}
        deleteIcon={props.deleteIcon ?? <CloseIcon className={classes.icon} />}
      />
      {editing ? (
        <ColorForm
          color={props.color}
          onClose={() => setEditing(false)}
          onSave={handleSave}
        />
      ) : null}
    </React.Fragment>
  );
};

ColorChip.propTypes = {
  color: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
export default ColorChip;
