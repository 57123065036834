import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Box, FormGroup, Switch, Tooltip } from "@mui/material";

import SelectInput from '@common/SelectInput'
import Price from "./Price";

const PriceListRow = (props) => {
  const currentSupplier = props.priceListRow.supplier
    ? props.suppliers.filter((s) => s.id === props.priceListRow.supplier.id)[0]
    : props.suppliers[0];
  const { t } = useTranslation();

  const updateSupplier = (supplier) => {
    let _supplier = { ...props.priceListRow.supplier };
    _supplier.id = supplier.id;
    props.onUpdatePriceListRow({ ...props.priceListRow, supplier: _supplier });
  };

  const setPrice = (price) => {
    let _prices = [...props.priceListRow.prices];
    const index = _prices.findIndex((p) => {
      if (price.hasOwnProperty("id")) {
        return p.id === price.id;
      }
      return p.kid === price.kid;
    });
    if (index !== -1) {
      _prices[index] = price;
      props.onUpdatePriceListRow({ ...props.priceListRow, prices: _prices });
    }
  };

  const parsePrice = (val) =>
    typeof val === "number" ? val : parseFloat(val.replace(",", "."));

  return (
    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={2}>
        <SelectInput
          label={'Leverantör'}
          onChange={(e) => updateSupplier({ id: e.target.value })}
          valuesArray={props.suppliers}
          disabled={props.supplierProductsView}
          value={(props.priceListRow.supplier && props.priceListRow.supplier.id) || props.suppliers[0]}
        />
      </Grid>
      <Grid item xs={9}>
        <Box display="flex">
          {props.quantities
            .filter((q) => q.hasOwnProperty("id"))
            .map((q) => {
              const price = props.priceListRow.prices.find(
                (p) => p.quantity.id === q.id
              );
              if (price) {
                let convertedPrice = parsePrice(price.value);
                if (
                  props.priceListRow.supplier &&
                  props.priceListRow.supplier.currency &&
                  props.priceListRow.supplier.currency.conversion_rate
                ) {
                  convertedPrice =
                    parsePrice(price.value) *
                    parseFloat(
                      props.priceListRow.supplier.currency.conversion_rate
                    );
                }
                const quantityPrice = props.quantityPrices.find(
                  (qp) => qp.quantity?.id === price.quantity.id
                );
                const customerPrice =
                  price.value &&
                  quantityPrice &&
                  parsePrice(quantityPrice.price);
                const margin = customerPrice
                  ? `${(
                      ((customerPrice - convertedPrice) / convertedPrice) *
                      100
                    ).toFixed(2)}%`
                  : "0%";
                return (
                  <Price
                    key={price.kid || price.id}
                    supplierProductsView={props.supplierProductsView}
                    price={price}
                    setPrice={setPrice}
                    margin={margin}
                    currency={
                      currentSupplier &&
                      currentSupplier.currency &&
                      currentSupplier.currency.symbol
                    }
                  />
                );
              }
              return <Box key={q.id} flex="1 1 0" marginRight={0.5} />;
            })}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <FormGroup>
          <Tooltip title={t("priceLists.actions.disable")}>
            <Switch
              checked={props.priceListRow.active}
              disabled={props.supplierProductsView}
              onChange={(e) =>
                props.onUpdatePriceListRow({
                  ...props.priceListRow,
                  active: e.currentTarget.checked,
                })
              }
              value={true}
              color="primary"
            />
          </Tooltip>
        </FormGroup>
      </Grid>
    </Grid>
  );
};

PriceListRow.propTypes = {
  priceListRow: PropTypes.object.isRequired,
  onUpdatePriceListRow: PropTypes.func.isRequired,
  quantities: PropTypes.array,
  quantityPrices: PropTypes.array,
  suppliers: PropTypes.array,
};

export default PriceListRow;
