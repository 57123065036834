import React from "react";

import {
  Paper,
  Grid,
  IconButton,
  TextField,
  Divider,
  Button,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import InlineLoader from "@components/common/Loaders/InlineLoader";

const useStyles = makeStyles({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { xs: "95%", sm: "70%", md: "60%", lg: "45%" },
    boxShadow: 24,
  },
  container: {
    padding: "2%",
  },
  headerContainer: {
    paddingLeft: "2%",
    paddingRight: "1%",
  },
  m_15: {
    margin: "15px",
  },
  float_r: {
    float: "right",
  },
});

const NewTagModal = ({
  tagName,
  handleTagNameChange,
  closeModal,
  handleSave,
  tagNameError,
  backdrop = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSubmit = (event) => {
    handleSave(event);
  };
  return (
    <Paper className={classes.modal}>
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          className={classes.headerContainer}
        >
          <Grid item xs={11}>
            <h3>{t("tags.pageTitles.create_new_tag")}</h3>
          </Grid>
          <Grid item xs={1}>
            <IconButton className={classes.float_r} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider width="100%" />
        <Grid
          container
          className={classes.container}
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12}>
            <TextField
              required
              label="Name"
              name="name"
              error={tagNameError !== ""}
              value={tagName}
              variant={"outlined"}
              placeholder={t("tags.placeholders.tag_name")}
              helperText={tagNameError && tagNameError}
              onChange={handleTagNameChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Divider width="100%" />
        <Grid container justifyContent="flex-end" className={classes.m_15}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t("tags.actions.create_tag")}
          </Button>
        </Grid>
      </Grid>
      <Backdrop open={backdrop}>
        <InlineLoader size={40} color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default NewTagModal;
