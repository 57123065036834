import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'

import HttpClient from '../HttpClient'
import Mdivider from '../common/Mdivider'
import Product from './Product'

const Products = () => {
  const [products, setProducts] = useState([])
  const [creating, setCreating] = useState(false)
  
  useEffect(() => {
    let didCancel = false
    HttpClient.get('/homepage_products').then((data) => {
      if(!didCancel) {
        setProducts(data)
      }
    })

    return () => { didCancel = true }
  }, [])

  const newProducts = event => {
    const files = event.target.files
    for (var i = 0; i < files.length; i++) {
      let file = files.item(i)
      let formData = new FormData()
      formData.append('homepage_product[image]', file)
      setCreating(true)
      HttpClient.post('/homepage_products', formData).then(res => {
        setProducts([...products, res])
        setCreating(true)
      })
    }
  }

  const deleteProduct = id => {
    const _products = products.filter(i => i.id !== id)
    if(id) {
      HttpClient.delete(`/homepage_products/${id}`).then( res => {
        if(res.success)
          setProducts(_products)
      })
    } else {
      setProducts(products)
    }
  }

  const updateProduct = (id, updatedProduct) => {
    let _products = [...products]
    const productIndex = _products.findIndex(i => i.id === id)
    products[productIndex] = updatedProduct
    setProducts(_products)
  }

  return (
    <Container>
      <Grid container >
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}><Typography varient='h3'>Namn</Typography></Grid>
          <Grid item xs={3}><Typography varient='h3'>Bild</Typography></Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Mdivider />
        {products.map(product => (
          <Product
            product={product}
            key={product.id}
            onDelete={deleteProduct}
            onUpdateImage={updateProduct} />
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <input
            style={{ display: 'none' }}
            id="file-upload"
            type="file"
            onChange={newProducts}
          />
          <label htmlFor="file-upload">
            <Fab
              color="primary"
              size="small"
              component="span"
              disabled={creating}>
              <AddIcon />
            </Fab>
          </label>
        </Grid>
      </Grid>
    </Container>
  )
}

Products.propTypes = {

}

export default Products
