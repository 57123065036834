import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import { Tabs, Tab, TableBody, TableContainer, Table, TableCell, TableHead, Paper, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Grid from '@mui/material/Grid'

import TitleBar from '../topbar/TitleBar'
import Form from './Form'
import AttributeGroup from './AttributeGroup'
import LoaderShowDouble from '@common/Loaders/LoaderShowDouble'
import { CategoryApi, ProductApi } from '@src/apis'
import SubCategories from '@components/categories/SubCategories'
import Products from '@components/categories/Products'
import SortingContext from '@helpers/sortingContext'
import Loader from '@common/Loaders/LoaderList'


const FormContainer = props => {
  const [value, setValue] = useState(0)
  const [subCategories, setSubCategories] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [isProductsLoading, setIsProductsLoading] = useState(false)

  const handleActiveIndexUpdate = async (event, newValue) => {
    setValue(newValue)
    setIsLoading(true)
    if (props.category.id && newValue === 2) {
      const response = await CategoryApi.getSubCategories(props.category.id)
      setSubCategories(response)
      setIsLoading(false)
    }

    if (props.category.id && newValue === 3) {
      const response = await CategoryApi.getProducts(props.category.id)
      setProducts(response)
      setIsProductsLoading(false)
    }
  }

  const classes = makeStyles(theme => ({
    root: {
      marginBottom: theme.spacing(4)
    },
    tab: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
  }))()

  const handleSortingUpdate = (updatedData, updatedRows) => {
    setSubCategories(updatedData)
    updatedRows.forEach((category) => {
      updateDraggedCategories(category)
    })
  }

  const updateDraggedCategories = async (category) => {
    await CategoryApi.updateCategory(category.id, { position: category.position })
  }


  const handleProductsSortingUpdate = (updatedData, updatedRows) => {
    setProducts(updatedData)
    updatedRows.forEach((product) => {
      updateDraggedProducts(product)
    })
  }

  const updateDraggedProducts = async (product) => {
    await ProductApi.updateProductAttributes(product.id, { position: product.position })
  }



  const subMenu = (
    <AppBar position="static" color="default" className={classes.root}>
      <Container className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Tabs value={value} indicatorColor="primary" onChange={handleActiveIndexUpdate} className={classes.tabs}>
              <Tab label="Kategori" className={classes.tab}/>
              <Tab label="Attribut" className={classes.tab}/>
              {
                props.category.id && <Tab label="Sub-Categories" className={classes.tab}/>
              }
              {
                props.category.id && <Tab label="Products" className={classes.tab}/>
              }
            </Tabs>
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton
              component={Link}
              to={'/categories'}
              aria-label="back"
              color="secondary">
              <ArrowBackIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  )

  const titleBar = (
    <TitleBar title={`${props.category.id ? 'Radigera' : 'Ny'} Kategori`} alignItems="center">
      <IconButton
        component={Link}
        to={'/categories'}
        size="medium"
        aria-label="back"
        color="secondary">
        <ArrowBackIcon/>
      </IconButton>
    </TitleBar>
  )
  return (
    <React.Fragment>
      {props.category.id ? subMenu : titleBar}
      <Container>
        {value === 0 && props.loading && <LoaderShowDouble/>}
        {value === 0 && !props.loading && <Form {...props}/>}
        {value === 1 && <AttributeGroup {...props}/>}
        {value === 2 && isLoading && <Loader/>}
        {value === 2 && !isLoading &&
          <TableContainer component={Paper}>
            <Table aria-label="components table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Bild</TableCell>
                  <TableCell>Namn</TableCell>
                  <TableCell>Populär</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <SortingContext
                  data={subCategories}
                  setData={handleSortingUpdate}
                  DataComponent={SubCategories}
                  componentType={'TableRow'}
                  addDelay={true}
                />
              </TableBody>
            </Table>
          </TableContainer>
        }
        {value === 3 && isProductsLoading && <Loader/>}
        {value === 3 && !isProductsLoading &&
          <TableContainer component={Paper}>
            <Table aria-label="components table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Bild</TableCell>
                  <TableCell>Namn</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <SortingContext
                  data={products}
                  setData={handleProductsSortingUpdate}
                  DataComponent={Products}
                  componentType={'TableRow'}
                  addDelay={true}
                />
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Container>
    </React.Fragment>
  )
}

FormContainer.propTypes = {
  category: PropTypes.object.isRequired,
  loading: PropTypes.bool
}

export default FormContainer
