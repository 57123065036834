import HttpClient from '@httpClient'

export const initialState = productID =>
{
  return {
    id: productID,
    name: "",
    quantities: [
      { kid: 1, value: "", price: "" },
      { kid: 2, value: "", price: "" },
      { kid: 3, value: "", price: "" },
      { kid: 4, value: "", price: "" },
    ],
    price_lists: [],
  }
};

export const pricingReducer = (product = initialState, action) => {
  switch (action.type) {
    case "INITIAL_REQUEST":
      let _product = { ...product };
      _product.quantities = action.quantities;
      handlePriceListForQuantities(_product, action.quantities, action.priceLists)
      return _product
    case "ADD_QUANTITY":
      let _quantities = [...product.quantities];
      const nextKey = nextTempKey(_quantities)
      _quantities.push({ kid: nextKey, value: "" });
      return { ...product, quantities: _quantities }
    case "NEW_QUANTITY_ADDED":
      const qIndex = product.quantities.findIndex( q => q.kid === action.newQuantity.kid)
      if (qIndex !== -1) {
        return handleNewQuantityPrices(product, action.newQuantity, qIndex)
      }
      return product
    case "ADD_PRICELIST":
      let _priceLists = [...product.price_lists];
      let newPriceList = emptyPriceList(product, action.payload.suppliers);
      if (_priceLists.length === 0) {
        HttpClient.get(`/products/${product.id}`).then((p) => {
          newPriceList.price_list_rows[0].supplier.id = p.supplier_id;
          _priceLists.push(newPriceList);
          return { ...product, price_lists: _priceLists };
        });
      } else {
        _priceLists.push(newPriceList);
        return { ...product, price_lists: _priceLists };
      }
    default:
      return product;
  }
};

const handleNewQuantityPrices = (prd, newQuantity, qIndex) => {
  let _product = { ...prd }
  const { kid, ...quantity } = newQuantity;
  _product.quantities[qIndex] = quantity;
  _product.price_lists = prd.price_lists.map((pl) => {
    if (pl.quantity_prices) {
      const nextQpKey = nextTempKey(pl.quantity_prices)
      pl.quantity_prices.push({ kid: nextQpKey, quantity: { id: newQuantity.id }, price: "" });
      pl.price_list_rows.map((plr) => {
        const nextPriceKey = nextTempKey(plr.prices)
        return plr.prices.push({ kid: nextPriceKey, value: "", quantity: { id: newQuantity.id } });
      });
    }
    return pl;
  });
  return { ...prd, ..._product }
}

const handlePriceListForQuantities = (_product, _quantities, _priceLists) => {
  _product.price_lists = _priceLists.map( _priceList =>{
    let _quantityPrices = _priceList.quantity_prices

    if (_priceList.quantity_prices.length !== _quantities.length) {
      _quantityPrices = _quantities.map((q, index) => {
        const quantityPrice = _priceList.quantity_prices.find(
          (qp) => qp.quantity?.id === q.id
        )
        if (quantityPrice) return quantityPrice
        return { kid: index + 1, quantity: { id: q.id }, price: '' }
      })
    }

    let _price_list_rows = _priceList.price_list_rows.map(plr => {
      if (plr.prices.length === _quantities.length) return plr;
      if (plr.prices.length !== _quantities.length) {
        const _prices = _quantities.map((q, index) => {
          const price = plr.prices.find(
            (p) => p.quantity.id === q.id
          );
          if (price) return price;
          return { kid: index + 1, value: "", quantity: { id: q.id } };
        });
        return { ...plr, prices: _prices }
      }
    })

    return { ..._priceList, price_list_rows: _price_list_rows, quantity_prices: _quantityPrices }
  })
}

const nextTempKey = (collection) => {
  return Math.max(...collection.map((qp) => qp.hasOwnProperty("kid") ? qp.kid : 0),
    0,
  ) + 1;
}

const emptyPriceList = (product, suppliers) => {
  let quantities = product.quantities
  const _quantities = quantities.filter((q) => q.hasOwnProperty("id"));
  if (_quantities.length > 0) {
    let plist = {
      kid: product.price_lists.length + 1,
      label: "",
      price_list_rows: [
        { kid: 1, supplier: { id: suppliers[0].id }, prices: [] },
      ],
      quantity_prices: [],
      p_attribute_values: [],
    };
    plist.price_list_rows[0].prices = _quantities.map((q, index) => {
      return { kid: index + 1, value: "", quantity: { id: q.id } };
    });
    plist.quantity_prices = _quantities.map((q, index) => {
      return { kid: index + 1, quantity: { id: q.id }, price: "" };
    });
    return plist;
  }
};
