import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import WallpaperIcon from '@mui/icons-material/Wallpaper'
import Mdivider from '../common/Mdivider'
import { openInNewTab } from '../../utils'

const FilteredPrintingPricing = ({ template }) => {
  return (
    <Grid container>
      <Grid container spacing={2}>
        <Grid container item xs={6} alignItems="center">
          <Typography variant='subtitle2'>{template.name}</Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">{
          template.line_drawing ?
            <Chip
              icon={<WallpaperIcon />}
              label="Teckning"
              clickable
              onClick={() => openInNewTab(template.line_drawing)}
            />
            : null
        }</Grid>
      </Grid>
      {template.lists.map(priceList => 
        <Grid container spacing={2} key={priceList.id}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              disabled
              value={priceList.name || ''}
              margin="normal"
              variant="outlined" />
          </Grid>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={10}>
                <Box display="flex">
                  {priceList.prices.map(price =>   
                    <Box key={price.quantity} flex="1 1 0" marginRight={0.5}>
                      <TextField
                        fullWidth
                        disabled
                        error={price.quantity_too_low}
                        value={price.price !== null ? price.price : ''}
                        margin="normal"
                        variant="outlined"
                        // helperText={price.quantity_too_low ? 'Amount too low' :  ''}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Mdivider />
    </Grid>
  )
}

FilteredPrintingPricing.propTypes = {
  template: PropTypes.object.isRequired
}

export default FilteredPrintingPricing
