import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { Grid, Box } from "@mui/material";
import { FormControl, NativeSelect, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import HttpClient from "../../HttpClient";
import InlineLoader from "@components/common/Loaders/InlineLoader";
import PopupAlert from "@contexts/PopupAlert";
import { useTranslation } from "react-i18next";
import { parseErrorMessage } from "@src/utils/sharedHelper";

const ProductImage = ({
  image,
  onDelete,
  productId,
  colorGroupId,
  formOptions,
  supplierProductsView,
}) => {
  const [pImage, setPImage] = useState({ ...image });
  const [updating, setUpdating] = useState(false);
  const addPopupAlert = useContext(PopupAlert);
  const { t } = useTranslation();

  const saveChange = (data) => {
    setUpdating(true);
    HttpClient.put(
      `/products/${productId}/color_groups/${colorGroupId}/product_images/${pImage.id}`,
      data
    )
      .then((res) => {
        setPImage(res);
        setUpdating(false);
        addPopupAlert(
          t("pictures.actions.update"),
          "success",
          t("pictures.actions.updated_successfully")
        );
      })
      .catch((error) => {
        setUpdating(false);
        addPopupAlert(
          t("pictures.actions.update"),
          "error",
          parseErrorMessage(error.message)
        );
      });
  };

  const handleDelete = (id) => {
    setUpdating(true);
    HttpClient.delete(
      `/products/${productId}/color_groups/${colorGroupId}/product_images/${id}`
    )
      .then(() => {
        onDelete(id);
        setUpdating(false);
        addPopupAlert(
          t("pictures.actions.delete"),
          "success",
          t("pictures.actions.deleted_successfully")
        );
      })
      .catch((error) => {
        setUpdating(false);
        addPopupAlert(
          t("pictures.actions.delete"),
          "error",
          parseErrorMessage(error.message)
        );
      });
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Box
          className={`image ${
            pImage.status === "awaiting_approval" ? "bg-light-yellow" : ""
          }`}
        >
          <a href={pImage.image_url} target="_blank" rel="noopener noreferrer">
            <img src={pImage.image_url} alt="saknas" />
          </a>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <OutlinedInput
          multiline
          minRows={2}
          disabled={supplierProductsView || updating}
          name="comment"
          value={pImage.comment || ""}
          onChange={(e) =>
            setPImage({ ...pImage, comment: e.currentTarget.value })
          }
          onBlur={() => saveChange(pImage)}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="outlined" size="small">
          <NativeSelect
            name="placement"
            disabled={supplierProductsView || updating}
            value={pImage.placement || ""}
            onChange={(e) =>
              saveChange({ ...pImage, placement: e.currentTarget.value })
            }
            input={<OutlinedInput name="placement" id="placement" />}
          >
            {formOptions.placement_options.map((po) => (
              <option key={po.value} value={po.value}>
                {po.label}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="outlined" size="small">
          <NativeSelect
            name="status"
            disabled={supplierProductsView || updating}
            value={pImage.status || ""}
            onChange={(e) =>
              saveChange({ ...pImage, status: e.currentTarget.value })
            }
            input={<OutlinedInput name="status" id="status" />}
          >
            {formOptions.conversion_states.map((cs) => (
              <option key={cs.value} value={cs.value}>
                {cs.label}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        {updating ? (
          <InlineLoader />
        ) : (
          <IconButton
            disabled={supplierProductsView}
            onClick={() => {
              if (window.confirm("Är du säker?")) handleDelete(pImage.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

ProductImage.propTypes = {
  image: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  colorGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  formOptions: PropTypes.object.isRequired,
};

export default ProductImage;
