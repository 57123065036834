import React from "react";
import PropTypes from "prop-types";

import { Chip } from "@mui/material";

const StatusChip = ({ status }) => {
  if (!status) return <></>;
  else if (status === "Unsuccessful")
    return <Chip label={status} color="error" />;
  else if (status === "Paused") return <Chip label={status} color="warning" />;
  else if (status === "In progress") return <Chip label={status} />;
  else return <Chip label={status} color="success" />;
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusChip;
