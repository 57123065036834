import React, { useState } from 'react'
import { ListItemIcon, IconButton, Switch, TableCell, Link } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import ImageThumbnail from '@shared/ImageThumbnail'
import { CategoryApi } from '@src/apis'

const SubCategories = ({ object }) => {
  const [subCategory, setSubCategory] = useState(object)

  const updateCategory = async (id, popularCategory) => {
    setSubCategory({ ...subCategory, popular: popularCategory })
    await CategoryApi.updateCategory(id, { popular: popularCategory })
  }

  return (
    <>
      <TableCell>
        <ListItemIcon edge="start">
          <IconButton color="primary" size="medium">
            <DragIndicatorIcon/>
          </IconButton>
        </ListItemIcon>
      </TableCell>
      <TableCell>
        <ListItemIcon>
          <ImageThumbnail imageUrl={subCategory?.image_url}/>
        </ListItemIcon>
      </TableCell>
      <TableCell>
        <Link href={`/categories/${subCategory.id}/edit`} target={'_blank'}>
          {subCategory.name}
        </Link>
      </TableCell>
      <TableCell>
        <Switch
          name="popular-category"
          checked={subCategory.popular === true}
          onChange={(e) => updateCategory(subCategory.id, e.target.checked)}
        />
      </TableCell>
    </>
  )
}

export default SubCategories
