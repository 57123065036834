import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Fab from '@mui/material/Fab'
import DeleteIcon from '@mui/icons-material/Delete'
import Paper from '@mui/material/Paper'

const ValueInput = props => {
  return (
    <Grid container>
      <Grid container item xs={12} md={6}>
        <Paper style={{ marginTop: '10px', width: '100%', padding: '0px 10px' }}>
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={10}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Värde"
                  value={props.attributeValue.value || ''}
                  onChange={e => props.setValue({ ...props.attributeValue, value: e.currentTarget.value })}
                  variant="outlined" />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Fab
                onClick={() => {
                  if(window.confirm('Är du säker?')) {
                    props.removeValue(props.attributeValue.id)
                  }
                }}
                color="secondary"
                size="small">
                <DeleteIcon />
              </Fab>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

ValueInput.propTypes = {
  attributeValue: PropTypes.object,
  setValue: PropTypes.func,
  removeValue: PropTypes.func
}

export default ValueInput