import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { Grid, Box, Typography, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";

import HttpClient from "@httpClient";
import { ProductApi, ColorGroupApi } from "@api";

import ProductResourceContext from "@contexts/ProductResource";
import ColorGroup from "./ColorGroup";
import Loader from "@common/Loaders/LoaderList";
import Mdivider from "@components/common/Mdivider";
import ProductReferenceImages from "@components/products/images/ProductReferenceImages";
import colorGroup from './ColorGroup'

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source.product_images);
  const destClone = Array.from(destination.product_images ?? []);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const ColorGroups = (props) => {
  const productResource = useContext(ProductResourceContext);
  const { t } = useTranslation();

  const product = {
    id: props.match.params.id,
  };

  const [colorGroups, setColorGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formOptions, setFromOptions] = useState({
    colors: [],
    placement_options: [],
    conversion_states: [],
  });

  const isSupplierProduct = productResource === "supplier_products";

  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    ProductApi.getColorGroups(product.id, productResource)
      .then((data) => {
        // get uniques color groups -
        const _colorGroups = data
          .filter((cg) => cg != null)
          .sort((cg1, cg2) => cg1.position - cg2.position);
        const _colorGroupsFilterred = [];
        const map = new Map();
        for (const colorGroup of _colorGroups) {
          if (!map.has(colorGroup.id)) {
            map.set(colorGroup.id, true); // set any value to Map
            _colorGroupsFilterred.push(colorGroup);
          }
        }
        if (!didCancel) setColorGroups(_colorGroupsFilterred);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
    return () => {
      didCancel = true;
    };
  }, [product.id]);

  useEffect(() => {
    HttpClient.get("/products/form_options").then((res) => {
      setFromOptions({
        colors: res.colors,
        placement_options: res.placement_options,
        conversion_states: res.conversion_states,
      });
    });
  }, []);

  const newColorGroup = () => {
    let numericIds = colorGroups
      .map(group => parseInt(group.id)) // Convert to number (NaN if UUID)
      .filter(id => !isNaN(id)); // Keep only numeric values

    let newId = numericIds.length > 0 ? Math.min(...numericIds, 0) - 1 : -1;
    let newGroup = {
      name: "",
      colors: [],
      position: colorGroups.length + 1,
      product_images: [],
      id: newId
    };
    setColorGroups([...colorGroups, newGroup]);
  };

  const newColorGroups = (event) => {
    let formData = new FormData();
    Array.from(event.target.files).forEach((file) => {
      formData.append("color_group[images][]", file);
    });
    HttpClient.post(
      `/products/${product.id}/color_groups`,
      formData,
      {},
      true,
    ).then((res) => {
      setColorGroups([...colorGroups, ...res]);
    });
  };

  const handleUpdateGroup = (id, updatedGroup) => {
    let _groups = [...colorGroups];
    const groupIndex = _groups.findIndex((i) => i.id === id);
    _groups[groupIndex] = updatedGroup;
    setColorGroups(_groups);
  };

  const handleDeleteGroup = (id) => {
    const _groups = colorGroups.filter((i) => i.id !== id);
    if (id < 0) {
      setColorGroups(_groups);
    } else {
      HttpClient.delete(
        `/products/${product.id}/color_groups/${id}`,
        {},
        true,
      ).then(() => setColorGroups(_groups));
    }
  };

  const findColorGroup = (id) => colorGroups.find((cg) => cg.id === id);

  const handleDragEnd = ({ source, destination }) => {
    // dropped outside the list
    if (!destination || isSupplierProduct) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const sortedImages = reorder(
        findColorGroup(source.droppableId).product_images,
        source.index,
        destination.index,
      );
      HttpClient.post(
        `/products/${product.id}/color_groups/${source.droppableId}/product_images/sort`,
        { product_images: sortedImages.map((i) => i.id) },
      ).then(() => {
        let _groups = [...colorGroups];
        const groupIndex = _groups.findIndex(
          (g) => g.id === source.droppableId,
        );
        _groups[groupIndex].product_images = sortedImages;
        setColorGroups(_groups);
      });
    } else if (destination.droppableId !== -1) {
      const result = move(
        findColorGroup(source.droppableId),
        findColorGroup(destination.droppableId),
        source,
        destination,
      );
      const sourceGroupIndex = colorGroups.findIndex(
        (g) => g.id === source.droppableId,
      );
      const destinationGroupIndex = colorGroups.findIndex(
        (g) => g.id === destination.droppableId,
      );
      let _groups = [...colorGroups];
      _groups[sourceGroupIndex].product_images = result[source.droppableId];
      _groups[destinationGroupIndex].product_images =
        result[destination.droppableId];
      setColorGroups(_groups);
      HttpClient.post(
        `/products/${product.id}/color_groups/${destination.droppableId}/product_images/sort`,
        { product_images: result[destination.droppableId].map((i) => i.id) },
      );
    }
  };

  const handleMoveToPosition = async (updatedColorGroup, newPosition) => {
    const pos = parseInt(newPosition, 10)
    const _colorGroups = colorGroups.filter(cg => cg.id !== updatedColorGroup.id)
    _colorGroups.splice(pos - 1, 0, updatedColorGroup)
    setColorGroups(_colorGroups)
    const postData = _colorGroups.map((cg, index) => {
      return { ...cg, position: index + 1 }
    })
    await ColorGroupApi.updatePositions(product.id, productResource, postData)
  }

  return (
    <div className="product-images">
      {loading ? (
        <Loader />
      ) : (
        <>
          <ul className="list-group header">
            <li className="list-group-item list-group-item-action">
              <Grid container spacing={1}>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  Namn
                </Grid>
                <Grid item xs={2}>
                  Färger
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={3}>
                    Bilder
                  </Grid>
                  <Grid item xs={4}>
                    Kommentarer
                  </Grid>
                  <Grid item xs={2}>
                    Placering
                  </Grid>
                  <Grid item xs={2}>
                    Status
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </li>
          </ul>
          <Box>
            <ul className="list-group">
              <DragDropContext onDragEnd={handleDragEnd}>
                {colorGroups.map((colorGroup, index) => (
                  <Droppable key={colorGroup.id} droppableId={colorGroup.id}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <ColorGroup
                          supplierProductsView={isSupplierProduct}
                          productId={product.id}
                          colorGroup={colorGroup}
                          onUpdate={handleUpdateGroup}
                          onDelete={handleDeleteGroup}
                          formOptions={formOptions}
                          index={index}
                          colorGroupsLength={colorGroups.length}
                          handleMoveToPosition={handleMoveToPosition}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </ul>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <Fab
                disabled={isSupplierProduct}
                onClick={newColorGroup}
                color="primary"
                size="small"
              >
                <AddIcon />
              </Fab>
            </Grid>
            <Grid item xs={1}>
              <input
                disabled={isSupplierProduct}
                accept="image/*"
                style={{ display: 'none' }}
                id="multi-upload"
                type="file"
                multiple
                onChange={newColorGroups}
              />
              <label htmlFor="multi-upload">
                <Fab
                  color="primary"
                  size="small"
                  component="span"
                  disabled={isSupplierProduct || loading}
                >
                  <AddToPhotosIcon disabled={isSupplierProduct} />
                </Fab>
              </label>
            </Grid>
          </Grid>
        </>
      )}

      <Mdivider />

      <Typography variant="h5" element="h5">
        {t("productReferenceImages.plural")}
      </Typography>

      <ProductReferenceImages />
    </div>
  );
};

ColorGroups.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ColorGroups;
