import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import ImageThumbnail from '@shared/ImageThumbnail'

const ItemSelectedComponent = ({ type, selectedItem, handleUnselect, size='small' }) => {
  return (
    <Grid container mt={1} xs={12} md={12} item key={selectedItem?.id} justifyContent={'center'} alignItems={'center'}>
      <Grid item container xs={12} lg={size === 'small' ? 9 : 12} sx={{ border: '1px solid #c7c7c7', padding: '10px'}} alignItems="center" spacing={1}>
        <Grid item container xs={2} alignItems="center" sx={{ 'wordBreak': 'break-all' }}>
          <ImageThumbnail imageUrl={selectedItem?.image_url} height={50} width={50}/>
        </Grid>
        <Grid container item xs={9}>
          <Grid item xs={12}>
            <Link to={`/${type}/${selectedItem?.id}`}>{selectedItem?.name}</Link>
          </Grid>
          <Grid item mt={1} xs={12}>
            {selectedItem?.description?.slice(0, 50) + (selectedItem?.description?.length > 20 ? '...' : '')}
          </Grid>
        </Grid>
        {
          !!handleUnselect &&
          <Grid item xs={1}>
            <CloseIcon sx={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleUnselect(selectedItem.id)}/>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

ItemSelectedComponent.propTypes = {
  type: PropTypes.string,
  selectedItem: PropTypes.object.isRequired,
  handleUnselect: PropTypes.func
}

export default ItemSelectedComponent
