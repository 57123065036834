import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,

    lng: "sv-SE",
    resources: {
      "sv-SE": {
        translation: {
          text: "Text",
          number: "Nummer",
          switch: "Växla",
          select: "Välja",
          multiselect: "Flera val",
          boolean_or_number: "Boolean eller Nummer",
          formattedNum: "{{num,number}}",
          reverseFormattedNum: "{{num,revNumber}}",
          formattedCurrency: "{{num,currency}}",
          vocabulary: {
            pictures: "Bilder",
            error: "Fel",
            success: "Framgång!",
            file: "Fil",
            actions: "Handlingar",
            none: "Ingen",
          },
          sorting: {
            title: "Sortera efter",
            name_ascending: "Namn stigande",
            name_descending: "Namn fallande",
            oldest: "äldst",
            latest: "senast",
          },
          filter: {
            title: "Filter",
            product: {
              all: "All",
              synced: "Synced",
              partially_synced: "Partially Synced",
              unsynced: "Un-Synced",
            },
          },
          alertTexts: {
            confirmation: "Är du säker?",
            questionDeletionConfirmation: "Är du säker på att du vill ta bort den här frågan?",
            productQuestionConfirmation: "Denna åtgärd tar bort frågan från den här produkten! Är du säker?",
            productQuestionEditConfirmation: ""
          },
          feedback: {
            actionFailed:
              "{{action}} misslyckades på grund av följande orsak(er)",
            unhandledException: "Ohanterat undantag",
            contactItDepartment:
              "Åtgärden misslyckades. Kontakta IT-avdelningen om problemet kvarstår",
          },
          actions: {
            edit: "Radigera",
            new: "Ny",
            action: "Actions",
            save: "Spara",
            show_all: "Visa Allt",
            close: "stänga",
            loadColors: "Ladda färger",
            load: "Ladda",
            add: "Lägg till",
            delete: "Radera",
          },
          products: {
            title: "produkters",
            addToCategory: "Lägg till för kategorin",
            addToCategoryMessage:
              "Är du säker? \n Detta lägger till detta attribut på alla produkter i denna kategorin",
            isSustainable: "Standarder",
            standard: "Standard",
            standardValue: "Standardvärde",
            name: "Namn",
            suppliers: "Leverantör",
            secondarySuppliers: "Sekundär leverantör",
            printingSuppliers: "Utskrift leverantör",
            suppliersArticleNumbers: "Leverantörs artikelnummer",
            syncStatus: "Sync Status",
            printingTemplate: "Tryckmall",
            markingPrices: "Markningpriser",
            prices: "Priser",
            price: "Pris",
            newProductTitle: "Ny produkt",
            attributeLabels: {
              name: "Namn",
              category: "Kategori",
              delivery_time: "Leveranstid",
              size: "Storlek",
              description: "Beskrivning",
              long_description: "Lång Beskrivning",
              brand: "Märke",
              country: "Land",
              visible: "Publik?",
              permalink: "SEO/META URL",
              seo_text: "SEO Text",
              custom_title: "Anpassad titel",
              seo_description: "SEO beskrivning",
              keywords: "Nyckelord",
              compliance: "Efterlevnad",
              redirect: "Redirect URL",
              priority: "Prioritet",
              notes: "Anteckningar (ej publika)",
              badges: "Badges",
              addon: "Addon",
              product_details: "Produkt detaljer",
              marking_details: "Märknings detaljer"
            },
            placeholders: {
              search_products: "Sök efter produkter",
            },
            actions: {
              load: "Produkten laddas",
            },
            relatedProducts: "Relaterade produkter",
          },
          articles: {
            stockAlwaysAvailable: "Stock is always available",
            actions: {
              load: "Artiklar laddas",
            },
          },
          suppliers: {
            primary: "Huvudleverantör",
            name: "Företag / Organisation",
            phone: "Telefonnummer",
            email: "E-Post",
            login: "Inloggning",
            url: "Webbplats(URL)",
            loginUrl: "Webbplats(URL)",
            customerNumber: "Kundnummer",
            username: "Användarnamn",
            password: "Lösenord",
            address: "Postadress",
            zip: "Postcode",
            city: "Stad",
            markAsPrimary: "Gör till huvudleverantör",
            organisation_number: "Organisationsnr",
            markPrimarySuccessful: "Markerad som huvudleverantör.",
            markPrimaryUnSuccessful: "Kunde inte markeras som huvudleverantör.",
            placeholders: {
              search_label: "Sök efter leverantörer",
            },
          },
          categories: {
              validations: {
                name_error: "Namnet får inte vara tomt.",
                permalink_error: "Permalänk krävs",
                permalink_exists_error: "Permalänk finns redan.",
                validate_permalink_error: "Det gick inte att validera permalänken.",
              },
              fieldLabels: {
                  name: "Namn",
                  name_plural: "Namn i plural", // Added
                  name_singular: "Namn i singular", // Added
                  description: "Beskrivning",
                  permalink: "Permalänk",
                  parentCategory: "Överordnad kategori",
                  custom_title: "Anpassad titel", // Added
                  priority: "Prioritet", // Added
                  position: "Position", // Added
                  redirect: "Omdirigera", // Added
                  sortOrder: "Sorteringsordning",
                  isActive: "Aktiv",
                  image: "Bild",
                  metaTitle: "Meta Titel",
                  metaDescription: "Meta Beskrivning",
                  seoKeywords: "SEO Nyckelord",
                  main: "Huvudkategori", // Added
                  a_o: "Visa kategori under A - Ö", // Added
                  product_category: "Produktkategori", // Added
                  removed: "Ta bort kategori", // Added
              },
              placeholders: {
                  search_label: "Sök kategorier",
                  name: "Ange kategori namn",
                  description: "Ange en beskrivning",
                  permalink: "Ange en permalänk",
              },
              actions: {
                  add: "Lägg till kategori",
                  edit: "Redigera kategori",
                  delete: "Ta bort kategori",
                  save: "Spara kategori",
              },
              messages: {
                  created: "Kategorin har skapats.",
                  updated: "Kategorin har uppdaterats.",
                  deleted: "Kategorin har tagits bort.",
                  unsuccessful_save: "Det gick inte att spara kategori."
              },
          },
          customers: {
            validations: {
              name_error: "Namn krävs.",
              user_error: "användarval är obligatoriskt",
            }
          },
          campaigns: {
            validations: {
              name_error: "Namn krävs.",
              url_error: "URL krävs.",
              layout_error: "Layout krävs.",
              image_error: "Bild krävs.",
            }
          },
          attributes: {
            name: "Attribut",
            values: "Attributvärden",
            inArticleNum: "I artikelnr.",
            showOnFilters: "visa på filter",
            placeholders: {
              search_label: "Sök attribut",
            },
            actions: {
              add: "Lägg till attribut",
            },
          },
          brands: {
            placeholders: {
              search_label: "Sök varumärken",
            },
          },
          colors: {
            actions: {
              delete: "Ta bort färg",
              deleted_successfully: "Färgen har tagits bort",
              add: "Lägg till färg",
              added_successfully: "Färgen har lagts till",
            },
            placeholders: {
              search_label: "Sök färger",
            },
            validations: {
              name_error: "Namn krävs.",
              code_error: "Koden är obligatorisk.",
            }
          },
          badges: {
            date_fields_label: "Tidsperiod för visning",
            date_fields_help_text: "Valfritt"
          },
          marking_methods: {
            plural: "Märkningsmetoder",
            singular: "Märkningsmetod",
            placeholders: {
              search_label: "Sök markeringsmetoder",
            },
            actions: {
              load: "Lastmärkningsmetoder",
            },
          },
          marking_templates: {
            placeholders: {
              search_label: "Sök markeringsmallar",
            },
            tabs: {
              system_generated: "System genererat",
              manual: "Manuell",
            },
            actions: {
              add: "Markeringsmallstillägg",
              delete: "Markera mallborttagning",
            },
            feedback: {
              added_successfully: "Framgång!",
            },
          },
          customers: {
            placeholders: {
              search_label: "Sök kunder",
            },
          },
          standards: {
            placeholders: {
              search_label: "Sök standarder",
            },
          },
          tags: {
            pageTitles: {
              add_products_to_tag: "Lägg till produkter till",
              create_new_tag: "Skapa ny tagg",
            },
            tableHeadings: {
              name: "Namn",
              image: "Bild",
              products: "produkter",
            },
            title: "Taggar",
            fieldLabels: {
              name: "Namn",
              permalink: "Permalink",
              name_plural: "Namn Plural",
              name_singular: "Namn Singular",
              custom_title: "Anpassad Title",
              description: "Beskrivning",
              header: "Rubrik",
              footer: "Sidfot",
              info: "info",
              footer_info: "Sidfot Info",
              keywords: "Nykelord",
              image: "Välj fil",
              show_until: "Göm tag från ovanstående kategorier",
              color_code: "Färg Cod",
              seo_index: "Seo Index",
              big_image: "Stor Bild",
              destroy_at: "Ta bort/radera tag och innehåll",
              is_spec: "är spec",
            },
            messages: {
              created: "",
              updated: "",
            },
            error_messages: {
              name_blank: "Taggnamn får inte vara tomt",
            },
            placeholders: {
              tag_name: "Taggnamn",
              search_tag: "Sök tag",
            },
            actions: {
              save_to_new_tag: "Spara till ny tagg",
              save_to_existing_tag: "Spara till befintlig tagg",
              add: "Lägg till",
              remove: "avlägsna",
              confirm_added: "Bekräfta tillagd",
              removal: "borttagning",
              create_tag: "skapa tagg",
              convert_to_category: "Konvertera till kategori",
              add_products: "lägga till produkter",
            },
          },
          supplierProducts: {
            title: "Leverantörs produkter",
            tableHeaders: {
              id: "#",
              name: "Namn",
              category: "Kategori",
              supplier: "Leverantör",
              state: "Stat",
              suggested_products: "Föreslagna produkter",
            },
            actions: {
              edit: "Redigera",
              mute: "Markera som sammanfogad",
              merge: "Markera som avstängd",
              create_new_product: "Skapa ny produkt",
              merge_into_existing: "Slå ihop till befintlig produkt",
              select_another_product: "välj en annan produkt",
              next: "Nästa",
            },
            modals: {
              category_modal: {
                heading: "Lägg till Kategori",
                actions: {
                  create_product: "Skapa produkt",
                },
                messages: {
                  link_products_to_new_category:
                    "Länka andra produkter i samma kategori till denna nya kategori ({{category_name}})",
                  link_to_existing_category:
                    "länka alltid {{category_name}} till {{selected_category_name}}",
                  already_linked_to_existing:
                    "Denna kategori var redan länkad till befintlig kategori",
                  already_created_new:
                    "En kategori med samma namn är redan skapad och länkad",
                },
              },
              suggested_products_modal: {
                heading: "Föreslagna produkter",
                main_message:
                  "För denna produkt hittades några matchande artiklar i följande systemprodukter: ",
              },
            },
            labels: {
              supplier_product_category: "Leverantörs produktkategori",
              create_new_category: "Skapa ny kategori",
              link_to_existing_category: "Länk till befintlig kategori",
            },
            placeholders: {
              search_by_name: "Sök efter produkter efter namn",
            },
            attributes: {
              name: "",
              category: "Kategori",
              size: "Storlek",
              description: "Beskrivning",
              keywords: "Nyckelord",
              compliance: "Efterlevnad",
              supplier: "Leverantör",
              brand: "Märke",
              status: {
                merged: "Sammanslagen",
                unmerged: "Inte-sammanslagen",
                muted: "Ignorerad",
              },
            },
            alerts: {
              update_success: "Leverantörsprodukt har uppdaterats",
              product_creation_success: "Produkten har skapats framgångsrikt",
            },
            tabs: {
              merged: "Slås samman",
              unmerged: "Osammanslagna",
              all: "Allt",
              muted: "Dämpad",
            },
          },
          questions: {
            title: 'Questions',
            editPageTitle: 'Redigera fråga',
            tableHeadings: {
              question: 'Fråga',
              answer: 'Svar',
              type: 'Typ',
              category: 'Kategori',
              public: 'Offentlig',
            },
            fieldLabels: {
              question: 'Fråga',
              answer: 'Svar',
              category: 'Kategori',
              type: 'Typ',
              public: 'Offentlig'
            },
            messages: {
              deleted: "Question Deleted successfully",
              productQuestionDeletion: "Question removed from product successfully",
              created: "Question created successfully",
              updated: "Question updated successfully"
            },
            filters: {
              category: 'Välj Kategori'
            },
            tabs: {
             generic: 'Generic',
             product_specific: 'Product Questions'
            }
          },
          printOptions: {
            name: "Utskriftsalternativ",
            attributes: {
              name: "Namn",
              min_area_cm2: "minsta yta (cm2)",
              max_area_cm2: "maximal yta (cm2)",
              position: "Skriv ut position",
            },
            formValidation: {
              name: {
                presence: "namnet får inte vara tomt",
              },
              color: {
                presence: "färg måste väljas",
              },
            },
            confirmation: {
              deletePrintOption:
                "Är du säker på att du vill ta bort utskriftsalternativet '{{printOptionName}}' från denna märkningsmall?",
              deleteImage: "Är du säker på att du vill ta bort den här bilden?",
            },
            errors: {
              additionOfPrintOptionToMarkingTemplate:
                "Tillägg av utskriftsmöjlighet till märkningsmall",
              deletePrintOption: "Radering av utskriftsalternativ",
              printOptionCreation: "Skapa utskriftsalternativ",
              printOptionEdit: "Redigera utskriftsalternativ",
              imageAddition: "Bildtillägg",
              imageRemoval: "Bildborttagning",
            },
            feedback: {
              noPrintOptions: "Inga utskriftsalternativ hittades",
              imageForPrintOption: "Skapa utskriftsalternativ",
              imageAdditionSuccessful: "Framgång!",
            },
          },
          productAttachments: {
            name: "Produktbilagor",
            attributes: {
              name: "Namn",
              type: "Typ",
              file: "Fil",
              public: "Publik",
              typeOptions: {
                printing_template: "Utskriftsmall",
                color_guide: "Färgguide",
                size_guide: "Storleksguide",
              },
            },
            formValidation: {
              name: {
                presence: "namnet får inte vara tomt",
              },
            },
            actions: {
              public: "Offentlig status ändras",
              fileDownload: "Filhämtning",
            },
          },
          colorGroups: {
            actions: {
              add: "Lägg till bildlista",
              update: "Uppdatera bildlistan",
              updatedSuccessfully: "Färggruppen har uppdaterats",
              addedSuccessfully: "Tillagt framgångsrikt",
            },
          },
          pictures: {
            actions: {
              add: "Lägg till bild",
              added_successfully: "Bilden har lagts till",
              update: "Uppdatera bild",
              updated_successfully: "Bilden har uppdaterats",
              delete: "Ta bort bild",
              deleted_successfully: "Bilden har tagits bort",
            },
          },
          priceLists: {
            actions: {
              add: "Lägg till prislista",
              disable: "Inaktivera prislista",
            },
          },
          supplierIntegrationJobStats: {
            singular: "Jobbstatistik",
            plural: "Jobbstatistik",
            attributes: {
              id: "id",
              total: "Totala produkter",
              type: "Typ",
              success: "Framgångsrika importerade produkter",
              failed: "Misslyckade produkter",
              unchanged: "Oförändrade produkter",
              stats: "Statistik",
              status: "Status",
              restarted_at: "Startade om kl",
              no_of_tries: "Antal försök",
              created_at: "Skapad",
              updated_at: "Senast uppdaterad",
            },
          },
          productReferenceImages: {
            singular: "Produktreferens Bild",
            plural: "Produktreferens Bilder",
            attributes: {
              image: "Bild",
              colors: "Färger",
              description: "Beskrivning",
              marking_method: "Märkningsmetod",
            },
            actions: {
              index: "Ladda produktreferensbilder",
              addColor: "Lägg till färg på en produktreferensbild",
              removeColor: "Ta bort färg från en produktreferensbild",
              addMarkingMethod:
                "Lägg till märkningsmetod till en produktreferensbild",
              removeMarkingMethod:
                "Ta bort märkningsmetoden från en produktreferensbild",
              uploadNewImages: "Lägger till nya produktreferensbilder",
              removal: "Ta bort produktens referensbild",
            },
          },
          breadcrumbs: {
            home: "Hem",
            dashboard: "Instrumentbräda",
            products: "Produkter",
            attributes: "Attribut",
            tags: "Taggar",
            colors: "Färger",
            countries: "Länder",
            suppliers: "Leverantörer",
            categories: "Kategorier",
            brands: "Varumärken",
            campaigns: "Kampanjer",
            markingMethods: "Märkningsmetoder",
            startingCosts: "Startkostnader",
            currencies: "Valutor",
            markingTemplates: "Markeringsmallar",
            homepage: "Hemsida",
            user: "Användare",
            units: "Enheter",
            standards: "Standarder",
            supplierProducts: "Leverantörsprodukter",
            questions: "Frågor",
            supplierIntegrationJobStats: "Jobbstatistik för leverantörsintegration",
          },
          TopMenu: {
            customers: "Kunder",
            suppliers: "Leverantörer",
            products: "Produkter",
            hiddenProducts: "Hidden Produkter",
            supplierProducts: "Leverantör Produkter",
            categories: "Kategorier",
            attributes: "Attribut",
            tags: "Taggar",
            improvementReports: "Förbättringsrapporter",
            logisticCarriers: "Speditör",
            queueManagement: "Köhantering",
            invoices: "Fakturor",
            invoiceFollowUp: "Fakturor Uppföljning",
            feedback: "Feedback",
            supplierOrders: "Leverantör Ordrar",
            offers: "Offerter",
            unorderedSales: "Obeställda",
            orderConfirmations: "Orderbekräftelser",
            sellerStatistics: "Säljarstatistik",
            claimStatistics: "Reklamationsstatistik",
            myCustomers: "Mina Kunder",
            supplierIntegrationJobStats: "Leverantörsintegrationsjobbstatistik",
            colors: "Färger",
            fileArchive: "Filarkiv",
            startingCosts: "Startkostnader",
            priceLists: "Prislistor",
            markings: "Märkningar",
            brands: "Märken",
            certifications: "Certifikat",
            awards: "Awards",
            printingMethods: "Tryckmetoder",
            announcements: "Announcements",
            emailTemplates: "E-postmallar",
            faq: "Frågor och svar",
            orderTemplates: "Ordermallar",
            homepage: "Startsida",
            blog: "Blog",
            pages: "Sidor",
            slackBots: "Slackbots",
            currencies: "Valutor",
            units: "Enheter",
            standards: "Standarder",
            countries: "Länder",
            campaigns: "Kampanjer",
            feedbackQuestions: "Feedbackfrågor",
            salesTargets: "Försäljningsmål",
            users: "Användare",
            userProfile: "Mina uppgifter",
            personalDiscounts: "Personalrabatter",
            internalPages: "Interna sidor",
            logOut: "Logga ut",
            panel: "Panel",
          },
          validations: {
            required: "Värde krävs.",
            email: "Ogiltigt e-postformat.",
            greater_than_zero: "Det bör vara större än 0",
            greater_than_zero_helper_text: "Ange ett tal som är större än eller lika med 0.",
          },
        },
      },
    },
    interpolation: {
      format: (value, format, lng) => {
        if (format === "number") {
          return new Intl.NumberFormat(lng, {
            style: "decimal",
            useGrouping: false,
            minimumFractionDigits: 1,
          }).format(value);
        } else if (format === "currency") {
          return new Intl.NumberFormat(lng, {
            style: "currency",
            currency: "SEK",
          }).format(value);
        } else if (format === "revNumber") {
          return value.replace(",", ".");
        }
      },
      escapeValue: false,
    },
    react: {
      wait: false,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default i18n;
