import React from 'react';

import { Paper } from '@mui/material'

const styles = {
  common: {
    borderRadius: '8px',
    position: 'relative',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', // Center the image
    height: 0
  },
  homepageFixed: {
    backgroundSize: 'cover',
    paddingBottom: '56.25%',
  },
  horizontal_image: {
    backgroundSize: 'cover',
    paddingTop: '100%',
    backgroundPosition: 'center', // Center the image
    display: 'flex',
    alignItems: 'center',
  },
  banner: {
    backgroundSize: 'cover',
    backgroundPosition: 'center', // Center the image
    paddingBottom: '133.33%',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: '#000',
    textAlign: 'left',
    padding: '12px 12px 12px 12px',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: '#000000',
    padding: '12px 12px 12px 12px',
  },
  label: {
    display: 'inline-block',
    marginLeft: '12px',
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    borderRadius: '5px',
  }
};

const CampaignCard = ({ campaign }) => {
  let style;
  let content;

  const getBackgroundImage = () => {
    return campaign.image_url || campaign.image_preview_url ? `url(${campaign.image_preview_url || campaign.image_url})` : 'none';
  };

  const getCommonContent = () => (
    <div style={styles.content}>
      <h2 dangerouslySetInnerHTML={{ __html: campaign.content }} />
    </div>
  );

  const getLabelContent = () => (
    <div style={styles.bannerContent}>
      <h1 dangerouslySetInnerHTML={{ __html: campaign.content }} />
      {campaign.label && <div style={styles.label} dangerouslySetInnerHTML={{ __html: campaign.label }}/>}
    </div>
  )


  switch (campaign.layout) {
    case 'homepage':
      let campaignStyle = campaign.fixed ? styles.homepageFixed : styles.horizontal_image
      style = { backgroundImage: getBackgroundImage(), ...styles.common, ...campaignStyle };
      content = getCommonContent();
      break;
    case 'banner':
      style = { backgroundImage: getBackgroundImage(), ...styles.common, ...styles.banner };
      content = getLabelContent();
      break;
    case 'grid':
      style = { backgroundImage: getBackgroundImage(), ...styles.common, ...styles.horizontal_image };
      content = getCommonContent();
      break;
    default:
      style = {};
      break;
  }

  return (
    <Paper sx={{...style}}>
      {content}
    </Paper>
  );
};

export default CampaignCard;
