import HttpClient from '@httpClient'
import { setUrlForGetRequest } from '@api/SharedFunctions'

const SUPPLIER_API = '/suppliers'

const getSuppliers = (params) => {
  return HttpClient.get(setUrlForGetRequest(SUPPLIER_API, params))
}

const getSupplier = (id) => {
  return HttpClient.get(`${SUPPLIER_API}/${id}`)
}

//Encapsulating in a JSON object
const SupplierApi = {
  getSupplier,
  getSuppliers,
}

export {SupplierApi}
