// utils/errorUtils.js

export const handleValidationErrors = (error, setValidationErrors, setAlert) => {
  if (error && error.status === 422) {
    // Extract validation errors from the response
    const validationErrors = error.validation_errors;
    const formattedErrors = {};
    
    // Format errors as { field: "error message" }
    for (const [field, messages] of Object.entries(validationErrors)) {
      formattedErrors[field] = messages.join(", ");
    }

    // Set server-side validation errors in state
    setValidationErrors((prevErrors) => ({ ...prevErrors, ...formattedErrors }));
  } else {
    setAlert({ severity: "error", text: error.message });
  }
};
