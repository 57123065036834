import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { FormControl, TextField, Grid, Fab, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import HttpClient from "@httpClient";
import CreatableAsyncAutocomplete from "@common/CreatableAsyncAutoComplete";
import InlineLoader from "@common/Loaders/InlineLoader";
import PopupAlert from "@contexts/PopupAlert";
import ProductImages from "./ProductImages";
import ColorChip from "../../colors/ColorChip";
import useAlert from '@customHooks/useAlert'
import { handleValidationErrors } from '@utils/errorUtils';
import { useValidation } from '@customHooks/useValidation';

const ColorGroup = (props) => {
  const [colorGroup, setColorGroup] = useState(props.colorGroup);
  const [colors, setColors] = useState(props.colorGroup.colors);
  const [updating, setUpdating] = useState(false);
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);
  const [alert, setAlert] = useAlert(props.created);

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: colorGroup.name, rules: { required: true } },
  });

  const customPosition = props.index + 1

  const saveChange = () => {
    setUpdating(true);
    if (colorGroup.id && colorGroup.id !== -1) {
      HttpClient.put(
        `/products/${props.productId}/color_groups/${colorGroup.id}`,
        colorGroup,
      ).then((res) => {
        setColorGroup(res);
        setUpdating(false);
        addPopupAlert(
          t("colorGroups.actions.add"),
          "success",
          t("colorGroups.actions.addedSuccessfully"),
        );
      })
      .catch(error => handleValidationErrors(error, setValidationErrors, setAlert))
    } else {
      HttpClient.post(
        `/products/${props.productId}/color_groups`,
        colorGroup,
      ).then((res) => {
        setColorGroup(res);
        setUpdating(false);
      })
      .catch(error => handleValidationErrors(error, setValidationErrors, setAlert))
    }
  };

  const handleColorUpdate = (color) => {
    const _colors = [...colors];
    const index = _colors.findIndex((c) => c.id === color.id);
    _colors[index] = color;
    setColors([..._colors]);
  };

  const addColor = (color) => {
    const _colors = [...colors, color];
    setUpdating(true);
    HttpClient.post(`/products/${props.productId}/color_groups/colors`, {
      colors: _colors.map((c) => c.id),
      color_group_id: colorGroup.id,
    }).then(() => {
      setUpdating(false);
      setColors(_colors);
      addPopupAlert(
        t("colors.actions.add"),
        "success",
        t("colors.actions.added_successfully"),
      );
    });
  };

  const deleteColor = (id) => {
    const _colors = colors.filter((c) => c.id !== id);
    if (id < 0) {
      setColors(_colors);
    } else {
      setUpdating(true);
      HttpClient.post(`/products/${props.productId}/color_groups/colors`, {
        colors: _colors.map((c) => c.id),
        color_group_id: colorGroup.id,
      }).then(() => {
        setUpdating(false);
        setColors(_colors);
        addPopupAlert(
          t("colors.actions.delete"),
          "success",
          t("colors.actions.deleted_successfully"),
        );
      });
    }
  };

  const handleDropdownChange = (event) => {
    const newPosition = parseInt(event.target.value, 10);
    if (!isNaN(newPosition) && newPosition !== customPosition) {
      setTimeout(() => {
        props.handleMoveToPosition(props.colorGroup, newPosition);
      }, 500);
    }
  }

  return (
    <li className="list-group-item list-group-item-action">
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Select
            value={customPosition}
            onChange={handleDropdownChange}
            displayEmpty
            size="small"
            renderValue={(selected) => {return selected;}}
          >
            {[...Array(props.colorGroupsLength).keys()].map((i) => (
              <MenuItem key={i + 1} value={i + 1}>
            {i + 1}
              </MenuItem>
              ))}
              </Select>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <TextField
              disabled={props.supplierProductsView || updating}
              value={colorGroup.name || ""}
              name="name"
              onChange={(e) =>
                setColorGroup({
                  ...colorGroup,
                  name: e.currentTarget.value,
                })
              }
              onBlur={saveChange}
              error={!!validationErrors.name}
              helperText={validationErrors.name}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Grid container>
            <Grid item xs={12}>
              <CreatableAsyncAutocomplete
                optionsUrl="/colors?filter"
                onChange={addColor}
                exclude={colors}
                onCreateOption={addColor}
                createUrl="/colors"
                resource="color"
                disabled={updating}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: "5px" }}>
            {colors?.map((color) => (
              <ColorChip
                key={color.id}
                supplierProductsView={props.supplierProductsView}
                color={color}
                onSave={handleColorUpdate}
                onDelete={() => {
                  window.confirm("Är du säker?") && deleteColor(color.id);
                }}
                disabled={updating}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <ProductImages
            supplierProductsView={props.supplierProductsView}
            key={colorGroup.id}
            productId={props.productId}
            colorGroupId={colorGroup.id}
            productImages={colorGroup.product_images}
            formOptions={props.formOptions}
          />
        </Grid>
        <Grid container item xs={1} justify="flex-end">
          {updating ? (
            <InlineLoader />
          ) : (
            <Fab
              onClick={() => {
                if (window.confirm("Är du säker?"))
                  props.onDelete(colorGroup.id);
              }}
              disabled={props.supplierProductsView}
              color="secondary"
              size="small"
            >
              <DeleteIcon />
            </Fab>
          )}
        </Grid>
      </Grid>
    </li>
  );
};

ColorGroup.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  colorGroup: PropTypes.object.isRequired,
  formOptions: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ColorGroup;
