// components/useValidation.js

import { useState } from 'react';
import { validateField } from '@utils/validationUtils';

export const useValidation = (fields) => {
  const [validationErrors, setValidationErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    for (const field in fields) {
      const { value, rules } = fields[field];
      const error = validateField(field, value, rules);
      if (error) newErrors[field] = error;
    }

    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  return { validationErrors, validate, setValidationErrors };
};
