import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { Container, Grid, Paper, Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@mui/styles'

import { CampaignApi } from '@api/CampaignApi'
import HttpClient from '@httpClient'

import TitleBar from '@components/topbar/TitleBar'
import Campaign from '@components/campaigns/Campaign'
import Loader from '@common/Loaders/LoaderList'
import ListTable from '@common/ListingTable/ListTable'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { CampaignSorting } from '@config/SortingLabels'
import { getSerialNumber } from '@components/SharedComponentFunctions'

import useListingActions from '@customHooks/useListingActions'

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto'
  }
})

const List = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    loading,
    sortOptionSelected,
    handleSearchingAndSortingChange,
    setData,
    setCount,
    setPage,
  } = useListingActions('campaigns', CampaignApi.getCampaigns, 'latest' );

  const removeCampaign = (id) => {
    if(window.confirm('Är du säker?')) {
      HttpClient.delete(`/campaigns/${id}`).then(() => {
        const newData = data.filter(c => c.id !== id);
        setData(newData)
        setCount(count - 1)

        // If the number of items on the current page is less than the current perPage after deletion, decrease the page number if needed
        if (newData.length === 0 && page > 1) {
          setPage(page - 1);
          handleSearchingAndSortingChange('page', page - 1);
        } else {
          handleSearchingAndSortingChange('page', page);
        }
      })
      .catch(error => {
        console.error('Error fetching and setting the campaigns:', error);
      })
    }
  }

  const campaignTableContent = () => {
    const campaignBody = data?.map((campaign, index) => (
      <Campaign
        key={campaign.id}
        campaign={campaign}
        deleteCampaign={removeCampaign}
        index={getSerialNumber(page, perPage, index)}
      />
    ));
    return { body: campaignBody, heading: ['', 'Name', 'URL', 'Layout', 'Bild', 'Publik', 'Fast'] }
  }

  const tableContent = campaignTableContent()

  return (
    <React.Fragment>
      <TitleBar title="Kampanjer">
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push('/campaigns/new')} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        <Grid container>
          <ListTableActions
            searchTerm={searchTerm}
            sortOptionSelected={sortOptionSelected}
            sortOptions={CampaignSorting}
            searchText={t('attributes.placeholders.search_label')}
            getData={handleSearchingAndSortingChange}
          />
        </Grid>
        {
          loading ? <Loader/> :
            <Paper square className={classes.root}>
              <ListTable
                tableHeadings={tableContent.heading}
                tableContent={tableContent.body}
                count={count}
                pageNo={page}
                rowsPerPage={perPage}
                onPageChange={(event, pageNo) => handleSearchingAndSortingChange('page', pageNo + 1)}
                handleChangeRowsPerPage={(event) => handleSearchingAndSortingChange('pageSize', event.target.value)}
                rowsControl={true}/>
            </Paper>
        }
      </Container>
    </React.Fragment>
  )
}

List.propTypes = {
  history: PropTypes.object
}

export default List
