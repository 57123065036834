import React from "react";
import { Button } from "@mui/material";
import InlineLoader from "@components/common/Loaders/InlineLoader";

const LoadingButton = ({
  label,
  onClick,
  variant = "outlined",
  isLoading = false,
  ...rest
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      style={{ position: "relative" }}
      disabled={isLoading}
      {...rest}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
          }}
        >
          <InlineLoader size={16} />
        </div>
      )}
      <span>{label}</span>
      {/* Wrapped the label in span due to google translate issue. https://github.com/facebook/react/issues/11538#issuecomment-390386520 */}
    </Button>
  );
};

export default LoadingButton;
