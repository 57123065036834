import React from 'react'
import PropTypes from 'prop-types'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Link } from 'react-router-dom'

const TitleBar = props => {
  const classes = makeStyles(theme => ({
    root: {
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }))()

  return (
    <AppBar position="static" color="default" className={classes.root}>
      <Container>
        <Grid container>
          <Grid container item xs={6} alignItems="center">
            <Typography variant="h5" component="span">{props.title}</Typography>
          </Grid>
          <Grid container item xs={6} alignItems="center" justifyContent="flex-end">
            {props.backButton ?
              <IconButton
                component={Link}
                to={props.backUrl}
              >
                <ArrowBack />
              </IconButton>
              : null
            }
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  )
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  backButton: PropTypes.bool,
  backUrl: PropTypes.string
}

export default TitleBar
