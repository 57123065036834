import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";
import StatusChip from "@components/supplier_integration_job_stats/StatusChip";

const JobStatsRow = ({
  id,
  type,
  total,
  success,
  failed,
  unchanged,
  created_at,
  updated_at,
  restarted_at,
  status,
  no_of_tries,
}) => {
  return (
    <TableRow key={id}>
      <TableCell align="left" sx={{whiteSpace: 'nowrap'}}>
        <Link to={`/supplier-integration-job-stats/${id}`}>{created_at}</Link>
      </TableCell>
      <TableCell align="left">{type}</TableCell>
      <TableCell align="left" sx={{whiteSpace: 'nowrap'}}>{restarted_at}</TableCell>
      <TableCell align="left" sx={{whiteSpace: 'nowrap'}}>{updated_at}</TableCell>
      <TableCell align="left">
        <StatusChip status={status} />
      </TableCell>
      <TableCell align="left">{no_of_tries}</TableCell>
      <TableCell align="left">{total}</TableCell>
      <TableCell align="left">{success}</TableCell>
      <TableCell align="left">{failed}</TableCell>
      <TableCell align="left">{unchanged}</TableCell>
    </TableRow>
  );
};

JobStatsRow.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  success: PropTypes.number.isRequired,
  failed: PropTypes.number.isRequired,
  unchanged: PropTypes.number.isRequired,
  created_at: PropTypes.instanceOf(Date).isRequired,
  updated_at: PropTypes.instanceOf(Date).isRequired,
  restarted_at: PropTypes.instanceOf(Date),
  status: PropTypes.string.isRequired,
  no_of_tries: PropTypes.number.isRequired,
};

export default JobStatsRow;
