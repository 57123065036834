import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { InputLabel, Select, MenuItem, Typography } from "@mui/material";
import FileInput from "@components/common/FileInput";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@mui/material/Grid";
import FormLabel from "@material-ui/core/FormLabel";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LoadingButton from "@components/common/LoadingButton";
import PopupAlert from "@contexts/PopupAlert";
import { buildFormData, parseErrorMessage } from "@src/utils/sharedHelper";
import { ProductApi } from "@src/apis";

const FormModal = ({
  open,
  productId,
  attachment,
  onSave,
  onClose,
  markingMethods,
}) => {
  const { t } = useTranslation();
  const addPopupAlert = useContext(PopupAlert);
  const actionTitle = `${attachment ? t("actions.edit") : t("actions.new")} ${t(
    "productAttachments.name",
  )}`;

  const saveAttachment = async (values) => {
    let api = null;

    const formData = new FormData();
    buildFormData(formData, values);

    if (attachment) {
      api = ProductApi.editAttachment(attachment.id, productId, formData, true);
    } else {
      api = ProductApi.newAttachment(productId, formData, true);
    }
    return api
      .then((res) => {
        addPopupAlert(actionTitle, "success");
        formik.resetForm({
          values: attachment ?? {},
        });
        onSave(res);
      })
      .catch((error) => {
        addPopupAlert(actionTitle, "error", parseErrorMessage(error.message));
      });
  };

  const formik = useFormik({
    initialValues: attachment ?? {},
    onSubmit: saveAttachment,
    validate: (values) => {
      const errors = {};
      if (!values.name)
        errors.name = t("productAttachments.formValidation.name.presence");
      return errors;
    },
  });

  const { values, errors, handleChange, setFieldValue, isSubmitting } = formik;

  useEffect(() => {
    formik.resetForm({
      values: attachment ?? {},
    });
  }, [attachment]);

  const fileJsx = () => {
    if (!!values.file_uid) {
      return (
        <Grid container alignItems="center">
          <span>{values.original_file_name}</span>
          <IconButton
            color="secondary"
            onClick={(e) => setFieldValue("file_uid", "")}
            sx={{ height: "15px", width: "15px", ml: 2 }}
            disabled={isSubmitting}
          >
            <CancelIcon />
          </IconButton>
        </Grid>
      );
    }

    if (!!values.file) {
      return (
        <Grid container alignItems="center">
          <span>{values.file.name}</span>
          <IconButton
            color="secondary"
            onClick={() => setFieldValue("file", null)}
            sx={{ height: "15px", width: "15px", ml: 2 }}
            disabled={isSubmitting}
          >
            <CancelIcon />
          </IconButton>
        </Grid>
      );
    } else {
      return (
        <FileInput
          onChange={(e) => setFieldValue("file", e.target.files[0])}
          accept="image/*,application/pdf"
        />
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{actionTitle}</DialogTitle>

      <DialogContent>
        <FormControl margin="normal" fullWidth>
          <TextField
            fullWidth
            label={t("productAttachments.attributes.name")}
            value={values.name ?? ""}
            error={!!errors.name}
            onChange={handleChange}
            name="name"
            variant="outlined"
          />
          {!!errors.name && (
            <Typography color="error" variant="p">
              {errors.name}
            </Typography>
          )}
        </FormControl>

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel id="Type">
            {t("productAttachments.attributes.type")}
          </InputLabel>
          <Select
            fullWidth
            labelId="Type"
            name="type"
            value={values.type ?? ""}
            onChange={handleChange}
            label={t("productAttachments.attributes.type")}
          >
            <MenuItem value="printing_template">
              {t("productAttachments.attributes.typeOptions.printing_template")}
            </MenuItem>
            <MenuItem value="color_guide">
              {t("productAttachments.attributes.typeOptions.color_guide")}
            </MenuItem>
            <MenuItem value="size_guide">
              {t("productAttachments.attributes.typeOptions.size_guide")}
            </MenuItem>
          </Select>
        </FormControl>

        {values.type === "printing_template" && (
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="marking_method">
              {t("marking_methods.plural")}
            </InputLabel>
            <Select
              fullWidth
              labelId="marking_method"
              name="marking_method.id"
              value={values.marking_method?.id ?? ""}
              onChange={handleChange}
              label={t("marking_methods.plural")}
            >
              <MenuItem value="">
                <em>{t("vocabulary.none")}</em>
              </MenuItem>
              {markingMethods?.map((mm) => (
                <MenuItem key={mm.id} value={mm.id}>
                  {mm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl variant="outlined" fullWidth margin="normal">
          <FormLabel>{t("vocabulary.file")}</FormLabel>
        </FormControl>

        {fileJsx()}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          <span>{t("actions.close")}</span>
        </Button>
        <LoadingButton
          onClick={formik.submitForm}
          isLoading={isSubmitting}
          variant="contained"
          label={t("actions.save")}
        />
      </DialogActions>
    </Dialog>
  );
};

FormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  productId: PropTypes.string.isRequired,
  attachment: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormModal;
