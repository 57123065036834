import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'

import { Button, TableCell, TableRow, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'

import LoadingButton from '@common/LoadingButton'

const useStyles = makeStyles({
  indexColumn: {
    width: '50px'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
})

const Tag = ({ id, name, products_count, deleteTag, index, convertToCategory, actionLoading }) => {
  const history = useHistory()
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row" className={classes.indexColumn}><Link to={`/admin/collections/edit/${id}`}>{index}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/tags/${id}/edit`}> {name}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/tags/${id}/products`}> {products_count}</Link></TableCell>
      <TableCell component="th" scope="row">
        <LoadingButton
          label={t('tags.actions.convert_to_category')}
          onClick={() => {if (window.confirm(t('alertTexts.confirmation'))) convertToCategory(id)} }
          isLoading={actionLoading === id }
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.cursorPointer} onClick={() => history.push(`/tags/${id}/add_products`)}>
        <Button variant={'outlined'}>{t('tags.actions.add_products')}</Button>
      </TableCell>
      <TableCell align="left">
        <Link to={`/tags/${id}/edit`}>
          <IconButton color="primary" size="small">
            <EditIcon/>
          </IconButton>
        </Link>
        <IconButton color="secondary" size="small" onClick={() => { if (window.confirm(t('alertTexts.confirmation'))) deleteTag(id) } }>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

Tag.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  products_count: PropTypes.number.isRequired,
  deleteTag: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  convertToCategory: PropTypes.func.isRequired,
  actionLoading: PropTypes.number
}

export default Tag
