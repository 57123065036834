import React from "react";
import PropTypes from "prop-types";

import { Grid, TextField, FormControl, Typography, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import Mdivider from "@components/common/Mdivider";
import PriceList from "@components/products/printing/PriceList";
import PrintOptions from "@components/products/printing/PrintOptions";

const Template = ({
  markingTemplate,
  productId,
  onDelete,
  supplierProductsView,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <TextField
              fullWidth
              disabled={supplierProductsView || true}
              label="Namn"
              value={markingTemplate.name}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <TextField
              fullWidth
              disabled={supplierProductsView || true}
              label="Tryckmetod"
              value={markingTemplate.marking_method.name}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <TextField
              fullWidth
              disabled={supplierProductsView || true}
              label="Leverantör"
              value={markingTemplate.supplier.name}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item container xs={1} alignItems={'center'}>
          <IconButton
            color="secondary"
            disabled={supplierProductsView}
            onClick={() => onDelete(markingTemplate.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 1, mb: 1 }}>
        <PrintOptions markingTemplate={markingTemplate} productId={productId} />
      </Grid>

      <Grid container>
        <Typography variant="subtitle1" element="h5">
          Startkostnad
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth variant="outlined">
              <TextField
                fullWidth
                disabled={supplierProductsView || true}
                margin="normal"
                label="In"
                value={markingTemplate.starting_cost?.in_price}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth variant="outlined">
              <TextField
                fullWidth
                disabled={supplierProductsView || true}
                margin="normal"
                label="Out"
                value={markingTemplate.starting_cost?.out_price}
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {markingTemplate.marking_price_lists.length ? (
          <>
            <Typography variant="subtitle1" element="h5">
              Markningpriser
            </Typography>
            <PriceList
              key={
                markingTemplate.marking_price_lists[0].id ||
                markingTemplate.marking_price_lists[0].kid
              }
              price_list={markingTemplate.marking_price_lists[0]}
              marking_template_id={markingTemplate.id}
              supplierProductsView={supplierProductsView}
            />
            {markingTemplate.marking_price_lists.length > 1 ? (
              <Grid item xs={12}>
                <FormControl margin="normal">
                  <Button
                    disabled={supplierProductsView}
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      (window.location.href = `/marking_templates/${markingTemplate.id}/edit`)
                    }
                  >
                    {" "}
                    Fler listor
                  </Button>
                </FormControl>
              </Grid>
            ) : null}
          </>
        ) : null}
      </Grid>
      <Mdivider />
    </>
  );
};

Template.propTypes = {
  markingTemplate: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Template;
