import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TextField, FormControl, TextareaAutosize, FormControlLabel, Switch } from '@mui/material'
import { SelectInput } from '@common'

const Form = ({ question, setQuestion, categories, types }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <FormControl fullWidth margin="normal">
        <TextField
          name={'question'}
          label={t('questions.fieldLabels.question')}
          value={question.question || ''}
          onChange={setQuestion}
          variant="outlined"
        />
      </FormControl>
      <SelectInput
        name={'category'}
        label={t('questions.fieldLabels.category')}
        onChange={setQuestion}
        value={question.category}
        valuesArray={categories}
      />
      <SelectInput
        name={'type'}
        label={t('questions.fieldLabels.type')}
        onChange={setQuestion}
        value={question.type}
        valuesArray={types}
      />
      <FormControl fullWidth variant="outlined" margin="normal">
        <FormControlLabel
          control={
            <Switch
              name={'public'}
              checked={String(question.public) === String(true)}
              onChange={setQuestion}
              value={question.public}
              color="primary"
            />
          }
          label={t('questions.fieldLabels.public')}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextareaAutosize
          name={'answer'}
          onChange={setQuestion}
          value={question.answer || ''}
          minRows={3}
        />
      </FormControl>
    </React.Fragment>
  )
}

Form.propTypes = {
  question: PropTypes.object.isRequired,
  setQuestion: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired
}

export default Form
