import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Box, TextField } from '@mui/material'

const QuantityPrice = props => {
  const { t } = useTranslation()
  const reverseFormatPrice = val => t('reverseFormattedNum', { num: val })

  return(
    <Box flex="1 1 0" marginRight={0.5}>
      <TextField
        fullWidth
        disabled={props.supplierProductsView}
        value={props.quantityPrice.price || ''}
        onChange={e => props.setQuantityPrice({ ...props.quantityPrice, price: reverseFormatPrice(e.currentTarget.value) })}
        margin="normal"
        variant="outlined" />
    </Box>
  )
}

QuantityPrice.propTypes = {
  quantityPrice: PropTypes.object.isRequired,
  setQuantityPrice: PropTypes.func.isRequired
}

export default QuantityPrice
