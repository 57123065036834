import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../HttpClient'
import Form from './Form'

const Edit = (props) => {
  const initialState = { name: '', unit: '', symbol: ''}
  if(props.match.params.id) {
    initialState.id = props.match.params.id
  }
  const [currency, updateCurrency] = useState(initialState)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if(props.match.params.id) {
      setLoading(true)
      HttpClient.get(`/currencies/${currency.id}`)
        .then((data) => {
          updateCurrency(data)
          setLoading(false)
        })
        .catch(error => setLoading(false))
    }
  },[currency.id, props.match.params.id])
  
  return(
    <Form created={props.location?.state?.created} currency={currency} loading={loading} history={props.history}/>
  )
}
Edit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default Edit