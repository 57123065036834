import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'

import { TextField, Switch, Typography, Chip, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    borderBottom: "1px solid #e0e0e0",
    marginBottom: theme.spacing(2),
  },
  mainRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  chip: {
    maxWidth: "60%",
    border: "1px solid #e0e0e0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  endActions: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  label: {
    fontWeight: 500,
  },
  icon: {
    backgroundColor: "#000",
    color: "#fff !important",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      color: "#fff",
    },
  },
  helpText: {
    marginTop: theme.spacing(0.5),
    fontSize: "0.8rem", // Corrected to remove '!important'
    color: theme.palette.text.secondary,
  },
}));

const Badge = ({ badge: initialBadge, onDelete, handleAddBadge, visibleDisabled }) => {
  const classes = useStyles();
  const [badge, setBadge] = useState(initialBadge);
  const { t } = useTranslation();

  const handleBadgeChange = async (e) => {
    const { name, value, checked, type } = e.target;

    const updatedBadge = {
      ...badge,
      [name]: type === "checkbox" ? checked : value,
    };
    setBadge(updatedBadge);
    handleAddBadge(updatedBadge);
  };

  return (
    <li className={classes.listItem}>
      <div className={classes.mainRow}>
        <Chip
          label={badge.name}
          title={badge.name}
          className={classes.chip}
          size="large"
        />
        <div className={classes.endActions}>
          <Switch
            checked={badge.visible === true}
            onChange={handleBadgeChange}
            name="visible"
            color="primary"
            disabled={visibleDisabled}
          />
          <CloseIcon
            className={classes.icon}
            onClick={() => onDelete(badge.id)}
          />
        </div>
      </div>
      {badge.visible === true && (
        <>
          <div>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={3} style={{ textAlign: "center" }}>
                <div>
                  <Typography>{t("badges.date_fields_label")}</Typography>
                </div>
                <div className={classes.helpText}>
                  {t("badges.date_fields_help_text")}
                </div>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="start-date"
                  name="start_date"
                  type="date"
                  placeholder="Startdatum"
                  value={badge.start_date || ""}
                  variant="outlined"
                  onChange={handleBadgeChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="end-date"
                  name="end_date"
                  type="date"
                  placeholder="Slutdatum"
                  value={badge.end_date || ""}
                  variant="outlined"
                  onChange={handleBadgeChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </li>
  );
};

Badge.propTypes = {
  badge: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    visible: PropTypes.bool,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  handleAddBadge: PropTypes.func.isRequired,
  visibleDisabled: PropTypes.bool.isRequired,
};

export default Badge;
