import i18n from "@src/i18n";

export const hasValues = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== "") {
      return true;
    }
  }
  return false;
};

const parseErrorListToUnorderedList = (errors) => {
  return (
    <ul
      style={{
        margin: 0,
        paddingLeft: "15px",
      }}
    >
      {errors?.map((error) => (
        <li>{error}</li>
      ))}
    </ul>
  );
};

export const parseErrorMessage = (message) => {
  if (typeof message === "undefined" || message === null || message === "") {
    return i18n.t("feedback.contactItDepartment");
  }

  if (Array.isArray(message) && message.length) {
    return parseErrorListToUnorderedList(message);
  }

  if (typeof message === "string" && !!message) {
    return message;
  }

  // Add more parsing methods here
  return message;
};

export const singularize = (word) => {
  const endings = {
    ves: "fe",
    ies: "y",
    i: "us",
    zes: "ze",
    ses: "s",
    es: "e",
    s: "",
  };
  return word.replace(
    new RegExp(`(${Object.keys(endings).join("|")})$`),
    (r) => endings[r],
  );
};

export const truncate = (text, length = 10) => {
  return `${text.substring(0, length)}...`;
};

// COPIED FROM ChatGpt.
// It's a method that recursively creates formData from a nested hash. It is particularly useful when
// we need to send nested params like `attachment.marking_method.id` as `attachment[marking_method][id]`
// to the backend.
export const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    formData.append(parentKey, data);
  }
};
