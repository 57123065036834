import { React, memo } from 'react'
import { Autocomplete, TextField, Grid } from '@mui/material'

const ProductFilters = ({ suppliers, categories, handleSupplierAndCategoryChange }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          disablePortal
          id='supplier'
          size='small'
          name='supplier-filter'
          options={suppliers}
          getOptionLabel={(supplier) => supplier.name}
          onChange={(e, v) => handleSupplierAndCategoryChange(v, 'supplier')}
          renderInput={(params) => (<TextField {...params} name='supplier-filter' label='Supplier' />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          disablePortal
          id='supplier'
          size='small'
          name='category-filter'
          options={categories}
          getOptionLabel={(category) => category.name}
          onChange={(e, v)=>handleSupplierAndCategoryChange(v, 'category')}
          renderInput={(params) => (<TextField {...params} name='category-filter' label='Category' />
          )}
        />
      </Grid>
    </Grid>
  )
}

ProductFilters.propTypes = {

}

export default memo(ProductFilters)
