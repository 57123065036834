import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Container, Grid, IconButton } from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'

import TitleBar from '../topbar/TitleBar'
import Mdivider from '../common/Mdivider'
import HttpClient from '../HttpClient'
import Values from './Values'
import LoaderShow from '../common/Loaders/LoaderShow'
import Alert from '@mui/material/Alert'
import useAlert from '@customHooks/useAlert'
import SelectInput from '@common/SelectInput'
import { useValidation } from '@customHooks/useValidation';
import { handleValidationErrors } from '@utils/errorUtils';

const Form = props => {
  const selectLabel = useRef(null)
  const [selectLabelWidth, setSelectLabelWidth] = useState(0)
  const [startingCost, setStartingCost] = useState(props.startingCost)
  const [alert, setAlert] = useAlert(props.created)
  
  useEffect(() => {
    setSelectLabelWidth(selectLabel.current?.offsetWidth)
    const _startingCost = { ...props.startingCost }
    _startingCost.currency = _startingCost.currency && _startingCost.currency.id ? _startingCost.currency : startingCost.currency // don't update starting cost if it is null or empty. Will be set to first currency below
    setStartingCost(_startingCost)
  },[props.startingCost])

  const { validationErrors, validate, setValidationErrors } = useValidation({
    name: { value: startingCost.name, rules: { required: true } }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveStartingCost();
    }
  };

  const saveStartingCost = () => {
    if(startingCost.id) {
      HttpClient.put(`/starting_costs/${startingCost.id}`, { starting_cost: startingCost })
        .then(() => setAlert({ severity: 'success', text: 'Updated successfully!' }))
        .catch(error => handleValidationErrors(error, setValidationErrors, setAlert) )
    } else {
      HttpClient.post('/starting_costs', { starting_cost: startingCost })
        .then((res) => {
          props.history.push({
            pathname: `/starting_costs/${res.id}/edit`,
            state: { created: true }
          })
        })
        .catch(error => handleValidationErrors(error, setValidationErrors, setAlert))
    }
  }
  
  const startingCostValues = startingCost.id ?
    <Values startingCost={startingCost} />
    :
    null

  return(
    <React.Fragment>
      <TitleBar title={`${startingCost.id ? 'Radigera' : 'Ny'} startkostnad`}>
        <IconButton
          component={Link}
          to="/starting_costs"
          aria-label="edit">
          <ArrowBack />
        </IconButton>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>{alert.text}</Alert>
        )}
        {props.loading ? <LoaderShow /> : (
          <Grid container>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Namn"
                    value={startingCost.name || ''}
                    onChange={e => setStartingCost({ ...startingCost, name: e.currentTarget.value })}
                    variant="outlined"
                    error={!!validationErrors.name}
                    helperText={validationErrors.name} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Beskrivning"
                    value={startingCost.description || ''}
                    onChange={e => setStartingCost({ ...startingCost, description: e.currentTarget.value })}
                    variant="outlined" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel ref={selectLabel} htmlFor="type"></InputLabel>
                  <SelectInput
                    label='Typ'
                    onChange={(e) => setStartingCost({ ...startingCost, type: e.target.value })}
                    valuesArray={props.costTypes}
                    value={startingCost.type}
                    propertyName='label'
                    identifier='value'
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="number"
                    label="Pris"
                    value={startingCost.price !== null ? startingCost.price : ''}
                    onChange={e => setStartingCost({ ...startingCost, price: e.currentTarget.value })}
                    variant="outlined" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Spara</Button>
              </Grid>
            </Grid>
            <Mdivider />
            {startingCostValues}
          </Grid>
        )}
      </Container>
    </React.Fragment>
  )
}
Form.propTypes = {
  startingCost: PropTypes.object.isRequired,
  costTypes: PropTypes.array.isRequired,
  history: PropTypes.object,
  loading: PropTypes.bool
}

export default Form
