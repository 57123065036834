import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CreatableSelect from 'react-select/creatable'

import HttpClient from '../HttpClient'

const CreatableAutocomplete = props => {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    HttpClient.get(props.optionsUrl).then(res => {
      let data = res.data ? res.data : res
      let _options = data.map(a => {
        return { label: a.name, value: a.id, ...a }
      })
      setOptions([...options, ..._options])
    })
  }, [])

  const handleChange = opt => {
    props.onChange(opt)
  }

  const handleCreate = inputValue => {
    setIsLoading(true)
    const postData = props.resource ? { [props.resource]: { name: inputValue } } : { name: inputValue }
    HttpClient.post(props.createUrl, postData)
      .then(response => {
        setIsLoading(false)
        setOptions([...options, { label: response.name, value: response.id, ...response }])
        props.onCreateOption(response)
      })
      .catch(error => console.error(error))
  }

  const filteredOptions = () => {
    return (props.exclude && props.exclude.length) ? options.filter(o => !props.exclude.some(e => e.id === o.id)) : options
  }
  
  const customStyles = {
    menu: provided => ({
      ...provided,
      zIndex: 2,
    }),
  }

  return (
    <CreatableSelect
      styles={customStyles}
      placeholder={'Välj...'}
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={filteredOptions()}
    />
  )
}

CreatableAutocomplete.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  exclude: PropTypes.array,  // if want to exclude some options
  onCreateOption: PropTypes.func.isRequired,
  createUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired
}

export default CreatableAutocomplete
