import React from 'react';
import Button from "@mui/material/Button";
import { useTranslation } from 'react-i18next';

const PrimarySupplierButton = ({ listType, supplier, productId, handleMarkAsPrimary, markAsPrimaryPrintingSupplier }) => {
  const { t } = useTranslation();

  const actionHandlers = {
    suppliers: () => handleMarkAsPrimary(supplier.price_list_id, supplier.price_list_row_id),
    printingSuppliers: () => markAsPrimaryPrintingSupplier(productId, supplier.supplier.id),
  };
  const shouldRenderButton = {
    suppliers: !supplier.primary_price_list_row,
    printingSuppliers: !supplier.primary_printing_supplier_id,
  };

  if (shouldRenderButton[listType]) {
    return (
      <Button
        variant="outlined"
        onClick={actionHandlers[listType]}
      >
        {t('suppliers.markAsPrimary')}
      </Button>
    );
  }

  return null;
};

export default PrimarySupplierButton;

