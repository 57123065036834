import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TableCell,
  TableRow,
  Container,
  Fab,
  Paper,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'

import ListTable from '@common/ListingTable/ListTable'
import { getUserPreference, setUserPreference } from '@utils/LocalStorageHelper/LocalStorageMethods'
import ImageThumbnail from '@shared/ImageThumbnail'
import { ProductSorting } from '@config/SortingLabels'
import ListTableActions from '@common/ListingTable/ListTableActions'
import { useDebounce } from '@customHooks/useDebouncing'
import TitleBar from '@components/topbar/TitleBar'
import { TagApi } from '@api/TagApi'
import LoaderList from '@common/Loaders/LoaderList'
import LoadingButton from '@common/LoadingButton'

const useStyles = makeStyles( {
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '15px'
  },
  checkboxColumn: {
    width: '150px'
  },
  table: { width: '100%' },
})

const TagProducts = props => {
  const tagProductsPerPage = getUserPreference( 'page_size', 'tagProductPage')
  const [tagProducts, setTagProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( tagProductsPerPage !== '' ? tagProductsPerPage : 20)
  const [tagProductsCount, setTagProductsCount] = useState(0)
  const [sortOptionSelected, setSortOptionSelected] = useState('latest')
  const [searchTerm, setSearchTerm] = useState('')
  const [tag, setTag] = useState({name: ''})
  const [actionLoading, setActionLoading] = useState([])

  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const classes = useStyles()
  const params = useParams()
  const { t } = useTranslation()

  useEffect( () => {
    const tagId = params.hasOwnProperty('id') ? +params.id : false
    if (tagId){
      let filterApplied = Object.keys(tag).length > 1
      getProducts(debouncedSearchTerm, sortOptionSelected, 1, perPage, filterApplied)
    }
  }, [debouncedSearchTerm])

  const handleSearchingAndSortingChange = (type, value) => {
    if (type === 'sort'){
      setSortOptionSelected(value)
      getProducts(searchTerm, value, 1, perPage)
    } else if(type === 'search') {
      setSearchTerm(value)
    } else if(type === 'page'){
      setPage(value)
      getProducts(searchTerm, sortOptionSelected, value, perPage)
    } else if (type === 'pageSize'){
      setPerPage(value)
      setPage(1)
      setUserPreference('page_size', 'tagProductPage', value)
      getProducts(searchTerm, sortOptionSelected, 1, value)
    }
  }

  const getProducts = async (searchQuery, sortOption, pageNo, perPageCount, filterApplied = true) => {
    setLoading(true)
    TagApi.tagProducts(params.id, { query: searchQuery, page: pageNo, per_page: perPageCount, sort_products_by: sortOption, sorting_changed: filterApplied }).then(response => {
      setTag(response.tag)
      !filterApplied && setSortOptionSelected(response.tag.sort_products_by)
      setTagProducts(response.tag_products)
      setTagProductsCount(response.total_count)
      setLoading(false)
    }).catch( err => {
      setLoading(false)
      console.log(err)
    })
  }

  const removeProducts = async (productIds = selectedProducts) => {
    try{
      setActionLoading(productIds)
      await TagApi.removeTagProducts(params.id, productIds)
      setActionLoading([])
      let products = tagProducts.filter(prod => !productIds.includes(prod.uid))
      setTagProducts([...products])
      setSelectedProducts([])
    } catch (e) {
      setActionLoading([])
      console.log(err)
    }
  }

  const handleSelectedProducts = (event, product) => {
    let products = [...selectedProducts]
    if (event.target.checked) {
      products.push(product.uid)
    } else {
      products = products.filter(prod => prod !== product.uid)
    }
    setSelectedProducts([...products])
  }

  const bulkAction = () => (
    selectedProducts.length > 0 &&
    <LoadingButton
      label={`${t('tags.actions.remove')} (${selectedProducts.length})`}
      onClick={() => {if(window.confirm(t('alertTexts.confirmation'))) removeProducts()}}
      isLoading={actionLoading.length > 1}
    />
  )

  const tagsTableContent = () => {
    const tagsBody = tagProducts?.map((product) => (
      <TableRow key={product.uid}>
        <TableCell className={classes.checkboxColumn}>
          <input
            type='checkbox'
            name="visible"
            style={{ cursor: 'pointer' }}
            checked={selectedProducts.indexOf(product.uid) !== -1}
            onChange={(event) => {
              handleSelectedProducts(event, product)
            }}/>
        </TableCell>
        <TableCell><ImageThumbnail imageUrl={product.image_url}/></TableCell>
        <TableCell><a href={`${window.location.origin}/products/${product.uid}`}>{product.name}</a></TableCell>
        <TableCell align="center">
          <LoadingButton
            label={t('tags.actions.remove')}
            onClick={() => {if(window.confirm(t('alertTexts.confirmation'))) removeProducts([product.uid])}}
            isLoading={actionLoading.includes(product.uid)}
          />
        </TableCell>
      </TableRow>
    ));
    return { body: tagsBody, heading: [bulkAction(), t('tags.tableHeadings.image'), t('tags.tableHeadings.name'), ''] }
  }

  const tableContent = tagsTableContent()

  return (
    <>
      <TitleBar title={`${tag.name} ${t('products.title')}`}>
        <Fab color="primary" size="small" aria-label="add" onClick={() => { props.history.push(`/tags/${params.id}/add_products`)} }>
          <AddIcon />
        </Fab>
      </TitleBar>
      <Container>
        <ListTableActions
          searchTerm={searchTerm}
          sortOptionSelected={sortOptionSelected}
          sortOptions={ProductSorting}
          searchText={t('products.placeholders.search_products')}
          getData={handleSearchingAndSortingChange}
        />
        { loading ? <LoaderList/> :
          <Paper square className={classes.root}>
            <ListTable
              tableHeadings={tableContent.heading}
              tableContent={tableContent.body}
              count={tagProductsCount}
              pageNo={page}
              rowsPerPage={perPage}
              onPageChange={(event, pageNo) => handleSearchingAndSortingChange('page', pageNo + 1)}
              handleChangeRowsPerPage={(event) => handleSearchingAndSortingChange('pageSize', event.target.value)}
              rowsControl={true}
            />
          </Paper>
        }
      </Container>
    </>
  )
}

export default TagProducts
