import React, { useState, useEffect, useRef, createRef } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";

import TitleBar from "../topbar/TitleBar";
import HttpClient from "../HttpClient";
import Mdivider from "../common/Mdivider";

import MarkingPriceList from "./MarkingPriceList";
import LoaderShowDouble from "../common/Loaders/LoaderShowDouble";
import Alert from "@mui/material/Alert";
import useAlert from "@customHooks/useAlert";
import { MarkingTemplateApi } from "@api/MarkingTemplateApi";

const Form = (props) => {
  const [markingTemplate, setMarkingTemplate] = useState(
    props.state.marking_template,
  );
  const [alert, setAlert] = useAlert(props.created);

  useEffect(() => {
    setMarkingTemplate(props.state.marking_template);
  }, [props.state.marking_template]);

  const numberOfSelect = 2;
  const selectLabels = useRef(
    [...Array(numberOfSelect)].map(() => createRef()),
  );
  const [selectLabelsWidth, setSelectLabelsWidth] = useState([
    ...Array(numberOfSelect),
  ]);
  useEffect(() => {
    setSelectLabelsWidth(
      selectLabels.current.map((innerElRef) => innerElRef.current.offsetWidth),
    );
  }, []);

  const handleChange = (e) => {
    const _markingTemplate = {
      ...markingTemplate,
      [e.target.name]: { id: e.currentTarget.value },
    };
    if (_markingTemplate.marking_method.id && _markingTemplate.supplier.id) {
      // loadStartingCosts(_markingTemplate.marking_method.id, _markingTemplate.supplier.id)
      // setMarkingTemplate(_markingTemplate)
      HttpClient.get(
        `/marking_methods/${_markingTemplate.marking_method.id}/starting_cost_values?supplier_id=${_markingTemplate.supplier.id}`,
      )
        .then((res) => {
          let startingCost = { ..._markingTemplate.starting_cost };
          startingCost.in_price = res.in_price;
          startingCost.out_price = res.out_price;
          setMarkingTemplate({
            ..._markingTemplate,
            starting_cost: startingCost,
          });
        })
        .catch((error) => console.error("Error getting starting cost", error));
    } else {
      setMarkingTemplate(_markingTemplate);
    }
  };

  const loadStartingCosts = (markingMethodId, supplierId) => {
    HttpClient.get(
      `/marking_methods/${markingMethodId}/starting_cost_values?supplier_id=${supplierId}`,
    )
      .then((res) => {
        let startingCost = { ...markingTemplate.starting_cost };
        startingCost.in_price = res.in_price;
        startingCost.out_price = res.out_price;
        setMarkingTemplate({ ...markingTemplate, starting_cost: startingCost });
      })
      .catch((error) => console.error("Error getting starting cost", error));
  };

  const saveMarkingTemplate = () => {
    if (markingTemplate.id) {
      MarkingTemplateApi.updateMarkingTemplate(markingTemplate.id, {
        marking_template: markingTemplate,
      })
        .then((res) =>
          setAlert({ severity: "success", text: "Updated successfully!" }),
        )
        .catch((error) => setAlert({ severity: "error", text: error.message }));
    } else {
      MarkingTemplateApi.createMarkingTemplate({
        marking_template: markingTemplate,
      })
        .then((res) => {
          props.history.push({
            pathname: `/marking_templates/${res.id}/edit`,
            state: { created: true },
          });
        })
        .catch((error) => setAlert({ severity: "error", text: error.message }));
    }
  };

  const addMarkingPriceList = () => {
    const markingPriceLists = [
      ...markingTemplate.marking_price_lists,
      {
        name: "",
        no_of_colors: "",
        kid: markingTemplate.marking_price_lists.length + 1,
        marking_prices: [
          {
            our_price: "",
            customer_price: "",
            quantity: "",
            kid: 1,
          },
        ],
      },
    ];
    setMarkingTemplate({
      ...markingTemplate,
      marking_price_lists: markingPriceLists,
    });
  };

  const handlePriceListDelete = (id) => {
    if (window.confirm("Är du säker?")) {
      const _markingPriceLists = markingTemplate.marking_price_lists.filter(
        (m) => m.id !== id,
      );
      HttpClient.delete(
        `/marking_templates/${markingTemplate.id}/marking_price_lists/${id}`,
      )
        .then(() =>
          setMarkingTemplate({
            ...markingTemplate,
            marking_price_lists: _markingPriceLists,
          }),
        )
        .catch((error) =>
          console.error("Error deleting marking price list", error),
        );
    }
  };

  const markingPriceLists = markingTemplate.id ? (
    <>
      <Mdivider />
      <Grid container>
        <Typography variant="subtitle1" element="h3">
          Markningpriser
        </Typography>
        {markingTemplate.marking_price_lists.map((priceList) => (
          <MarkingPriceList
            key={priceList.id || priceList.kid}
            markingPriceList={priceList}
            markingTemplateId={markingTemplate.id}
            onDelete={handlePriceListDelete}
            selectedNoOfColors={props.selectedNoOfColors}
            setSelectedNoOfColors={props.setSelectedNoOfColors}
            maximum_colors={markingTemplate.maximum_colors}
          />
        ))}
      </Grid>
      <Mdivider />
      <Grid container>
        <Grid item xs={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={addMarkingPriceList}
          >
            Ny Lista
          </Button>
        </Grid>
      </Grid>
    </>
  ) : null;

  return (
    <>
      <TitleBar title={`${markingTemplate.id ? "Redigera" : "Ny"} Markning`}>
        <Fab
          color="secondary"
          size="small"
          aria-label="add"
          onClick={() => {
            props.history.push("/marking_templates");
          }}
        >
          <ArrowBack />
        </Fab>
      </TitleBar>
      <Container>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>
            {alert.text}
          </Alert>
        )}
        {props.loading ? (
          <LoaderShowDouble />
        ) : (
          <Grid container>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <FormControl margin="normal" fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    label="Namn"
                    value={markingTemplate.name}
                    onChange={(e) =>
                      setMarkingTemplate({
                        ...markingTemplate,
                        name: e.currentTarget.value,
                      })
                    }
                    variant="outlined"
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    label="Label"
                    value={markingTemplate.label}
                    onChange={(e) =>
                      setMarkingTemplate({
                        ...markingTemplate,
                        label: e.currentTarget.value,
                      })
                    }
                    variant="outlined"
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth variant="outlined">
                  <TextField
                    type="number"
                    fullWidth
                    label="Max Färger"
                    value={markingTemplate.maximum_colors}
                    onChange={(e) => {
                      if (!!e.currentTarget.value) {
                        const maxColors = parseInt(e.currentTarget.value);
                        props.setSelectedNoOfColors(
                          props.selectedNoOfColors.filter(
                            (n) => n <= maxColors,
                          ),
                        );
                      }

                      setMarkingTemplate({
                        ...markingTemplate,
                        maximum_colors: e.currentTarget.value,
                      });
                    }}
                    variant="outlined"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel
                    ref={selectLabels.current[0]}
                    htmlFor="marking_method"
                  >
                    Tryckmetod
                  </InputLabel>
                  <Select
                    native
                    value={`${
                      markingTemplate.marking_method &&
                      markingTemplate.marking_method.id
                    }`}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        name="marking_method"
                        id="marking_method"
                        labelWidth={selectLabelsWidth[0]}
                      />
                    }
                  >
                    {props.state.marking_methods.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl margin="normal" fullWidth variant="outlined">
                  <InputLabel ref={selectLabels.current[1]} htmlFor="supplier">
                    Leverantör
                  </InputLabel>
                  <Select
                    native
                    value={`${markingTemplate.supplier.id}`}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        name="supplier"
                        id="supplier"
                        labelWidth={selectLabelsWidth[1]}
                      />
                    }
                  >
                    {props.state.suppliers.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl margin="normal" fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    label="Leverantörskod"
                    value={markingTemplate.code || ""}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" element="h3">
                  Startkostnader
                </Typography>
                <FormControl margin="normal" fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    readOnly
                    label="In"
                    value={markingTemplate.starting_cost?.in_price}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    readOnly
                    label="Out"
                    value={markingTemplate.starting_cost?.out_price}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Mdivider />
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={saveMarkingTemplate}
              >
                Spara
              </Button>
            </Grid>
            {markingPriceLists}
          </Grid>
        )}
      </Container>
    </>
  );
};
Form.propTypes = {
  history: PropTypes.object,
  state: PropTypes.object,
  loading: PropTypes.bool,
  selectedNoOfColors: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedNoOfColors: PropTypes.func,
};

export default Form;
