import React, { useState, useContext } from 'react'
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AlertContext } from "./List";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Backdrop,
  Button,
  Grid,
  IconButton,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { SupplierProductApi } from "@api";

import SearchInputWithView from "@shared/SearchInputWithView";
import { useTranslation } from "react-i18next";
import InlineLoader from "@components/common/Loaders/InlineLoader";
import { searchTypes } from '@config/constants'

const useStyles = makeStyles({
  headerContainer: {
    paddingLeft: "2%",
    paddingRight: "1%",
  },
  float_r: {
    float: "right",
  },
});

const CategoryModal = ({
  closeModal,
  supplierProduct,
  setProductSupplierState,
}) => {

  let _selectedCategory = supplierProduct.linked_category_id ? {
    id: supplierProduct.linked_category_id,
    name: supplierProduct.linked_category_name,
    image_url: supplierProduct.linked_category_image_url
  } : null

  const classes = useStyles();
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertContext);

  const [newCategoryView, setNewCategoryView] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(_selectedCategory);
  const [linkSupplierCategory, setLinkSupplierCategory] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const createProduct = async () => {
    try {
      setShowBackdrop(true);
      const data = {};
      data.link_to_category = linkSupplierCategory;
      if (newCategoryView) {
        data.category_creation_type = "new";
      } else {
        data.category_creation_type = "existing";
        data.linked_category_id = selectedCategory?.id;
      }
      await SupplierProductApi.createNewProduct(supplierProduct.id, data);
      setProductSupplierState(supplierProduct.id, "merged");
      setAlert({
        severity: "success",
        text: t("supplierProducts.alerts.product_creation_success"),
      });
      setShowBackdrop(false);
      closeModal();
    } catch (e) {
      setShowBackdrop(false);
      closeModal();
      console.log(e);
    }
  };

  return (
    <Paper sx={{ padding: "10px" }}>
      <Grid container item alignItems="center" className={classes.headerContainer}>
        <Grid item xs={11}>
          <h3>{t("supplierProducts.modals.category_modal.heading")}</h3>
        </Grid>
        <Grid item xs={1}>
          <IconButton className={classes.float_r} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {supplierProduct.category_name && (
          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
            <strong>
              {t("supplierProducts.labels.supplier_product_category")}:{" "}
            </strong>{" "}
            {supplierProduct.category_name}
          </Grid>
        )}
      </Grid>
      <Box className={classes.headerContainer}>
        {supplierProduct.category_name && (
          <>
            <RadioGroup
              className={classes.headerContainer}
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={newCategoryView}
              name="row-radio-buttons-group"
              value={newCategoryView}
              onChange={(e) => setNewCategoryView(e.target.value === 'true')}
            >
              <FormControlLabel
                value={'true'}
                control={<Radio />}
                // No need to allow users to create new category when there is already
                // a category linked with the same name so that means we have already a
                // category in our system that has the same name as this supplier category
                disabled={(supplierProduct.linked_category_id) && (supplierProduct.linked_category_name === supplierProduct.category_name)}
                label={t("supplierProducts.labels.create_new_category")}
              />
              <FormControlLabel
                value={'false'}
                control={<Radio />}
                label={t("supplierProducts.labels.link_to_existing_category")}
              />
            </RadioGroup>
          </>
        )}
        {!newCategoryView &&
          <>
            <SearchInputWithView
              type={searchTypes.categorySearch}
              searchInputLabel={t('categories.placeholders.search_by_name')}
              setSelectedItem={(value) => {setSelectedCategory(value)}}
              selectedItem={selectedCategory}
            />
          </>
        }
        {(supplierProduct.linked_category_id) && (
          <p>
            { supplierProduct.linked_category_name === supplierProduct.category_name
              ? t(
                  "supplierProducts.modals.category_modal.messages.already_created_new"
                )
              : t(
                  "supplierProducts.modals.category_modal.messages.already_linked_to_existing"
                )}{" "}
            i-e{" "}
            <Link to={`/categories/${supplierProduct.linked_category_id}/edit`}>
              {supplierProduct.linked_category_name}
            </Link>
          </p>
        )}
        {
          supplierProduct?.category_id && newCategoryView &&
          <FormControlLabel
            className={classes.headerContainer}
            sx={{ marginTop: "15px" }}
            control={
              <Checkbox
                checked={linkSupplierCategory}
                onChange={(e) => setLinkSupplierCategory(!linkSupplierCategory)}
                color="primary"
              />
            }
            label={t("supplierProducts.modals.category_modal.messages.link_products_to_new_category", { category_name: supplierProduct?.category_name })
            }
          />
        }
        {
          supplierProduct?.category_id && !newCategoryView && selectedCategory?.id && selectedCategory?.id !== supplierProduct?.linked_category_id &&
          <FormControlLabel
            className={classes.headerContainer}
            sx={{ marginTop: "15px" }}
            control={
              <Checkbox
                checked={linkSupplierCategory}
                onChange={(e) => setLinkSupplierCategory(!linkSupplierCategory)}
                color="primary"
              />
            }
            label={t(
              "supplierProducts.modals.category_modal.messages.link_to_existing_category",
              {
                category_name: supplierProduct.category_name,
                selected_category_name: selectedCategory.name,
              }
            )
            }
          />
        }
      </Box>
      <hr />
      <Grid container justifyContent={"flex-end"}>
        <Grid item>
          <Button
            disabled={!newCategoryView && !selectedCategory}
            variant={"contained"}
            onClick={createProduct}
          >
            {t("supplierProducts.modals.category_modal.actions.create_product")}
          </Button>
        </Grid>
      </Grid>
      <Backdrop open={showBackdrop}>
        <InlineLoader size={40} color="inherit" />
      </Backdrop>
    </Paper>
  );
};

CategoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  supplierProduct: PropTypes.object.isRequired,
  setProductSupplierState: PropTypes.func.isRequired,
};

export default CategoryModal;
